<template>
  <div class="load-view" id="loadingArea">
    <div class="spinner-border" style="width: 3rem; height: 3rem" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <a
    data-bs-toggle="modal"
    id="show_bank_modal"
    hidden
    data-bs-target="#bankShow"
    class="btn default-radius"
  ></a>
  <div
    class="modal fade"
    id="bankShow"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="bankShowLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="bankShowLabel">請輸入您的收款銀行帳號</h5>
        </div>
        <div class="modal-body">
          <div class="mb-3 row">
            <div class="col-12">
              <div class="row mb-1">
                <label class="col-lg-3 col-md-12 y-center text-start"
                  >銀行戶名</label
                >
                <div class="col-lg-9 col-md-12">
                  <input
                    type="text"
                    class="form-control-dealer-member"
                    style="line-height: 30px"
                    v-model="bank_name"
                  />
                </div>
              </div>
              <div class="row mb-1">
                <label class="col-lg-3 col-md-12 y-center text-start"
                  >銀行名稱</label
                >
                <div class="col-lg-9 col-md-12">
                  <BankTemplate
                    class="form-control-dealer-member"
                    style="margin: 0px !important"
                    v-model:defaultData="bankData"
                  />
                </div>
              </div>
              <div class="row mb-1">
                <label class="col-lg-3 col-md-12 y-center text-start"
                  >分行名稱</label
                >
                <div class="col-lg-9 col-md-12">
                  <BranchTemplate
                    class="form-control-dealer-member"
                    style="margin: 0px !important"
                    v-model:defaultData="bankData"
                  />
                </div>
              </div>
              <div class="row mb-1">
                <label class="col-lg-3 col-md-12 y-center text-start"
                  >銀行帳號</label
                >
                <div class="col-lg-9 col-md-12">
                  <input
                    type="text"
                    class="form-control-dealer-member"
                    style="line-height: 30px"
                    v-model="bank_account"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <a @click="send_bank()" class="btn default-radius"> 送出 </a>
        </div>
      </div>
    </div>
  </div>
  <router-view />
<noscript><img height="1" width="1" style="display:none"
  src="https://www.facebook.com/tr?id=513222684225735&ev=PageView&noscript=1"
/></noscript>
<noscript><img height="1" width="1" style="display:none"
src="https://www.facebook.com/tr?id=352484080554185&ev=PageView&noscript=1"
/></noscript>

</template>
<script>
import BankTemplate from "@/components/ItemsGroup/BankGroup/BankTemplate.vue";
import BranchTemplate from "@/components/ItemsGroup/BankGroup/BranchTemplate.vue";
import {
  isbindpost,
  freshRefcode,
  subMallCheckOut,
  mallCheckOut,
  isBankpost,
  updateBank,
} from "./api";
import { ref, reactive } from "vue";
export default {
  name: "App",
  metaInfo: {
    title: "Default App Title",
    titleTemplate: "%s | vue-meta Example App",
  },
  components: {
    BankTemplate,
    BranchTemplate,
  },
  setup() {
    const bankData = reactive({
      bankCode: "",
      branchCode: "",
      bankName: "",
      branchName: "",
    });

    return {
      bankData,
    };
  },
  data() {
    return {
      bank_name: "",
      bank_account: "",
    };
  },
  mounted() {
    window.onload = function () {
      try {
        const myCollapsible = document.querySelector("#cartCollapse");

        myCollapsible.addEventListener("show.bs.collapse", () => {
          const cartMask = document.getElementById("cartMask");
          cartMask.style.display = "block";
          cartMask.addEventListener("click", function () {
            cartMask.style.display = "none";
            myCollapsible.className = "collapse-horizontal collapse";
          });
        });
      } catch (e) {
        console.log(e);
      }
      window.addEventListener("scroll", () => {
        let navbarHeight = "";
        if (
          navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
          )
        ) {
          navbarHeight = document.getElementById("navbarmobile");
        } else {
          navbarHeight = document.getElementById("navbar");
        }
        try {
          const headerNewsHeight =
            document.getElementById("headerNews").clientHeight;
          const navSpaceHeight = document.getElementById("navSpace");
          const scrollPos = window.scrollY;
          if (scrollPos >= headerNewsHeight) {
            navSpaceHeight.style.height = navbarHeight.clientHeight + "px";
            navbarHeight.className = " navbar bg-light fixed-top";
          } else {
            navSpaceHeight.style.height = "0px";
            navbarHeight.className = " navbar bg-light ";
          }
        } catch (e) {
          console.log(e);
        }
      });
      setTimeout(function () {
        document.getElementById("loadingArea").style.display = "none";
        try {
          var img = document.querySelectorAll(".ck-content img");
          for (var i = 0; i < img.length; i++) {
            if (img[i].width + 30 >= screen.width) {
              img[i].style = "height:auto;max-width:100%";
            }
          }
        } catch (e) {
          console.log(e);
        }
        try {
          var input = document.querySelectorAll(".ck-content input");
          for (var j = 0; j < input.length; j++) {
            if (input[j].width + 30 >= screen.width) {
              input[j].style = "height:auto;max-width:100%";
            }
          }
        } catch (e) {
          console.log(e);
        }
      }, 500);
    };
    this.rebind();
    this.checkMall();
  },
  methods: {
    gotop() {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
    async rebind() {
      let swalClass = this.$siteClass;
      const self = this;
      // await isbindpost().then(async (res) => {
      //     if (res.data.status) {
      //       const { value: refcode } = await this.$swal.fire({
      //         customClass: this.$siteClass,
      //         title: "尚未綁定推薦人",
      //         input: "text",
      //         inputAttributes: {
      //           autocapitalize: "off",
      //           maxlength: 10,
      //           autocorrect: "off",
      //         },
      //         inputPlaceholder: "請填寫推薦碼",
      //         scrollbarPadding: false,
      //         icon: "warning",
      //         showCancelButton: false,
      //         allowOutsideClick: false,
      //         confirmButtonColor: "#3085d6",
      //         cancelButtonColor: "#d33",
      //         confirmButtonText: "立即綁定",
      //       });
      //       await freshRefcode({ refcode: refcode }).then(async (res) => {
      //         if (res.data.status) {
      //           this.alertS(res.data.message, "/");
      //         } else {
      //           this.$swal
      //             .fire({
      //               customClass: swalClass,
      //               icon: "error",
      //               title: "錯誤",
      //               confirmButtonText: "確認",
      //               text: res.data.message,
      //               timer: 2000,
      //             })
      //             .then(function () {
      //               self.rebind();
      //             });
      //         }
      //       });
      //     }
      // }).catch((err) => {
      //   console.log(err);
      // });
      await isBankpost()
        .then(async (res) => {
          if (res.data.status) {
            document.getElementById("show_bank_modal").click();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    send_bank() {
      let swalClass = this.$siteClass;
      const self = this;
      let postData = {
        bank_user_name: this.bank_name,
        bank_name: this.bankData.bankName,
        bank_code: this.bankData.bankCode,
        bank_branch: this.bankData.branchName,
        branch_code: this.bankData.branchCode,
        bank_account: this.bank_account,
      };
      updateBank(postData)
        .then(async (res) => {
          if (res.data.status) {
            this.alertS(res.data.message, "/");
          } else {
            this.$swal
              .fire({
                customClass: swalClass,
                icon: "error",
                title: "錯誤",
                confirmButtonText: "確認",
                text: res.data.message,
                timer: 2000,
              })
              .then(function () {
                self.rebind();
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkMall() {
      // if (localStorage.getItem("isLiff") != "1") {
        if (sessionStorage.getItem("subMallStr")) {
          const shop_id = sessionStorage.getItem("subMallStr").split("_")[1];
          subMallCheckOut({ shop_id: shop_id })
            .then((res) => {
              if (!res.data.status) {
                if (res.data.data.shop_id === 1) {
                  localStorage.setItem("isLiff", "0");
                  window.location.href = `/`;
                } else {
                  localStorage.setItem("isLiff", "0");
                  window.location.href = `/shop_${res.data.data.shop_id}/`;
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          mallCheckOut()
            .then((res) => {
              if (!res.data.status) {
                localStorage.setItem("isLiff", "0");
                window.location.href = `/shop_${res.data.data.shop_id}/`;
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      // }
    },
  },
};
</script>
<style lang="scss">
#app {
  font-family: "Microsoft JhengHei", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #333333;
}
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #333333;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.load-view {
  width: 100vw;
  height: 100vh;
  display: block;
  z-index: 99999999999;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.vs__search {
  margin: 0px !important;
  font-size: 16px !important;
}

.vs__selected {
  margin: 0px !important;
  font-size: 16px !important;
}
</style>
