export const bankData = [
  {
    bankCodeString: '0040000',
    bankCode: '004',
    bankName: '臺灣銀行',
    totalName: '004 ╴臺灣銀行'
  },
  {
    bankCodeString: '0050000',
    bankCode: '005',
    bankName: '臺灣土地銀行',
    totalName: '005 ╴臺灣土地銀行'
  },
  {
    bankCodeString: '0060000',
    bankCode: '006',
    bankName: '合作金庫商業銀行',
    totalName: '006 ╴合作金庫商業銀行'
  },
  {
    bankCodeString: '0070000',
    bankCode: '007',
    bankName: '第一商業銀行',
    totalName: '007 ╴第一商業銀行'
  },
  {
    bankCodeString: '0080000',
    bankCode: '008',
    bankName: '華南商業銀行',
    totalName: '008 ╴華南商業銀行'
  },
  {
    bankCodeString: '0090000',
    bankCode: '009',
    bankName: '彰化商業銀行',
    totalName: '009 ╴彰化商業銀行'
  },
  {
    bankCodeString: '0110000',
    bankCode: '011',
    bankName: '上海商業儲蓄銀行',
    totalName: '011 ╴上海商業儲蓄銀行'
  },
  {
    bankCodeString: '0120000',
    bankCode: '012',
    bankName: '台北富邦商業銀行',
    totalName: '012 ╴台北富邦商業銀行'
  },
  {
    bankCodeString: '0130000',
    bankCode: '013',
    bankName: '國泰世華商業銀行',
    totalName: '013 ╴國泰世華商業銀行'
  },
  {
    bankCodeString: '0150000',
    bankCode: '015',
    bankName: '中國輸出入銀行',
    totalName: '015 ╴中國輸出入銀行'
  },
  {
    bankCodeString: '0160000',
    bankCode: '016',
    bankName: '高雄銀行',
    totalName: '016 ╴高雄銀行'
  },
  {
    bankCodeString: '0170000',
    bankCode: '017',
    bankName: '兆豐國際商業銀行',
    totalName: '017 ╴兆豐國際商業銀行'
  },
  {
    bankCodeString: '0180000',
    bankCode: '018',
    bankName: '全國農業金庫',
    totalName: '018 ╴全國農業金庫'
  },
  {
    bankCodeString: '0210000',
    bankCode: '021',
    bankName: '花旗(台灣)商業銀行',
    totalName: '021 ╴花旗(台灣)商業銀行'
  },
  {
    bankCodeString: '0480000',
    bankCode: '048',
    bankName: '王道商業銀行',
    totalName: '048 ╴王道商業銀行'
  },
  {
    bankCodeString: '0500000',
    bankCode: '050',
    bankName: '臺灣中小企業銀行',
    totalName: '050 ╴臺灣中小企業銀行'
  },
  {
    bankCodeString: '0520000',
    bankCode: '052',
    bankName: '渣打國際商業銀行',
    totalName: '052 ╴渣打國際商業銀行'
  },
  {
    bankCodeString: '0530000',
    bankCode: '053',
    bankName: '台中商業銀行',
    totalName: '053 ╴台中商業銀行'
  },
  {
    bankCodeString: '0540000',
    bankCode: '054',
    bankName: '京城商業銀行',
    totalName: '054 ╴京城商業銀行'
  },
  {
    bankCodeString: '0810000',
    bankCode: '081',
    bankName: '滙豐(台灣)商業銀行',
    totalName: '081 ╴滙豐(台灣)商業銀行'
  },
  {
    bankCodeString: '1010000',
    bankCode: '101',
    bankName: '瑞興商業銀行',
    totalName: '101 ╴瑞興商業銀行'
  },
  {
    bankCodeString: '1020000',
    bankCode: '102',
    bankName: '華泰商業銀行',
    totalName: '102 ╴華泰商業銀行'
  },
  {
    bankCodeString: '1030000',
    bankCode: '103',
    bankName: '臺灣新光商業銀行',
    totalName: '103 ╴臺灣新光商業銀行'
  },
  {
    bankCodeString: '1080000',
    bankCode: '108',
    bankName: '陽信商業銀行',
    totalName: '108 ╴陽信商業銀行'
  },
  {
    bankCodeString: '1180000',
    bankCode: '118',
    bankName: '板信商業銀行',
    totalName: '118 ╴板信商業銀行'
  },
  {
    bankCodeString: '1470000',
    bankCode: '147',
    bankName: '三信商業銀行',
    totalName: '147 ╴三信商業銀行'
  },
  {
    bankCodeString: '7011616',
    bankCode: '700',
    bankName: '中華郵政',
    totalName: '700 ╴中華郵政'
  },
  {
    bankCodeString: '8030000',
    bankCode: '803',
    bankName: '聯邦商業銀行',
    totalName: '803 ╴聯邦商業銀行'
  },
  {
    bankCodeString: '8050000',
    bankCode: '805',
    bankName: '遠東國際商業銀行',
    totalName: '805 ╴遠東國際商業銀行'
  },
  {
    bankCodeString: '8060000',
    bankCode: '806',
    bankName: '元大商業銀行',
    totalName: '806 ╴元大商業銀行'
  },
  {
    bankCodeString: '8070000',
    bankCode: '807',
    bankName: '永豐商業銀行',
    totalName: '807 ╴永豐商業銀行'
  },
  {
    bankCodeString: '8080000',
    bankCode: '808',
    bankName: '玉山商業銀行',
    totalName: '808 ╴玉山商業銀行'
  },
  {
    bankCodeString: '8090000',
    bankCode: '809',
    bankName: '凱基商業銀行',
    totalName: '809 ╴凱基商業銀行'
  },
  {
    bankCodeString: '8100000',
    bankCode: '810',
    bankName: '星展(台灣)商業銀行',
    totalName: '810 ╴星展(台灣)商業銀行'
  },
  {
    bankCodeString: '8120000',
    bankCode: '812',
    bankName: '台新國際商業銀行',
    totalName: '812 ╴台新國際商業銀行'
  },
  {
    bankCodeString: '8150000',
    bankCode: '815',
    bankName: '日盛國際商業銀行',
    totalName: '815 ╴日盛國際商業銀行'
  },
  {
    bankCodeString: '8160000',
    bankCode: '816',
    bankName: '安泰商業銀行',
    totalName: '816 ╴安泰商業銀行'
  },
  {
    bankCodeString: '8220000',
    bankCode: '822',
    bankName: '中國信託商業銀行',
    totalName: '822 ╴中國信託商業銀行'
  },
  {
    bankCodeString: '8230000',
    bankCode: '823',
    bankName: '將來商業銀行',
    totalName: '823 ╴將來商業銀行'
  },
  {
    bankCodeString: '8240000',
    bankCode: '824',
    bankName: '連線商業銀行',
    totalName: '824 ╴連線商業銀行'
  },
  {
    bankCodeString: '8260000',
    bankCode: '826',
    bankName: '樂天國際商業銀行',
    totalName: '826 ╴樂天國際商業銀行'
  }
]