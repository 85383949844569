<template>
   <v-select :clearable="false"  v-model="selectBranch.branchCode"   label="totalName" :reduce="(options) => options.branchCode"  placeholder="請選擇分行"
            :options="branchSelect">
   </v-select>
</template>
<script>
import { branchData } from '../../../assets/js/branchCode.js'
import { computed, watch, reactive, ref } from 'vue'

export default {
  props: {
    defaultData: {
      type: Object,
      default: function () {
        return {
          bankCode: '',
          bankName: '',
          branchCode: '',
          branchName: ''
        }
      }
    }
  },
  setup (props, { emit }) {
    const branchArray = branchData
    const branchSelect = ref([])
    const selectBranch = computed({
      get: () => reactive(props.defaultData),
      set: val => emit('update:defaultData', val)
    })

    watch(
      () => selectBranch.value.bankCode,
      (newNum) => {
        selectBranch.value.branchCode = ''
        selectBranch.value.branchName = ''
        branchSelect.value = branchArray[newNum]
      },
      {
        deep: true
      }
    )
    watch(selectBranch, () => {
      const realBranchName = branchArray[selectBranch.value.bankCode].filter(function(value) {
        return value.bankCode == selectBranch.value.bankCode && value.branchCode == selectBranch.value.branchCode
      });
      if(realBranchName.length>0){
          selectBranch.value.branchName = realBranchName[0].branchName
      }
    }, { deep: true });

    return {
      branchArray,
      selectBranch,
      branchSelect
    }
  }
}
</script>