const siteClass = {
  container: '...',
  popup: 'site-popup',
  header: '...',
  title: 'site-title',
  closeButton: 'site-close',
  icon: 'site-icon',
  image: '...',
  content: '...',
  htmlContainer: 'site-html-container',
  input: '...',
  inputLabel: '...',
  validationMessage: '...',
  actions: '...',
  cancelButton: 'site-cancel',
  confirmButton: 'site-confirm',
  denyButton: '',
  loader: '...',
  footer: '....'
}
module.exports = {
  myMixin: {
    methods: {
      alertS (msg, href = '') {
        this.$swal.fire({
          customClass: siteClass,
          icon: 'success',
          title: '成功',
          text: msg
        })
          .then(function () {
            if (href !== '') {
              window.location.href = href
            }
          })
      },
      alertW (msg, href = '') {
        this.$swal.fire({
          customClass: siteClass,
          icon: 'error',
          title: '錯誤',
          confirmButtonText: '確認',
          text: msg
        }).then(function () {
          if (href !== '') {
            window.location.href = href
          }
        })
      },
      alertQ (msg, href = '') {
        this.$swal.fire({
          customClass: siteClass,
          icon: 'question',
          title: '提醒',
          text: msg
        }).then(function () {
          if (href !== '') {
            window.location.href = href
          }
        })
      },
      loadingStart () {
        this.$swal.fire({
          background: 'transparent',
          showCancelButton: false,
          showConfirmButton: false,
          showCloseButton: false,
          reverseButtons: false,
          allowOutsideClick: false,
          backdrop: 'swal2-backdrop-hide',
          html: `<div class="text-center site-loading" >
                      <div class="spinner-border " role="status">
                          <span class="visually-hidden">Loading...</span>
                      </div>
          </div>`
        })
      }
    }
  }
}
