export const branchData = {
  '101': [
    {
      bankCodeString: '1010011',
      bankCode: '101',
      bankName: '瑞興商業銀行',
      branchCode: '0011',
      branchName: '營業部',
      totalName: '0011 ╴營業部'
    },
    {
      bankCodeString: '1010020',
      bankCode: '101',
      bankName: '瑞興商業銀行',
      branchCode: '0020',
      branchName: '民生分行',
      totalName: '0020 ╴民生分行'
    },
    {
      bankCodeString: '1010039',
      bankCode: '101',
      bankName: '瑞興商業銀行',
      branchCode: '0039',
      branchName: '建成分行',
      totalName: '0039 ╴建成分行'
    },
    {
      bankCodeString: '1010048',
      bankCode: '101',
      bankName: '瑞興商業銀行',
      branchCode: '0048',
      branchName: '大橋分行',
      totalName: '0048 ╴大橋分行'
    },
    {
      bankCodeString: '1010057',
      bankCode: '101',
      bankName: '瑞興商業銀行',
      branchCode: '0057',
      branchName: '南京東路分行',
      totalName: '0057 ╴南京東路分行'
    },
    {
      bankCodeString: '1010066',
      bankCode: '101',
      bankName: '瑞興商業銀行',
      branchCode: '0066',
      branchName: '昆明分行',
      totalName: '0066 ╴昆明分行'
    },
    {
      bankCodeString: '1010075',
      bankCode: '101',
      bankName: '瑞興商業銀行',
      branchCode: '0075',
      branchName: '長安分行',
      totalName: '0075 ╴長安分行'
    },
    {
      bankCodeString: '1010084',
      bankCode: '101',
      bankName: '瑞興商業銀行',
      branchCode: '0084',
      branchName: '永吉分行',
      totalName: '0084 ╴永吉分行'
    },
    {
      bankCodeString: '1010093',
      bankCode: '101',
      bankName: '瑞興商業銀行',
      branchCode: '0093',
      branchName: '和平東路分行',
      totalName: '0093 ╴和平東路分行'
    },
    {
      bankCodeString: '1010109',
      bankCode: '101',
      bankName: '瑞興商業銀行',
      branchCode: '0109',
      branchName: '石牌分行',
      totalName: '0109 ╴石牌分行'
    },
    {
      bankCodeString: '1010118',
      bankCode: '101',
      bankName: '瑞興商業銀行',
      branchCode: '0118',
      branchName: '內湖分行',
      totalName: '0118 ╴內湖分行'
    },
    {
      bankCodeString: '1010127',
      bankCode: '101',
      bankName: '瑞興商業銀行',
      branchCode: '0127',
      branchName: '成功簡易型分行',
      totalName: '0127 ╴成功簡易型分行'
    },
    {
      bankCodeString: '1010136',
      bankCode: '101',
      bankName: '瑞興商業銀行',
      branchCode: '0136',
      branchName: '古亭分行',
      totalName: '0136 ╴古亭分行'
    },
    {
      bankCodeString: '1010145',
      bankCode: '101',
      bankName: '瑞興商業銀行',
      branchCode: '0145',
      branchName: '景美分行',
      totalName: '0145 ╴景美分行'
    },
    {
      bankCodeString: '1010154',
      bankCode: '101',
      bankName: '瑞興商業銀行',
      branchCode: '0154',
      branchName: '南港分行',
      totalName: '0154 ╴南港分行'
    },
    {
      bankCodeString: '1010163',
      bankCode: '101',
      bankName: '瑞興商業銀行',
      branchCode: '0163',
      branchName: '信義簡易型分行',
      totalName: '0163 ╴信義簡易型分行'
    },
    {
      bankCodeString: '1010172',
      bankCode: '101',
      bankName: '瑞興商業銀行',
      branchCode: '0172',
      branchName: '萬華分行',
      totalName: '0172 ╴萬華分行'
    },
    {
      bankCodeString: '1010181',
      bankCode: '101',
      bankName: '瑞興商業銀行',
      branchCode: '0181',
      branchName: '士林分行',
      totalName: '0181 ╴士林分行'
    },
    {
      bankCodeString: '1010190',
      bankCode: '101',
      bankName: '瑞興商業銀行',
      branchCode: '0190',
      branchName: '松山簡易型分行',
      totalName: '0190 ╴松山簡易型分行'
    },
    {
      bankCodeString: '1010206',
      bankCode: '101',
      bankName: '瑞興商業銀行',
      branchCode: '0206',
      branchName: '城內分行',
      totalName: '0206 ╴城內分行'
    },
    {
      bankCodeString: '1010215',
      bankCode: '101',
      bankName: '瑞興商業銀行',
      branchCode: '0215',
      branchName: '桃園分行',
      totalName: '0215 ╴桃園分行'
    },
    {
      bankCodeString: '1010224',
      bankCode: '101',
      bankName: '瑞興商業銀行',
      branchCode: '0224',
      branchName: '中和分行',
      totalName: '0224 ╴中和分行'
    },
    {
      bankCodeString: '1010242',
      bankCode: '101',
      bankName: '瑞興商業銀行',
      branchCode: '0242',
      branchName: '信託部',
      totalName: '0242 ╴信託部'
    },
    {
      bankCodeString: '1010251',
      bankCode: '101',
      bankName: '瑞興商業銀行',
      branchCode: '0251',
      branchName: '國外部',
      totalName: '0251 ╴國外部'
    },
    {
      bankCodeString: '1010260',
      bankCode: '101',
      bankName: '瑞興商業銀行',
      branchCode: '0260',
      branchName: '國際金融業務分行',
      totalName: '0260 ╴國際金融業務分行'
    },
    {
      bankCodeString: '1019001',
      bankCode: '101',
      bankName: '瑞興商業銀行',
      branchCode: '9001',
      branchName: '作業管理部',
      totalName: '9001 ╴作業管理部'
    },
    {
      bankCodeString: '1019002',
      bankCode: '101',
      bankName: '瑞興商業銀行',
      branchCode: '9002',
      branchName: '資訊部',
      totalName: '9002 ╴資訊部'
    },
    {
      bankCodeString: '1019003',
      bankCode: '101',
      bankName: '瑞興商業銀行',
      branchCode: '9003',
      branchName: '稽核部',
      totalName: '9003 ╴稽核部'
    },
    {
      bankCodeString: '1019005',
      bankCode: '101',
      bankName: '瑞興商業銀行',
      branchCode: '9005',
      branchName: '行政管理部',
      totalName: '9005 ╴行政管理部'
    },
    {
      bankCodeString: '1019006',
      bankCode: '101',
      bankName: '瑞興商業銀行',
      branchCode: '9006',
      branchName: '人力資源部',
      totalName: '9006 ╴人力資源部'
    },
    {
      bankCodeString: '1019007',
      bankCode: '101',
      bankName: '瑞興商業銀行',
      branchCode: '9007',
      branchName: '業務部',
      totalName: '9007 ╴業務部'
    },
    {
      bankCodeString: '1019008',
      bankCode: '101',
      bankName: '瑞興商業銀行',
      branchCode: '9008',
      branchName: '會計部',
      totalName: '9008 ╴會計部'
    },
    {
      bankCodeString: '1019014',
      bankCode: '101',
      bankName: '瑞興商業銀行',
      branchCode: '9014',
      branchName: '審查部',
      totalName: '9014 ╴審查部'
    },
    {
      bankCodeString: '1019015',
      bankCode: '101',
      bankName: '瑞興商業銀行',
      branchCode: '9015',
      branchName: '債權管理部',
      totalName: '9015 ╴債權管理部'
    },
    {
      bankCodeString: '1019017',
      bankCode: '101',
      bankName: '瑞興商業銀行',
      branchCode: '9017',
      branchName: '財務部',
      totalName: '9017 ╴財務部'
    },
    {
      bankCodeString: '1019018',
      bankCode: '101',
      bankName: '瑞興商業銀行',
      branchCode: '9018',
      branchName: '總經理室',
      totalName: '9018 ╴總經理室'
    },
    {
      bankCodeString: '1019020',
      bankCode: '101',
      bankName: '瑞興商業銀行',
      branchCode: '9020',
      branchName: '風險管理部',
      totalName: '9020 ╴風險管理部'
    },
    {
      bankCodeString: '1019026',
      bankCode: '101',
      bankName: '瑞興商業銀行',
      branchCode: '9026',
      branchName: '財富管理部',
      totalName: '9026 ╴財富管理部'
    },
    {
      bankCodeString: '1019027',
      bankCode: '101',
      bankName: '瑞興商業銀行',
      branchCode: '9027',
      branchName: '法律事務部',
      totalName: '9027 ╴法律事務部'
    },
    {
      bankCodeString: '1019028',
      bankCode: '101',
      bankName: '瑞興商業銀行',
      branchCode: '9028',
      branchName: '法令遵循部',
      totalName: '9028 ╴法令遵循部'
    },
    {
      bankCodeString: '1019030',
      bankCode: '101',
      bankName: '瑞興商業銀行',
      branchCode: '9030',
      branchName: '行銷企劃部',
      totalName: '9030 ╴行銷企劃部'
    }
  ],
  '102': [
    {
      bankCodeString: '1020001',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0001',
      branchName: '作業服務部',
      totalName: '0001 ╴作業服務部'
    },
    {
      bankCodeString: '1020002',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0002',
      branchName: '審查部',
      totalName: '0002 ╴審查部'
    },
    {
      bankCodeString: '1020003',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0003',
      branchName: '債權管理部',
      totalName: '0003 ╴債權管理部'
    },
    {
      bankCodeString: '1020005',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0005',
      branchName: '財務部',
      totalName: '0005 ╴財務部'
    },
    {
      bankCodeString: '1020006',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0006',
      branchName: '資訊部',
      totalName: '0006 ╴資訊部'
    },
    {
      bankCodeString: '1020007',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0007',
      branchName: '總務部',
      totalName: '0007 ╴總務部'
    },
    {
      bankCodeString: '1020008',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0008',
      branchName: '董事會稽核部',
      totalName: '0008 ╴董事會稽核部'
    },
    {
      bankCodeString: '1020009',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0009',
      branchName: '企劃考核部',
      totalName: '0009 ╴企劃考核部'
    },
    {
      bankCodeString: '1020010',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0010',
      branchName: '營業部',
      totalName: '0010 ╴營業部'
    },
    {
      bankCodeString: '1020011',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0011',
      branchName: '會計部',
      totalName: '0011 ╴會計部'
    },
    {
      bankCodeString: '1020012',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0012',
      branchName: '人事室',
      totalName: '0012 ╴人事室'
    },
    {
      bankCodeString: '1020013',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0013',
      branchName: '個金策略部',
      totalName: '0013 ╴個金策略部'
    },
    {
      bankCodeString: '1020014',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0014',
      branchName: '商務金融部',
      totalName: '0014 ╴商務金融部'
    },
    {
      bankCodeString: '1020015',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0015',
      branchName: '授信控管部',
      totalName: '0015 ╴授信控管部'
    },
    {
      bankCodeString: '1020016',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0016',
      branchName: '法人行銷部',
      totalName: '0016 ╴法人行銷部'
    },
    {
      bankCodeString: '1020017',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0017',
      branchName: '理財事業部',
      totalName: '0017 ╴理財事業部'
    },
    {
      bankCodeString: '1020019',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0019',
      branchName: '信用卡中心',
      totalName: '0019 ╴信用卡中心'
    },
    {
      bankCodeString: '1020021',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0021',
      branchName: '風險管理部',
      totalName: '0021 ╴風險管理部'
    },
    {
      bankCodeString: '1020029',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0029',
      branchName: '迪化街分行',
      totalName: '0029 ╴迪化街分行'
    },
    {
      bankCodeString: '1020038',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0038',
      branchName: '建成分行',
      totalName: '0038 ╴建成分行'
    },
    {
      bankCodeString: '1020047',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0047',
      branchName: '大同分行',
      totalName: '0047 ╴大同分行'
    },
    {
      bankCodeString: '1020056',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0056',
      branchName: '中山分行',
      totalName: '0056 ╴中山分行'
    },
    {
      bankCodeString: '1020065',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0065',
      branchName: '大安分行',
      totalName: '0065 ╴大安分行'
    },
    {
      bankCodeString: '1020074',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0074',
      branchName: '松山分行',
      totalName: '0074 ╴松山分行'
    },
    {
      bankCodeString: '1020083',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0083',
      branchName: '古亭分行',
      totalName: '0083 ╴古亭分行'
    },
    {
      bankCodeString: '1020092',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0092',
      branchName: '士林分行',
      totalName: '0092 ╴士林分行'
    },
    {
      bankCodeString: '1020108',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0108',
      branchName: '內湖分行',
      totalName: '0108 ╴內湖分行'
    },
    {
      bankCodeString: '1020117',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0117',
      branchName: '信義分行',
      totalName: '0117 ╴信義分行'
    },
    {
      bankCodeString: '1020126',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0126',
      branchName: '永吉分行',
      totalName: '0126 ╴永吉分行'
    },
    {
      bankCodeString: '1020135',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0135',
      branchName: '和平分行',
      totalName: '0135 ╴和平分行'
    },
    {
      bankCodeString: '1020144',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0144',
      branchName: '光復分行',
      totalName: '0144 ╴光復分行'
    },
    {
      bankCodeString: '1020153',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0153',
      branchName: '文山分行',
      totalName: '0153 ╴文山分行'
    },
    {
      bankCodeString: '1020171',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0171',
      branchName: '石牌分行',
      totalName: '0171 ╴石牌分行'
    },
    {
      bankCodeString: '1020180',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0180',
      branchName: '萬華分行',
      totalName: '0180 ╴萬華分行'
    },
    {
      bankCodeString: '1020199',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0199',
      branchName: '桃園分行',
      totalName: '0199 ╴桃園分行'
    },
    {
      bankCodeString: '1020205',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0205',
      branchName: '松德分行',
      totalName: '0205 ╴松德分行'
    },
    {
      bankCodeString: '1020214',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0214',
      branchName: '新莊分行',
      totalName: '0214 ╴新莊分行'
    },
    {
      bankCodeString: '1020223',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0223',
      branchName: '中和分行',
      totalName: '0223 ╴中和分行'
    },
    {
      bankCodeString: '1020232',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0232',
      branchName: '板橋分行',
      totalName: '0232 ╴板橋分行'
    },
    {
      bankCodeString: '1020241',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0241',
      branchName: '南京東路分行',
      totalName: '0241 ╴南京東路分行'
    },
    {
      bankCodeString: '1020250',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0250',
      branchName: '敦化分行',
      totalName: '0250 ╴敦化分行'
    },
    {
      bankCodeString: '1020269',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0269',
      branchName: '新店分行',
      totalName: '0269 ╴新店分行'
    },
    {
      bankCodeString: '1020278',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0278',
      branchName: '中壢分行',
      totalName: '0278 ╴中壢分行'
    },
    {
      bankCodeString: '1020287',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0287',
      branchName: '高雄分行',
      totalName: '0287 ╴高雄分行'
    },
    {
      bankCodeString: '1020296',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0296',
      branchName: '三重分行',
      totalName: '0296 ╴三重分行'
    },
    {
      bankCodeString: '1020302',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0302',
      branchName: '總部分行',
      totalName: '0302 ╴總部分行'
    },
    {
      bankCodeString: '1020311',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0311',
      branchName: '國外部',
      totalName: '0311 ╴國外部'
    },
    {
      bankCodeString: '1020320',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0320',
      branchName: '信託部',
      totalName: '0320 ╴信託部'
    },
    {
      bankCodeString: '1020339',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0339',
      branchName: '國際金融業務分行',
      totalName: '0339 ╴國際金融業務分行'
    },
    {
      bankCodeString: '1020348',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0348',
      branchName: '台南分行',
      totalName: '0348 ╴台南分行'
    },
    {
      bankCodeString: '1020357',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0357',
      branchName: '北高雄分行',
      totalName: '0357 ╴北高雄分行'
    },
    {
      bankCodeString: '1020366',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0366',
      branchName: '台中分行',
      totalName: '0366 ╴台中分行'
    },
    {
      bankCodeString: '1020375',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0375',
      branchName: '彰化分行',
      totalName: '0375 ╴彰化分行'
    },
    {
      bankCodeString: '1020384',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0384',
      branchName: '北台中分行',
      totalName: '0384 ╴北台中分行'
    },
    {
      bankCodeString: '1020860',
      bankCode: '102',
      bankName: '華泰商業銀行',
      branchCode: '0860',
      branchName: '兼營證券商',
      totalName: '0860 ╴兼營證券商'
    }
  ],
  '103': [
    {
      bankCodeString: '1030001',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0001',
      branchName: '業務拓展部',
      totalName: '0001 ╴業務拓展部'
    },
    {
      bankCodeString: '1030002',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0002',
      branchName: '審查部',
      totalName: '0002 ╴審查部'
    },
    {
      bankCodeString: '1030003',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0003',
      branchName: '消費金融部',
      totalName: '0003 ╴消費金融部'
    },
    {
      bankCodeString: '1030004',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0004',
      branchName: '資訊服務部',
      totalName: '0004 ╴資訊服務部'
    },
    {
      bankCodeString: '1030005',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0005',
      branchName: '債權管理部',
      totalName: '0005 ╴債權管理部'
    },
    {
      bankCodeString: '1030008',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0008',
      branchName: '人力資源部',
      totalName: '0008 ╴人力資源部'
    },
    {
      bankCodeString: '1030009',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0009',
      branchName: '稽核室',
      totalName: '0009 ╴稽核室'
    },
    {
      bankCodeString: '1030010',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0010',
      branchName: '秘書室',
      totalName: '0010 ╴秘書室'
    },
    {
      bankCodeString: '1030011',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0011',
      branchName: '金融市場部',
      totalName: '0011 ╴金融市場部'
    },
    {
      bankCodeString: '1030012',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0012',
      branchName: '信用卡部',
      totalName: '0012 ╴信用卡部'
    },
    {
      bankCodeString: '1030013',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0013',
      branchName: '財會部',
      totalName: '0013 ╴財會部'
    },
    {
      bankCodeString: '1030017',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0017',
      branchName: '總務行政部',
      totalName: '0017 ╴總務行政部'
    },
    {
      bankCodeString: '1030019',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0019',
      branchName: '中正分行',
      totalName: '0019 ╴中正分行'
    },
    {
      bankCodeString: '1030022',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0022',
      branchName: '客戶服務部',
      totalName: '0022 ╴客戶服務部'
    },
    {
      bankCodeString: '1030024',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0024',
      branchName: '個人不動產暨理財授信部',
      totalName: '0024 ╴個人不動產暨理財授信部'
    },
    {
      bankCodeString: '1030025',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0025',
      branchName: '財富管理部',
      totalName: '0025 ╴財富管理部'
    },
    {
      bankCodeString: '1030026',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0026',
      branchName: '風險管理部',
      totalName: '0026 ╴風險管理部'
    },
    {
      bankCodeString: '1030027',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0027',
      branchName: '企業金融部',
      totalName: '0027 ╴企業金融部'
    },
    {
      bankCodeString: '1030028',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0028',
      branchName: '東台北分行',
      totalName: '0028 ╴東台北分行'
    },
    {
      bankCodeString: '1030029',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0029',
      branchName: '電子商務綜合部',
      totalName: '0029 ╴電子商務綜合部'
    },
    {
      bankCodeString: '1030037',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0037',
      branchName: '龍山分行',
      totalName: '0037 ╴龍山分行'
    },
    {
      bankCodeString: '1030046',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0046',
      branchName: '西園分行',
      totalName: '0046 ╴西園分行'
    },
    {
      bankCodeString: '1030055',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0055',
      branchName: '西門分行',
      totalName: '0055 ╴西門分行'
    },
    {
      bankCodeString: '1030064',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0064',
      branchName: '大同分行',
      totalName: '0064 ╴大同分行'
    },
    {
      bankCodeString: '1030073',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0073',
      branchName: '復興分行',
      totalName: '0073 ╴復興分行'
    },
    {
      bankCodeString: '1030082',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0082',
      branchName: '忠孝分行',
      totalName: '0082 ╴忠孝分行'
    },
    {
      bankCodeString: '1030091',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0091',
      branchName: '五常分行',
      totalName: '0091 ╴五常分行'
    },
    {
      bankCodeString: '1030107',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0107',
      branchName: '城北分行',
      totalName: '0107 ╴城北分行'
    },
    {
      bankCodeString: '1030116',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0116',
      branchName: '城內分行',
      totalName: '0116 ╴城內分行'
    },
    {
      bankCodeString: '1030125',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0125',
      branchName: '館前簡易型分行',
      totalName: '0125 ╴館前簡易型分行'
    },
    {
      bankCodeString: '1030134',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0134',
      branchName: '新金湖分行',
      totalName: '0134 ╴新金湖分行'
    },
    {
      bankCodeString: '1030143',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0143',
      branchName: '慶城分行',
      totalName: '0143 ╴慶城分行'
    },
    {
      bankCodeString: '1030152',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0152',
      branchName: '內湖分行',
      totalName: '0152 ╴內湖分行'
    },
    {
      bankCodeString: '1030161',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0161',
      branchName: '世貿分行',
      totalName: '0161 ╴世貿分行'
    },
    {
      bankCodeString: '1030170',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0170',
      branchName: '松山分行',
      totalName: '0170 ╴松山分行'
    },
    {
      bankCodeString: '1030189',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0189',
      branchName: '南港分行',
      totalName: '0189 ╴南港分行'
    },
    {
      bankCodeString: '1030198',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0198',
      branchName: '林森北路分行',
      totalName: '0198 ╴林森北路分行'
    },
    {
      bankCodeString: '1030204',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0204',
      branchName: '大安分行',
      totalName: '0204 ╴大安分行'
    },
    {
      bankCodeString: '1030213',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0213',
      branchName: '新竹分行',
      totalName: '0213 ╴新竹分行'
    },
    {
      bankCodeString: '1030222',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0222',
      branchName: '中壢分行',
      totalName: '0222 ╴中壢分行'
    },
    {
      bankCodeString: '1030231',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0231',
      branchName: '桃園分行',
      totalName: '0231 ╴桃園分行'
    },
    {
      bankCodeString: '1030240',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0240',
      branchName: '東三重分行',
      totalName: '0240 ╴東三重分行'
    },
    {
      bankCodeString: '1030259',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0259',
      branchName: '竹北分行',
      totalName: '0259 ╴竹北分行'
    },
    {
      bankCodeString: '1030268',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0268',
      branchName: '連城路分行',
      totalName: '0268 ╴連城路分行'
    },
    {
      bankCodeString: '1030277',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0277',
      branchName: '竹科分行',
      totalName: '0277 ╴竹科分行'
    },
    {
      bankCodeString: '1030286',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0286',
      branchName: '土城分行',
      totalName: '0286 ╴土城分行'
    },
    {
      bankCodeString: '1030295',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0295',
      branchName: '蘆洲分行',
      totalName: '0295 ╴蘆洲分行'
    },
    {
      bankCodeString: '1030301',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0301',
      branchName: '建成分行',
      totalName: '0301 ╴建成分行'
    },
    {
      bankCodeString: '1030310',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0310',
      branchName: '北三重分行',
      totalName: '0310 ╴北三重分行'
    },
    {
      bankCodeString: '1030324',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0324',
      branchName: '信託部',
      totalName: '0324 ╴信託部'
    },
    {
      bankCodeString: '1030329',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0329',
      branchName: '永和分行',
      totalName: '0329 ╴永和分行'
    },
    {
      bankCodeString: '1030338',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0338',
      branchName: '新埔分行',
      totalName: '0338 ╴新埔分行'
    },
    {
      bankCodeString: '1030347',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0347',
      branchName: '台中分行',
      totalName: '0347 ╴台中分行'
    },
    {
      bankCodeString: '1030356',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0356',
      branchName: '中港分行',
      totalName: '0356 ╴中港分行'
    },
    {
      bankCodeString: '1030365',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0365',
      branchName: '左營華夏路分行',
      totalName: '0365 ╴左營華夏路分行'
    },
    {
      bankCodeString: '1030374',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0374',
      branchName: '大甲分行',
      totalName: '0374 ╴大甲分行'
    },
    {
      bankCodeString: '1030383',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0383',
      branchName: '大墩分行',
      totalName: '0383 ╴大墩分行'
    },
    {
      bankCodeString: '1030392',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0392',
      branchName: '員林分行',
      totalName: '0392 ╴員林分行'
    },
    {
      bankCodeString: '1030408',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0408',
      branchName: '南屯分行',
      totalName: '0408 ╴南屯分行'
    },
    {
      bankCodeString: '1030417',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0417',
      branchName: '東台南分行',
      totalName: '0417 ╴東台南分行'
    },
    {
      bankCodeString: '1030426',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0426',
      branchName: '大里分行',
      totalName: '0426 ╴大里分行'
    },
    {
      bankCodeString: '1030435',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0435',
      branchName: '松竹分行',
      totalName: '0435 ╴松竹分行'
    },
    {
      bankCodeString: '1030444',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0444',
      branchName: '彰化分行',
      totalName: '0444 ╴彰化分行'
    },
    {
      bankCodeString: '1030453',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0453',
      branchName: '樹林分行',
      totalName: '0453 ╴樹林分行'
    },
    {
      bankCodeString: '1030462',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0462',
      branchName: '新板分行',
      totalName: '0462 ╴新板分行'
    },
    {
      bankCodeString: '1030471',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0471',
      branchName: '古亭分行',
      totalName: '0471 ╴古亭分行'
    },
    {
      bankCodeString: '1030480',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0480',
      branchName: '士林分行',
      totalName: '0480 ╴士林分行'
    },
    {
      bankCodeString: '1030499',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0499',
      branchName: '營業部',
      totalName: '0499 ╴營業部'
    },
    {
      bankCodeString: '1030505',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0505',
      branchName: '丹鳳分行',
      totalName: '0505 ╴丹鳳分行'
    },
    {
      bankCodeString: '1030514',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0514',
      branchName: '北投復興崗分行',
      totalName: '0514 ╴北投復興崗分行'
    },
    {
      bankCodeString: '1030523',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0523',
      branchName: '新生南路分行',
      totalName: '0523 ╴新生南路分行'
    },
    {
      bankCodeString: '1030532',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0532',
      branchName: '國外部',
      totalName: '0532 ╴國外部'
    },
    {
      bankCodeString: '1030541',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0541',
      branchName: '新營分行',
      totalName: '0541 ╴新營分行'
    },
    {
      bankCodeString: '1030550',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0550',
      branchName: '天母簡易型分行',
      totalName: '0550 ╴天母簡易型分行'
    },
    {
      bankCodeString: '1030569',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0569',
      branchName: '新店分行',
      totalName: '0569 ╴新店分行'
    },
    {
      bankCodeString: '1030578',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0578',
      branchName: '大直分行',
      totalName: '0578 ╴大直分行'
    },
    {
      bankCodeString: '1030587',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0587',
      branchName: '興隆簡易型分行',
      totalName: '0587 ╴興隆簡易型分行'
    },
    {
      bankCodeString: '1030596',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0596',
      branchName: '八德分行',
      totalName: '0596 ╴八德分行'
    },
    {
      bankCodeString: '1030610',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0610',
      branchName: '兼營證券商',
      totalName: '0610 ╴兼營證券商'
    },
    {
      bankCodeString: '1030611',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0611',
      branchName: '長安分行',
      totalName: '0611 ╴長安分行'
    },
    {
      bankCodeString: '1030620',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0620',
      branchName: '嘉義分行',
      totalName: '0620 ╴嘉義分行'
    },
    {
      bankCodeString: '1030648',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0648',
      branchName: '鳳山分行',
      totalName: '0648 ╴鳳山分行'
    },
    {
      bankCodeString: '1030657',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0657',
      branchName: '台南分行',
      totalName: '0657 ╴台南分行'
    },
    {
      bankCodeString: '1030666',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0666',
      branchName: '北嘉義分行',
      totalName: '0666 ╴北嘉義分行'
    },
    {
      bankCodeString: '1030675',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0675',
      branchName: '斗六分行',
      totalName: '0675 ╴斗六分行'
    },
    {
      bankCodeString: '1030684',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0684',
      branchName: '竹南分行',
      totalName: '0684 ╴竹南分行'
    },
    {
      bankCodeString: '1030693',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0693',
      branchName: '花蓮分行',
      totalName: '0693 ╴花蓮分行'
    },
    {
      bankCodeString: '1030709',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0709',
      branchName: '基隆分行',
      totalName: '0709 ╴基隆分行'
    },
    {
      bankCodeString: '1030718',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0718',
      branchName: '宜蘭分行',
      totalName: '0718 ╴宜蘭分行'
    },
    {
      bankCodeString: '1030727',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0727',
      branchName: '三峽分行',
      totalName: '0727 ╴三峽分行'
    },
    {
      bankCodeString: '1030736',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0736',
      branchName: '路竹分行',
      totalName: '0736 ╴路竹分行'
    },
    {
      bankCodeString: '1030745',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0745',
      branchName: '高雄分行',
      totalName: '0745 ╴高雄分行'
    },
    {
      bankCodeString: '1030754',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0754',
      branchName: '新莊分行',
      totalName: '0754 ╴新莊分行'
    },
    {
      bankCodeString: '1030763',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0763',
      branchName: '江子翠分行',
      totalName: '0763 ╴江子翠分行'
    },
    {
      bankCodeString: '1030772',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0772',
      branchName: '林口分行',
      totalName: '0772 ╴林口分行'
    },
    {
      bankCodeString: '1030781',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0781',
      branchName: '彌陀簡易型分行',
      totalName: '0781 ╴彌陀簡易型分行'
    },
    {
      bankCodeString: '1030790',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0790',
      branchName: '岡山分行',
      totalName: '0790 ╴岡山分行'
    },
    {
      bankCodeString: '1030806',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0806',
      branchName: '北高雄分行',
      totalName: '0806 ╴北高雄分行'
    },
    {
      bankCodeString: '1030815',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0815',
      branchName: '國際金融業務分行',
      totalName: '0815 ╴國際金融業務分行'
    },
    {
      bankCodeString: '1030824',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0824',
      branchName: '小港分行',
      totalName: '0824 ╴小港分行'
    },
    {
      bankCodeString: '1030832',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0832',
      branchName: '信託部',
      totalName: '0832 ╴信託部'
    },
    {
      bankCodeString: '1030833',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0833',
      branchName: '中華分行',
      totalName: '0833 ╴中華分行'
    },
    {
      bankCodeString: '1030842',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0842',
      branchName: '承德分行',
      totalName: '0842 ╴承德分行'
    },
    {
      bankCodeString: '1030851',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0851',
      branchName: '敦南分行',
      totalName: '0851 ╴敦南分行'
    },
    {
      bankCodeString: '1030860',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0860',
      branchName: '中和分行',
      totalName: '0860 ╴中和分行'
    },
    {
      bankCodeString: '1030879',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0879',
      branchName: '南台中分行',
      totalName: '0879 ╴南台中分行'
    },
    {
      bankCodeString: '1030888',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0888',
      branchName: '水湳分行',
      totalName: '0888 ╴水湳分行'
    },
    {
      bankCodeString: '1030897',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0897',
      branchName: '北屯分行',
      totalName: '0897 ╴北屯分行'
    },
    {
      bankCodeString: '1030903',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0903',
      branchName: '西屯分行',
      totalName: '0903 ╴西屯分行'
    },
    {
      bankCodeString: '1030912',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0912',
      branchName: '向上分行',
      totalName: '0912 ╴向上分行'
    },
    {
      bankCodeString: '1030921',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0921',
      branchName: '十甲分行',
      totalName: '0921 ╴十甲分行'
    },
    {
      bankCodeString: '1030930',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0930',
      branchName: '豐原分行',
      totalName: '0930 ╴豐原分行'
    },
    {
      bankCodeString: '1030949',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0949',
      branchName: '永安分行',
      totalName: '0949 ╴永安分行'
    },
    {
      bankCodeString: '1030958',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0958',
      branchName: '屏東分行',
      totalName: '0958 ╴屏東分行'
    },
    {
      bankCodeString: '1030967',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0967',
      branchName: '東園分行',
      totalName: '0967 ╴東園分行'
    },
    {
      bankCodeString: '1030976',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0976',
      branchName: '萬丹分行',
      totalName: '0976 ╴萬丹分行'
    },
    {
      bankCodeString: '1030985',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '0985',
      branchName: '七賢分行',
      totalName: '0985 ╴七賢分行'
    },
    {
      bankCodeString: '1031003',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '1003',
      branchName: '汐止分行',
      totalName: '1003 ╴汐止分行'
    },
    {
      bankCodeString: '1031012',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '1012',
      branchName: '桃北分行',
      totalName: '1012 ╴桃北分行'
    },
    {
      bankCodeString: '1031021',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '1021',
      branchName: '六家分行',
      totalName: '1021 ╴六家分行'
    },
    {
      bankCodeString: '1031030',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '1030',
      branchName: '九如分行',
      totalName: '1030 ╴九如分行'
    },
    {
      bankCodeString: '1031049',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '1049',
      branchName: '永康分行',
      totalName: '1049 ╴永康分行'
    },
    {
      bankCodeString: '1031058',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '1058',
      branchName: '青埔分行',
      totalName: '1058 ╴青埔分行'
    },
    {
      bankCodeString: '1031067',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '1067',
      branchName: '沙鹿分行',
      totalName: '1067 ╴沙鹿分行'
    },
    {
      bankCodeString: '1031076',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '1076',
      branchName: '大雅分行',
      totalName: '1076 ╴大雅分行'
    },
    {
      bankCodeString: '1031094',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '1094',
      branchName: '草屯分行',
      totalName: '1094 ╴草屯分行'
    },
    {
      bankCodeString: '1031100',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '1100',
      branchName: '南東分行',
      totalName: '1100 ╴南東分行'
    },
    {
      bankCodeString: '1033425',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '3425',
      branchName: '緬甸仰光代表人辦事處',
      totalName: '3425 ╴緬甸仰光代表人辦事處'
    },
    {
      bankCodeString: '1039025',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '9025',
      branchName: '越南胡志明市代表人辦事處',
      totalName: '9025 ╴越南胡志明市代表人辦事處'
    },
    {
      bankCodeString: '1039036',
      bankCode: '103',
      bankName: '臺灣新光商業銀行',
      branchCode: '9036',
      branchName: '香港分行',
      totalName: '9036 ╴香港分行'
    }
  ],
  '108': [
    {
      bankCodeString: '1080001',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0001',
      branchName: '稽核室',
      totalName: '0001 ╴稽核室'
    },
    {
      bankCodeString: '1080003',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0003',
      branchName: '資訊室',
      totalName: '0003 ╴資訊室'
    },
    {
      bankCodeString: '1080004',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0004',
      branchName: '秘書處',
      totalName: '0004 ╴秘書處'
    },
    {
      bankCodeString: '1080005',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0005',
      branchName: '企劃室',
      totalName: '0005 ╴企劃室'
    },
    {
      bankCodeString: '1080006',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0006',
      branchName: '會計室',
      totalName: '0006 ╴會計室'
    },
    {
      bankCodeString: '1080007',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0007',
      branchName: '企業金融事業部',
      totalName: '0007 ╴企業金融事業部'
    },
    {
      bankCodeString: '1080008',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0008',
      branchName: '人力資源處',
      totalName: '0008 ╴人力資源處'
    },
    {
      bankCodeString: '1080009',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0009',
      branchName: '信用卡事業部',
      totalName: '0009 ╴信用卡事業部'
    },
    {
      bankCodeString: '1080010',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0010',
      branchName: '財務部',
      totalName: '0010 ╴財務部'
    },
    {
      bankCodeString: '1080011',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0011',
      branchName: '消金事業部',
      totalName: '0011 ╴消金事業部'
    },
    {
      bankCodeString: '1080012',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0012',
      branchName: '法人金融作業中心',
      totalName: '0012 ╴法人金融作業中心'
    },
    {
      bankCodeString: '1080013',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0013',
      branchName: '專案拓展室',
      totalName: '0013 ╴專案拓展室'
    },
    {
      bankCodeString: '1080014',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0014',
      branchName: '營業部',
      totalName: '0014 ╴營業部'
    },
    {
      bankCodeString: '1080015',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0015',
      branchName: '研究發展室',
      totalName: '0015 ╴研究發展室'
    },
    {
      bankCodeString: '1080023',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0023',
      branchName: '石牌分行',
      totalName: '0023 ╴石牌分行'
    },
    {
      bankCodeString: '1080032',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0032',
      branchName: '北投分行',
      totalName: '0032 ╴北投分行'
    },
    {
      bankCodeString: '1080041',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0041',
      branchName: '社子分行',
      totalName: '0041 ╴社子分行'
    },
    {
      bankCodeString: '1080050',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0050',
      branchName: '士林分行',
      totalName: '0050 ╴士林分行'
    },
    {
      bankCodeString: '1080069',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0069',
      branchName: '大屯分行',
      totalName: '0069 ╴大屯分行'
    },
    {
      bankCodeString: '1080078',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0078',
      branchName: '劍潭分行',
      totalName: '0078 ╴劍潭分行'
    },
    {
      bankCodeString: '1080087',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0087',
      branchName: '蘭雅分行',
      totalName: '0087 ╴蘭雅分行'
    },
    {
      bankCodeString: '1080096',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0096',
      branchName: '天母分行',
      totalName: '0096 ╴天母分行'
    },
    {
      bankCodeString: '1080102',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0102',
      branchName: '社中分行',
      totalName: '0102 ╴社中分行'
    },
    {
      bankCodeString: '1080111',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0111',
      branchName: '吉林分行',
      totalName: '0111 ╴吉林分行'
    },
    {
      bankCodeString: '1080120',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0120',
      branchName: '成功分行',
      totalName: '0120 ╴成功分行'
    },
    {
      bankCodeString: '1080139',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0139',
      branchName: '民生分行',
      totalName: '0139 ╴民生分行'
    },
    {
      bankCodeString: '1080148',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0148',
      branchName: '延吉分行',
      totalName: '0148 ╴延吉分行'
    },
    {
      bankCodeString: '1080157',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0157',
      branchName: '木柵分行',
      totalName: '0157 ╴木柵分行'
    },
    {
      bankCodeString: '1080166',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0166',
      branchName: '龍江分行',
      totalName: '0166 ╴龍江分行'
    },
    {
      bankCodeString: '1080175',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0175',
      branchName: '南京分行',
      totalName: '0175 ╴南京分行'
    },
    {
      bankCodeString: '1080184',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0184',
      branchName: '景美分行',
      totalName: '0184 ╴景美分行'
    },
    {
      bankCodeString: '1080193',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0193',
      branchName: '中興分行',
      totalName: '0193 ╴中興分行'
    },
    {
      bankCodeString: '1080209',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0209',
      branchName: '信義分行',
      totalName: '0209 ╴信義分行'
    },
    {
      bankCodeString: '1080218',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0218',
      branchName: '中和分行',
      totalName: '0218 ╴中和分行'
    },
    {
      bankCodeString: '1080227',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0227',
      branchName: '永和分行',
      totalName: '0227 ╴永和分行'
    },
    {
      bankCodeString: '1080236',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0236',
      branchName: '蘆洲分行',
      totalName: '0236 ╴蘆洲分行'
    },
    {
      bankCodeString: '1080245',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0245',
      branchName: '板橋分行',
      totalName: '0245 ╴板橋分行'
    },
    {
      bankCodeString: '1080254',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0254',
      branchName: '信託部',
      totalName: '0254 ╴信託部'
    },
    {
      bankCodeString: '1080263',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0263',
      branchName: '新和分行',
      totalName: '0263 ╴新和分行'
    },
    {
      bankCodeString: '1080272',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0272',
      branchName: '溪洲分行',
      totalName: '0272 ╴溪洲分行'
    },
    {
      bankCodeString: '1080281',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0281',
      branchName: '泰山分行',
      totalName: '0281 ╴泰山分行'
    },
    {
      bankCodeString: '1080290',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0290',
      branchName: '新莊分行',
      totalName: '0290 ╴新莊分行'
    },
    {
      bankCodeString: '1080306',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0306',
      branchName: '古亭分行',
      totalName: '0306 ╴古亭分行'
    },
    {
      bankCodeString: '1080315',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0315',
      branchName: '三重分行',
      totalName: '0315 ╴三重分行'
    },
    {
      bankCodeString: '1080324',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0324',
      branchName: '大業分行',
      totalName: '0324 ╴大業分行'
    },
    {
      bankCodeString: '1080333',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0333',
      branchName: '雙和分行',
      totalName: '0333 ╴雙和分行'
    },
    {
      bankCodeString: '1080342',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0342',
      branchName: '復興分行',
      totalName: '0342 ╴復興分行'
    },
    {
      bankCodeString: '1080351',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0351',
      branchName: '大安分行',
      totalName: '0351 ╴大安分行'
    },
    {
      bankCodeString: '1080360',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0360',
      branchName: '桃園分行',
      totalName: '0360 ╴桃園分行'
    },
    {
      bankCodeString: '1080379',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0379',
      branchName: '新店分行',
      totalName: '0379 ╴新店分行'
    },
    {
      bankCodeString: '1080388',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0388',
      branchName: '國外部',
      totalName: '0388 ╴國外部'
    },
    {
      bankCodeString: '1080397',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0397',
      branchName: '新福分行',
      totalName: '0397 ╴新福分行'
    },
    {
      bankCodeString: '1080403',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0403',
      branchName: '新竹分行',
      totalName: '0403 ╴新竹分行'
    },
    {
      bankCodeString: '1080412',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0412',
      branchName: '員林分行',
      totalName: '0412 ╴員林分行'
    },
    {
      bankCodeString: '1080421',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0421',
      branchName: '花蓮分行',
      totalName: '0421 ╴花蓮分行'
    },
    {
      bankCodeString: '1080430',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0430',
      branchName: '精武分行',
      totalName: '0430 ╴精武分行'
    },
    {
      bankCodeString: '1080449',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0449',
      branchName: '社頭分行',
      totalName: '0449 ╴社頭分行'
    },
    {
      bankCodeString: '1080458',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0458',
      branchName: '屏東分行',
      totalName: '0458 ╴屏東分行'
    },
    {
      bankCodeString: '1080467',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0467',
      branchName: '和平分行',
      totalName: '0467 ╴和平分行'
    },
    {
      bankCodeString: '1080476',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0476',
      branchName: '高雄分行',
      totalName: '0476 ╴高雄分行'
    },
    {
      bankCodeString: '1080485',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0485',
      branchName: '大同分行',
      totalName: '0485 ╴大同分行'
    },
    {
      bankCodeString: '1080494',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0494',
      branchName: '北屯分行',
      totalName: '0494 ╴北屯分行'
    },
    {
      bankCodeString: '1080500',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0500',
      branchName: '嘉義分行',
      totalName: '0500 ╴嘉義分行'
    },
    {
      bankCodeString: '1080519',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0519',
      branchName: '新埔分行',
      totalName: '0519 ╴新埔分行'
    },
    {
      bankCodeString: '1080528',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0528',
      branchName: '台南分行',
      totalName: '0528 ╴台南分行'
    },
    {
      bankCodeString: '1080555',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0555',
      branchName: '東寧分行',
      totalName: '0555 ╴東寧分行'
    },
    {
      bankCodeString: '1080564',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0564',
      branchName: '中華分行',
      totalName: '0564 ╴中華分行'
    },
    {
      bankCodeString: '1080573',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0573',
      branchName: '健康分行',
      totalName: '0573 ╴健康分行'
    },
    {
      bankCodeString: '1080582',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0582',
      branchName: '台中分行',
      totalName: '0582 ╴台中分行'
    },
    {
      bankCodeString: '1080607',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0607',
      branchName: '西華分行',
      totalName: '0607 ╴西華分行'
    },
    {
      bankCodeString: '1080616',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0616',
      branchName: '安順分行',
      totalName: '0616 ╴安順分行'
    },
    {
      bankCodeString: '1080625',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0625',
      branchName: '國際金融業務分行',
      totalName: '0625 ╴國際金融業務分行'
    },
    {
      bankCodeString: '1080634',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0634',
      branchName: '左營分行',
      totalName: '0634 ╴左營分行'
    },
    {
      bankCodeString: '1080643',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0643',
      branchName: '向上分行',
      totalName: '0643 ╴向上分行'
    },
    {
      bankCodeString: '1080652',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0652',
      branchName: '內湖分行',
      totalName: '0652 ╴內湖分行'
    },
    {
      bankCodeString: '1080661',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0661',
      branchName: '中壢分行',
      totalName: '0661 ╴中壢分行'
    },
    {
      bankCodeString: '1080670',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0670',
      branchName: '五股分行',
      totalName: '0670 ╴五股分行'
    },
    {
      bankCodeString: '1080689',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0689',
      branchName: '林森分行',
      totalName: '0689 ╴林森分行'
    },
    {
      bankCodeString: '1080698',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0698',
      branchName: '立文分行',
      totalName: '0698 ╴立文分行'
    },
    {
      bankCodeString: '1080704',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0704',
      branchName: '新興分行',
      totalName: '0704 ╴新興分行'
    },
    {
      bankCodeString: '1080713',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0713',
      branchName: '青年分行',
      totalName: '0713 ╴青年分行'
    },
    {
      bankCodeString: '1080722',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0722',
      branchName: '三鳳分行',
      totalName: '0722 ╴三鳳分行'
    },
    {
      bankCodeString: '1080731',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0731',
      branchName: '四維分行',
      totalName: '0731 ╴四維分行'
    },
    {
      bankCodeString: '1080740',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0740',
      branchName: '大公分行',
      totalName: '0740 ╴大公分行'
    },
    {
      bankCodeString: '1080759',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0759',
      branchName: '大順分行',
      totalName: '0759 ╴大順分行'
    },
    {
      bankCodeString: '1080768',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0768',
      branchName: '海光分行',
      totalName: '0768 ╴海光分行'
    },
    {
      bankCodeString: '1080777',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0777',
      branchName: '前鎮分行',
      totalName: '0777 ╴前鎮分行'
    },
    {
      bankCodeString: '1080786',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0786',
      branchName: '平等分行',
      totalName: '0786 ╴平等分行'
    },
    {
      bankCodeString: '1080795',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0795',
      branchName: '基隆分行',
      totalName: '0795 ╴基隆分行'
    },
    {
      bankCodeString: '1080801',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0801',
      branchName: '小港分行',
      totalName: '0801 ╴小港分行'
    },
    {
      bankCodeString: '1080810',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0810',
      branchName: '右昌分行',
      totalName: '0810 ╴右昌分行'
    },
    {
      bankCodeString: '1080829',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0829',
      branchName: '汐止分行',
      totalName: '0829 ╴汐止分行'
    },
    {
      bankCodeString: '1080838',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0838',
      branchName: '五甲分行',
      totalName: '0838 ╴五甲分行'
    },
    {
      bankCodeString: '1080847',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0847',
      branchName: '鼎力分行',
      totalName: '0847 ╴鼎力分行'
    },
    {
      bankCodeString: '1080856',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0856',
      branchName: '楠梓分行',
      totalName: '0856 ╴楠梓分行'
    },
    {
      bankCodeString: '1080865',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0865',
      branchName: '旗山分行',
      totalName: '0865 ╴旗山分行'
    },
    {
      bankCodeString: '1080874',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0874',
      branchName: '土城分行',
      totalName: '0874 ╴土城分行'
    },
    {
      bankCodeString: '1080883',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0883',
      branchName: '南桃園分行',
      totalName: '0883 ╴南桃園分行'
    },
    {
      bankCodeString: '1080900',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0900',
      branchName: '兼營證券商',
      totalName: '0900 ╴兼營證券商'
    },
    {
      bankCodeString: '1080917',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0917',
      branchName: '林園分行',
      totalName: '0917 ╴林園分行'
    },
    {
      bankCodeString: '1080926',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0926',
      branchName: '岡山分行',
      totalName: '0926 ╴岡山分行'
    },
    {
      bankCodeString: '1080935',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0935',
      branchName: '仁德分行',
      totalName: '0935 ╴仁德分行'
    },
    {
      bankCodeString: '1080944',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0944',
      branchName: '東桃園分行',
      totalName: '0944 ╴東桃園分行'
    },
    {
      bankCodeString: '1080953',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0953',
      branchName: '永康分行',
      totalName: '0953 ╴永康分行'
    },
    {
      bankCodeString: '1080971',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0971',
      branchName: '南港分行',
      totalName: '0971 ╴南港分行'
    },
    {
      bankCodeString: '1080980',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0980',
      branchName: '里港分行',
      totalName: '0980 ╴里港分行'
    },
    {
      bankCodeString: '1080999',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '0999',
      branchName: '大里分行',
      totalName: '0999 ╴大里分行'
    },
    {
      bankCodeString: '1081017',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '1017',
      branchName: '萬華分行',
      totalName: '1017 ╴萬華分行'
    },
    {
      bankCodeString: '1081026',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '1026',
      branchName: '台北分行',
      totalName: '1026 ╴台北分行'
    },
    {
      bankCodeString: '1081035',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '1035',
      branchName: '長安分行',
      totalName: '1035 ╴長安分行'
    },
    {
      bankCodeString: '1081053',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '1053',
      branchName: '羅東分行',
      totalName: '1053 ╴羅東分行'
    },
    {
      bankCodeString: '1081062',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '1062',
      branchName: '竹北分行',
      totalName: '1062 ╴竹北分行'
    },
    {
      bankCodeString: '1081071',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '1071',
      branchName: '重新分行',
      totalName: '1071 ╴重新分行'
    },
    {
      bankCodeString: '1081080',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '1080',
      branchName: '彰化分行',
      totalName: '1080 ╴彰化分行'
    },
    {
      bankCodeString: '1081336',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '1336',
      branchName: '苗栗分行',
      totalName: '1336 ╴苗栗分行'
    },
    {
      bankCodeString: '1081354',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '1354',
      branchName: '龍井分行',
      totalName: '1354 ╴龍井分行'
    },
    {
      bankCodeString: '1081363',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '1363',
      branchName: '雲林分行',
      totalName: '1363 ╴雲林分行'
    },
    {
      bankCodeString: '1081372',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '1372',
      branchName: '南投分行',
      totalName: '1372 ╴南投分行'
    },
    {
      bankCodeString: '1081503',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '1503',
      branchName: '臺東分行',
      totalName: '1503 ╴臺東分行'
    },
    {
      bankCodeString: '1081512',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '1512',
      branchName: '宜蘭分行',
      totalName: '1512 ╴宜蘭分行'
    },
    {
      bankCodeString: '1081567',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '1567',
      branchName: '林口分行',
      totalName: '1567 ╴林口分行'
    },
    {
      bankCodeString: '1081576',
      bankCode: '108',
      bankName: '陽信商業銀行',
      branchCode: '1576',
      branchName: '豐原分行',
      totalName: '1576 ╴豐原分行'
    }
  ],
  '118': [
    {
      bankCodeString: '1180001',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0001',
      branchName: '審查部',
      totalName: '0001 ╴審查部'
    },
    {
      bankCodeString: '1180003',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0003',
      branchName: '總務部',
      totalName: '0003 ╴總務部'
    },
    {
      bankCodeString: '1180004',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0004',
      branchName: '人力資源部',
      totalName: '0004 ╴人力資源部'
    },
    {
      bankCodeString: '1180005',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0005',
      branchName: '會計部',
      totalName: '0005 ╴會計部'
    },
    {
      bankCodeString: '1180006',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0006',
      branchName: '資訊部',
      totalName: '0006 ╴資訊部'
    },
    {
      bankCodeString: '1180007',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0007',
      branchName: '稽核處',
      totalName: '0007 ╴稽核處'
    },
    {
      bankCodeString: '1180008',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0008',
      branchName: '董事會',
      totalName: '0008 ╴董事會'
    },
    {
      bankCodeString: '1180009',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0009',
      branchName: '財務部',
      totalName: '0009 ╴財務部'
    },
    {
      bankCodeString: '1180010',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0010',
      branchName: '債權管理部南區',
      totalName: '0010 ╴債權管理部南區'
    },
    {
      bankCodeString: '1180011',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0011',
      branchName: '板橋分行',
      totalName: '0011 ╴板橋分行'
    },
    {
      bankCodeString: '1180013',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0013',
      branchName: '授信管理部',
      totalName: '0013 ╴授信管理部'
    },
    {
      bankCodeString: '1180014',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0014',
      branchName: '債權管理部',
      totalName: '0014 ╴債權管理部'
    },
    {
      bankCodeString: '1180015',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0015',
      branchName: '風險管理部',
      totalName: '0015 ╴風險管理部'
    },
    {
      bankCodeString: '1180016',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0016',
      branchName: '財富管理部',
      totalName: '0016 ╴財富管理部'
    },
    {
      bankCodeString: '1180020',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0020',
      branchName: '後埔分行',
      totalName: '0020 ╴後埔分行'
    },
    {
      bankCodeString: '1180039',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0039',
      branchName: '永和分行',
      totalName: '0039 ╴永和分行'
    },
    {
      bankCodeString: '1180048',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0048',
      branchName: '埔墘分行',
      totalName: '0048 ╴埔墘分行'
    },
    {
      bankCodeString: '1180057',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0057',
      branchName: '華江分行',
      totalName: '0057 ╴華江分行'
    },
    {
      bankCodeString: '1180066',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0066',
      branchName: '民族分行',
      totalName: '0066 ╴民族分行'
    },
    {
      bankCodeString: '1180075',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0075',
      branchName: '中和分行',
      totalName: '0075 ╴中和分行'
    },
    {
      bankCodeString: '1180084',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0084',
      branchName: '土城分行',
      totalName: '0084 ╴土城分行'
    },
    {
      bankCodeString: '1180093',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0093',
      branchName: '文化分行',
      totalName: '0093 ╴文化分行'
    },
    {
      bankCodeString: '1180109',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0109',
      branchName: '大觀分行',
      totalName: '0109 ╴大觀分行'
    },
    {
      bankCodeString: '1180118',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0118',
      branchName: '興南分行',
      totalName: '0118 ╴興南分行'
    },
    {
      bankCodeString: '1180127',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0127',
      branchName: '業務部',
      totalName: '0127 ╴業務部'
    },
    {
      bankCodeString: '1180145',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0145',
      branchName: '小港分行',
      totalName: '0145 ╴小港分行'
    },
    {
      bankCodeString: '1180163',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0163',
      branchName: '新興分行',
      totalName: '0163 ╴新興分行'
    },
    {
      bankCodeString: '1180181',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0181',
      branchName: '前鎮分行',
      totalName: '0181 ╴前鎮分行'
    },
    {
      bankCodeString: '1180206',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0206',
      branchName: '陽明分行',
      totalName: '0206 ╴陽明分行'
    },
    {
      bankCodeString: '1180215',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0215',
      branchName: '高新莊分行',
      totalName: '0215 ╴高新莊分行'
    },
    {
      bankCodeString: '1180279',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0279',
      branchName: '信義分行',
      totalName: '0279 ╴信義分行'
    },
    {
      bankCodeString: '1180288',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0288',
      branchName: '新莊分行',
      totalName: '0288 ╴新莊分行'
    },
    {
      bankCodeString: '1180297',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0297',
      branchName: '秀朗分行',
      totalName: '0297 ╴秀朗分行'
    },
    {
      bankCodeString: '1180303',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0303',
      branchName: '桃園分行',
      totalName: '0303 ╴桃園分行'
    },
    {
      bankCodeString: '1180312',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0312',
      branchName: '三重分行',
      totalName: '0312 ╴三重分行'
    },
    {
      bankCodeString: '1180330',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0330',
      branchName: '員山分行',
      totalName: '0330 ╴員山分行'
    },
    {
      bankCodeString: '1180349',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0349',
      branchName: '桃鶯分行',
      totalName: '0349 ╴桃鶯分行'
    },
    {
      bankCodeString: '1180358',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0358',
      branchName: '民生分行',
      totalName: '0358 ╴民生分行'
    },
    {
      bankCodeString: '1180367',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0367',
      branchName: '龍岡分行',
      totalName: '0367 ╴龍岡分行'
    },
    {
      bankCodeString: '1180376',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0376',
      branchName: '信託部',
      totalName: '0376 ╴信託部'
    },
    {
      bankCodeString: '1180385',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0385',
      branchName: '樹林分行',
      totalName: '0385 ╴樹林分行'
    },
    {
      bankCodeString: '1180394',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0394',
      branchName: '金城分行',
      totalName: '0394 ╴金城分行'
    },
    {
      bankCodeString: '1180400',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0400',
      branchName: '新竹分行',
      totalName: '0400 ╴新竹分行'
    },
    {
      bankCodeString: '1180419',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0419',
      branchName: '八德分行',
      totalName: '0419 ╴八德分行'
    },
    {
      bankCodeString: '1180428',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0428',
      branchName: '台中分行',
      totalName: '0428 ╴台中分行'
    },
    {
      bankCodeString: '1180437',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0437',
      branchName: '台南分行',
      totalName: '0437 ╴台南分行'
    },
    {
      bankCodeString: '1180446',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0446',
      branchName: '新店分行',
      totalName: '0446 ╴新店分行'
    },
    {
      bankCodeString: '1180455',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0455',
      branchName: '內湖分行',
      totalName: '0455 ╴內湖分行'
    },
    {
      bankCodeString: '1180473',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0473',
      branchName: '國外部',
      totalName: '0473 ╴國外部'
    },
    {
      bankCodeString: '1180482',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0482',
      branchName: '中正分行',
      totalName: '0482 ╴中正分行'
    },
    {
      bankCodeString: '1180491',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0491',
      branchName: '國際金融業務分行',
      totalName: '0491 ╴國際金融業務分行'
    },
    {
      bankCodeString: '1180507',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0507',
      branchName: '嘉義分行',
      totalName: '0507 ╴嘉義分行'
    },
    {
      bankCodeString: '1180590',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0590',
      branchName: '兼營證券商',
      totalName: '0590 ╴兼營證券商'
    },
    {
      bankCodeString: '1180598',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0598',
      branchName: '蘆洲分行',
      totalName: '0598 ╴蘆洲分行'
    },
    {
      bankCodeString: '1180604',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0604',
      branchName: '羅東分行',
      totalName: '0604 ╴羅東分行'
    },
    {
      bankCodeString: '1180613',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0613',
      branchName: '北台中分行',
      totalName: '0613 ╴北台中分行'
    },
    {
      bankCodeString: '1180622',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0622',
      branchName: '成功分行',
      totalName: '0622 ╴成功分行'
    },
    {
      bankCodeString: '1180631',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0631',
      branchName: '福和分行',
      totalName: '0631 ╴福和分行'
    },
    {
      bankCodeString: '1180659',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0659',
      branchName: '營業部',
      totalName: '0659 ╴營業部'
    },
    {
      bankCodeString: '1180668',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0668',
      branchName: '瑞光分行',
      totalName: '0668 ╴瑞光分行'
    },
    {
      bankCodeString: '1180677',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0677',
      branchName: '丹鳳分行',
      totalName: '0677 ╴丹鳳分行'
    },
    {
      bankCodeString: '1180686',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0686',
      branchName: '北新分行',
      totalName: '0686 ╴北新分行'
    },
    {
      bankCodeString: '1180695',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0695',
      branchName: '艋舺分行',
      totalName: '0695 ╴艋舺分行'
    },
    {
      bankCodeString: '1180701',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0701',
      branchName: '興隆分行',
      totalName: '0701 ╴興隆分行'
    },
    {
      bankCodeString: '1180710',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0710',
      branchName: '雙園分行',
      totalName: '0710 ╴雙園分行'
    },
    {
      bankCodeString: '1180729',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0729',
      branchName: '西門分行',
      totalName: '0729 ╴西門分行'
    },
    {
      bankCodeString: '1180738',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0738',
      branchName: '古亭分行',
      totalName: '0738 ╴古亭分行'
    },
    {
      bankCodeString: '1180747',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0747',
      branchName: '安東分行',
      totalName: '0747 ╴安東分行'
    },
    {
      bankCodeString: '1180756',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0756',
      branchName: '松山分行',
      totalName: '0756 ╴松山分行'
    },
    {
      bankCodeString: '1180765',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0765',
      branchName: '士林分行',
      totalName: '0765 ╴士林分行'
    },
    {
      bankCodeString: '1180774',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0774',
      branchName: '東湖分行',
      totalName: '0774 ╴東湖分行'
    },
    {
      bankCodeString: '1180792',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0792',
      branchName: '復興分行',
      totalName: '0792 ╴復興分行'
    },
    {
      bankCodeString: '1180808',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0808',
      branchName: '民權分行',
      totalName: '0808 ╴民權分行'
    },
    {
      bankCodeString: '1180817',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0817',
      branchName: '重慶分行',
      totalName: '0817 ╴重慶分行'
    },
    {
      bankCodeString: '1180826',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0826',
      branchName: '木柵分行',
      totalName: '0826 ╴木柵分行'
    },
    {
      bankCodeString: '1180844',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0844',
      branchName: '大直分行',
      totalName: '0844 ╴大直分行'
    },
    {
      bankCodeString: '1180853',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0853',
      branchName: '萬大分行',
      totalName: '0853 ╴萬大分行'
    },
    {
      bankCodeString: '1180871',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0871',
      branchName: '保險代理部',
      totalName: '0871 ╴保險代理部'
    },
    {
      bankCodeString: '1180880',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0880',
      branchName: '南京東路分行',
      totalName: '0880 ╴南京東路分行'
    },
    {
      bankCodeString: '1180899',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0899',
      branchName: '北桃園分行',
      totalName: '0899 ╴北桃園分行'
    },
    {
      bankCodeString: '1180905',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0905',
      branchName: '環東分行',
      totalName: '0905 ╴環東分行'
    },
    {
      bankCodeString: '1180914',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0914',
      branchName: '汐止分行',
      totalName: '0914 ╴汐止分行'
    },
    {
      bankCodeString: '1180923',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0923',
      branchName: '燕巢分行',
      totalName: '0923 ╴燕巢分行'
    },
    {
      bankCodeString: '1180941',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '0941',
      branchName: '南港分行',
      totalName: '0941 ╴南港分行'
    },
    {
      bankCodeString: '1181932',
      bankCode: '118',
      bankName: '板信商業銀行',
      branchCode: '1932',
      branchName: '苗栗分行',
      totalName: '1932 ╴苗栗分行'
    }
  ],
  '147': [
    {
      bankCodeString: '1470001',
      bankCode: '147',
      bankName: '三信商業銀行',
      branchCode: '0001',
      branchName: '管理部',
      totalName: '0001 ╴管理部'
    },
    {
      bankCodeString: '1470002',
      bankCode: '147',
      bankName: '三信商業銀行',
      branchCode: '0002',
      branchName: '業務部',
      totalName: '0002 ╴業務部'
    },
    {
      bankCodeString: '1470003',
      bankCode: '147',
      bankName: '三信商業銀行',
      branchCode: '0003',
      branchName: '審查部',
      totalName: '0003 ╴審查部'
    },
    {
      bankCodeString: '1470004',
      bankCode: '147',
      bankName: '三信商業銀行',
      branchCode: '0004',
      branchName: '稽核室',
      totalName: '0004 ╴稽核室'
    },
    {
      bankCodeString: '1470005',
      bankCode: '147',
      bankName: '三信商業銀行',
      branchCode: '0005',
      branchName: '會計室',
      totalName: '0005 ╴會計室'
    },
    {
      bankCodeString: '1470006',
      bankCode: '147',
      bankName: '三信商業銀行',
      branchCode: '0006',
      branchName: '資訊室',
      totalName: '0006 ╴資訊室'
    },
    {
      bankCodeString: '1470007',
      bankCode: '147',
      bankName: '三信商業銀行',
      branchCode: '0007',
      branchName: '消費金融中心',
      totalName: '0007 ╴消費金融中心'
    },
    {
      bankCodeString: '1470008',
      bankCode: '147',
      bankName: '三信商業銀行',
      branchCode: '0008',
      branchName: '催收中心',
      totalName: '0008 ╴催收中心'
    },
    {
      bankCodeString: '1470010',
      bankCode: '147',
      bankName: '三信商業銀行',
      branchCode: '0010',
      branchName: '信用卡中心',
      totalName: '0010 ╴信用卡中心'
    },
    {
      bankCodeString: '1470011',
      bankCode: '147',
      bankName: '三信商業銀行',
      branchCode: '0011',
      branchName: '商務金融中心',
      totalName: '0011 ╴商務金融中心'
    },
    {
      bankCodeString: '1470013',
      bankCode: '147',
      bankName: '三信商業銀行',
      branchCode: '0013',
      branchName: '台中分行',
      totalName: '0013 ╴台中分行'
    },
    {
      bankCodeString: '1470022',
      bankCode: '147',
      bankName: '三信商業銀行',
      branchCode: '0022',
      branchName: '營業部',
      totalName: '0022 ╴營業部'
    },
    {
      bankCodeString: '1470040',
      bankCode: '147',
      bankName: '三信商業銀行',
      branchCode: '0040',
      branchName: '成功分行',
      totalName: '0040 ╴成功分行'
    },
    {
      bankCodeString: '1470059',
      bankCode: '147',
      bankName: '三信商業銀行',
      branchCode: '0059',
      branchName: '西屯分行',
      totalName: '0059 ╴西屯分行'
    },
    {
      bankCodeString: '1470068',
      bankCode: '147',
      bankName: '三信商業銀行',
      branchCode: '0068',
      branchName: '國光分行',
      totalName: '0068 ╴國光分行'
    },
    {
      bankCodeString: '1470077',
      bankCode: '147',
      bankName: '三信商業銀行',
      branchCode: '0077',
      branchName: '大智分行',
      totalName: '0077 ╴大智分行'
    },
    {
      bankCodeString: '1470086',
      bankCode: '147',
      bankName: '三信商業銀行',
      branchCode: '0086',
      branchName: '林森分行',
      totalName: '0086 ╴林森分行'
    },
    {
      bankCodeString: '1470095',
      bankCode: '147',
      bankName: '三信商業銀行',
      branchCode: '0095',
      branchName: '南門分行',
      totalName: '0095 ╴南門分行'
    },
    {
      bankCodeString: '1470101',
      bankCode: '147',
      bankName: '三信商業銀行',
      branchCode: '0101',
      branchName: '進化分行',
      totalName: '0101 ╴進化分行'
    },
    {
      bankCodeString: '1470110',
      bankCode: '147',
      bankName: '三信商業銀行',
      branchCode: '0110',
      branchName: '南屯分行',
      totalName: '0110 ╴南屯分行'
    },
    {
      bankCodeString: '1470138',
      bankCode: '147',
      bankName: '三信商業銀行',
      branchCode: '0138',
      branchName: '北屯分行',
      totalName: '0138 ╴北屯分行'
    },
    {
      bankCodeString: '1470147',
      bankCode: '147',
      bankName: '三信商業銀行',
      branchCode: '0147',
      branchName: '豐原分行',
      totalName: '0147 ╴豐原分行'
    },
    {
      bankCodeString: '1470156',
      bankCode: '147',
      bankName: '三信商業銀行',
      branchCode: '0156',
      branchName: '彰化分行',
      totalName: '0156 ╴彰化分行'
    },
    {
      bankCodeString: '1470165',
      bankCode: '147',
      bankName: '三信商業銀行',
      branchCode: '0165',
      branchName: '員林分行',
      totalName: '0165 ╴員林分行'
    },
    {
      bankCodeString: '1470183',
      bankCode: '147',
      bankName: '三信商業銀行',
      branchCode: '0183',
      branchName: '台北分行',
      totalName: '0183 ╴台北分行'
    },
    {
      bankCodeString: '1470192',
      bankCode: '147',
      bankName: '三信商業銀行',
      branchCode: '0192',
      branchName: '桃園分行',
      totalName: '0192 ╴桃園分行'
    },
    {
      bankCodeString: '1470208',
      bankCode: '147',
      bankName: '三信商業銀行',
      branchCode: '0208',
      branchName: '板橋分行',
      totalName: '0208 ╴板橋分行'
    },
    {
      bankCodeString: '1470253',
      bankCode: '147',
      bankName: '三信商業銀行',
      branchCode: '0253',
      branchName: '豐信分行',
      totalName: '0253 ╴豐信分行'
    },
    {
      bankCodeString: '1470262',
      bankCode: '147',
      bankName: '三信商業銀行',
      branchCode: '0262',
      branchName: '中山分行',
      totalName: '0262 ╴中山分行'
    },
    {
      bankCodeString: '1470299',
      bankCode: '147',
      bankName: '三信商業銀行',
      branchCode: '0299',
      branchName: '台南分行',
      totalName: '0299 ╴台南分行'
    },
    {
      bankCodeString: '1470305',
      bankCode: '147',
      bankName: '三信商業銀行',
      branchCode: '0305',
      branchName: '高雄分行',
      totalName: '0305 ╴高雄分行'
    },
    {
      bankCodeString: '1470314',
      bankCode: '147',
      bankName: '三信商業銀行',
      branchCode: '0314',
      branchName: '新莊分行',
      totalName: '0314 ╴新莊分行'
    },
    {
      bankCodeString: '1470323',
      bankCode: '147',
      bankName: '三信商業銀行',
      branchCode: '0323',
      branchName: '新竹分行',
      totalName: '0323 ╴新竹分行'
    },
    {
      bankCodeString: '1470332',
      bankCode: '147',
      bankName: '三信商業銀行',
      branchCode: '0332',
      branchName: '鳳山分行',
      totalName: '0332 ╴鳳山分行'
    },
    {
      bankCodeString: '1470341',
      bankCode: '147',
      bankName: '三信商業銀行',
      branchCode: '0341',
      branchName: '大雅分行',
      totalName: '0341 ╴大雅分行'
    },
    {
      bankCodeString: '1470350',
      bankCode: '147',
      bankName: '三信商業銀行',
      branchCode: '0350',
      branchName: '橋頭分行',
      totalName: '0350 ╴橋頭分行'
    },
    {
      bankCodeString: '1470369',
      bankCode: '147',
      bankName: '三信商業銀行',
      branchCode: '0369',
      branchName: '大肚分行',
      totalName: '0369 ╴大肚分行'
    },
    {
      bankCodeString: '1470378',
      bankCode: '147',
      bankName: '三信商業銀行',
      branchCode: '0378',
      branchName: '龍井分行',
      totalName: '0378 ╴龍井分行'
    },
    {
      bankCodeString: '1470387',
      bankCode: '147',
      bankName: '三信商業銀行',
      branchCode: '0387',
      branchName: '田中分行',
      totalName: '0387 ╴田中分行'
    },
    {
      bankCodeString: '1470396',
      bankCode: '147',
      bankName: '三信商業銀行',
      branchCode: '0396',
      branchName: '太平分行',
      totalName: '0396 ╴太平分行'
    },
    {
      bankCodeString: '1470402',
      bankCode: '147',
      bankName: '三信商業銀行',
      branchCode: '0402',
      branchName: '青埔分行',
      totalName: '0402 ╴青埔分行'
    },
    {
      bankCodeString: '1470411',
      bankCode: '147',
      bankName: '三信商業銀行',
      branchCode: '0411',
      branchName: '烏日分行',
      totalName: '0411 ╴烏日分行'
    },
    {
      bankCodeString: '1470907',
      bankCode: '147',
      bankName: '三信商業銀行',
      branchCode: '0907',
      branchName: '信託部',
      totalName: '0907 ╴信託部'
    },
    {
      bankCodeString: '1470925',
      bankCode: '147',
      bankName: '三信商業銀行',
      branchCode: '0925',
      branchName: '國外部',
      totalName: '0925 ╴國外部'
    },
    {
      bankCodeString: '1470934',
      bankCode: '147',
      bankName: '三信商業銀行',
      branchCode: '0934',
      branchName: '國際金融業務分行',
      totalName: '0934 ╴國際金融業務分行'
    }
  ],
  '700': [
    {
      bankCodeString: '7000021',
      bankCode: '700',
      bankName: '中華郵政',
      branchCode: '0021',
      branchName: '中華郵政',
      totalName: '0021 ╴中華郵政'
    }
  ],
  '803': [
    {
      bankCodeString: '8030010',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0010',
      branchName: '台北分行',
      totalName: '0010 ╴台北分行'
    },
    {
      bankCodeString: '8030021',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0021',
      branchName: '營業部',
      totalName: '0021 ╴營業部'
    },
    {
      bankCodeString: '8030032',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0032',
      branchName: '桃園分行',
      totalName: '0032 ╴桃園分行'
    },
    {
      bankCodeString: '8030043',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0043',
      branchName: '台中分行',
      totalName: '0043 ╴台中分行'
    },
    {
      bankCodeString: '8030054',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0054',
      branchName: '南京東路分行',
      totalName: '0054 ╴南京東路分行'
    },
    {
      bankCodeString: '8030065',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0065',
      branchName: '中壢分行',
      totalName: '0065 ╴中壢分行'
    },
    {
      bankCodeString: '8030076',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0076',
      branchName: '三重分行',
      totalName: '0076 ╴三重分行'
    },
    {
      bankCodeString: '8030087',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0087',
      branchName: '信託部',
      totalName: '0087 ╴信託部'
    },
    {
      bankCodeString: '8030098',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0098',
      branchName: '東台北分行',
      totalName: '0098 ╴東台北分行'
    },
    {
      bankCodeString: '8030102',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0102',
      branchName: '高雄分行',
      totalName: '0102 ╴高雄分行'
    },
    {
      bankCodeString: '8030113',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0113',
      branchName: '板橋分行',
      totalName: '0113 ╴板橋分行'
    },
    {
      bankCodeString: '8030124',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0124',
      branchName: '台南分行',
      totalName: '0124 ╴台南分行'
    },
    {
      bankCodeString: '8030135',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0135',
      branchName: '員林分行',
      totalName: '0135 ╴員林分行'
    },
    {
      bankCodeString: '8030146',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0146',
      branchName: '國外部',
      totalName: '0146 ╴國外部'
    },
    {
      bankCodeString: '8030157',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0157',
      branchName: '仁愛分行',
      totalName: '0157 ╴仁愛分行'
    },
    {
      bankCodeString: '8030168',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0168',
      branchName: '南崁分行',
      totalName: '0168 ╴南崁分行'
    },
    {
      bankCodeString: '8030179',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0179',
      branchName: '新竹分行',
      totalName: '0179 ╴新竹分行'
    },
    {
      bankCodeString: '8030180',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0180',
      branchName: '蘆洲分行',
      totalName: '0180 ╴蘆洲分行'
    },
    {
      bankCodeString: '8030191',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0191',
      branchName: '國際金融業務分行',
      totalName: '0191 ╴國際金融業務分行'
    },
    {
      bankCodeString: '8030205',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0205',
      branchName: '九如分行',
      totalName: '0205 ╴九如分行'
    },
    {
      bankCodeString: '8030216',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0216',
      branchName: '雙和分行',
      totalName: '0216 ╴雙和分行'
    },
    {
      bankCodeString: '8030227',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0227',
      branchName: '迴龍分行',
      totalName: '0227 ╴迴龍分行'
    },
    {
      bankCodeString: '8030238',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0238',
      branchName: '嘉義分行',
      totalName: '0238 ╴嘉義分行'
    },
    {
      bankCodeString: '8030249',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0249',
      branchName: '公館分行',
      totalName: '0249 ╴公館分行'
    },
    {
      bankCodeString: '8030250',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0250',
      branchName: '內壢分行',
      totalName: '0250 ╴內壢分行'
    },
    {
      bankCodeString: '8030261',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0261',
      branchName: '忠孝分行',
      totalName: '0261 ╴忠孝分行'
    },
    {
      bankCodeString: '8030272',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0272',
      branchName: '新莊分行',
      totalName: '0272 ╴新莊分行'
    },
    {
      bankCodeString: '8030283',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0283',
      branchName: '鳳山分行',
      totalName: '0283 ╴鳳山分行'
    },
    {
      bankCodeString: '8030294',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0294',
      branchName: '桃鶯分行',
      totalName: '0294 ╴桃鶯分行'
    },
    {
      bankCodeString: '8030308',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0308',
      branchName: '龍潭分行',
      totalName: '0308 ╴龍潭分行'
    },
    {
      bankCodeString: '8030319',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0319',
      branchName: '新店分行',
      totalName: '0319 ╴新店分行'
    },
    {
      bankCodeString: '8030320',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0320',
      branchName: '大直分行',
      totalName: '0320 ╴大直分行'
    },
    {
      bankCodeString: '8030331',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0331',
      branchName: '中山簡易型分行',
      totalName: '0331 ╴中山簡易型分行'
    },
    {
      bankCodeString: '8030342',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0342',
      branchName: '文心分行',
      totalName: '0342 ╴文心分行'
    },
    {
      bankCodeString: '8030353',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0353',
      branchName: '健行分行',
      totalName: '0353 ╴健行分行'
    },
    {
      bankCodeString: '8030364',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0364',
      branchName: '中和分行',
      totalName: '0364 ╴中和分行'
    },
    {
      bankCodeString: '8030375',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0375',
      branchName: '內湖分行',
      totalName: '0375 ╴內湖分行'
    },
    {
      bankCodeString: '8030386',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0386',
      branchName: '大園分行',
      totalName: '0386 ╴大園分行'
    },
    {
      bankCodeString: '8030397',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0397',
      branchName: '永和分行',
      totalName: '0397 ╴永和分行'
    },
    {
      bankCodeString: '8030401',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0401',
      branchName: '中港分行',
      totalName: '0401 ╴中港分行'
    },
    {
      bankCodeString: '8030412',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0412',
      branchName: '通化簡易型分行',
      totalName: '0412 ╴通化簡易型分行'
    },
    {
      bankCodeString: '8030423',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0423',
      branchName: '士東分行',
      totalName: '0423 ╴士東分行'
    },
    {
      bankCodeString: '8030434',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0434',
      branchName: '微風簡易型分行',
      totalName: '0434 ╴微風簡易型分行'
    },
    {
      bankCodeString: '8030445',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0445',
      branchName: '長春分行',
      totalName: '0445 ╴長春分行'
    },
    {
      bankCodeString: '8030456',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0456',
      branchName: '松江分行',
      totalName: '0456 ╴松江分行'
    },
    {
      bankCodeString: '8030467',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0467',
      branchName: '大安分行',
      totalName: '0467 ╴大安分行'
    },
    {
      bankCodeString: '8030478',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0478',
      branchName: '永吉分行',
      totalName: '0478 ╴永吉分行'
    },
    {
      bankCodeString: '8030489',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0489',
      branchName: '文林分行',
      totalName: '0489 ╴文林分行'
    },
    {
      bankCodeString: '8030490',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0490',
      branchName: '東門分行',
      totalName: '0490 ╴東門分行'
    },
    {
      bankCodeString: '8030515',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0515',
      branchName: '西湖分行',
      totalName: '0515 ╴西湖分行'
    },
    {
      bankCodeString: '8030526',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0526',
      branchName: '北投簡易型分行',
      totalName: '0526 ╴北投簡易型分行'
    },
    {
      bankCodeString: '8030537',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0537',
      branchName: '北三重分行',
      totalName: '0537 ╴北三重分行'
    },
    {
      bankCodeString: '8030548',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0548',
      branchName: '後埔分行',
      totalName: '0548 ╴後埔分行'
    },
    {
      bankCodeString: '8030559',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0559',
      branchName: '北中和簡易型分行',
      totalName: '0559 ╴北中和簡易型分行'
    },
    {
      bankCodeString: '8030560',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0560',
      branchName: '富國分行',
      totalName: '0560 ╴富國分行'
    },
    {
      bankCodeString: '8030571',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0571',
      branchName: '樹林分行',
      totalName: '0571 ╴樹林分行'
    },
    {
      bankCodeString: '8030582',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0582',
      branchName: '汐止分行',
      totalName: '0582 ╴汐止分行'
    },
    {
      bankCodeString: '8030593',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0593',
      branchName: '北桃園分行',
      totalName: '0593 ╴北桃園分行'
    },
    {
      bankCodeString: '8030607',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0607',
      branchName: '北中壢分行',
      totalName: '0607 ╴北中壢分行'
    },
    {
      bankCodeString: '8030618',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0618',
      branchName: '北台中分行',
      totalName: '0618 ╴北台中分行'
    },
    {
      bankCodeString: '8030629',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0629',
      branchName: '民權分行',
      totalName: '0629 ╴民權分行'
    },
    {
      bankCodeString: '8030630',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0630',
      branchName: '西屯分行',
      totalName: '0630 ╴西屯分行'
    },
    {
      bankCodeString: '8030641',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0641',
      branchName: '興中分行',
      totalName: '0641 ╴興中分行'
    },
    {
      bankCodeString: '8030652',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0652',
      branchName: '北屯分行',
      totalName: '0652 ╴北屯分行'
    },
    {
      bankCodeString: '8030685',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0685',
      branchName: '府城分行',
      totalName: '0685 ╴府城分行'
    },
    {
      bankCodeString: '8030696',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0696',
      branchName: '豐原分行',
      totalName: '0696 ╴豐原分行'
    },
    {
      bankCodeString: '8030700',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0700',
      branchName: '富強分行',
      totalName: '0700 ╴富強分行'
    },
    {
      bankCodeString: '8030711',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0711',
      branchName: '開元分行',
      totalName: '0711 ╴開元分行'
    },
    {
      bankCodeString: '8030722',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0722',
      branchName: '南台南分行',
      totalName: '0722 ╴南台南分行'
    },
    {
      bankCodeString: '8030733',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0733',
      branchName: '苓雅分行',
      totalName: '0733 ╴苓雅分行'
    },
    {
      bankCodeString: '8030744',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0744',
      branchName: '北高雄分行',
      totalName: '0744 ╴北高雄分行'
    },
    {
      bankCodeString: '8030755',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0755',
      branchName: '三民分行',
      totalName: '0755 ╴三民分行'
    },
    {
      bankCodeString: '8030766',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0766',
      branchName: '五甲分行',
      totalName: '0766 ╴五甲分行'
    },
    {
      bankCodeString: '8030777',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0777',
      branchName: '屏東分行',
      totalName: '0777 ╴屏東分行'
    },
    {
      bankCodeString: '8030788',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0788',
      branchName: '和平分行',
      totalName: '0788 ╴和平分行'
    },
    {
      bankCodeString: '8030799',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0799',
      branchName: '永春分行',
      totalName: '0799 ╴永春分行'
    },
    {
      bankCodeString: '8030803',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0803',
      branchName: '田心分行',
      totalName: '0803 ╴田心分行'
    },
    {
      bankCodeString: '8030814',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0814',
      branchName: '安康分行',
      totalName: '0814 ╴安康分行'
    },
    {
      bankCodeString: '8030825',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0825',
      branchName: '大竹分行',
      totalName: '0825 ╴大竹分行'
    },
    {
      bankCodeString: '8030836',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0836',
      branchName: '龜山分行',
      totalName: '0836 ╴龜山分行'
    },
    {
      bankCodeString: '8030847',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0847',
      branchName: '林口分行',
      totalName: '0847 ╴林口分行'
    },
    {
      bankCodeString: '8030858',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0858',
      branchName: '五股分行',
      totalName: '0858 ╴五股分行'
    },
    {
      bankCodeString: '8030869',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0869',
      branchName: '高榮分行',
      totalName: '0869 ╴高榮分行'
    },
    {
      bankCodeString: '8030870',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0870',
      branchName: '大業分行',
      totalName: '0870 ╴大業分行'
    },
    {
      bankCodeString: '8030881',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0881',
      branchName: '蘆竹分行',
      totalName: '0881 ╴蘆竹分行'
    },
    {
      bankCodeString: '8030892',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0892',
      branchName: '土城分行',
      totalName: '0892 ╴土城分行'
    },
    {
      bankCodeString: '8030906',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0906',
      branchName: '南桃園分行',
      totalName: '0906 ╴南桃園分行'
    },
    {
      bankCodeString: '8030917',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0917',
      branchName: '集賢分行',
      totalName: '0917 ╴集賢分行'
    },
    {
      bankCodeString: '8030928',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0928',
      branchName: '三峽分行',
      totalName: '0928 ╴三峽分行'
    },
    {
      bankCodeString: '8030939',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0939',
      branchName: '東湖分行',
      totalName: '0939 ╴東湖分行'
    },
    {
      bankCodeString: '8030940',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0940',
      branchName: '淡水簡易型分行',
      totalName: '0940 ╴淡水簡易型分行'
    },
    {
      bankCodeString: '8030951',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0951',
      branchName: '金城分行',
      totalName: '0951 ╴金城分行'
    },
    {
      bankCodeString: '8030962',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '0962',
      branchName: '南港分行',
      totalName: '0962 ╴南港分行'
    },
    {
      bankCodeString: '8037019',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '7019',
      branchName: '越南胡志明市代表人辦事處',
      totalName: '7019 ╴越南胡志明市代表人辦事處'
    },
    {
      bankCodeString: '8037020',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '7020',
      branchName: '香港代表人辦事處',
      totalName: '7020 ╴香港代表人辦事處'
    },
    {
      bankCodeString: '8037031',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '7031',
      branchName: '越南河內代表人辦事處',
      totalName: '7031 ╴越南河內代表人辦事處'
    },
    {
      bankCodeString: '8038005',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '8005',
      branchName: '證券金融部',
      totalName: '8005 ╴證券金融部'
    },
    {
      bankCodeString: '8038580',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '8580',
      branchName: '兼營證券商',
      totalName: '8580 ╴兼營證券商'
    },
    {
      bankCodeString: '8038581',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '8581',
      branchName: '證券商高雄分公司',
      totalName: '8581 ╴證券商高雄分公司'
    },
    {
      bankCodeString: '8038582',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '8582',
      branchName: '證券商嘉義分公司',
      totalName: '8582 ╴證券商嘉義分公司'
    },
    {
      bankCodeString: '8038583',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '8583',
      branchName: '證券商仁愛分公司',
      totalName: '8583 ╴證券商仁愛分公司'
    },
    {
      bankCodeString: '8038584',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '8584',
      branchName: '證券商雙和分公司',
      totalName: '8584 ╴證券商雙和分公司'
    },
    {
      bankCodeString: '8038585',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '8585',
      branchName: '證券商三重分公司',
      totalName: '8585 ╴證券商三重分公司'
    },
    {
      bankCodeString: '8039002',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: '9002',
      branchName: '(香港)聯邦財務有限公司',
      totalName: '9002 ╴(香港)聯邦財務有限公司'
    },
    {
      bankCodeString: '803P801',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: 'P801',
      branchName: '業務管理部',
      totalName: 'P801 ╴業務管理部'
    },
    {
      bankCodeString: '803P802',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: 'P802',
      branchName: '消費金融部',
      totalName: 'P802 ╴消費金融部'
    },
    {
      bankCodeString: '803P804',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: 'P804',
      branchName: '人事部',
      totalName: 'P804 ╴人事部'
    },
    {
      bankCodeString: '803P806',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: 'P806',
      branchName: '總務部',
      totalName: 'P806 ╴總務部'
    },
    {
      bankCodeString: '803P807',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: 'P807',
      branchName: '資訊部',
      totalName: 'P807 ╴資訊部'
    },
    {
      bankCodeString: '803P808',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: 'P808',
      branchName: '企業金融部',
      totalName: 'P808 ╴企業金融部'
    },
    {
      bankCodeString: '803P809',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: 'P809',
      branchName: '信用卡中心(台北)',
      totalName: 'P809 ╴信用卡中心(台北)'
    },
    {
      bankCodeString: '803P810',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: 'P810',
      branchName: '信用卡中心(高雄)',
      totalName: 'P810 ╴信用卡中心(高雄)'
    },
    {
      bankCodeString: '803P811',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: 'P811',
      branchName: '行銷企劃部',
      totalName: 'P811 ╴行銷企劃部'
    },
    {
      bankCodeString: '803P812',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: 'P812',
      branchName: '財務部',
      totalName: 'P812 ╴財務部'
    },
    {
      bankCodeString: '803P813',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: 'P813',
      branchName: '稽核部',
      totalName: 'P813 ╴稽核部'
    },
    {
      bankCodeString: '803P814',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: 'P814',
      branchName: '債權管理部',
      totalName: 'P814 ╴債權管理部'
    },
    {
      bankCodeString: '803P815',
      bankCode: '803',
      bankName: '聯邦商業銀行',
      branchCode: 'P815',
      branchName: '理財貸款部',
      totalName: 'P815 ╴理財貸款部'
    }
  ],
  '805': [
    {
      bankCodeString: '8050012',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0012',
      branchName: '營業部',
      totalName: '0012 ╴營業部'
    },
    {
      bankCodeString: '8050023',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0023',
      branchName: '台北城中分行',
      totalName: '0023 ╴台北城中分行'
    },
    {
      bankCodeString: '8050034',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0034',
      branchName: '台北逸仙分行',
      totalName: '0034 ╴台北逸仙分行'
    },
    {
      bankCodeString: '8050045',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0045',
      branchName: '桃園分行',
      totalName: '0045 ╴桃園分行'
    },
    {
      bankCodeString: '8050056',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0056',
      branchName: '台中公益分行',
      totalName: '0056 ╴台中公益分行'
    },
    {
      bankCodeString: '8050067',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0067',
      branchName: '台南分行',
      totalName: '0067 ╴台南分行'
    },
    {
      bankCodeString: '8050078',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0078',
      branchName: '高雄五福分行',
      totalName: '0078 ╴高雄五福分行'
    },
    {
      bankCodeString: '8050090',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0090',
      branchName: '板橋南雅分行',
      totalName: '0090 ╴板橋南雅分行'
    },
    {
      bankCodeString: '8050104',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0104',
      branchName: '新竹巨城分行',
      totalName: '0104 ╴新竹巨城分行'
    },
    {
      bankCodeString: '8050115',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0115',
      branchName: '高雄中正分行',
      totalName: '0115 ╴高雄中正分行'
    },
    {
      bankCodeString: '8050126',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0126',
      branchName: '信託部',
      totalName: '0126 ╴信託部'
    },
    {
      bankCodeString: '8050137',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0137',
      branchName: '國外部',
      totalName: '0137 ╴國外部'
    },
    {
      bankCodeString: '8050148',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0148',
      branchName: '三重分行',
      totalName: '0148 ╴三重分行'
    },
    {
      bankCodeString: '8050159',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0159',
      branchName: '台北松江分行',
      totalName: '0159 ╴台北松江分行'
    },
    {
      bankCodeString: '8050160',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0160',
      branchName: '永康分行',
      totalName: '0160 ╴永康分行'
    },
    {
      bankCodeString: '8050171',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0171',
      branchName: '中壢分行',
      totalName: '0171 ╴中壢分行'
    },
    {
      bankCodeString: '8050182',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0182',
      branchName: '國際金融業務分行',
      totalName: '0182 ╴國際金融業務分行'
    },
    {
      bankCodeString: '8050193',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0193',
      branchName: '永和分行',
      totalName: '0193 ╴永和分行'
    },
    {
      bankCodeString: '8050207',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0207',
      branchName: '台北東門分行',
      totalName: '0207 ╴台北東門分行'
    },
    {
      bankCodeString: '8050218',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0218',
      branchName: '高雄文化中心分行',
      totalName: '0218 ╴高雄文化中心分行'
    },
    {
      bankCodeString: '8050229',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0229',
      branchName: '台中文心分行',
      totalName: '0229 ╴台中文心分行'
    },
    {
      bankCodeString: '8050230',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0230',
      branchName: '新竹科學園區分行',
      totalName: '0230 ╴新竹科學園區分行'
    },
    {
      bankCodeString: '8050241',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0241',
      branchName: '桃園大興分行',
      totalName: '0241 ╴桃園大興分行'
    },
    {
      bankCodeString: '8050252',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0252',
      branchName: '台北南門分行',
      totalName: '0252 ╴台北南門分行'
    },
    {
      bankCodeString: '8050263',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0263',
      branchName: '台北新莊分行',
      totalName: '0263 ╴台北新莊分行'
    },
    {
      bankCodeString: '8050274',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0274',
      branchName: '台北松山分行',
      totalName: '0274 ╴台北松山分行'
    },
    {
      bankCodeString: '8050285',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0285',
      branchName: '新店分行',
      totalName: '0285 ╴新店分行'
    },
    {
      bankCodeString: '8050296',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0296',
      branchName: '台北重慶分行',
      totalName: '0296 ╴台北重慶分行'
    },
    {
      bankCodeString: '8050300',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0300',
      branchName: '竹北分行',
      totalName: '0300 ╴竹北分行'
    },
    {
      bankCodeString: '8050311',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0311',
      branchName: '台北忠孝分行',
      totalName: '0311 ╴台北忠孝分行'
    },
    {
      bankCodeString: '8050322',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0322',
      branchName: '林口分行',
      totalName: '0322 ╴林口分行'
    },
    {
      bankCodeString: '8050333',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0333',
      branchName: '板橋文化分行',
      totalName: '0333 ╴板橋文化分行'
    },
    {
      bankCodeString: '8050344',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0344',
      branchName: '台中自由分行',
      totalName: '0344 ╴台中自由分行'
    },
    {
      bankCodeString: '8050355',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0355',
      branchName: '新莊富國分行',
      totalName: '0355 ╴新莊富國分行'
    },
    {
      bankCodeString: '8050366',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0366',
      branchName: '台北永吉分行',
      totalName: '0366 ╴台北永吉分行'
    },
    {
      bankCodeString: '8050388',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0388',
      branchName: '桃園大有分行',
      totalName: '0388 ╴桃園大有分行'
    },
    {
      bankCodeString: '8050399',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0399',
      branchName: '台北金湖簡易型分行',
      totalName: '0399 ╴台北金湖簡易型分行'
    },
    {
      bankCodeString: '8050414',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0414',
      branchName: '台北承德分行',
      totalName: '0414 ╴台北承德分行'
    },
    {
      bankCodeString: '8050425',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0425',
      branchName: '台北古亭分行',
      totalName: '0425 ╴台北古亭分行'
    },
    {
      bankCodeString: '8050436',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0436',
      branchName: '台北信義分行',
      totalName: '0436 ╴台北信義分行'
    },
    {
      bankCodeString: '8050458',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0458',
      branchName: '蘆洲分行',
      totalName: '0458 ╴蘆洲分行'
    },
    {
      bankCodeString: '8050469',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0469',
      branchName: '中和分行',
      totalName: '0469 ╴中和分行'
    },
    {
      bankCodeString: '8050470',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0470',
      branchName: '板橋中正分行',
      totalName: '0470 ╴板橋中正分行'
    },
    {
      bankCodeString: '8050481',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0481',
      branchName: '新竹經國分行',
      totalName: '0481 ╴新竹經國分行'
    },
    {
      bankCodeString: '8050492',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0492',
      branchName: '台中大雅分行',
      totalName: '0492 ╴台中大雅分行'
    },
    {
      bankCodeString: '8050506',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0506',
      branchName: '台中朝富分行',
      totalName: '0506 ╴台中朝富分行'
    },
    {
      bankCodeString: '8050517',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0517',
      branchName: '南投分行',
      totalName: '0517 ╴南投分行'
    },
    {
      bankCodeString: '8050528',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0528',
      branchName: '嘉義分行',
      totalName: '0528 ╴嘉義分行'
    },
    {
      bankCodeString: '8050540',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0540',
      branchName: '高雄博愛分行',
      totalName: '0540 ╴高雄博愛分行'
    },
    {
      bankCodeString: '8050562',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0562',
      branchName: '大里分行',
      totalName: '0562 ╴大里分行'
    },
    {
      bankCodeString: '8050573',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0573',
      branchName: '崇德分行',
      totalName: '0573 ╴崇德分行'
    },
    {
      bankCodeString: '8050584',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0584',
      branchName: '板橋大遠百分行',
      totalName: '0584 ╴板橋大遠百分行'
    },
    {
      bankCodeString: '8050595',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0595',
      branchName: '台北敦南分行',
      totalName: '0595 ╴台北敦南分行'
    },
    {
      bankCodeString: '8050609',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0609',
      branchName: '雙和分行',
      totalName: '0609 ╴雙和分行'
    },
    {
      bankCodeString: '8050610',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0610',
      branchName: '台北南京東路分行',
      totalName: '0610 ╴台北南京東路分行'
    },
    {
      bankCodeString: '8050621',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0621',
      branchName: '台北大稻埕分行',
      totalName: '0621 ╴台北大稻埕分行'
    },
    {
      bankCodeString: '8050632',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0632',
      branchName: '石牌分行',
      totalName: '0632 ╴石牌分行'
    },
    {
      bankCodeString: '8050654',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0654',
      branchName: '台北復興簡易型分行',
      totalName: '0654 ╴台北復興簡易型分行'
    },
    {
      bankCodeString: '8050890',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '0890',
      branchName: '兼營證券商',
      totalName: '0890 ╴兼營證券商'
    },
    {
      bankCodeString: '8058605',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '8605',
      branchName: '秘書處',
      totalName: '8605 ╴秘書處'
    },
    {
      bankCodeString: '8058607',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '8607',
      branchName: '會計處',
      totalName: '8607 ╴會計處'
    },
    {
      bankCodeString: '8058801',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '8801',
      branchName: '資訊服務處',
      totalName: '8801 ╴資訊服務處'
    },
    {
      bankCodeString: '8058802',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '8802',
      branchName: '稽核處',
      totalName: '8802 ╴稽核處'
    },
    {
      bankCodeString: '8058803',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '8803',
      branchName: '人力資源處',
      totalName: '8803 ╴人力資源處'
    },
    {
      bankCodeString: '8058804',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '8804',
      branchName: '金融市場部',
      totalName: '8804 ╴金融市場部'
    },
    {
      bankCodeString: '8058805',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '8805',
      branchName: '企劃處',
      totalName: '8805 ╴企劃處'
    },
    {
      bankCodeString: '8058806',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '8806',
      branchName: '信用卡部',
      totalName: '8806 ╴信用卡部'
    },
    {
      bankCodeString: '8058807',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '8807',
      branchName: '風險管理處',
      totalName: '8807 ╴風險管理處'
    },
    {
      bankCodeString: '8058808',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '8808',
      branchName: '法務處',
      totalName: '8808 ╴法務處'
    },
    {
      bankCodeString: '8058809',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '8809',
      branchName: '消費金融部',
      totalName: '8809 ╴消費金融部'
    },
    {
      bankCodeString: '8058810',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '8810',
      branchName: '法人金融部',
      totalName: '8810 ╴法人金融部'
    },
    {
      bankCodeString: '8058811',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '8811',
      branchName: '貿易金融營業處',
      totalName: '8811 ╴貿易金融營業處'
    },
    {
      bankCodeString: '8058812',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '8812',
      branchName: '消費金及信用卡事業群銷售通路部',
      totalName: '8812 ╴消費金及信用卡事業群銷售通路部'
    },
    {
      bankCodeString: '8059004',
      bankCode: '805',
      bankName: '遠東國際商業銀行',
      branchCode: '9004',
      branchName: '香港分行',
      totalName: '9004 ╴香港分行'
    }
  ],
  '806': [
    {
      bankCodeString: '8060009',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0009',
      branchName: '資訊部',
      totalName: '0009 ╴資訊部'
    },
    {
      bankCodeString: '8060013',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0013',
      branchName: '台中分行',
      totalName: '0013 ╴台中分行'
    },
    {
      bankCodeString: '8060024',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0024',
      branchName: '文心分行',
      totalName: '0024 ╴文心分行'
    },
    {
      bankCodeString: '8060035',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0035',
      branchName: '台北分行',
      totalName: '0035 ╴台北分行'
    },
    {
      bankCodeString: '8060046',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0046',
      branchName: '沙鹿分行',
      totalName: '0046 ╴沙鹿分行'
    },
    {
      bankCodeString: '8060057',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0057',
      branchName: '豐原分行',
      totalName: '0057 ╴豐原分行'
    },
    {
      bankCodeString: '8060068',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0068',
      branchName: '彰化分行',
      totalName: '0068 ╴彰化分行'
    },
    {
      bankCodeString: '8060079',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0079',
      branchName: '員林分行',
      totalName: '0079 ╴員林分行'
    },
    {
      bankCodeString: '8060080',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0080',
      branchName: '三重分行',
      totalName: '0080 ╴三重分行'
    },
    {
      bankCodeString: '8060091',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0091',
      branchName: '平鎮分行',
      totalName: '0091 ╴平鎮分行'
    },
    {
      bankCodeString: '8060105',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0105',
      branchName: '草屯分行',
      totalName: '0105 ╴草屯分行'
    },
    {
      bankCodeString: '8060116',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0116',
      branchName: '永康分行',
      totalName: '0116 ╴永康分行'
    },
    {
      bankCodeString: '8060138',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0138',
      branchName: '信託部',
      totalName: '0138 ╴信託部'
    },
    {
      bankCodeString: '8060149',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0149',
      branchName: '國外部',
      totalName: '0149 ╴國外部'
    },
    {
      bankCodeString: '8060150',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0150',
      branchName: '景美分行',
      totalName: '0150 ╴景美分行'
    },
    {
      bankCodeString: '8060161',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0161',
      branchName: '新竹分行',
      totalName: '0161 ╴新竹分行'
    },
    {
      bankCodeString: '8060172',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0172',
      branchName: '鹿港分行',
      totalName: '0172 ╴鹿港分行'
    },
    {
      bankCodeString: '8060183',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0183',
      branchName: '斗信分行',
      totalName: '0183 ╴斗信分行'
    },
    {
      bankCodeString: '8060194',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0194',
      branchName: '嘉義分行',
      totalName: '0194 ╴嘉義分行'
    },
    {
      bankCodeString: '8060208',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0208',
      branchName: '國際金融業務分行',
      totalName: '0208 ╴國際金融業務分行'
    },
    {
      bankCodeString: '8060219',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0219',
      branchName: '南京東路分行',
      totalName: '0219 ╴南京東路分行'
    },
    {
      bankCodeString: '8060220',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0220',
      branchName: '永和分行',
      totalName: '0220 ╴永和分行'
    },
    {
      bankCodeString: '8060231',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0231',
      branchName: '復興分行',
      totalName: '0231 ╴復興分行'
    },
    {
      bankCodeString: '8060242',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0242',
      branchName: '大里分行',
      totalName: '0242 ╴大里分行'
    },
    {
      bankCodeString: '8060253',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0253',
      branchName: '博愛分行',
      totalName: '0253 ╴博愛分行'
    },
    {
      bankCodeString: '8060264',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0264',
      branchName: '苗栗分行',
      totalName: '0264 ╴苗栗分行'
    },
    {
      bankCodeString: '8060275',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0275',
      branchName: '崇德分行',
      totalName: '0275 ╴崇德分行'
    },
    {
      bankCodeString: '8060286',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0286',
      branchName: '北斗分行',
      totalName: '0286 ╴北斗分行'
    },
    {
      bankCodeString: '8060297',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0297',
      branchName: '佳里分行',
      totalName: '0297 ╴佳里分行'
    },
    {
      bankCodeString: '8060301',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0301',
      branchName: '大甲分行',
      totalName: '0301 ╴大甲分行'
    },
    {
      bankCodeString: '8060312',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0312',
      branchName: '虎尾分行',
      totalName: '0312 ╴虎尾分行'
    },
    {
      bankCodeString: '8060334',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0334',
      branchName: '士林分行',
      totalName: '0334 ╴士林分行'
    },
    {
      bankCodeString: '8060345',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0345',
      branchName: '南崁分行',
      totalName: '0345 ╴南崁分行'
    },
    {
      bankCodeString: '8060367',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0367',
      branchName: '古亭分行',
      totalName: '0367 ╴古亭分行'
    },
    {
      bankCodeString: '8060378',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0378',
      branchName: '新莊分行',
      totalName: '0378 ╴新莊分行'
    },
    {
      bankCodeString: '8060389',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0389',
      branchName: '中壢分行',
      totalName: '0389 ╴中壢分行'
    },
    {
      bankCodeString: '8060404',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0404',
      branchName: '三民分行',
      totalName: '0404 ╴三民分行'
    },
    {
      bankCodeString: '8060415',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0415',
      branchName: '城中分行',
      totalName: '0415 ╴城中分行'
    },
    {
      bankCodeString: '8060426',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0426',
      branchName: '屏東分行',
      totalName: '0426 ╴屏東分行'
    },
    {
      bankCodeString: '8060448',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0448',
      branchName: '斗南分行',
      totalName: '0448 ╴斗南分行'
    },
    {
      bankCodeString: '8060482',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0482',
      branchName: '板橋分行',
      totalName: '0482 ╴板橋分行'
    },
    {
      bankCodeString: '8060493',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0493',
      branchName: '桃園分行',
      totalName: '0493 ╴桃園分行'
    },
    {
      bankCodeString: '8060518',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0518',
      branchName: '三多分行',
      totalName: '0518 ╴三多分行'
    },
    {
      bankCodeString: '8060529',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0529',
      branchName: '東信分行',
      totalName: '0529 ╴東信分行'
    },
    {
      bankCodeString: '8060585',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0585',
      branchName: '內湖分行',
      totalName: '0585 ╴內湖分行'
    },
    {
      bankCodeString: '8060596',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0596',
      branchName: '羅東分行',
      totalName: '0596 ╴羅東分行'
    },
    {
      bankCodeString: '8060600',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0600',
      branchName: '金門分行',
      totalName: '0600 ╴金門分行'
    },
    {
      bankCodeString: '8060611',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0611',
      branchName: '台南分行',
      totalName: '0611 ╴台南分行'
    },
    {
      bankCodeString: '8060622',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0622',
      branchName: '林口分行',
      totalName: '0622 ╴林口分行'
    },
    {
      bankCodeString: '8060633',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0633',
      branchName: '竹北分行',
      totalName: '0633 ╴竹北分行'
    },
    {
      bankCodeString: '8060670',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0670',
      branchName: '兼營證券商',
      totalName: '0670 ╴兼營證券商'
    },
    {
      bankCodeString: '8060688',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0688',
      branchName: '府城分行',
      totalName: '0688 ╴府城分行'
    },
    {
      bankCodeString: '8060699',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0699',
      branchName: '府東分行',
      totalName: '0699 ╴府東分行'
    },
    {
      bankCodeString: '8060806',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0806',
      branchName: '開元分行',
      totalName: '0806 ╴開元分行'
    },
    {
      bankCodeString: '8060828',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0828',
      branchName: '安和分行',
      totalName: '0828 ╴安和分行'
    },
    {
      bankCodeString: '8060839',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0839',
      branchName: '中和分行',
      totalName: '0839 ╴中和分行'
    },
    {
      bankCodeString: '8060840',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0840',
      branchName: '松江分行',
      totalName: '0840 ╴松江分行'
    },
    {
      bankCodeString: '8060851',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0851',
      branchName: '竹科分行',
      totalName: '0851 ╴竹科分行'
    },
    {
      bankCodeString: '8060862',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0862',
      branchName: '天母分行',
      totalName: '0862 ╴天母分行'
    },
    {
      bankCodeString: '8060873',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0873',
      branchName: '蘆洲分行',
      totalName: '0873 ╴蘆洲分行'
    },
    {
      bankCodeString: '8060884',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0884',
      branchName: '信義分行',
      totalName: '0884 ╴信義分行'
    },
    {
      bankCodeString: '8060895',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0895',
      branchName: '中山北路分行',
      totalName: '0895 ╴中山北路分行'
    },
    {
      bankCodeString: '8060909',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0909',
      branchName: '民生分行',
      totalName: '0909 ╴民生分行'
    },
    {
      bankCodeString: '8060910',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0910',
      branchName: '新店分行',
      totalName: '0910 ╴新店分行'
    },
    {
      bankCodeString: '8060932',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0932',
      branchName: '忠孝分行',
      totalName: '0932 ╴忠孝分行'
    },
    {
      bankCodeString: '8060943',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0943',
      branchName: '桃興分行',
      totalName: '0943 ╴桃興分行'
    },
    {
      bankCodeString: '8060954',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0954',
      branchName: '中港分行',
      totalName: '0954 ╴中港分行'
    },
    {
      bankCodeString: '8060965',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0965',
      branchName: '花蓮分行',
      totalName: '0965 ╴花蓮分行'
    },
    {
      bankCodeString: '8060976',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0976',
      branchName: '承德分行',
      totalName: '0976 ╴承德分行'
    },
    {
      bankCodeString: '8060987',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0987',
      branchName: '太平分行',
      totalName: '0987 ╴太平分行'
    },
    {
      bankCodeString: '8060998',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '0998',
      branchName: '營業部',
      totalName: '0998 ╴營業部'
    },
    {
      bankCodeString: '8061009',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1009',
      branchName: '土城分行',
      totalName: '1009 ╴土城分行'
    },
    {
      bankCodeString: '8061021',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1021',
      branchName: '大同分行',
      totalName: '1021 ╴大同分行'
    },
    {
      bankCodeString: '8061032',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1032',
      branchName: '松山分行',
      totalName: '1032 ╴松山分行'
    },
    {
      bankCodeString: '8061043',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1043',
      branchName: '新生分行',
      totalName: '1043 ╴新生分行'
    },
    {
      bankCodeString: '8061076',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1076',
      branchName: '北三重分行',
      totalName: '1076 ╴北三重分行'
    },
    {
      bankCodeString: '8061087',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1087',
      branchName: '上新莊分行',
      totalName: '1087 ╴上新莊分行'
    },
    {
      bankCodeString: '8061102',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1102',
      branchName: '高雄分行',
      totalName: '1102 ╴高雄分行'
    },
    {
      bankCodeString: '8061191',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1191',
      branchName: '大統分行',
      totalName: '1191 ╴大統分行'
    },
    {
      bankCodeString: '8061205',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1205',
      branchName: '雙和分行',
      totalName: '1205 ╴雙和分行'
    },
    {
      bankCodeString: '8061216',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1216',
      branchName: '北投分行',
      totalName: '1216 ╴北投分行'
    },
    {
      bankCodeString: '8061227',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1227',
      branchName: '延平分行',
      totalName: '1227 ╴延平分行'
    },
    {
      bankCodeString: '8061238',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1238',
      branchName: '文德分行',
      totalName: '1238 ╴文德分行'
    },
    {
      bankCodeString: '8061250',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1250',
      branchName: '左營分行',
      totalName: '1250 ╴左營分行'
    },
    {
      bankCodeString: '8061261',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1261',
      branchName: '埔墘分行',
      totalName: '1261 ╴埔墘分行'
    },
    {
      bankCodeString: '8061272',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1272',
      branchName: '公館分行',
      totalName: '1272 ╴公館分行'
    },
    {
      bankCodeString: '8061283',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1283',
      branchName: '新店中正分行',
      totalName: '1283 ╴新店中正分行'
    },
    {
      bankCodeString: '8061308',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1308',
      branchName: '西門分行',
      totalName: '1308 ╴西門分行'
    },
    {
      bankCodeString: '8061319',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1319',
      branchName: '樹林分行',
      totalName: '1319 ╴樹林分行'
    },
    {
      bankCodeString: '8061320',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1320',
      branchName: '光復分行',
      totalName: '1320 ╴光復分行'
    },
    {
      bankCodeString: '8061331',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1331',
      branchName: '南港分行',
      totalName: '1331 ╴南港分行'
    },
    {
      bankCodeString: '8061342',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1342',
      branchName: '苓雅分行',
      totalName: '1342 ╴苓雅分行'
    },
    {
      bankCodeString: '8061353',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1353',
      branchName: '敦化分行',
      totalName: '1353 ╴敦化分行'
    },
    {
      bankCodeString: '8061364',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1364',
      branchName: '屏榮分行',
      totalName: '1364 ╴屏榮分行'
    },
    {
      bankCodeString: '8061375',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1375',
      branchName: '前鎮中山分行',
      totalName: '1375 ╴前鎮中山分行'
    },
    {
      bankCodeString: '8061386',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1386',
      branchName: '高鳳分行',
      totalName: '1386 ╴高鳳分行'
    },
    {
      bankCodeString: '8061397',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1397',
      branchName: '北府分行',
      totalName: '1397 ╴北府分行'
    },
    {
      bankCodeString: '8061401',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1401',
      branchName: '南嘉義分行',
      totalName: '1401 ╴南嘉義分行'
    },
    {
      bankCodeString: '8061412',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1412',
      branchName: '東台北分行',
      totalName: '1412 ╴東台北分行'
    },
    {
      bankCodeString: '8061423',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1423',
      branchName: '南新莊分行',
      totalName: '1423 ╴南新莊分行'
    },
    {
      bankCodeString: '8061434',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1434',
      branchName: '新壢分行',
      totalName: '1434 ╴新壢分行'
    },
    {
      bankCodeString: '8061445',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1445',
      branchName: '水湳分行',
      totalName: '1445 ╴水湳分行'
    },
    {
      bankCodeString: '8061456',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1456',
      branchName: '大昌分行',
      totalName: '1456 ╴大昌分行'
    },
    {
      bankCodeString: '8061467',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1467',
      branchName: '大直分行',
      totalName: '1467 ╴大直分行'
    },
    {
      bankCodeString: '8061478',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1478',
      branchName: '東板橋分行',
      totalName: '1478 ╴東板橋分行'
    },
    {
      bankCodeString: '8061489',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1489',
      branchName: '沙鹿中山分行',
      totalName: '1489 ╴沙鹿中山分行'
    },
    {
      bankCodeString: '8061490',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1490',
      branchName: '十全分行',
      totalName: '1490 ╴十全分行'
    },
    {
      bankCodeString: '8061504',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1504',
      branchName: '延吉分行',
      totalName: '1504 ╴延吉分行'
    },
    {
      bankCodeString: '8061526',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1526',
      branchName: '成功分行',
      totalName: '1526 ╴成功分行'
    },
    {
      bankCodeString: '8061548',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1548',
      branchName: '右昌分行',
      totalName: '1548 ╴右昌分行'
    },
    {
      bankCodeString: '8061559',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1559',
      branchName: '前金分行',
      totalName: '1559 ╴前金分行'
    },
    {
      bankCodeString: '8061560',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1560',
      branchName: '五甲分行',
      totalName: '1560 ╴五甲分行'
    },
    {
      bankCodeString: '8061571',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1571',
      branchName: '連城分行',
      totalName: '1571 ╴連城分行'
    },
    {
      bankCodeString: '8061582',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1582',
      branchName: '新營分行',
      totalName: '1582 ╴新營分行'
    },
    {
      bankCodeString: '8061593',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1593',
      branchName: '和平分行',
      totalName: '1593 ╴和平分行'
    },
    {
      bankCodeString: '8061607',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1607',
      branchName: '金華分行',
      totalName: '1607 ╴金華分行'
    },
    {
      bankCodeString: '8061618',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1618',
      branchName: '瑞光分行',
      totalName: '1618 ╴瑞光分行'
    },
    {
      bankCodeString: '8061629',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1629',
      branchName: '灣裡分行',
      totalName: '1629 ╴灣裡分行'
    },
    {
      bankCodeString: '8061630',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1630',
      branchName: '安南分行',
      totalName: '1630 ╴安南分行'
    },
    {
      bankCodeString: '8061641',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1641',
      branchName: '江翠分行',
      totalName: '1641 ╴江翠分行'
    },
    {
      bankCodeString: '8061652',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1652',
      branchName: '長庚分行',
      totalName: '1652 ╴長庚分行'
    },
    {
      bankCodeString: '8061674',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1674',
      branchName: '世貿分行',
      totalName: '1674 ╴世貿分行'
    },
    {
      bankCodeString: '8061685',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1685',
      branchName: '東新竹分行',
      totalName: '1685 ╴東新竹分行'
    },
    {
      bankCodeString: '8061696',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1696',
      branchName: '汐止分行',
      totalName: '1696 ╴汐止分行'
    },
    {
      bankCodeString: '8061700',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1700',
      branchName: '忠誠分行',
      totalName: '1700 ╴忠誠分行'
    },
    {
      bankCodeString: '8061722',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1722',
      branchName: '澄清分行',
      totalName: '1722 ╴澄清分行'
    },
    {
      bankCodeString: '8061733',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1733',
      branchName: '秀朗分行',
      totalName: '1733 ╴秀朗分行'
    },
    {
      bankCodeString: '8061744',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1744',
      branchName: '小港分行',
      totalName: '1744 ╴小港分行'
    },
    {
      bankCodeString: '8061755',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1755',
      branchName: '北桃園分行',
      totalName: '1755 ╴北桃園分行'
    },
    {
      bankCodeString: '8061766',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1766',
      branchName: '永春分行',
      totalName: '1766 ╴永春分行'
    },
    {
      bankCodeString: '8061777',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1777',
      branchName: '圓山分行',
      totalName: '1777 ╴圓山分行'
    },
    {
      bankCodeString: '8061788',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1788',
      branchName: '豐仁分行',
      totalName: '1788 ╴豐仁分行'
    },
    {
      bankCodeString: '8061799',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1799',
      branchName: '彰興分行',
      totalName: '1799 ╴彰興分行'
    },
    {
      bankCodeString: '8061814',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1814',
      branchName: '大里德芳分行',
      totalName: '1814 ╴大里德芳分行'
    },
    {
      bankCodeString: '8061825',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1825',
      branchName: '北台中分行',
      totalName: '1825 ╴北台中分行'
    },
    {
      bankCodeString: '8061836',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1836',
      branchName: '旗津分行',
      totalName: '1836 ╴旗津分行'
    },
    {
      bankCodeString: '8061858',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1858',
      branchName: '鼓山分行',
      totalName: '1858 ╴鼓山分行'
    },
    {
      bankCodeString: '8061869',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1869',
      branchName: '前鎮分行',
      totalName: '1869 ╴前鎮分行'
    },
    {
      bankCodeString: '8061870',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1870',
      branchName: '明誠分行',
      totalName: '1870 ╴明誠分行'
    },
    {
      bankCodeString: '8061881',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1881',
      branchName: '中正分行',
      totalName: '1881 ╴中正分行'
    },
    {
      bankCodeString: '8061892',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1892',
      branchName: '石牌分行',
      totalName: '1892 ╴石牌分行'
    },
    {
      bankCodeString: '8061928',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1928',
      branchName: '南門分行',
      totalName: '1928 ╴南門分行'
    },
    {
      bankCodeString: '8061939',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1939',
      branchName: '新板分行',
      totalName: '1939 ╴新板分行'
    },
    {
      bankCodeString: '8061940',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1940',
      branchName: '北新分行',
      totalName: '1940 ╴北新分行'
    },
    {
      bankCodeString: '8061951',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1951',
      branchName: '南屯分行',
      totalName: '1951 ╴南屯分行'
    },
    {
      bankCodeString: '8061962',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1962',
      branchName: '中山分行',
      totalName: '1962 ╴中山分行'
    },
    {
      bankCodeString: '8061973',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1973',
      branchName: '東門分行',
      totalName: '1973 ╴東門分行'
    },
    {
      bankCodeString: '8061984',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1984',
      branchName: '金城分行',
      totalName: '1984 ╴金城分行'
    },
    {
      bankCodeString: '8061995',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '1995',
      branchName: '民族分行',
      totalName: '1995 ╴民族分行'
    },
    {
      bankCodeString: '8062006',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '2006',
      branchName: '頭份分行',
      totalName: '2006 ╴頭份分行'
    },
    {
      bankCodeString: '8062017',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '2017',
      branchName: '萬華分行',
      totalName: '2017 ╴萬華分行'
    },
    {
      bankCodeString: '8062028',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '2028',
      branchName: '敦安分行',
      totalName: '2028 ╴敦安分行'
    },
    {
      bankCodeString: '8062039',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '2039',
      branchName: '環東分行',
      totalName: '2039 ╴環東分行'
    },
    {
      bankCodeString: '8062040',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '2040',
      branchName: '城東分行',
      totalName: '2040 ╴城東分行'
    },
    {
      bankCodeString: '8068031',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '8031',
      branchName: '財務部',
      totalName: '8031 ╴財務部'
    },
    {
      bankCodeString: '8068042',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '8042',
      branchName: '債權管理部',
      totalName: '8042 ╴債權管理部'
    },
    {
      bankCodeString: '8068064',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '8064',
      branchName: '財富管理部',
      totalName: '8064 ╴財富管理部'
    },
    {
      bankCodeString: '8069016',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '9016',
      branchName: 'Yuanta Savings Bank(Philippines)元大儲蓄銀行(菲律賓)',
      totalName: '9016 ╴Yuanta Savings Bank(Philippines)元大儲蓄銀行(菲律賓)'
    },
    {
      bankCodeString: '8069049',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '9049',
      branchName: '緬甸仰光代表人辦事處',
      totalName: '9049 ╴緬甸仰光代表人辦事處'
    },
    {
      bankCodeString: '8069050',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '9050',
      branchName: 'Yuanta Savings Bank (Korea)元大儲蓄銀行(韓國)',
      totalName: '9050 ╴Yuanta Savings Bank (Korea)元大儲蓄銀行(韓國)'
    },
    {
      bankCodeString: '8069061',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '9061',
      branchName: 'Yuanta Savings Bank(Korea) Euljiro Branch',
      totalName: '9061 ╴Yuanta Savings Bank(Korea) Euljiro Branch'
    },
    {
      bankCodeString: '8069072',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '9072',
      branchName: '香港分行',
      totalName: '9072 ╴香港分行'
    },
    {
      bankCodeString: '8069150',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '9150',
      branchName: '金融交易作業部',
      totalName: '9150 ╴金融交易作業部'
    },
    {
      bankCodeString: '8069170',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '9170',
      branchName: '金融交易部',
      totalName: '9170 ╴金融交易部'
    },
    {
      bankCodeString: '8069260',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '9260',
      branchName: '存匯作業部',
      totalName: '9260 ╴存匯作業部'
    },
    {
      bankCodeString: '8069270',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '9270',
      branchName: '管理部',
      totalName: '9270 ╴管理部'
    },
    {
      bankCodeString: '8069280',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '9280',
      branchName: '人力資源部',
      totalName: '9280 ╴人力資源部'
    },
    {
      bankCodeString: '8069290',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '9290',
      branchName: '會計部',
      totalName: '9290 ╴會計部'
    },
    {
      bankCodeString: '8069310',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '9310',
      branchName: '法律事務部',
      totalName: '9310 ╴法律事務部'
    },
    {
      bankCodeString: '8069330',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '9330',
      branchName: '總經理室',
      totalName: '9330 ╴總經理室'
    },
    {
      bankCodeString: '8069340',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '9340',
      branchName: '秘書室',
      totalName: '9340 ╴秘書室'
    },
    {
      bankCodeString: '8069350',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '9350',
      branchName: '稽核室',
      totalName: '9350 ╴稽核室'
    },
    {
      bankCodeString: '8069370',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '9370',
      branchName: '法人金融業務部',
      totalName: '9370 ╴法人金融業務部'
    },
    {
      bankCodeString: '8069380',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '9380',
      branchName: '個人金融業務部',
      totalName: '9380 ╴個人金融業務部'
    },
    {
      bankCodeString: '8069390',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '9390',
      branchName: '審查部',
      totalName: '9390 ╴審查部'
    },
    {
      bankCodeString: '8069510',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '9510',
      branchName: '授信作業部',
      totalName: '9510 ╴授信作業部'
    },
    {
      bankCodeString: '8069520',
      bankCode: '806',
      bankName: '元大商業銀行',
      branchCode: '9520',
      branchName: '風險管理部',
      totalName: '9520 ╴風險管理部'
    }
  ],
  '807': [
    {
      bankCodeString: '8070001',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0001',
      branchName: '董事會秘書處',
      totalName: '0001 ╴董事會秘書處'
    },
    {
      bankCodeString: '8070002',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0002',
      branchName: '稽核處',
      totalName: '0002 ╴稽核處'
    },
    {
      bankCodeString: '8070003',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0003',
      branchName: '管理部',
      totalName: '0003 ╴管理部'
    },
    {
      bankCodeString: '8070004',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0004',
      branchName: '企金授管處',
      totalName: '0004 ╴企金授管處'
    },
    {
      bankCodeString: '8070006',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0006',
      branchName: '人力資源處',
      totalName: '0006 ╴人力資源處'
    },
    {
      bankCodeString: '8070008',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0008',
      branchName: '法令遵循處',
      totalName: '0008 ╴法令遵循處'
    },
    {
      bankCodeString: '8070009',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0009',
      branchName: '作業處',
      totalName: '0009 ╴作業處'
    },
    {
      bankCodeString: '8070010',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0010',
      branchName: '北區區域中心',
      totalName: '0010 ╴北區區域中心'
    },
    {
      bankCodeString: '8070011',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0011',
      branchName: '中區區域中心',
      totalName: '0011 ╴中區區域中心'
    },
    {
      bankCodeString: '8070012',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0012',
      branchName: '南區區域中心',
      totalName: '0012 ╴南區區域中心'
    },
    {
      bankCodeString: '8070013',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0013',
      branchName: '個金授管處',
      totalName: '0013 ╴個金授管處'
    },
    {
      bankCodeString: '8070014',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0014',
      branchName: '世貿分行',
      totalName: '0014 ╴世貿分行'
    },
    {
      bankCodeString: '8070015',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0015',
      branchName: '會計處',
      totalName: '0015 ╴會計處'
    },
    {
      bankCodeString: '8070016',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0016',
      branchName: '行政處',
      totalName: '0016 ╴行政處'
    },
    {
      bankCodeString: '8070017',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0017',
      branchName: '資訊處',
      totalName: '0017 ╴資訊處'
    },
    {
      bankCodeString: '807001A',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '001A',
      branchName: '逾催管理部',
      totalName: '001A ╴逾催管理部'
    },
    {
      bankCodeString: '807001B',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '001B',
      branchName: '風險管理處',
      totalName: '001B ╴風險管理處'
    },
    {
      bankCodeString: '807001C',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '001C',
      branchName: '個金作業中心',
      totalName: '001C ╴個金作業中心'
    },
    {
      bankCodeString: '807001D',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '001D',
      branchName: '零售金融處',
      totalName: '001D ╴零售金融處'
    },
    {
      bankCodeString: '807001E',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '001E',
      branchName: '消費金融處信用控管部',
      totalName: '001E ╴消費金融處信用控管部'
    },
    {
      bankCodeString: '807001G',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '001G',
      branchName: '理財業務部',
      totalName: '001G ╴理財業務部'
    },
    {
      bankCodeString: '807001H',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '001H',
      branchName: '海外財務部',
      totalName: '001H ╴海外財務部'
    },
    {
      bankCodeString: '807001N',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '001N',
      branchName: '環球金融部',
      totalName: '001N ╴環球金融部'
    },
    {
      bankCodeString: '807001O',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '001O',
      branchName: '財務作業中心',
      totalName: '001O ╴財務作業中心'
    },
    {
      bankCodeString: '807001P',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '001P',
      branchName: '綜合作業中心',
      totalName: '001P ╴綜合作業中心'
    },
    {
      bankCodeString: '807001Q',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '001Q',
      branchName: '車貸部',
      totalName: '001Q ╴車貸部'
    },
    {
      bankCodeString: '807001R',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '001R',
      branchName: '信貸部',
      totalName: '001R ╴信貸部'
    },
    {
      bankCodeString: '807001S',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '001S',
      branchName: '行銷管理部',
      totalName: '001S ╴行銷管理部'
    },
    {
      bankCodeString: '8070020',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0020',
      branchName: '交易處',
      totalName: '0020 ╴交易處'
    },
    {
      bankCodeString: '8070021',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0021',
      branchName: '行銷管理處',
      totalName: '0021 ╴行銷管理處'
    },
    {
      bankCodeString: '8070022',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0022',
      branchName: '財富金融處',
      totalName: '0022 ╴財富金融處'
    },
    {
      bankCodeString: '8070025',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0025',
      branchName: '敦北分行',
      totalName: '0025 ╴敦北分行'
    },
    {
      bankCodeString: '8070036',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0036',
      branchName: '台中分行',
      totalName: '0036 ╴台中分行'
    },
    {
      bankCodeString: '8070047',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0047',
      branchName: '高雄分行',
      totalName: '0047 ╴高雄分行'
    },
    {
      bankCodeString: '8070058',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0058',
      branchName: '忠孝分行',
      totalName: '0058 ╴忠孝分行'
    },
    {
      bankCodeString: '8070069',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0069',
      branchName: '西松分行',
      totalName: '0069 ╴西松分行'
    },
    {
      bankCodeString: '8070070',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0070',
      branchName: '板新分行',
      totalName: '0070 ╴板新分行'
    },
    {
      bankCodeString: '8070081',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0081',
      branchName: '信託部',
      totalName: '0081 ╴信託部'
    },
    {
      bankCodeString: '8070092',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0092',
      branchName: '敦南分行',
      totalName: '0092 ╴敦南分行'
    },
    {
      bankCodeString: '8070106',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0106',
      branchName: '新泰分行',
      totalName: '0106 ╴新泰分行'
    },
    {
      bankCodeString: '8070117',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0117',
      branchName: '桃園分行',
      totalName: '0117 ╴桃園分行'
    },
    {
      bankCodeString: '8070128',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0128',
      branchName: '北台中分行',
      totalName: '0128 ╴北台中分行'
    },
    {
      bankCodeString: '8070139',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0139',
      branchName: '台南分行',
      totalName: '0139 ╴台南分行'
    },
    {
      bankCodeString: '8070140',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0140',
      branchName: '國外部',
      totalName: '0140 ╴國外部'
    },
    {
      bankCodeString: '8070151',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0151',
      branchName: '松江分行',
      totalName: '0151 ╴松江分行'
    },
    {
      bankCodeString: '8070162',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0162',
      branchName: '嘉義分行',
      totalName: '0162 ╴嘉義分行'
    },
    {
      bankCodeString: '8070173',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0173',
      branchName: '三民分行',
      totalName: '0173 ╴三民分行'
    },
    {
      bankCodeString: '8070184',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0184',
      branchName: '新竹分行',
      totalName: '0184 ╴新竹分行'
    },
    {
      bankCodeString: '8070195',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0195',
      branchName: '三重分行',
      totalName: '0195 ╴三重分行'
    },
    {
      bankCodeString: '8070209',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0209',
      branchName: '國際金融業務分行',
      totalName: '0209 ╴國際金融業務分行'
    },
    {
      bankCodeString: '8070210',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0210',
      branchName: '台北分行',
      totalName: '0210 ╴台北分行'
    },
    {
      bankCodeString: '8070221',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0221',
      branchName: '彰化分行',
      totalName: '0221 ╴彰化分行'
    },
    {
      bankCodeString: '8070232',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0232',
      branchName: '岡山分行',
      totalName: '0232 ╴岡山分行'
    },
    {
      bankCodeString: '8070243',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0243',
      branchName: '中壢分行',
      totalName: '0243 ╴中壢分行'
    },
    {
      bankCodeString: '8070254',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0254',
      branchName: '鳳山分行',
      totalName: '0254 ╴鳳山分行'
    },
    {
      bankCodeString: '8070276',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0276',
      branchName: '北新分行',
      totalName: '0276 ╴北新分行'
    },
    {
      bankCodeString: '8070287',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0287',
      branchName: '屏東分行',
      totalName: '0287 ╴屏東分行'
    },
    {
      bankCodeString: '8070298',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0298',
      branchName: '北桃園分行',
      totalName: '0298 ╴北桃園分行'
    },
    {
      bankCodeString: '8070302',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0302',
      branchName: '學府分行',
      totalName: '0302 ╴學府分行'
    },
    {
      bankCodeString: '8070313',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0313',
      branchName: '南高雄分行',
      totalName: '0313 ╴南高雄分行'
    },
    {
      bankCodeString: '8070324',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0324',
      branchName: '光華分行',
      totalName: '0324 ╴光華分行'
    },
    {
      bankCodeString: '8070335',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0335',
      branchName: '東門分行',
      totalName: '0335 ╴東門分行'
    },
    {
      bankCodeString: '8070346',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0346',
      branchName: '天母分行',
      totalName: '0346 ╴天母分行'
    },
    {
      bankCodeString: '8070357',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0357',
      branchName: '東板橋分行',
      totalName: '0357 ╴東板橋分行'
    },
    {
      bankCodeString: '8070368',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0368',
      branchName: '東台南分行',
      totalName: '0368 ╴東台南分行'
    },
    {
      bankCodeString: '8070379',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0379',
      branchName: '竹南分行',
      totalName: '0379 ╴竹南分行'
    },
    {
      bankCodeString: '8070380',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0380',
      branchName: '南台中分行',
      totalName: '0380 ╴南台中分行'
    },
    {
      bankCodeString: '8070391',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0391',
      branchName: '竹北自強分行',
      totalName: '0391 ╴竹北自強分行'
    },
    {
      bankCodeString: '8070405',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0405',
      branchName: '永康分行',
      totalName: '0405 ╴永康分行'
    },
    {
      bankCodeString: '8070416',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0416',
      branchName: '中科分行',
      totalName: '0416 ╴中科分行'
    },
    {
      bankCodeString: '8070427',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0427',
      branchName: '北高雄分行',
      totalName: '0427 ╴北高雄分行'
    },
    {
      bankCodeString: '8070438',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0438',
      branchName: '金門分行',
      totalName: '0438 ╴金門分行'
    },
    {
      bankCodeString: '8070449',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0449',
      branchName: '興大分行',
      totalName: '0449 ╴興大分行'
    },
    {
      bankCodeString: '8070450',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0450',
      branchName: '中崙分行',
      totalName: '0450 ╴中崙分行'
    },
    {
      bankCodeString: '8070461',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0461',
      branchName: '西湖分行',
      totalName: '0461 ╴西湖分行'
    },
    {
      bankCodeString: '8070472',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0472',
      branchName: '豐原分行',
      totalName: '0472 ╴豐原分行'
    },
    {
      bankCodeString: '8070483',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0483',
      branchName: '大里分行',
      totalName: '0483 ╴大里分行'
    },
    {
      bankCodeString: '8070650',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '0650',
      branchName: '兼營證券商',
      totalName: '0650 ╴兼營證券商'
    },
    {
      bankCodeString: '8071011',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1011',
      branchName: '忠孝東路分行',
      totalName: '1011 ╴忠孝東路分行'
    },
    {
      bankCodeString: '8071033',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1033',
      branchName: '建成分行',
      totalName: '1033 ╴建成分行'
    },
    {
      bankCodeString: '8071044',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1044',
      branchName: '西門分行',
      totalName: '1044 ╴西門分行'
    },
    {
      bankCodeString: '8071055',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1055',
      branchName: '萬華分行',
      totalName: '1055 ╴萬華分行'
    },
    {
      bankCodeString: '8071066',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1066',
      branchName: '興隆分行',
      totalName: '1066 ╴興隆分行'
    },
    {
      bankCodeString: '8071077',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1077',
      branchName: '南門分行',
      totalName: '1077 ╴南門分行'
    },
    {
      bankCodeString: '8071088',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1088',
      branchName: '長安分行',
      totalName: '1088 ╴長安分行'
    },
    {
      bankCodeString: '8071099',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1099',
      branchName: '延平分行',
      totalName: '1099 ╴延平分行'
    },
    {
      bankCodeString: '8071103',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1103',
      branchName: '北投分行',
      totalName: '1103 ╴北投分行'
    },
    {
      bankCodeString: '8071114',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1114',
      branchName: '松山分行',
      totalName: '1114 ╴松山分行'
    },
    {
      bankCodeString: '8071125',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1125',
      branchName: '中山分行',
      totalName: '1125 ╴中山分行'
    },
    {
      bankCodeString: '8071136',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1136',
      branchName: '士林分行',
      totalName: '1136 ╴士林分行'
    },
    {
      bankCodeString: '8071158',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1158',
      branchName: '雙園分行',
      totalName: '1158 ╴雙園分行'
    },
    {
      bankCodeString: '8071169',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1169',
      branchName: '永和分行',
      totalName: '1169 ╴永和分行'
    },
    {
      bankCodeString: '8071170',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1170',
      branchName: '板橋分行',
      totalName: '1170 ╴板橋分行'
    },
    {
      bankCodeString: '8071181',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1181',
      branchName: '基隆分行',
      totalName: '1181 ╴基隆分行'
    },
    {
      bankCodeString: '8071192',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1192',
      branchName: '羅東分行',
      totalName: '1192 ╴羅東分行'
    },
    {
      bankCodeString: '8071206',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1206',
      branchName: '宜蘭分行',
      totalName: '1206 ╴宜蘭分行'
    },
    {
      bankCodeString: '8071217',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1217',
      branchName: '營業部',
      totalName: '1217 ╴營業部'
    },
    {
      bankCodeString: '8071228',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1228',
      branchName: '景美分行',
      totalName: '1228 ╴景美分行'
    },
    {
      bankCodeString: '8071239',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1239',
      branchName: '新莊分行',
      totalName: '1239 ╴新莊分行'
    },
    {
      bankCodeString: '8071251',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1251',
      branchName: '汐止分行',
      totalName: '1251 ╴汐止分行'
    },
    {
      bankCodeString: '8071262',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1262',
      branchName: '城中分行',
      totalName: '1262 ╴城中分行'
    },
    {
      bankCodeString: '8071273',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1273',
      branchName: '光復分行',
      totalName: '1273 ╴光復分行'
    },
    {
      bankCodeString: '8071284',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1284',
      branchName: '正義分行',
      totalName: '1284 ╴正義分行'
    },
    {
      bankCodeString: '8071295',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1295',
      branchName: '中和分行',
      totalName: '1295 ╴中和分行'
    },
    {
      bankCodeString: '8071310',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1310',
      branchName: '士東分行',
      totalName: '1310 ╴士東分行'
    },
    {
      bankCodeString: '8071321',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1321',
      branchName: '中港分行',
      totalName: '1321 ╴中港分行'
    },
    {
      bankCodeString: '8071332',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1332',
      branchName: '蘆洲分行',
      totalName: '1332 ╴蘆洲分行'
    },
    {
      bankCodeString: '8071343',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1343',
      branchName: '內湖分行',
      totalName: '1343 ╴內湖分行'
    },
    {
      bankCodeString: '8071354',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1354',
      branchName: '社子分行',
      totalName: '1354 ╴社子分行'
    },
    {
      bankCodeString: '8071365',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1365',
      branchName: '信義分行',
      totalName: '1365 ╴信義分行'
    },
    {
      bankCodeString: '8071376',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1376',
      branchName: '永春分行',
      totalName: '1376 ╴永春分行'
    },
    {
      bankCodeString: '8071387',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1387',
      branchName: '南三重分行',
      totalName: '1387 ╴南三重分行'
    },
    {
      bankCodeString: '8071398',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1398',
      branchName: '積穗分行',
      totalName: '1398 ╴積穗分行'
    },
    {
      bankCodeString: '8071402',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1402',
      branchName: '鶯歌分行',
      totalName: '1402 ╴鶯歌分行'
    },
    {
      bankCodeString: '8071413',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1413',
      branchName: '新店分行',
      totalName: '1413 ╴新店分行'
    },
    {
      bankCodeString: '8071435',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1435',
      branchName: '西盛分行',
      totalName: '1435 ╴西盛分行'
    },
    {
      bankCodeString: '8071446',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1446',
      branchName: '板橋忠孝分行',
      totalName: '1446 ╴板橋忠孝分行'
    },
    {
      bankCodeString: '8071457',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1457',
      branchName: '思源分行',
      totalName: '1457 ╴思源分行'
    },
    {
      bankCodeString: '8071468',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1468',
      branchName: '土城分行',
      totalName: '1468 ╴土城分行'
    },
    {
      bankCodeString: '8071479',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1479',
      branchName: '三興分行',
      totalName: '1479 ╴三興分行'
    },
    {
      bankCodeString: '8071480',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1480',
      branchName: '龍江分行',
      totalName: '1480 ╴龍江分行'
    },
    {
      bankCodeString: '8071491',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1491',
      branchName: '樹林分行',
      totalName: '1491 ╴樹林分行'
    },
    {
      bankCodeString: '8071505',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1505',
      branchName: '五股分行',
      totalName: '1505 ╴五股分行'
    },
    {
      bankCodeString: '8071538',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1538',
      branchName: '松德分行',
      totalName: '1538 ╴松德分行'
    },
    {
      bankCodeString: '8071549',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1549',
      branchName: '東湖分行',
      totalName: '1549 ╴東湖分行'
    },
    {
      bankCodeString: '8071550',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1550',
      branchName: '華江分行',
      totalName: '1550 ╴華江分行'
    },
    {
      bankCodeString: '8071561',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1561',
      branchName: '北三重分行',
      totalName: '1561 ╴北三重分行'
    },
    {
      bankCodeString: '8071572',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1572',
      branchName: '蘭雅分行',
      totalName: '1572 ╴蘭雅分行'
    },
    {
      bankCodeString: '8071583',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1583',
      branchName: '德惠分行',
      totalName: '1583 ╴德惠分行'
    },
    {
      bankCodeString: '8071594',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1594',
      branchName: '迴龍分行',
      totalName: '1594 ╴迴龍分行'
    },
    {
      bankCodeString: '8071608',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1608',
      branchName: '仁愛分行',
      totalName: '1608 ╴仁愛分行'
    },
    {
      bankCodeString: '8071620',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1620',
      branchName: '和平分行',
      totalName: '1620 ╴和平分行'
    },
    {
      bankCodeString: '8071642',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1642',
      branchName: '重新分行',
      totalName: '1642 ╴重新分行'
    },
    {
      bankCodeString: '8071653',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1653',
      branchName: '中興分行',
      totalName: '1653 ╴中興分行'
    },
    {
      bankCodeString: '8071664',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1664',
      branchName: '海山分行',
      totalName: '1664 ╴海山分行'
    },
    {
      bankCodeString: '8071697',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1697',
      branchName: '南蘆洲分行',
      totalName: '1697 ╴南蘆洲分行'
    },
    {
      bankCodeString: '8071701',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1701',
      branchName: '江子翠分行',
      totalName: '1701 ╴江子翠分行'
    },
    {
      bankCodeString: '8071712',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1712',
      branchName: '民安分行',
      totalName: '1712 ╴民安分行'
    },
    {
      bankCodeString: '8071723',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1723',
      branchName: '苓雅分行',
      totalName: '1723 ╴苓雅分行'
    },
    {
      bankCodeString: '8071734',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1734',
      branchName: '林口忠孝分行',
      totalName: '1734 ╴林口忠孝分行'
    },
    {
      bankCodeString: '8071745',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1745',
      branchName: '板橋民族分行',
      totalName: '1745 ╴板橋民族分行'
    },
    {
      bankCodeString: '8071756',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1756',
      branchName: '泰山分行',
      totalName: '1756 ╴泰山分行'
    },
    {
      bankCodeString: '8071767',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1767',
      branchName: '花蓮分行',
      totalName: '1767 ╴花蓮分行'
    },
    {
      bankCodeString: '8071778',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1778',
      branchName: '南港分行',
      totalName: '1778 ╴南港分行'
    },
    {
      bankCodeString: '8071789',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1789',
      branchName: '深坑分行',
      totalName: '1789 ╴深坑分行'
    },
    {
      bankCodeString: '8071790',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1790',
      branchName: '南崁分行',
      totalName: '1790 ╴南崁分行'
    },
    {
      bankCodeString: '8071804',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1804',
      branchName: '北台南分行',
      totalName: '1804 ╴北台南分行'
    },
    {
      bankCodeString: '8071815',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1815',
      branchName: '西屯分行',
      totalName: '1815 ╴西屯分行'
    },
    {
      bankCodeString: '8071826',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1826',
      branchName: '成大分行',
      totalName: '1826 ╴成大分行'
    },
    {
      bankCodeString: '8071837',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1837',
      branchName: '濟南路分行',
      totalName: '1837 ╴濟南路分行'
    },
    {
      bankCodeString: '8071859',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1859',
      branchName: '南桃園分行',
      totalName: '1859 ╴南桃園分行'
    },
    {
      bankCodeString: '8071860',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1860',
      branchName: '竹科分行',
      totalName: '1860 ╴竹科分行'
    },
    {
      bankCodeString: '8071871',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1871',
      branchName: '員林分行',
      totalName: '1871 ╴員林分行'
    },
    {
      bankCodeString: '8071882',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1882',
      branchName: '楊梅分行',
      totalName: '1882 ╴楊梅分行'
    },
    {
      bankCodeString: '8071893',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1893',
      branchName: '內壢分行',
      totalName: '1893 ╴內壢分行'
    },
    {
      bankCodeString: '8071907',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1907',
      branchName: '重慶北路分行',
      totalName: '1907 ╴重慶北路分行'
    },
    {
      bankCodeString: '8071929',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1929',
      branchName: '大園分行',
      totalName: '1929 ╴大園分行'
    },
    {
      bankCodeString: '8071930',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1930',
      branchName: '竹北光明分行',
      totalName: '1930 ╴竹北光明分行'
    },
    {
      bankCodeString: '8071963',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '1963',
      branchName: '竹圍分行',
      totalName: '1963 ╴竹圍分行'
    },
    {
      bankCodeString: '8077404',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '7404',
      branchName: '永豐銀行(中國)有限公司成都分行',
      totalName: '7404 ╴永豐銀行(中國)有限公司成都分行'
    },
    {
      bankCodeString: '8077415',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '7415',
      branchName: '永豐銀行(中國)有限公司南京分行',
      totalName: '7415 ╴永豐銀行(中國)有限公司南京分行'
    },
    {
      bankCodeString: '8079017',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '9017',
      branchName: '香港分行',
      totalName: '9017 ╴香港分行'
    },
    {
      bankCodeString: '8079028',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '9028',
      branchName: '越南代表人辦事處',
      totalName: '9028 ╴越南代表人辦事處'
    },
    {
      bankCodeString: '8079040',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '9040',
      branchName: '洛杉磯分行',
      totalName: '9040 ╴洛杉磯分行'
    },
    {
      bankCodeString: '8079671',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '9671',
      branchName: '永豐銀行(中國)有限公司',
      totalName: '9671 ╴永豐銀行(中國)有限公司'
    },
    {
      bankCodeString: '8079718',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '9718',
      branchName: '永豐銀行（中國）有限公司廣州分行',
      totalName: '9718 ╴永豐銀行（中國）有限公司廣州分行'
    },
    {
      bankCodeString: '8079729',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '9729',
      branchName: '永豐銀行（中國）有限公司上海分行',
      totalName: '9729 ╴永豐銀行（中國）有限公司上海分行'
    },
    {
      bankCodeString: '8079800',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '9800',
      branchName: '澳門分行',
      totalName: '9800 ╴澳門分行'
    },
    {
      bankCodeString: '8079811',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '9811',
      branchName: '九龍支行',
      totalName: '9811 ╴九龍支行'
    },
    {
      bankCodeString: '8079844',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: '9844',
      branchName: '胡志明市分行',
      totalName: '9844 ╴胡志明市分行'
    },
    {
      bankCodeString: '807CD02',
      bankCode: '807',
      bankName: '永豐商業銀行',
      branchCode: 'CD02',
      branchName: '信用卡處',
      totalName: 'CD02 ╴信用卡處'
    }
  ],
  '808': [
    {
      bankCodeString: '8080002',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0002',
      branchName: '管理事務處',
      totalName: '0002 ╴管理事務處'
    },
    {
      bankCodeString: '8080003',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0003',
      branchName: '會計處',
      totalName: '0003 ╴會計處'
    },
    {
      bankCodeString: '8080004',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0004',
      branchName: '稽核處',
      totalName: '0004 ╴稽核處'
    },
    {
      bankCodeString: '8080005',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0005',
      branchName: '顧客服務處',
      totalName: '0005 ╴顧客服務處'
    },
    {
      bankCodeString: '8080006',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0006',
      branchName: '資訊處',
      totalName: '0006 ╴資訊處'
    },
    {
      bankCodeString: '8080007',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0007',
      branchName: '個人金融事業處',
      totalName: '0007 ╴個人金融事業處'
    },
    {
      bankCodeString: '8080008',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0008',
      branchName: '財務金融事業處',
      totalName: '0008 ╴財務金融事業處'
    },
    {
      bankCodeString: '8080009',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0009',
      branchName: '人力資源處',
      totalName: '0009 ╴人力資源處'
    },
    {
      bankCodeString: '8080010',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0010',
      branchName: '信用卡事業處',
      totalName: '0010 ╴信用卡事業處'
    },
    {
      bankCodeString: '8080011',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0011',
      branchName: '法律事務處',
      totalName: '0011 ╴法律事務處'
    },
    {
      bankCodeString: '8080012',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0012',
      branchName: '債權管理處',
      totalName: '0012 ╴債權管理處'
    },
    {
      bankCodeString: '8080013',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0013',
      branchName: '電子金融部',
      totalName: '0013 ╴電子金融部'
    },
    {
      bankCodeString: '8080014',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0014',
      branchName: '法人金融事業處',
      totalName: '0014 ╴法人金融事業處'
    },
    {
      bankCodeString: '8080015',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0015',
      branchName: '營業部',
      totalName: '0015 ╴營業部'
    },
    {
      bankCodeString: '8080016',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0016',
      branchName: '風險管理處',
      totalName: '0016 ╴風險管理處'
    },
    {
      bankCodeString: '8080017',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0017',
      branchName: '財富管理事業處',
      totalName: '0017 ╴財富管理事業處'
    },
    {
      bankCodeString: '8080026',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0026',
      branchName: '南京東路分行',
      totalName: '0026 ╴南京東路分行'
    },
    {
      bankCodeString: '8080048',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0048',
      branchName: '城東分行',
      totalName: '0048 ╴城東分行'
    },
    {
      bankCodeString: '8080059',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0059',
      branchName: '新莊分行',
      totalName: '0059 ╴新莊分行'
    },
    {
      bankCodeString: '8080060',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0060',
      branchName: '新竹分行',
      totalName: '0060 ╴新竹分行'
    },
    {
      bankCodeString: '8080071',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0071',
      branchName: '嘉義分行',
      totalName: '0071 ╴嘉義分行'
    },
    {
      bankCodeString: '8080082',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0082',
      branchName: '高雄分行',
      totalName: '0082 ╴高雄分行'
    },
    {
      bankCodeString: '8080093',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0093',
      branchName: '信託部',
      totalName: '0093 ╴信託部'
    },
    {
      bankCodeString: '8080107',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0107',
      branchName: '國際事務部',
      totalName: '0107 ╴國際事務部'
    },
    {
      bankCodeString: '8080118',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0118',
      branchName: '基隆路分行',
      totalName: '0118 ╴基隆路分行'
    },
    {
      bankCodeString: '8080129',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0129',
      branchName: '雙和分行',
      totalName: '0129 ╴雙和分行'
    },
    {
      bankCodeString: '8080130',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0130',
      branchName: '中壢分行',
      totalName: '0130 ╴中壢分行'
    },
    {
      bankCodeString: '8080141',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0141',
      branchName: '文心分行',
      totalName: '0141 ╴文心分行'
    },
    {
      bankCodeString: '8080152',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0152',
      branchName: '台南分行',
      totalName: '0152 ╴台南分行'
    },
    {
      bankCodeString: '8080163',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0163',
      branchName: '天母分行',
      totalName: '0163 ╴天母分行'
    },
    {
      bankCodeString: '8080174',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0174',
      branchName: '埔墘分行',
      totalName: '0174 ╴埔墘分行'
    },
    {
      bankCodeString: '8080185',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0185',
      branchName: '桃園分行',
      totalName: '0185 ╴桃園分行'
    },
    {
      bankCodeString: '8080196',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0196',
      branchName: '前鎮分行',
      totalName: '0196 ╴前鎮分行'
    },
    {
      bankCodeString: '8080200',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0200',
      branchName: '國際金融業務分行',
      totalName: '0200 ╴國際金融業務分行'
    },
    {
      bankCodeString: '8080211',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0211',
      branchName: '民生分行',
      totalName: '0211 ╴民生分行'
    },
    {
      bankCodeString: '8080222',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0222',
      branchName: '大里分行',
      totalName: '0222 ╴大里分行'
    },
    {
      bankCodeString: '8080233',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0233',
      branchName: '永康分行',
      totalName: '0233 ╴永康分行'
    },
    {
      bankCodeString: '8080244',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0244',
      branchName: '鳳山分行',
      totalName: '0244 ╴鳳山分行'
    },
    {
      bankCodeString: '8080255',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0255',
      branchName: '三和分行',
      totalName: '0255 ╴三和分行'
    },
    {
      bankCodeString: '8080266',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0266',
      branchName: '土城分行',
      totalName: '0266 ╴土城分行'
    },
    {
      bankCodeString: '8080277',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0277',
      branchName: '八德分行',
      totalName: '0277 ╴八德分行'
    },
    {
      bankCodeString: '8080288',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0288',
      branchName: '大墩分行',
      totalName: '0288 ╴大墩分行'
    },
    {
      bankCodeString: '8080299',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0299',
      branchName: '七賢分行',
      totalName: '0299 ╴七賢分行'
    },
    {
      bankCodeString: '8080303',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0303',
      branchName: '復興分行',
      totalName: '0303 ╴復興分行'
    },
    {
      bankCodeString: '8080314',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0314',
      branchName: '敦南分行',
      totalName: '0314 ╴敦南分行'
    },
    {
      bankCodeString: '8080325',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0325',
      branchName: '三重分行',
      totalName: '0325 ╴三重分行'
    },
    {
      bankCodeString: '8080336',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0336',
      branchName: '彰化分行',
      totalName: '0336 ╴彰化分行'
    },
    {
      bankCodeString: '8080347',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0347',
      branchName: '北高雄分行',
      totalName: '0347 ╴北高雄分行'
    },
    {
      bankCodeString: '8080358',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0358',
      branchName: '大雅分行',
      totalName: '0358 ╴大雅分行'
    },
    {
      bankCodeString: '8080369',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0369',
      branchName: '楊梅分行',
      totalName: '0369 ╴楊梅分行'
    },
    {
      bankCodeString: '8080370',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0370',
      branchName: '樹林分行',
      totalName: '0370 ╴樹林分行'
    },
    {
      bankCodeString: '8080381',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0381',
      branchName: '豐原分行',
      totalName: '0381 ╴豐原分行'
    },
    {
      bankCodeString: '8080392',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0392',
      branchName: '斗六分行',
      totalName: '0392 ╴斗六分行'
    },
    {
      bankCodeString: '8080406',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0406',
      branchName: '長春分行',
      totalName: '0406 ╴長春分行'
    },
    {
      bankCodeString: '8080417',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0417',
      branchName: '中山分行',
      totalName: '0417 ╴中山分行'
    },
    {
      bankCodeString: '8080428',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0428',
      branchName: '佳里分行',
      totalName: '0428 ╴佳里分行'
    },
    {
      bankCodeString: '8080439',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0439',
      branchName: '中和分行',
      totalName: '0439 ╴中和分行'
    },
    {
      bankCodeString: '8080440',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0440',
      branchName: '竹南分行',
      totalName: '0440 ╴竹南分行'
    },
    {
      bankCodeString: '8080451',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0451',
      branchName: '羅東分行',
      totalName: '0451 ╴羅東分行'
    },
    {
      bankCodeString: '8080462',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0462',
      branchName: '內湖分行',
      totalName: '0462 ╴內湖分行'
    },
    {
      bankCodeString: '8080473',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0473',
      branchName: '壢新分行',
      totalName: '0473 ╴壢新分行'
    },
    {
      bankCodeString: '8080484',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0484',
      branchName: '板新分行',
      totalName: '0484 ╴板新分行'
    },
    {
      bankCodeString: '8080495',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0495',
      branchName: '光華分行',
      totalName: '0495 ╴光華分行'
    },
    {
      bankCodeString: '8080509',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0509',
      branchName: '東嘉義分行',
      totalName: '0509 ╴東嘉義分行'
    },
    {
      bankCodeString: '8080510',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0510',
      branchName: '成功分行',
      totalName: '0510 ╴成功分行'
    },
    {
      bankCodeString: '8080521',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0521',
      branchName: '永和分行',
      totalName: '0521 ╴永和分行'
    },
    {
      bankCodeString: '8080532',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0532',
      branchName: '城中分行',
      totalName: '0532 ╴城中分行'
    },
    {
      bankCodeString: '8080543',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0543',
      branchName: '五股分行',
      totalName: '0543 ╴五股分行'
    },
    {
      bankCodeString: '8080554',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0554',
      branchName: '新店分行',
      totalName: '0554 ╴新店分行'
    },
    {
      bankCodeString: '8080565',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0565',
      branchName: '松山分行',
      totalName: '0565 ╴松山分行'
    },
    {
      bankCodeString: '8080576',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0576',
      branchName: '和平分行',
      totalName: '0576 ╴和平分行'
    },
    {
      bankCodeString: '8080587',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0587',
      branchName: '新板特區分行',
      totalName: '0587 ╴新板特區分行'
    },
    {
      bankCodeString: '8080598',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0598',
      branchName: '民權分行',
      totalName: '0598 ╴民權分行'
    },
    {
      bankCodeString: '8080602',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0602',
      branchName: '新莊副都心分行',
      totalName: '0602 ╴新莊副都心分行'
    },
    {
      bankCodeString: '8080613',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0613',
      branchName: '南崁分行',
      totalName: '0613 ╴南崁分行'
    },
    {
      bankCodeString: '8080624',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0624',
      branchName: '台大分行',
      totalName: '0624 ╴台大分行'
    },
    {
      bankCodeString: '8080635',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0635',
      branchName: '左營分行',
      totalName: '0635 ╴左營分行'
    },
    {
      bankCodeString: '8080646',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0646',
      branchName: '楠梓分行',
      totalName: '0646 ╴楠梓分行'
    },
    {
      bankCodeString: '8080657',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0657',
      branchName: '士林分行',
      totalName: '0657 ╴士林分行'
    },
    {
      bankCodeString: '8080668',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0668',
      branchName: '苓雅分行',
      totalName: '0668 ╴苓雅分行'
    },
    {
      bankCodeString: '8080679',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0679',
      branchName: '仁愛分行',
      totalName: '0679 ╴仁愛分行'
    },
    {
      bankCodeString: '8080680',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0680',
      branchName: '南屯分行',
      totalName: '0680 ╴南屯分行'
    },
    {
      bankCodeString: '808068T',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '068T',
      branchName: '兼營證券商',
      totalName: '068T ╴兼營證券商'
    },
    {
      bankCodeString: '8080691',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0691',
      branchName: '木柵分行',
      totalName: '0691 ╴木柵分行'
    },
    {
      bankCodeString: '8080705',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0705',
      branchName: '大昌分行',
      totalName: '0705 ╴大昌分行'
    },
    {
      bankCodeString: '8080716',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0716',
      branchName: '台東分行',
      totalName: '0716 ╴台東分行'
    },
    {
      bankCodeString: '8080727',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0727',
      branchName: '澄清分行',
      totalName: '0727 ╴澄清分行'
    },
    {
      bankCodeString: '8080738',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0738',
      branchName: '小港分行',
      totalName: '0738 ╴小港分行'
    },
    {
      bankCodeString: '8080749',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0749',
      branchName: '北新莊分行',
      totalName: '0749 ╴北新莊分行'
    },
    {
      bankCodeString: '8080750',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0750',
      branchName: '竹北分行',
      totalName: '0750 ╴竹北分行'
    },
    {
      bankCodeString: '8080761',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0761',
      branchName: '東台南分行',
      totalName: '0761 ╴東台南分行'
    },
    {
      bankCodeString: '8080772',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0772',
      branchName: '集賢分行',
      totalName: '0772 ╴集賢分行'
    },
    {
      bankCodeString: '8080783',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0783',
      branchName: '基隆分行',
      totalName: '0783 ╴基隆分行'
    },
    {
      bankCodeString: '8080794',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0794',
      branchName: '岡山分行',
      totalName: '0794 ╴岡山分行'
    },
    {
      bankCodeString: '8080808',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0808',
      branchName: '連城分行',
      totalName: '0808 ╴連城分行'
    },
    {
      bankCodeString: '8080819',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0819',
      branchName: '林園分行',
      totalName: '0819 ╴林園分行'
    },
    {
      bankCodeString: '8080820',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0820',
      branchName: '蘆洲分行',
      totalName: '0820 ╴蘆洲分行'
    },
    {
      bankCodeString: '8080831',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0831',
      branchName: '後庄分行',
      totalName: '0831 ╴後庄分行'
    },
    {
      bankCodeString: '8080842',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0842',
      branchName: '中路特區分行',
      totalName: '0842 ╴中路特區分行'
    },
    {
      bankCodeString: '8080853',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0853',
      branchName: '東三重分行',
      totalName: '0853 ╴東三重分行'
    },
    {
      bankCodeString: '8080864',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0864',
      branchName: '北投分行',
      totalName: '0864 ╴北投分行'
    },
    {
      bankCodeString: '8080875',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0875',
      branchName: '忠孝分行',
      totalName: '0875 ╴忠孝分行'
    },
    {
      bankCodeString: '8080886',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0886',
      branchName: '林口分行',
      totalName: '0886 ╴林口分行'
    },
    {
      bankCodeString: '8080897',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0897',
      branchName: '東湖分行',
      totalName: '0897 ╴東湖分行'
    },
    {
      bankCodeString: '8080901',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0901',
      branchName: '北新分行',
      totalName: '0901 ╴北新分行'
    },
    {
      bankCodeString: '8080912',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0912',
      branchName: '中崙分行',
      totalName: '0912 ╴中崙分行'
    },
    {
      bankCodeString: '8080923',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0923',
      branchName: '建成分行',
      totalName: '0923 ╴建成分行'
    },
    {
      bankCodeString: '8080934',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0934',
      branchName: '屏東分行',
      totalName: '0934 ╴屏東分行'
    },
    {
      bankCodeString: '8080945',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0945',
      branchName: '二重分行',
      totalName: '0945 ╴二重分行'
    },
    {
      bankCodeString: '8080956',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0956',
      branchName: '台北分行',
      totalName: '0956 ╴台北分行'
    },
    {
      bankCodeString: '8080967',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0967',
      branchName: '大安分行',
      totalName: '0967 ╴大安分行'
    },
    {
      bankCodeString: '8080978',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0978',
      branchName: '東港分行',
      totalName: '0978 ╴東港分行'
    },
    {
      bankCodeString: '8080989',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0989',
      branchName: '古亭分行',
      totalName: '0989 ╴古亭分行'
    },
    {
      bankCodeString: '8080990',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '0990',
      branchName: '草屯分行',
      totalName: '0990 ╴草屯分行'
    },
    {
      bankCodeString: '8081001',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '1001',
      branchName: '新營分行',
      totalName: '1001 ╴新營分行'
    },
    {
      bankCodeString: '8081012',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '1012',
      branchName: '迴龍分行',
      totalName: '1012 ╴迴龍分行'
    },
    {
      bankCodeString: '8081023',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '1023',
      branchName: '花蓮分行',
      totalName: '1023 ╴花蓮分行'
    },
    {
      bankCodeString: '8081034',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '1034',
      branchName: '澎湖分行',
      totalName: '1034 ╴澎湖分行'
    },
    {
      bankCodeString: '8081045',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '1045',
      branchName: '三峽分行',
      totalName: '1045 ╴三峽分行'
    },
    {
      bankCodeString: '8081056',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '1056',
      branchName: '新生分行',
      totalName: '1056 ╴新生分行'
    },
    {
      bankCodeString: '8081067',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '1067',
      branchName: '金華分行',
      totalName: '1067 ╴金華分行'
    },
    {
      bankCodeString: '8081078',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '1078',
      branchName: '光復分行',
      totalName: '1078 ╴光復分行'
    },
    {
      bankCodeString: '8081089',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '1089',
      branchName: '新湖分行',
      totalName: '1089 ╴新湖分行'
    },
    {
      bankCodeString: '8081090',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '1090',
      branchName: '桃鶯分行',
      totalName: '1090 ╴桃鶯分行'
    },
    {
      bankCodeString: '8081104',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '1104',
      branchName: '員林分行',
      totalName: '1104 ╴員林分行'
    },
    {
      bankCodeString: '8081115',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '1115',
      branchName: '永安分行',
      totalName: '1115 ╴永安分行'
    },
    {
      bankCodeString: '8081126',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '1126',
      branchName: '新豐分行',
      totalName: '1126 ╴新豐分行'
    },
    {
      bankCodeString: '8081137',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '1137',
      branchName: '仁德分行',
      totalName: '1137 ╴仁德分行'
    },
    {
      bankCodeString: '8081148',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '1148',
      branchName: '泰和分行',
      totalName: '1148 ╴泰和分行'
    },
    {
      bankCodeString: '8081159',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '1159',
      branchName: '西屯分行',
      totalName: '1159 ╴西屯分行'
    },
    {
      bankCodeString: '8081160',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '1160',
      branchName: '南勢角分行',
      totalName: '1160 ╴南勢角分行'
    },
    {
      bankCodeString: '8081171',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '1171',
      branchName: '板橋分行',
      totalName: '1171 ╴板橋分行'
    },
    {
      bankCodeString: '8081182',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '1182',
      branchName: '南港分行',
      totalName: '1182 ╴南港分行'
    },
    {
      bankCodeString: '8081193',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '1193',
      branchName: '東門分行',
      totalName: '1193 ╴東門分行'
    },
    {
      bankCodeString: '8081207',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '1207',
      branchName: '南土城分行',
      totalName: '1207 ╴南土城分行'
    },
    {
      bankCodeString: '8081218',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '1218',
      branchName: '朴子分行',
      totalName: '1218 ╴朴子分行'
    },
    {
      bankCodeString: '8081229',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '1229',
      branchName: '信義分行',
      totalName: '1229 ╴信義分行'
    },
    {
      bankCodeString: '8081230',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '1230',
      branchName: '汐止分行',
      totalName: '1230 ╴汐止分行'
    },
    {
      bankCodeString: '8081241',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '1241',
      branchName: '新樹分行',
      totalName: '1241 ╴新樹分行'
    },
    {
      bankCodeString: '8081252',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '1252',
      branchName: '中工分行',
      totalName: '1252 ╴中工分行'
    },
    {
      bankCodeString: '8081274',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '1274',
      branchName: '鹽行分行',
      totalName: '1274 ╴鹽行分行'
    },
    {
      bankCodeString: '8081285',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '1285',
      branchName: '頭份分行',
      totalName: '1285 ╴頭份分行'
    },
    {
      bankCodeString: '8081296',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '1296',
      branchName: '後龍分行',
      totalName: '1296 ╴後龍分行'
    },
    {
      bankCodeString: '8081300',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '1300',
      branchName: '五權分行',
      totalName: '1300 ╴五權分行'
    },
    {
      bankCodeString: '8081311',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '1311',
      branchName: '南永康分行',
      totalName: '1311 ╴南永康分行'
    },
    {
      bankCodeString: '8081322',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '1322',
      branchName: '藝文分行',
      totalName: '1322 ╴藝文分行'
    },
    {
      bankCodeString: '8081333',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '1333',
      branchName: '中原分行',
      totalName: '1333 ╴中原分行'
    },
    {
      bankCodeString: '8081344',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '1344',
      branchName: '竹科分行',
      totalName: '1344 ╴竹科分行'
    },
    {
      bankCodeString: '8081355',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '1355',
      branchName: '太平分行',
      totalName: '1355 ╴太平分行'
    },
    {
      bankCodeString: '8081366',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '1366',
      branchName: '玉山台中分行',
      totalName: '1366 ╴玉山台中分行'
    },
    {
      bankCodeString: '8081377',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '1377',
      branchName: '六家分行',
      totalName: '1377 ╴六家分行'
    },
    {
      bankCodeString: '8081388',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '1388',
      branchName: '北屯分行',
      totalName: '1388 ╴北屯分行'
    },
    {
      bankCodeString: '8081399',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '1399',
      branchName: '烏日分行',
      totalName: '1399 ╴烏日分行'
    },
    {
      bankCodeString: '8081403',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '1403',
      branchName: '安南分行',
      totalName: '1403 ╴安南分行'
    },
    {
      bankCodeString: '8081425',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '1425',
      branchName: '沙鹿分行',
      totalName: '1425 ╴沙鹿分行'
    },
    {
      bankCodeString: '8081436',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '1436',
      branchName: '東林口分行',
      totalName: '1436 ╴東林口分行'
    },
    {
      bankCodeString: '8081458',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '1458',
      branchName: '南山廣場分行',
      totalName: '1458 ╴南山廣場分行'
    },
    {
      bankCodeString: '8087003',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '7003',
      branchName: '玉山銀行(中國)有限公司',
      totalName: '7003 ╴玉山銀行(中國)有限公司'
    },
    {
      bankCodeString: '8087014',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '7014',
      branchName: '玉山銀行(中國)有限公司東莞分行',
      totalName: '7014 ╴玉山銀行(中國)有限公司東莞分行'
    },
    {
      bankCodeString: '8087025',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '7025',
      branchName: '玉山銀行(中國)有限公司東莞長安支行',
      totalName: '7025 ╴玉山銀行(中國)有限公司東莞長安支行'
    },
    {
      bankCodeString: '8087036',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '7036',
      branchName: '玉山銀行(中國)有限公司深圳分行',
      totalName: '7036 ╴玉山銀行(中國)有限公司深圳分行'
    },
    {
      bankCodeString: '8087058',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '7058',
      branchName: '廣州分行',
      totalName: '7058 ╴廣州分行'
    },
    {
      bankCodeString: '8088000',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '8000',
      branchName: '聯合商業銀行',
      totalName: '8000 ╴聯合商業銀行'
    },
    {
      bankCodeString: '8088011',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '8011',
      branchName: '聯合商業銀行西哈努克分行',
      totalName: '8011 ╴聯合商業銀行西哈努克分行'
    },
    {
      bankCodeString: '8088022',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '8022',
      branchName: '聯合商業銀行暹粒分行',
      totalName: '8022 ╴聯合商業銀行暹粒分行'
    },
    {
      bankCodeString: '8088055',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '8055',
      branchName: '聯合商業銀行朗哥市場分行',
      totalName: '8055 ╴聯合商業銀行朗哥市場分行'
    },
    {
      bankCodeString: '8088077',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '8077',
      branchName: '聯合商業銀行鐵橋頭分行',
      totalName: '8077 ╴聯合商業銀行鐵橋頭分行'
    },
    {
      bankCodeString: '8088088',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '8088',
      branchName: '聯合商業銀行五洲分行',
      totalName: '8088 ╴聯合商業銀行五洲分行'
    },
    {
      bankCodeString: '8088099',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '8099',
      branchName: '聯合商業銀行堆谷分行',
      totalName: '8099 ╴聯合商業銀行堆谷分行'
    },
    {
      bankCodeString: '8088103',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '8103',
      branchName: '聯合商業銀行德他拉分行',
      totalName: '8103 ╴聯合商業銀行德他拉分行'
    },
    {
      bankCodeString: '8088114',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '8114',
      branchName: '聯合商業銀行德拉歐分行',
      totalName: '8114 ╴聯合商業銀行德拉歐分行'
    },
    {
      bankCodeString: '8088125',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '8125',
      branchName: '聯合商業銀行舊運動場分行',
      totalName: '8125 ╴聯合商業銀行舊運動場分行'
    },
    {
      bankCodeString: '8088136',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '8136',
      branchName: '聯合商業銀行上棉芷分行',
      totalName: '8136 ╴聯合商業銀行上棉芷分行'
    },
    {
      bankCodeString: '8088147',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '8147',
      branchName: '聯合商業銀行舊市場分行',
      totalName: '8147 ╴聯合商業銀行舊市場分行'
    },
    {
      bankCodeString: '8088158',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '8158',
      branchName: '聯合商業銀行水淨華分行',
      totalName: '8158 ╴聯合商業銀行水淨華分行'
    },
    {
      bankCodeString: '8088169',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '8169',
      branchName: '聯合商業銀行中洲分行',
      totalName: '8169 ╴聯合商業銀行中洲分行'
    },
    {
      bankCodeString: '8089007',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '9007',
      branchName: '洛杉磯分行',
      totalName: '9007 ╴洛杉磯分行'
    },
    {
      bankCodeString: '8089018',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '9018',
      branchName: '香港分行',
      totalName: '9018 ╴香港分行'
    },
    {
      bankCodeString: '8089052',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '9052',
      branchName: '新加坡分行',
      totalName: '9052 ╴新加坡分行'
    },
    {
      bankCodeString: '8089100',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '9100',
      branchName: '同奈分行',
      totalName: '9100 ╴同奈分行'
    },
    {
      bankCodeString: '8089122',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '9122',
      branchName: '玉山銀行雪梨分行',
      totalName: '9122 ╴玉山銀行雪梨分行'
    },
    {
      bankCodeString: '8089133',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '9133',
      branchName: '仰光分行',
      totalName: '9133 ╴仰光分行'
    },
    {
      bankCodeString: '8089155',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '9155',
      branchName: '東京分行',
      totalName: '9155 ╴東京分行'
    },
    {
      bankCodeString: '8089166',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '9166',
      branchName: '布里斯本分行',
      totalName: '9166 ╴布里斯本分行'
    },
    {
      bankCodeString: '8089915',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '9915',
      branchName: '曼谷代表人辦事處',
      totalName: '9915 ╴曼谷代表人辦事處'
    },
    {
      bankCodeString: '8089926',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '9926',
      branchName: '胡志明市代表人辦事處',
      totalName: '9926 ╴胡志明市代表人辦事處'
    },
    {
      bankCodeString: '8089993',
      bankCode: '808',
      bankName: '玉山商業銀行',
      branchCode: '9993',
      branchName: '河內市代表人辦事處',
      totalName: '9993 ╴河內市代表人辦事處'
    }
  ],
  '809': [
    {
      bankCodeString: '8090016',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0016',
      branchName: '中山分行',
      totalName: '0016 ╴中山分行'
    },
    {
      bankCodeString: '8090027',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0027',
      branchName: '忠孝分行',
      totalName: '0027 ╴忠孝分行'
    },
    {
      bankCodeString: '8090038',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0038',
      branchName: '三重分行',
      totalName: '0038 ╴三重分行'
    },
    {
      bankCodeString: '8090049',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0049',
      branchName: '台中分行',
      totalName: '0049 ╴台中分行'
    },
    {
      bankCodeString: '8090050',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0050',
      branchName: '台南分行',
      totalName: '0050 ╴台南分行'
    },
    {
      bankCodeString: '8090061',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0061',
      branchName: '高雄分行',
      totalName: '0061 ╴高雄分行'
    },
    {
      bankCodeString: '8090072',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0072',
      branchName: '城東分行',
      totalName: '0072 ╴城東分行'
    },
    {
      bankCodeString: '8090083',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0083',
      branchName: '信託部',
      totalName: '0083 ╴信託部'
    },
    {
      bankCodeString: '8090108',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0108',
      branchName: '板橋分行',
      totalName: '0108 ╴板橋分行'
    },
    {
      bankCodeString: '8090119',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0119',
      branchName: '桃園分行',
      totalName: '0119 ╴桃園分行'
    },
    {
      bankCodeString: '8090120',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0120',
      branchName: '中壢分行',
      totalName: '0120 ╴中壢分行'
    },
    {
      bankCodeString: '8090131',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0131',
      branchName: '北高雄分行',
      totalName: '0131 ╴北高雄分行'
    },
    {
      bankCodeString: '8090142',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0142',
      branchName: '國外部',
      totalName: '0142 ╴國外部'
    },
    {
      bankCodeString: '8090153',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0153',
      branchName: '新莊分行',
      totalName: '0153 ╴新莊分行'
    },
    {
      bankCodeString: '8090164',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0164',
      branchName: '左營分行',
      totalName: '0164 ╴左營分行'
    },
    {
      bankCodeString: '8090175',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0175',
      branchName: '員林分行',
      totalName: '0175 ╴員林分行'
    },
    {
      bankCodeString: '8090186',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0186',
      branchName: '國際金融業務分行',
      totalName: '0186 ╴國際金融業務分行'
    },
    {
      bankCodeString: '8090197',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0197',
      branchName: '敦南分行',
      totalName: '0197 ╴敦南分行'
    },
    {
      bankCodeString: '8090201',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0201',
      branchName: '松江分行',
      totalName: '0201 ╴松江分行'
    },
    {
      bankCodeString: '8090212',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0212',
      branchName: '蘆洲分行',
      totalName: '0212 ╴蘆洲分行'
    },
    {
      bankCodeString: '8090223',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0223',
      branchName: '鳳山分行',
      totalName: '0223 ╴鳳山分行'
    },
    {
      bankCodeString: '8090234',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0234',
      branchName: '新竹分行',
      totalName: '0234 ╴新竹分行'
    },
    {
      bankCodeString: '8090245',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0245',
      branchName: '松山分行',
      totalName: '0245 ╴松山分行'
    },
    {
      bankCodeString: '8090256',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0256',
      branchName: '土城分行',
      totalName: '0256 ╴土城分行'
    },
    {
      bankCodeString: '8090267',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0267',
      branchName: '中和分行',
      totalName: '0267 ╴中和分行'
    },
    {
      bankCodeString: '8090278',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0278',
      branchName: '赤崁分行',
      totalName: '0278 ╴赤崁分行'
    },
    {
      bankCodeString: '8090289',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0289',
      branchName: '基隆分行',
      totalName: '0289 ╴基隆分行'
    },
    {
      bankCodeString: '8090304',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0304',
      branchName: '東門分行',
      totalName: '0304 ╴東門分行'
    },
    {
      bankCodeString: '8090315',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0315',
      branchName: '北門分行',
      totalName: '0315 ╴北門分行'
    },
    {
      bankCodeString: '8090326',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0326',
      branchName: '屏東分行',
      totalName: '0326 ╴屏東分行'
    },
    {
      bankCodeString: '8090337',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0337',
      branchName: '雙和分行',
      totalName: '0337 ╴雙和分行'
    },
    {
      bankCodeString: '8090348',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0348',
      branchName: '歸仁分行',
      totalName: '0348 ╴歸仁分行'
    },
    {
      bankCodeString: '8090359',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0359',
      branchName: '海東分行',
      totalName: '0359 ╴海東分行'
    },
    {
      bankCodeString: '8090371',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0371',
      branchName: '永康分行',
      totalName: '0371 ╴永康分行'
    },
    {
      bankCodeString: '8090382',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0382',
      branchName: '天母分行',
      totalName: '0382 ╴天母分行'
    },
    {
      bankCodeString: '8090393',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0393',
      branchName: '新店分行',
      totalName: '0393 ╴新店分行'
    },
    {
      bankCodeString: '8090407',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0407',
      branchName: '大安分行',
      totalName: '0407 ╴大安分行'
    },
    {
      bankCodeString: '8090429',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0429',
      branchName: '苗栗分行',
      totalName: '0429 ╴苗栗分行'
    },
    {
      bankCodeString: '8090452',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0452',
      branchName: '花蓮分行',
      totalName: '0452 ╴花蓮分行'
    },
    {
      bankCodeString: '8090474',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0474',
      branchName: '彰化分行',
      totalName: '0474 ╴彰化分行'
    },
    {
      bankCodeString: '8090485',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0485',
      branchName: '豐原分行',
      totalName: '0485 ╴豐原分行'
    },
    {
      bankCodeString: '8090511',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0511',
      branchName: '大里分行',
      totalName: '0511 ╴大里分行'
    },
    {
      bankCodeString: '8090522',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0522',
      branchName: '嘉義分行',
      totalName: '0522 ╴嘉義分行'
    },
    {
      bankCodeString: '8090577',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0577',
      branchName: '風城分行',
      totalName: '0577 ╴風城分行'
    },
    {
      bankCodeString: '8090588',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0588',
      branchName: '南大分行',
      totalName: '0588 ╴南大分行'
    },
    {
      bankCodeString: '8090599',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0599',
      branchName: '竹科分行',
      totalName: '0599 ╴竹科分行'
    },
    {
      bankCodeString: '8090625',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0625',
      branchName: '台東分行',
      totalName: '0625 ╴台東分行'
    },
    {
      bankCodeString: '8090660',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0660',
      branchName: '兼營證券商',
      totalName: '0660 ╴兼營證券商'
    },
    {
      bankCodeString: '8090669',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0669',
      branchName: '羅東分行',
      totalName: '0669 ╴羅東分行'
    },
    {
      bankCodeString: '8090670',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0670',
      branchName: '大直分行',
      totalName: '0670 ╴大直分行'
    },
    {
      bankCodeString: '8090681',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0681',
      branchName: '高美館分行',
      totalName: '0681 ╴高美館分行'
    },
    {
      bankCodeString: '8090692',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0692',
      branchName: '市政分行',
      totalName: '0692 ╴市政分行'
    },
    {
      bankCodeString: '8090706',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0706',
      branchName: '藝文分行',
      totalName: '0706 ╴藝文分行'
    },
    {
      bankCodeString: '8090717',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0717',
      branchName: '瑞光分行',
      totalName: '0717 ╴瑞光分行'
    },
    {
      bankCodeString: '8090728',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '0728',
      branchName: '民生分行',
      totalName: '0728 ╴民生分行'
    },
    {
      bankCodeString: '8096880',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '6880',
      branchName: '金融市場處',
      totalName: '6880 ╴金融市場處'
    },
    {
      bankCodeString: '8097600',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '7600',
      branchName: '商人金融處',
      totalName: '7600 ╴商人金融處'
    },
    {
      bankCodeString: '8099170',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '9170',
      branchName: '董事會秘書處',
      totalName: '9170 ╴董事會秘書處'
    },
    {
      bankCodeString: '8099180',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '9180',
      branchName: '稽核處',
      totalName: '9180 ╴稽核處'
    },
    {
      bankCodeString: '8099210',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '9210',
      branchName: '法務處',
      totalName: '9210 ╴法務處'
    },
    {
      bankCodeString: '8099220',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '9220',
      branchName: '法令遵循處',
      totalName: '9220 ╴法令遵循處'
    },
    {
      bankCodeString: '8099230',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '9230',
      branchName: '企劃處',
      totalName: '9230 ╴企劃處'
    },
    {
      bankCodeString: '8099240',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '9240',
      branchName: '客群經營處',
      totalName: '9240 ╴客群經營處'
    },
    {
      bankCodeString: '8099250',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '9250',
      branchName: '風險管理處',
      totalName: '9250 ╴風險管理處'
    },
    {
      bankCodeString: '8099270',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '9270',
      branchName: '資訊處',
      totalName: '9270 ╴資訊處'
    },
    {
      bankCodeString: '8099280',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '9280',
      branchName: '人力資源處',
      totalName: '9280 ╴人力資源處'
    },
    {
      bankCodeString: '8099290',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '9290',
      branchName: '海外事業發展處',
      totalName: '9290 ╴海外事業發展處'
    },
    {
      bankCodeString: '8099300',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '9300',
      branchName: '財務管理處',
      totalName: '9300 ╴財務管理處'
    },
    {
      bankCodeString: '8099400',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '9400',
      branchName: '作業暨行政處',
      totalName: '9400 ╴作業暨行政處'
    },
    {
      bankCodeString: '8099500',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '9500',
      branchName: '通路營運處',
      totalName: '9500 ╴通路營運處'
    },
    {
      bankCodeString: '8099610',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '9610',
      branchName: '商業分析處',
      totalName: '9610 ╴商業分析處'
    },
    {
      bankCodeString: '8099640',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '9640',
      branchName: '商業金融處',
      totalName: '9640 ╴商業金融處'
    },
    {
      bankCodeString: '8099680',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '9680',
      branchName: '數位金融處',
      totalName: '9680 ╴數位金融處'
    },
    {
      bankCodeString: '8099700',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '9700',
      branchName: '企業金融處',
      totalName: '9700 ╴企業金融處'
    },
    {
      bankCodeString: '8099800',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '9800',
      branchName: '消費金融處',
      totalName: '9800 ╴消費金融處'
    },
    {
      bankCodeString: '8099840',
      bankCode: '809',
      bankName: '凱基商業銀行',
      branchCode: '9840',
      branchName: '債權管理處',
      totalName: '9840 ╴債權管理處'
    }
  ],
  '810': [
    {
      bankCodeString: '8100001',
      bankCode: '810',
      bankName: '星展(台灣)商業銀行',
      branchCode: '0001',
      branchName: '資訊暨營運管理處',
      totalName: '0001 ╴資訊暨營運管理處'
    },
    {
      bankCodeString: '8100021',
      bankCode: '810',
      bankName: '星展(台灣)商業銀行',
      branchCode: '0021',
      branchName: '忠孝分行',
      totalName: '0021 ╴忠孝分行'
    },
    {
      bankCodeString: '8100054',
      bankCode: '810',
      bankName: '星展(台灣)商業銀行',
      branchCode: '0054',
      branchName: '桃園分行',
      totalName: '0054 ╴桃園分行'
    },
    {
      bankCodeString: '8100098',
      bankCode: '810',
      bankName: '星展(台灣)商業銀行',
      branchCode: '0098',
      branchName: '台南分行',
      totalName: '0098 ╴台南分行'
    },
    {
      bankCodeString: '8100102',
      bankCode: '810',
      bankName: '星展(台灣)商業銀行',
      branchCode: '0102',
      branchName: '大安分行',
      totalName: '0102 ╴大安分行'
    },
    {
      bankCodeString: '8100124',
      bankCode: '810',
      bankName: '星展(台灣)商業銀行',
      branchCode: '0124',
      branchName: '太平分行',
      totalName: '0124 ╴太平分行'
    },
    {
      bankCodeString: '8100135',
      bankCode: '810',
      bankName: '星展(台灣)商業銀行',
      branchCode: '0135',
      branchName: '中清分行',
      totalName: '0135 ╴中清分行'
    },
    {
      bankCodeString: '8100157',
      bankCode: '810',
      bankName: '星展(台灣)商業銀行',
      branchCode: '0157',
      branchName: '三重分行',
      totalName: '0157 ╴三重分行'
    },
    {
      bankCodeString: '8100179',
      bankCode: '810',
      bankName: '星展(台灣)商業銀行',
      branchCode: '0179',
      branchName: '信義分行',
      totalName: '0179 ╴信義分行'
    },
    {
      bankCodeString: '8100180',
      bankCode: '810',
      bankName: '星展(台灣)商業銀行',
      branchCode: '0180',
      branchName: '中港分行',
      totalName: '0180 ╴中港分行'
    },
    {
      bankCodeString: '8100191',
      bankCode: '810',
      bankName: '星展(台灣)商業銀行',
      branchCode: '0191',
      branchName: '星展(台灣)國際金融業務分行',
      totalName: '0191 ╴星展(台灣)國際金融業務分行'
    },
    {
      bankCodeString: '8100205',
      bankCode: '810',
      bankName: '星展(台灣)商業銀行',
      branchCode: '0205',
      branchName: '左營分行',
      totalName: '0205 ╴左營分行'
    },
    {
      bankCodeString: '8100227',
      bankCode: '810',
      bankName: '星展(台灣)商業銀行',
      branchCode: '0227',
      branchName: '天母分行',
      totalName: '0227 ╴天母分行'
    },
    {
      bankCodeString: '8100250',
      bankCode: '810',
      bankName: '星展(台灣)商業銀行',
      branchCode: '0250',
      branchName: '新莊分行',
      totalName: '0250 ╴新莊分行'
    },
    {
      bankCodeString: '8100294',
      bankCode: '810',
      bankName: '星展(台灣)商業銀行',
      branchCode: '0294',
      branchName: '民權分行',
      totalName: '0294 ╴民權分行'
    },
    {
      bankCodeString: '8100308',
      bankCode: '810',
      bankName: '星展(台灣)商業銀行',
      branchCode: '0308',
      branchName: '苓雅分行',
      totalName: '0308 ╴苓雅分行'
    },
    {
      bankCodeString: '8100320',
      bankCode: '810',
      bankName: '星展(台灣)商業銀行',
      branchCode: '0320',
      branchName: '新店分行',
      totalName: '0320 ╴新店分行'
    },
    {
      bankCodeString: '8100331',
      bankCode: '810',
      bankName: '星展(台灣)商業銀行',
      branchCode: '0331',
      branchName: '前鎮分行',
      totalName: '0331 ╴前鎮分行'
    },
    {
      bankCodeString: '8100364',
      bankCode: '810',
      bankName: '星展(台灣)商業銀行',
      branchCode: '0364',
      branchName: '南京東路分行',
      totalName: '0364 ╴南京東路分行'
    },
    {
      bankCodeString: '8100397',
      bankCode: '810',
      bankName: '星展(台灣)商業銀行',
      branchCode: '0397',
      branchName: '新竹分行',
      totalName: '0397 ╴新竹分行'
    },
    {
      bankCodeString: '8100401',
      bankCode: '810',
      bankName: '星展(台灣)商業銀行',
      branchCode: '0401',
      branchName: '大同分行',
      totalName: '0401 ╴大同分行'
    },
    {
      bankCodeString: '8100412',
      bankCode: '810',
      bankName: '星展(台灣)商業銀行',
      branchCode: '0412',
      branchName: '敦南分行',
      totalName: '0412 ╴敦南分行'
    },
    {
      bankCodeString: '8100423',
      bankCode: '810',
      bankName: '星展(台灣)商業銀行',
      branchCode: '0423',
      branchName: '內湖分行',
      totalName: '0423 ╴內湖分行'
    },
    {
      bankCodeString: '8100456',
      bankCode: '810',
      bankName: '星展(台灣)商業銀行',
      branchCode: '0456',
      branchName: '松山分行',
      totalName: '0456 ╴松山分行'
    },
    {
      bankCodeString: '8100467',
      bankCode: '810',
      bankName: '星展(台灣)商業銀行',
      branchCode: '0467',
      branchName: '松江分行',
      totalName: '0467 ╴松江分行'
    },
    {
      bankCodeString: '8100478',
      bankCode: '810',
      bankName: '星展(台灣)商業銀行',
      branchCode: '0478',
      branchName: '松仁分行',
      totalName: '0478 ╴松仁分行'
    },
    {
      bankCodeString: '8100489',
      bankCode: '810',
      bankName: '星展(台灣)商業銀行',
      branchCode: '0489',
      branchName: '新站分行',
      totalName: '0489 ╴新站分行'
    },
    {
      bankCodeString: '8100490',
      bankCode: '810',
      bankName: '星展(台灣)商業銀行',
      branchCode: '0490',
      branchName: '中正分行',
      totalName: '0490 ╴中正分行'
    },
    {
      bankCodeString: '8100504',
      bankCode: '810',
      bankName: '星展(台灣)商業銀行',
      branchCode: '0504',
      branchName: '中興分行',
      totalName: '0504 ╴中興分行'
    },
    {
      bankCodeString: '8100559',
      bankCode: '810',
      bankName: '星展(台灣)商業銀行',
      branchCode: '0559',
      branchName: '四維分行',
      totalName: '0559 ╴四維分行'
    },
    {
      bankCodeString: '8100858',
      bankCode: '810',
      bankName: '星展(台灣)商業銀行',
      branchCode: '0858',
      branchName: '內湖科學園區分行',
      totalName: '0858 ╴內湖科學園區分行'
    },
    {
      bankCodeString: '810CA01',
      bankCode: '810',
      bankName: '星展(台灣)商業銀行',
      branchCode: 'CA01',
      branchName: '放款及存款產品部-車貸',
      totalName: 'CA01 ╴放款及存款產品部-車貸'
    },
    {
      bankCodeString: '810CI01',
      bankCode: '810',
      bankName: '星展(台灣)商業銀行',
      branchCode: 'CI01',
      branchName: '放款及存款產品部-信用卡',
      totalName: 'CI01 ╴放款及存款產品部-信用卡'
    },
    {
      bankCodeString: '810CI02',
      bankCode: '810',
      bankName: '星展(台灣)商業銀行',
      branchCode: 'CI02',
      branchName: '放款及存款產品部-現金卡',
      totalName: 'CI02 ╴放款及存款產品部-現金卡'
    },
    {
      bankCodeString: '810CP01',
      bankCode: '810',
      bankName: '星展(台灣)商業銀行',
      branchCode: 'CP01',
      branchName: '放款及存款產品部-房貸',
      totalName: 'CP01 ╴放款及存款產品部-房貸'
    },
    {
      bankCodeString: '810CW01',
      bankCode: '810',
      bankName: '星展(台灣)商業銀行',
      branchCode: 'CW01',
      branchName: '放款及存款產品部-信貸',
      totalName: 'CW01 ╴放款及存款產品部-信貸'
    }
  ],
  '812': [
    {
      bankCodeString: '8120012',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0012',
      branchName: '營業部',
      totalName: '0012 ╴營業部'
    },
    {
      bankCodeString: '8120023',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0023',
      branchName: '敦南分行',
      totalName: '0023 ╴敦南分行'
    },
    {
      bankCodeString: '8120045',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0045',
      branchName: '新生分行',
      totalName: '0045 ╴新生分行'
    },
    {
      bankCodeString: '8120056',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0056',
      branchName: '新莊分行',
      totalName: '0056 ╴新莊分行'
    },
    {
      bankCodeString: '8120067',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0067',
      branchName: '桃園分行',
      totalName: '0067 ╴桃園分行'
    },
    {
      bankCodeString: '8120078',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0078',
      branchName: '台中分行',
      totalName: '0078 ╴台中分行'
    },
    {
      bankCodeString: '8120089',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0089',
      branchName: '板橋分行',
      totalName: '0089 ╴板橋分行'
    },
    {
      bankCodeString: '8120090',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0090',
      branchName: '高雄分行',
      totalName: '0090 ╴高雄分行'
    },
    {
      bankCodeString: '8120104',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0104',
      branchName: '台南分行',
      totalName: '0104 ╴台南分行'
    },
    {
      bankCodeString: '8120115',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0115',
      branchName: '南京東路分行',
      totalName: '0115 ╴南京東路分行'
    },
    {
      bankCodeString: '8120126',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0126',
      branchName: '中壢分行',
      totalName: '0126 ╴中壢分行'
    },
    {
      bankCodeString: '8120137',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0137',
      branchName: '信託部',
      totalName: '0137 ╴信託部'
    },
    {
      bankCodeString: '8120148',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0148',
      branchName: '國外部',
      totalName: '0148 ╴國外部'
    },
    {
      bankCodeString: '8120159',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0159',
      branchName: '苓雅分行',
      totalName: '0159 ╴苓雅分行'
    },
    {
      bankCodeString: '8120160',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0160',
      branchName: '蘆洲分行',
      totalName: '0160 ╴蘆洲分行'
    },
    {
      bankCodeString: '8120171',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0171',
      branchName: '天母分行',
      totalName: '0171 ╴天母分行'
    },
    {
      bankCodeString: '8120182',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0182',
      branchName: '大里分行',
      totalName: '0182 ╴大里分行'
    },
    {
      bankCodeString: '8120193',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0193',
      branchName: '國際金融業務分行',
      totalName: '0193 ╴國際金融業務分行'
    },
    {
      bankCodeString: '8120207',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0207',
      branchName: '中和分行',
      totalName: '0207 ╴中和分行'
    },
    {
      bankCodeString: '8120218',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0218',
      branchName: '嘉義分行',
      totalName: '0218 ╴嘉義分行'
    },
    {
      bankCodeString: '8120229',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0229',
      branchName: '豐原分行',
      totalName: '0229 ╴豐原分行'
    },
    {
      bankCodeString: '8120230',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0230',
      branchName: '花蓮分行',
      totalName: '0230 ╴花蓮分行'
    },
    {
      bankCodeString: '8120241',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0241',
      branchName: '新竹分行',
      totalName: '0241 ╴新竹分行'
    },
    {
      bankCodeString: '8120252',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0252',
      branchName: '七賢分行',
      totalName: '0252 ╴七賢分行'
    },
    {
      bankCodeString: '8120263',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0263',
      branchName: '信義分行',
      totalName: '0263 ╴信義分行'
    },
    {
      bankCodeString: '8120274',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0274',
      branchName: '北台中分行',
      totalName: '0274 ╴北台中分行'
    },
    {
      bankCodeString: '8120285',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0285',
      branchName: '三重分行',
      totalName: '0285 ╴三重分行'
    },
    {
      bankCodeString: '8120296',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0296',
      branchName: '永福分行',
      totalName: '0296 ╴永福分行'
    },
    {
      bankCodeString: '8120300',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0300',
      branchName: '屏東分行',
      totalName: '0300 ╴屏東分行'
    },
    {
      bankCodeString: '8120311',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0311',
      branchName: '大安分行',
      totalName: '0311 ╴大安分行'
    },
    {
      bankCodeString: '8120322',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0322',
      branchName: '龍潭分行',
      totalName: '0322 ╴龍潭分行'
    },
    {
      bankCodeString: '8120333',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0333',
      branchName: '崇德分行',
      totalName: '0333 ╴崇德分行'
    },
    {
      bankCodeString: '8120344',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0344',
      branchName: '金華分行',
      totalName: '0344 ╴金華分行'
    },
    {
      bankCodeString: '8120355',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0355',
      branchName: '後甲分行',
      totalName: '0355 ╴後甲分行'
    },
    {
      bankCodeString: '8120366',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0366',
      branchName: '海佃分行',
      totalName: '0366 ╴海佃分行'
    },
    {
      bankCodeString: '8120377',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0377',
      branchName: '彰化分行',
      totalName: '0377 ╴彰化分行'
    },
    {
      bankCodeString: '8120388',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0388',
      branchName: '五甲分行',
      totalName: '0388 ╴五甲分行'
    },
    {
      bankCodeString: '8120399',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0399',
      branchName: '鳳山分行',
      totalName: '0399 ╴鳳山分行'
    },
    {
      bankCodeString: '8120403',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0403',
      branchName: '古亭分行',
      totalName: '0403 ╴古亭分行'
    },
    {
      bankCodeString: '8120414',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0414',
      branchName: '建橋分行',
      totalName: '0414 ╴建橋分行'
    },
    {
      bankCodeString: '8120436',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0436',
      branchName: '民生分行',
      totalName: '0436 ╴民生分行'
    },
    {
      bankCodeString: '8120447',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0447',
      branchName: '太平分行',
      totalName: '0447 ╴太平分行'
    },
    {
      bankCodeString: '8120458',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0458',
      branchName: '和平分行',
      totalName: '0458 ╴和平分行'
    },
    {
      bankCodeString: '8120469',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0469',
      branchName: '北高雄分行',
      totalName: '0469 ╴北高雄分行'
    },
    {
      bankCodeString: '8120470',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0470',
      branchName: '南新莊分行',
      totalName: '0470 ╴南新莊分行'
    },
    {
      bankCodeString: '8120481',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0481',
      branchName: '內湖分行',
      totalName: '0481 ╴內湖分行'
    },
    {
      bankCodeString: '8120492',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0492',
      branchName: '永和分行',
      totalName: '0492 ╴永和分行'
    },
    {
      bankCodeString: '8120517',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0517',
      branchName: '逢甲分行',
      totalName: '0517 ╴逢甲分行'
    },
    {
      bankCodeString: '8120528',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0528',
      branchName: '竹科分行',
      totalName: '0528 ╴竹科分行'
    },
    {
      bankCodeString: '8120540',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0540',
      branchName: '南屯分行',
      totalName: '0540 ╴南屯分行'
    },
    {
      bankCodeString: '8120551',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0551',
      branchName: '大直分行',
      totalName: '0551 ╴大直分行'
    },
    {
      bankCodeString: '8120562',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0562',
      branchName: '南門分行',
      totalName: '0562 ╴南門分行'
    },
    {
      bankCodeString: '8120573',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0573',
      branchName: '上海代表人辦事處',
      totalName: '0573 ╴上海代表人辦事處'
    },
    {
      bankCodeString: '8120610',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0610',
      branchName: '西門分行',
      totalName: '0610 ╴西門分行'
    },
    {
      bankCodeString: '8120620',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0620',
      branchName: '兼營證券商',
      totalName: '0620 ╴兼營證券商'
    },
    {
      bankCodeString: '8120621',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0621',
      branchName: '敦北分行',
      totalName: '0621 ╴敦北分行'
    },
    {
      bankCodeString: '8120632',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0632',
      branchName: '忠孝分行',
      totalName: '0632 ╴忠孝分行'
    },
    {
      bankCodeString: '8120643',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0643',
      branchName: '復興分行',
      totalName: '0643 ╴復興分行'
    },
    {
      bankCodeString: '8120654',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0654',
      branchName: '板南分行',
      totalName: '0654 ╴板南分行'
    },
    {
      bankCodeString: '8120665',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0665',
      branchName: '民權分行',
      totalName: '0665 ╴民權分行'
    },
    {
      bankCodeString: '8120676',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0676',
      branchName: '三民分行',
      totalName: '0676 ╴三民分行'
    },
    {
      bankCodeString: '8120687',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0687',
      branchName: '建北分行',
      totalName: '0687 ╴建北分行'
    },
    {
      bankCodeString: '8120698',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0698',
      branchName: '三和分行',
      totalName: '0698 ╴三和分行'
    },
    {
      bankCodeString: '8120702',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0702',
      branchName: '北新店分行',
      totalName: '0702 ╴北新店分行'
    },
    {
      bankCodeString: '8120713',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0713',
      branchName: '府城分行',
      totalName: '0713 ╴府城分行'
    },
    {
      bankCodeString: '8120724',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0724',
      branchName: '東高雄分行',
      totalName: '0724 ╴東高雄分行'
    },
    {
      bankCodeString: '8120735',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0735',
      branchName: '基隆路分行',
      totalName: '0735 ╴基隆路分行'
    },
    {
      bankCodeString: '8120746',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0746',
      branchName: '新店分行',
      totalName: '0746 ╴新店分行'
    },
    {
      bankCodeString: '8120757',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0757',
      branchName: '北桃園分行',
      totalName: '0757 ╴北桃園分行'
    },
    {
      bankCodeString: '8120768',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0768',
      branchName: '大墩分行',
      totalName: '0768 ╴大墩分行'
    },
    {
      bankCodeString: '8120779',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0779',
      branchName: '延平分行',
      totalName: '0779 ╴延平分行'
    },
    {
      bankCodeString: '8120780',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0780',
      branchName: '南崁分行',
      totalName: '0780 ╴南崁分行'
    },
    {
      bankCodeString: '8120791',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0791',
      branchName: '景平分行',
      totalName: '0791 ╴景平分行'
    },
    {
      bankCodeString: '8120805',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0805',
      branchName: '文心分行',
      totalName: '0805 ╴文心分行'
    },
    {
      bankCodeString: '8120816',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0816',
      branchName: '岡山分行',
      totalName: '0816 ╴岡山分行'
    },
    {
      bankCodeString: '8120827',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0827',
      branchName: '松江分行',
      totalName: '0827 ╴松江分行'
    },
    {
      bankCodeString: '8120838',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0838',
      branchName: '大雅分行',
      totalName: '0838 ╴大雅分行'
    },
    {
      bankCodeString: '8120849',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0849',
      branchName: '汐止分行',
      totalName: '0849 ╴汐止分行'
    },
    {
      bankCodeString: '8120850',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0850',
      branchName: '成功分行',
      totalName: '0850 ╴成功分行'
    },
    {
      bankCodeString: '8120861',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0861',
      branchName: '石牌分行',
      totalName: '0861 ╴石牌分行'
    },
    {
      bankCodeString: '8120872',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0872',
      branchName: '永康分行',
      totalName: '0872 ╴永康分行'
    },
    {
      bankCodeString: '8120883',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0883',
      branchName: '南松山分行',
      totalName: '0883 ╴南松山分行'
    },
    {
      bankCodeString: '8120894',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0894',
      branchName: '淡水分行',
      totalName: '0894 ╴淡水分行'
    },
    {
      bankCodeString: '8120908',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0908',
      branchName: '東基隆分行',
      totalName: '0908 ╴東基隆分行'
    },
    {
      bankCodeString: '8120919',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0919',
      branchName: '新板分行',
      totalName: '0919 ╴新板分行'
    },
    {
      bankCodeString: '8120920',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0920',
      branchName: '北師分行',
      totalName: '0920 ╴北師分行'
    },
    {
      bankCodeString: '8120931',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0931',
      branchName: '永華分行',
      totalName: '0931 ╴永華分行'
    },
    {
      bankCodeString: '8120942',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0942',
      branchName: '江翠分行',
      totalName: '0942 ╴江翠分行'
    },
    {
      bankCodeString: '8120975',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0975',
      branchName: '松德分行',
      totalName: '0975 ╴松德分行'
    },
    {
      bankCodeString: '8120986',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0986',
      branchName: '北大分行',
      totalName: '0986 ╴北大分行'
    },
    {
      bankCodeString: '8120997',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '0997',
      branchName: '南港分行',
      totalName: '0997 ╴南港分行'
    },
    {
      bankCodeString: '8121008',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '1008',
      branchName: '關東橋分行',
      totalName: '1008 ╴關東橋分行'
    },
    {
      bankCodeString: '8121019',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '1019',
      branchName: '竹北分行',
      totalName: '1019 ╴竹北分行'
    },
    {
      bankCodeString: '8121020',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '1020',
      branchName: '南寮分行',
      totalName: '1020 ╴南寮分行'
    },
    {
      bankCodeString: '8121031',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '1031',
      branchName: '八德分行',
      totalName: '1031 ╴八德分行'
    },
    {
      bankCodeString: '8121042',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '1042',
      branchName: '員林分行',
      totalName: '1042 ╴員林分行'
    },
    {
      bankCodeString: '8121053',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '1053',
      branchName: '市府分行',
      totalName: '1053 ╴市府分行'
    },
    {
      bankCodeString: '8121064',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '1064',
      branchName: '右昌分行',
      totalName: '1064 ╴右昌分行'
    },
    {
      bankCodeString: '8121075',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '1075',
      branchName: '沙鹿分行',
      totalName: '1075 ╴沙鹿分行'
    },
    {
      bankCodeString: '8121086',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '1086',
      branchName: '竹南分行',
      totalName: '1086 ╴竹南分行'
    },
    {
      bankCodeString: '8121097',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '1097',
      branchName: '羅東分行',
      totalName: '1097 ╴羅東分行'
    },
    {
      bankCodeString: '8121101',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '1101',
      branchName: '東湖分行',
      totalName: '1101 ╴東湖分行'
    },
    {
      bankCodeString: '8121112',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '1112',
      branchName: '文山分行',
      totalName: '1112 ╴文山分行'
    },
    {
      bankCodeString: '8121123',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '1123',
      branchName: '副都心分行',
      totalName: '1123 ╴副都心分行'
    },
    {
      bankCodeString: '8121134',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '1134',
      branchName: '景美分行',
      totalName: '1134 ╴景美分行'
    },
    {
      bankCodeString: '8121145',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '1145',
      branchName: '雄科分行',
      totalName: '1145 ╴雄科分行'
    },
    {
      bankCodeString: '8127010',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '7010',
      branchName: '董事會秘書處',
      totalName: '7010 ╴董事會秘書處'
    },
    {
      bankCodeString: '8127020',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '7020',
      branchName: '董事會稽核處',
      totalName: '7020 ╴董事會稽核處'
    },
    {
      bankCodeString: '8127030',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '7030',
      branchName: '個金企劃處',
      totalName: '7030 ╴個金企劃處'
    },
    {
      bankCodeString: '8127050',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '7050',
      branchName: '總務部',
      totalName: '7050 ╴總務部'
    },
    {
      bankCodeString: '8127060',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '7060',
      branchName: '資訊服務處',
      totalName: '7060 ╴資訊服務處'
    },
    {
      bankCodeString: '8127070',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '7070',
      branchName: '財務金融處',
      totalName: '7070 ╴財務金融處'
    },
    {
      bankCodeString: '8127071',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '7071',
      branchName: '金融行銷處',
      totalName: '7071 ╴金融行銷處'
    },
    {
      bankCodeString: '8127072',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '7072',
      branchName: '財務風險管理處',
      totalName: '7072 ╴財務風險管理處'
    },
    {
      bankCodeString: '8127073',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '7073',
      branchName: '自營部',
      totalName: '7073 ╴自營部'
    },
    {
      bankCodeString: '8127100',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '7100',
      branchName: '作業服務處',
      totalName: '7100 ╴作業服務處'
    },
    {
      bankCodeString: '8127110',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '7110',
      branchName: '會計處',
      totalName: '7110 ╴會計處'
    },
    {
      bankCodeString: '8127120',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '7120',
      branchName: '信用卡事業處',
      totalName: '7120 ╴信用卡事業處'
    },
    {
      bankCodeString: '8127121',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '7121',
      branchName: '行銷傳播處',
      totalName: '7121 ╴行銷傳播處'
    },
    {
      bankCodeString: '8127130',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '7130',
      branchName: '人力資源處',
      totalName: '7130 ╴人力資源處'
    },
    {
      bankCodeString: '8127131',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '7131',
      branchName: '法金授信管理部',
      totalName: '7131 ╴法金授信管理部'
    },
    {
      bankCodeString: '8127132',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '7132',
      branchName: '法金管理部',
      totalName: '7132 ╴法金管理部'
    },
    {
      bankCodeString: '8127133',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '7133',
      branchName: '消金授管部',
      totalName: '7133 ╴消金授管部'
    },
    {
      bankCodeString: '8127134',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '7134',
      branchName: '法金業務處',
      totalName: '7134 ╴法金業務處'
    },
    {
      bankCodeString: '8127135',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '7135',
      branchName: '商務金融事業處',
      totalName: '7135 ╴商務金融事業處'
    },
    {
      bankCodeString: '8127136',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '7136',
      branchName: '環貿金融處',
      totalName: '7136 ╴環貿金融處'
    },
    {
      bankCodeString: '8127138',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '7138',
      branchName: '客群經營事業處',
      totalName: '7138 ╴客群經營事業處'
    },
    {
      bankCodeString: '8127140',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '7140',
      branchName: '法制處',
      totalName: '7140 ╴法制處'
    },
    {
      bankCodeString: '8127150',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '7150',
      branchName: '個金資產管理處',
      totalName: '7150 ╴個金資產管理處'
    },
    {
      bankCodeString: '8127160',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '7160',
      branchName: '個金授信管理處',
      totalName: '7160 ╴個金授信管理處'
    },
    {
      bankCodeString: '8129004',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '9004',
      branchName: '香港分行',
      totalName: '9004 ╴香港分行'
    },
    {
      bankCodeString: '8129200',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '9200',
      branchName: '新加坡分行',
      totalName: '9200 ╴新加坡分行'
    },
    {
      bankCodeString: '8129222',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '9222',
      branchName: '布里斯本分行',
      totalName: '9222 ╴布里斯本分行'
    },
    {
      bankCodeString: '8129233',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '9233',
      branchName: '東京分行',
      totalName: '9233 ╴東京分行'
    },
    {
      bankCodeString: '8129244',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '9244',
      branchName: '緬甸仰光代表人辦事處',
      totalName: '9244 ╴緬甸仰光代表人辦事處'
    },
    {
      bankCodeString: '8129255',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '9255',
      branchName: '馬來西亞納閩分行',
      totalName: '9255 ╴馬來西亞納閩分行'
    },
    {
      bankCodeString: '8129266',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '9266',
      branchName: '馬來西亞納閩分行吉隆坡行銷服務處',
      totalName: '9266 ╴馬來西亞納閩分行吉隆坡行銷服務處'
    },
    {
      bankCodeString: '8129277',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '9277',
      branchName: '泰國曼谷代表人辦事處',
      totalName: '9277 ╴泰國曼谷代表人辦事處'
    },
    {
      bankCodeString: '8129990',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: '9990',
      branchName: '越南胡志明市代表人辦事處',
      totalName: '9990 ╴越南胡志明市代表人辦事處'
    },
    {
      bankCodeString: '812FN05',
      bankCode: '812',
      bankName: '台新國際商業銀行',
      branchCode: 'FN05',
      branchName: '金融同業部',
      totalName: 'FN05 ╴金融同業部'
    }
  ],
  '815': [
    {
      bankCodeString: '8150015',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0015',
      branchName: '營業部',
      totalName: '0015 ╴營業部'
    },
    {
      bankCodeString: '8150026',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0026',
      branchName: '松江分行',
      totalName: '0026 ╴松江分行'
    },
    {
      bankCodeString: '8150037',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0037',
      branchName: '延平分行',
      totalName: '0037 ╴延平分行'
    },
    {
      bankCodeString: '8150048',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0048',
      branchName: '內湖分行',
      totalName: '0048 ╴內湖分行'
    },
    {
      bankCodeString: '8150059',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0059',
      branchName: '桃園分行',
      totalName: '0059 ╴桃園分行'
    },
    {
      bankCodeString: '8150060',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0060',
      branchName: '台中分行',
      totalName: '0060 ╴台中分行'
    },
    {
      bankCodeString: '8150071',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0071',
      branchName: '台南分行',
      totalName: '0071 ╴台南分行'
    },
    {
      bankCodeString: '8150082',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0082',
      branchName: '信託處',
      totalName: '0082 ╴信託處'
    },
    {
      bankCodeString: '8150093',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0093',
      branchName: '高雄分行',
      totalName: '0093 ╴高雄分行'
    },
    {
      bankCodeString: '8150107',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0107',
      branchName: '新莊分行',
      totalName: '0107 ╴新莊分行'
    },
    {
      bankCodeString: '8150118',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0118',
      branchName: '中壢分行',
      totalName: '0118 ╴中壢分行'
    },
    {
      bankCodeString: '8150129',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0129',
      branchName: '松山分行',
      totalName: '0129 ╴松山分行'
    },
    {
      bankCodeString: '8150130',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0130',
      branchName: '松南分行',
      totalName: '0130 ╴松南分行'
    },
    {
      bankCodeString: '8150141',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0141',
      branchName: '八德分行',
      totalName: '0141 ╴八德分行'
    },
    {
      bankCodeString: '8150152',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0152',
      branchName: '國外部',
      totalName: '0152 ╴國外部'
    },
    {
      bankCodeString: '8150163',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0163',
      branchName: '板橋分行',
      totalName: '0163 ╴板橋分行'
    },
    {
      bankCodeString: '8150174',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0174',
      branchName: '信義分行',
      totalName: '0174 ╴信義分行'
    },
    {
      bankCodeString: '8150185',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0185',
      branchName: '苓雅分行',
      totalName: '0185 ╴苓雅分行'
    },
    {
      bankCodeString: '8150196',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0196',
      branchName: '前金分行',
      totalName: '0196 ╴前金分行'
    },
    {
      bankCodeString: '8150200',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0200',
      branchName: '國際金融業務分行',
      totalName: '0200 ╴國際金融業務分行'
    },
    {
      bankCodeString: '8150211',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0211',
      branchName: '嘉義分行',
      totalName: '0211 ╴嘉義分行'
    },
    {
      bankCodeString: '8150222',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0222',
      branchName: '三重分行',
      totalName: '0222 ╴三重分行'
    },
    {
      bankCodeString: '8150233',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0233',
      branchName: '北台中分行',
      totalName: '0233 ╴北台中分行'
    },
    {
      bankCodeString: '8150244',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0244',
      branchName: '雙和分行',
      totalName: '0244 ╴雙和分行'
    },
    {
      bankCodeString: '8150255',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0255',
      branchName: '士林分行',
      totalName: '0255 ╴士林分行'
    },
    {
      bankCodeString: '8150266',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0266',
      branchName: '樹林分行',
      totalName: '0266 ╴樹林分行'
    },
    {
      bankCodeString: '8150277',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0277',
      branchName: '北桃園分行',
      totalName: '0277 ╴北桃園分行'
    },
    {
      bankCodeString: '8150288',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0288',
      branchName: '新竹分行',
      totalName: '0288 ╴新竹分行'
    },
    {
      bankCodeString: '8150299',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0299',
      branchName: '敦化分行',
      totalName: '0299 ╴敦化分行'
    },
    {
      bankCodeString: '8150303',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0303',
      branchName: '北高雄分行',
      totalName: '0303 ╴北高雄分行'
    },
    {
      bankCodeString: '8150314',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0314',
      branchName: '新營分行',
      totalName: '0314 ╴新營分行'
    },
    {
      bankCodeString: '8150325',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0325',
      branchName: '頭份分行',
      totalName: '0325 ╴頭份分行'
    },
    {
      bankCodeString: '8150336',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0336',
      branchName: '大里分行',
      totalName: '0336 ╴大里分行'
    },
    {
      bankCodeString: '8150347',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0347',
      branchName: '光復分行',
      totalName: '0347 ╴光復分行'
    },
    {
      bankCodeString: '8150358',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0358',
      branchName: '員林分行',
      totalName: '0358 ╴員林分行'
    },
    {
      bankCodeString: '8150369',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0369',
      branchName: '虎尾分行',
      totalName: '0369 ╴虎尾分行'
    },
    {
      bankCodeString: '8150370',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0370',
      branchName: '屏東分行',
      totalName: '0370 ╴屏東分行'
    },
    {
      bankCodeString: '8150381',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0381',
      branchName: '南門分行',
      totalName: '0381 ╴南門分行'
    },
    {
      bankCodeString: '8150392',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0392',
      branchName: '大安分行',
      totalName: '0392 ╴大安分行'
    },
    {
      bankCodeString: '8150406',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0406',
      branchName: '敦北分行',
      totalName: '0406 ╴敦北分行'
    },
    {
      bankCodeString: '8150417',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0417',
      branchName: '南京分行',
      totalName: '0417 ╴南京分行'
    },
    {
      bankCodeString: '8150428',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0428',
      branchName: '竹北分行',
      totalName: '0428 ╴竹北分行'
    },
    {
      bankCodeString: '8150439',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0439',
      branchName: '安平分行',
      totalName: '0439 ╴安平分行'
    },
    {
      bankCodeString: '8150451',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0451',
      branchName: '新店分行',
      totalName: '0451 ╴新店分行'
    },
    {
      bankCodeString: '8150462',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0462',
      branchName: '土城分行',
      totalName: '0462 ╴土城分行'
    },
    {
      bankCodeString: '8150473',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0473',
      branchName: '南港分行',
      totalName: '0473 ╴南港分行'
    },
    {
      bankCodeString: '8150484',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0484',
      branchName: '蘆洲分行',
      totalName: '0484 ╴蘆洲分行'
    },
    {
      bankCodeString: '8150815',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0815',
      branchName: '信用卡事業處',
      totalName: '0815 ╴信用卡事業處'
    },
    {
      bankCodeString: '8150900',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0900',
      branchName: '董事會稽核處',
      totalName: '0900 ╴董事會稽核處'
    },
    {
      bankCodeString: '8150901',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0901',
      branchName: '商業金融處',
      totalName: '0901 ╴商業金融處'
    },
    {
      bankCodeString: '8150906',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0906',
      branchName: '個人理財處',
      totalName: '0906 ╴個人理財處'
    },
    {
      bankCodeString: '8150907',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0907',
      branchName: '行政處人力資源部',
      totalName: '0907 ╴行政處人力資源部'
    },
    {
      bankCodeString: '8150908',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0908',
      branchName: '會計處',
      totalName: '0908 ╴會計處'
    },
    {
      bankCodeString: '8150910',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0910',
      branchName: '資訊處',
      totalName: '0910 ╴資訊處'
    },
    {
      bankCodeString: '8150911',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0911',
      branchName: '資產管理處',
      totalName: '0911 ╴資產管理處'
    },
    {
      bankCodeString: '8150912',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0912',
      branchName: '授信管理處',
      totalName: '0912 ╴授信管理處'
    },
    {
      bankCodeString: '8150914',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0914',
      branchName: '行政處',
      totalName: '0914 ╴行政處'
    },
    {
      bankCodeString: '8150915',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0915',
      branchName: '個金授信處',
      totalName: '0915 ╴個金授信處'
    },
    {
      bankCodeString: '8150916',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0916',
      branchName: '債權管理處',
      totalName: '0916 ╴債權管理處'
    },
    {
      bankCodeString: '8150917',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0917',
      branchName: '作業處',
      totalName: '0917 ╴作業處'
    },
    {
      bankCodeString: '8150918',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0918',
      branchName: '消金事業處',
      totalName: '0918 ╴消金事業處'
    },
    {
      bankCodeString: '8150919',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0919',
      branchName: '企金行銷部',
      totalName: '0919 ╴企金行銷部'
    },
    {
      bankCodeString: '8150920',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0920',
      branchName: '財務投資處',
      totalName: '0920 ╴財務投資處'
    },
    {
      bankCodeString: '8150924',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0924',
      branchName: '客服中心',
      totalName: '0924 ╴客服中心'
    },
    {
      bankCodeString: '8150925',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0925',
      branchName: '產品事業處',
      totalName: '0925 ╴產品事業處'
    },
    {
      bankCodeString: '8150926',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0926',
      branchName: '貿金行銷企劃部',
      totalName: '0926 ╴貿金行銷企劃部'
    },
    {
      bankCodeString: '8150927',
      bankCode: '815',
      bankName: '日盛國際商業銀行',
      branchCode: '0927',
      branchName: '營業推展處',
      totalName: '0927 ╴營業推展處'
    }
  ],
  '816': [
    {
      bankCodeString: '8160016',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0016',
      branchName: '長安東路分行',
      totalName: '0016 ╴長安東路分行'
    },
    {
      bankCodeString: '8160027',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0027',
      branchName: '信義分行',
      totalName: '0027 ╴信義分行'
    },
    {
      bankCodeString: '8160038',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0038',
      branchName: '營業部',
      totalName: '0038 ╴營業部'
    },
    {
      bankCodeString: '8160049',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0049',
      branchName: '新生分行',
      totalName: '0049 ╴新生分行'
    },
    {
      bankCodeString: '8160050',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0050',
      branchName: '桃園分行',
      totalName: '0050 ╴桃園分行'
    },
    {
      bankCodeString: '8160061',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0061',
      branchName: '新莊分行',
      totalName: '0061 ╴新莊分行'
    },
    {
      bankCodeString: '8160072',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0072',
      branchName: '台中分行',
      totalName: '0072 ╴台中分行'
    },
    {
      bankCodeString: '8160083',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0083',
      branchName: '高雄分行',
      totalName: '0083 ╴高雄分行'
    },
    {
      bankCodeString: '8160094',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0094',
      branchName: '台南分行',
      totalName: '0094 ╴台南分行'
    },
    {
      bankCodeString: '8160108',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0108',
      branchName: '興隆分行',
      totalName: '0108 ╴興隆分行'
    },
    {
      bankCodeString: '8160119',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0119',
      branchName: '延平分行',
      totalName: '0119 ╴延平分行'
    },
    {
      bankCodeString: '8160120',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0120',
      branchName: '中壢分行',
      totalName: '0120 ╴中壢分行'
    },
    {
      bankCodeString: '8160131',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0131',
      branchName: '板橋分行',
      totalName: '0131 ╴板橋分行'
    },
    {
      bankCodeString: '8160142',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0142',
      branchName: '豐原分行',
      totalName: '0142 ╴豐原分行'
    },
    {
      bankCodeString: '8160153',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0153',
      branchName: '前金分行',
      totalName: '0153 ╴前金分行'
    },
    {
      bankCodeString: '8160164',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0164',
      branchName: '中和分行',
      totalName: '0164 ╴中和分行'
    },
    {
      bankCodeString: '8160175',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0175',
      branchName: '國外部',
      totalName: '0175 ╴國外部'
    },
    {
      bankCodeString: '8160186',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0186',
      branchName: '鳳山分行',
      totalName: '0186 ╴鳳山分行'
    },
    {
      bankCodeString: '8160197',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0197',
      branchName: '松江分行',
      totalName: '0197 ╴松江分行'
    },
    {
      bankCodeString: '8160201',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0201',
      branchName: '中崙分行',
      totalName: '0201 ╴中崙分行'
    },
    {
      bankCodeString: '8160212',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0212',
      branchName: '嘉義分行',
      totalName: '0212 ╴嘉義分行'
    },
    {
      bankCodeString: '8160223',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0223',
      branchName: '三重分行',
      totalName: '0223 ╴三重分行'
    },
    {
      bankCodeString: '8160234',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0234',
      branchName: '國際金融業務分行',
      totalName: '0234 ╴國際金融業務分行'
    },
    {
      bankCodeString: '8160245',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0245',
      branchName: '沙鹿分行',
      totalName: '0245 ╴沙鹿分行'
    },
    {
      bankCodeString: '8160256',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0256',
      branchName: '新竹分行',
      totalName: '0256 ╴新竹分行'
    },
    {
      bankCodeString: '8160267',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0267',
      branchName: '員林分行',
      totalName: '0267 ╴員林分行'
    },
    {
      bankCodeString: '8160278',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0278',
      branchName: '建國分行',
      totalName: '0278 ╴建國分行'
    },
    {
      bankCodeString: '8160289',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0289',
      branchName: '北高雄分行',
      totalName: '0289 ╴北高雄分行'
    },
    {
      bankCodeString: '8160290',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0290',
      branchName: '新店分行',
      totalName: '0290 ╴新店分行'
    },
    {
      bankCodeString: '8160304',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0304',
      branchName: '和平分行',
      totalName: '0304 ╴和平分行'
    },
    {
      bankCodeString: '8160315',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0315',
      branchName: '成功分行',
      totalName: '0315 ╴成功分行'
    },
    {
      bankCodeString: '8160326',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0326',
      branchName: '通化分行',
      totalName: '0326 ╴通化分行'
    },
    {
      bankCodeString: '8160337',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0337',
      branchName: '南門分行',
      totalName: '0337 ╴南門分行'
    },
    {
      bankCodeString: '8160348',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0348',
      branchName: '汀州分行',
      totalName: '0348 ╴汀州分行'
    },
    {
      bankCodeString: '8160359',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0359',
      branchName: '農安分行',
      totalName: '0359 ╴農安分行'
    },
    {
      bankCodeString: '8160360',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0360',
      branchName: '景美分行',
      totalName: '0360 ╴景美分行'
    },
    {
      bankCodeString: '8160371',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0371',
      branchName: '民權分行',
      totalName: '0371 ╴民權分行'
    },
    {
      bankCodeString: '8160382',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0382',
      branchName: '松山分行',
      totalName: '0382 ╴松山分行'
    },
    {
      bankCodeString: '8160393',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0393',
      branchName: '內湖分行',
      totalName: '0393 ╴內湖分行'
    },
    {
      bankCodeString: '8160407',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0407',
      branchName: '瑞湖分行',
      totalName: '0407 ╴瑞湖分行'
    },
    {
      bankCodeString: '8160418',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0418',
      branchName: '蘆洲分行',
      totalName: '0418 ╴蘆洲分行'
    },
    {
      bankCodeString: '8160429',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0429',
      branchName: '永康分行',
      totalName: '0429 ╴永康分行'
    },
    {
      bankCodeString: '8160430',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0430',
      branchName: '西壢分行',
      totalName: '0430 ╴西壢分行'
    },
    {
      bankCodeString: '8160441',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0441',
      branchName: '北桃園簡易型分行',
      totalName: '0441 ╴北桃園簡易型分行'
    },
    {
      bankCodeString: '8160452',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0452',
      branchName: '幸福分行',
      totalName: '0452 ╴幸福分行'
    },
    {
      bankCodeString: '8160463',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0463',
      branchName: '天母分行',
      totalName: '0463 ╴天母分行'
    },
    {
      bankCodeString: '8160474',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0474',
      branchName: '龍潭簡易型分行',
      totalName: '0474 ╴龍潭簡易型分行'
    },
    {
      bankCodeString: '8160500',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0500',
      branchName: '石牌分行',
      totalName: '0500 ╴石牌分行'
    },
    {
      bankCodeString: '8160511',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0511',
      branchName: '八德簡易型分行',
      totalName: '0511 ╴八德簡易型分行'
    },
    {
      bankCodeString: '8160522',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0522',
      branchName: '竹北簡易型分行',
      totalName: '0522 ╴竹北簡易型分行'
    },
    {
      bankCodeString: '8160533',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0533',
      branchName: '永和分行',
      totalName: '0533 ╴永和分行'
    },
    {
      bankCodeString: '8160566',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0566',
      branchName: '大里分行',
      totalName: '0566 ╴大里分行'
    },
    {
      bankCodeString: '8160690',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0690',
      branchName: '兼營證券商',
      totalName: '0690 ╴兼營證券商'
    },
    {
      bankCodeString: '8160903',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0903',
      branchName: '董事會稽核室',
      totalName: '0903 ╴董事會稽核室'
    },
    {
      bankCodeString: '8160904',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0904',
      branchName: '資訊管理部',
      totalName: '0904 ╴資訊管理部'
    },
    {
      bankCodeString: '8160905',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0905',
      branchName: '會計部',
      totalName: '0905 ╴會計部'
    },
    {
      bankCodeString: '8160908',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0908',
      branchName: '人力資源部',
      totalName: '0908 ╴人力資源部'
    },
    {
      bankCodeString: '8160909',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0909',
      branchName: '法務暨遵法部',
      totalName: '0909 ╴法務暨遵法部'
    },
    {
      bankCodeString: '8160912',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0912',
      branchName: '個金管理部',
      totalName: '0912 ╴個金管理部'
    },
    {
      bankCodeString: '8160913',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0913',
      branchName: '消金授信部',
      totalName: '0913 ╴消金授信部'
    },
    {
      bankCodeString: '8160920',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0920',
      branchName: '財富管理部',
      totalName: '0920 ╴財富管理部'
    },
    {
      bankCodeString: '8160921',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0921',
      branchName: '財務控管部',
      totalName: '0921 ╴財務控管部'
    },
    {
      bankCodeString: '8160922',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0922',
      branchName: '風險控管部',
      totalName: '0922 ╴風險控管部'
    },
    {
      bankCodeString: '8160923',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0923',
      branchName: '債權管理部',
      totalName: '0923 ╴債權管理部'
    },
    {
      bankCodeString: '8160924',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0924',
      branchName: '法金授信部',
      totalName: '0924 ╴法金授信部'
    },
    {
      bankCodeString: '8160925',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0925',
      branchName: '商品營運部',
      totalName: '0925 ╴商品營運部'
    },
    {
      bankCodeString: '8160926',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0926',
      branchName: '商人銀行部',
      totalName: '0926 ╴商人銀行部'
    },
    {
      bankCodeString: '8160951',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0951',
      branchName: '財務部',
      totalName: '0951 ╴財務部'
    },
    {
      bankCodeString: '8160952',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0952',
      branchName: '信用卡中心',
      totalName: '0952 ╴信用卡中心'
    },
    {
      bankCodeString: '8160954',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0954',
      branchName: '金融行銷部',
      totalName: '0954 ╴金融行銷部'
    },
    {
      bankCodeString: '8160956',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0956',
      branchName: '消金產品部',
      totalName: '0956 ╴消金產品部'
    },
    {
      bankCodeString: '816096A',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '096A',
      branchName: '法金北一區域中心',
      totalName: '096A ╴法金北一區域中心'
    },
    {
      bankCodeString: '816096B',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '096B',
      branchName: '法金北二區域中心',
      totalName: '096B ╴法金北二區域中心'
    },
    {
      bankCodeString: '816096C',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '096C',
      branchName: '法金北三區域中心',
      totalName: '096C ╴法金北三區域中心'
    },
    {
      bankCodeString: '816096D',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '096D',
      branchName: '法金北四區域中心',
      totalName: '096D ╴法金北四區域中心'
    },
    {
      bankCodeString: '816096E',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '096E',
      branchName: '武陵區域中心',
      totalName: '096E ╴武陵區域中心'
    },
    {
      bankCodeString: '816096F',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '096F',
      branchName: '法金桃竹區域中心',
      totalName: '096F ╴法金桃竹區域中心'
    },
    {
      bankCodeString: '816096G',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '096G',
      branchName: '法金台中區域中心',
      totalName: '096G ╴法金台中區域中心'
    },
    {
      bankCodeString: '816096H',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '096H',
      branchName: '法金台南區域中心',
      totalName: '096H ╴法金台南區域中心'
    },
    {
      bankCodeString: '816096I',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '096I',
      branchName: '法金高雄區域中心',
      totalName: '096I ╴法金高雄區域中心'
    },
    {
      bankCodeString: '816096J',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '096J',
      branchName: '法金北五區域中心',
      totalName: '096J ╴法金北五區域中心'
    },
    {
      bankCodeString: '816096K',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '096K',
      branchName: '法金北六區域中心',
      totalName: '096K ╴法金北六區域中心'
    },
    {
      bankCodeString: '816096L',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '096L',
      branchName: '法金北七區域中心',
      totalName: '096L ╴法金北七區域中心'
    },
    {
      bankCodeString: '816096M',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '096M',
      branchName: '法金北八區域中心',
      totalName: '096M ╴法金北八區域中心'
    },
    {
      bankCodeString: '816096N',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '096N',
      branchName: '商業金融區',
      totalName: '096N ╴商業金融區'
    },
    {
      bankCodeString: '816097A',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '097A',
      branchName: '消金世貿區域中心',
      totalName: '097A ╴消金世貿區域中心'
    },
    {
      bankCodeString: '816097B',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '097B',
      branchName: '消金中正區域中心',
      totalName: '097B ╴消金中正區域中心'
    },
    {
      bankCodeString: '816097C',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '097C',
      branchName: '消金延平區域中心',
      totalName: '097C ╴消金延平區域中心'
    },
    {
      bankCodeString: '816097D',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '097D',
      branchName: '消金武陵區域中心',
      totalName: '097D ╴消金武陵區域中心'
    },
    {
      bankCodeString: '816097E',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '097E',
      branchName: '消金公益區域中心',
      totalName: '097E ╴消金公益區域中心'
    },
    {
      bankCodeString: '816097F',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '097F',
      branchName: '消金左營區域中心',
      totalName: '097F ╴消金左營區域中心'
    },
    {
      bankCodeString: '8160981',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0981',
      branchName: '財富管理總處處長辦公室',
      totalName: '0981 ╴財富管理總處處長辦公室'
    },
    {
      bankCodeString: '8160986',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '0986',
      branchName: '風控長辦公室',
      totalName: '0986 ╴風控長辦公室'
    },
    {
      bankCodeString: '8169101',
      bankCode: '816',
      bankName: '安泰商業銀行',
      branchCode: '9101',
      branchName: '信託部',
      totalName: '9101 ╴信託部'
    }
  ],
  '822': [
    {
      bankCodeString: '8220015',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0015',
      branchName: '敦北分行',
      totalName: '0015 ╴敦北分行'
    },
    {
      bankCodeString: '8220026',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0026',
      branchName: '台中分行',
      totalName: '0026 ╴台中分行'
    },
    {
      bankCodeString: '822002I',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '002I',
      branchName: '全球消費金融產品事業處',
      totalName: '002I ╴全球消費金融產品事業處'
    },
    {
      bankCodeString: '822002O',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '002O',
      branchName: '全球商銀產品事業總處',
      totalName: '002O ╴全球商銀產品事業總處'
    },
    {
      bankCodeString: '8220037',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0037',
      branchName: '高雄分行',
      totalName: '0037 ╴高雄分行'
    },
    {
      bankCodeString: '8220048',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0048',
      branchName: 'Land and Houses Bank Public Company Limited ╴Head Offic',
      totalName: '0048 ╴Land and Houses Bank Public Company Limited ╴Head Offic'
    },
    {
      bankCodeString: '8220059',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0059',
      branchName: '台南分行',
      totalName: '0059 ╴台南分行'
    },
    {
      bankCodeString: '8220060',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0060',
      branchName: '三重分行',
      totalName: '0060 ╴三重分行'
    },
    {
      bankCodeString: '8220071',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0071',
      branchName: '城東分行',
      totalName: '0071 ╴城東分行'
    },
    {
      bankCodeString: '8220082',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0082',
      branchName: '嘉義分行',
      totalName: '0082 ╴嘉義分行'
    },
    {
      bankCodeString: '8220093',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0093',
      branchName: '中崙分行',
      totalName: '0093 ╴中崙分行'
    },
    {
      bankCodeString: '8220107',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0107',
      branchName: '城中分行',
      totalName: '0107 ╴城中分行'
    },
    {
      bankCodeString: '8220118',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0118',
      branchName: '三民分行',
      totalName: '0118 ╴三民分行'
    },
    {
      bankCodeString: '8220129',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0129',
      branchName: '中壢分行',
      totalName: '0129 ╴中壢分行'
    },
    {
      bankCodeString: '8220130',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0130',
      branchName: '雙和分行',
      totalName: '0130 ╴雙和分行'
    },
    {
      bankCodeString: '8220141',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0141',
      branchName: '中山分行',
      totalName: '0141 ╴中山分行'
    },
    {
      bankCodeString: '8220152',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0152',
      branchName: '仁愛分行',
      totalName: '0152 ╴仁愛分行'
    },
    {
      bankCodeString: '8220163',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0163',
      branchName: '敦南分行',
      totalName: '0163 ╴敦南分行'
    },
    {
      bankCodeString: '8220174',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0174',
      branchName: '板橋分行',
      totalName: '0174 ╴板橋分行'
    },
    {
      bankCodeString: '8220185',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0185',
      branchName: '忠孝分行',
      totalName: '0185 ╴忠孝分行'
    },
    {
      bankCodeString: '8220196',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0196',
      branchName: '新興分行',
      totalName: '0196 ╴新興分行'
    },
    {
      bankCodeString: '8220200',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0200',
      branchName: '南京東路分行',
      totalName: '0200 ╴南京東路分行'
    },
    {
      bankCodeString: '8220211',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0211',
      branchName: '信託部',
      totalName: '0211 ╴信託部'
    },
    {
      bankCodeString: '8220222',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0222',
      branchName: '西台南分行',
      totalName: '0222 ╴西台南分行'
    },
    {
      bankCodeString: '8220232',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0232',
      branchName: '國際管理辦公室',
      totalName: '0232 ╴國際管理辦公室'
    },
    {
      bankCodeString: '8220233',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0233',
      branchName: 'LH Financial Group Public Company Limited',
      totalName: '0233 ╴LH Financial Group Public Company Limited'
    },
    {
      bankCodeString: '8220244',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0244',
      branchName: '國際金融業務分行',
      totalName: '0244 ╴國際金融業務分行'
    },
    {
      bankCodeString: '8220255',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0255',
      branchName: '松山分行',
      totalName: '0255 ╴松山分行'
    },
    {
      bankCodeString: '8220264',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0264',
      branchName: 'Land and Houses Securities Public Company Limited ╴Bang',
      totalName: '0264 ╴Land and Houses Securities Public Company Limited ╴Bang'
    },
    {
      bankCodeString: '8220266',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0266',
      branchName: '新莊分行',
      totalName: '0266 ╴新莊分行'
    },
    {
      bankCodeString: '8220277',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0277',
      branchName: '桃園分行',
      totalName: '0277 ╴桃園分行'
    },
    {
      bankCodeString: '8220288',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0288',
      branchName: '中港分行',
      totalName: '0288 ╴中港分行'
    },
    {
      bankCodeString: '8220299',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0299',
      branchName: '新竹分行',
      totalName: '0299 ╴新竹分行'
    },
    {
      bankCodeString: '8220303',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0303',
      branchName: '蘆洲分行',
      totalName: '0303 ╴蘆洲分行'
    },
    {
      bankCodeString: '8220314',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0314',
      branchName: '新店分行',
      totalName: '0314 ╴新店分行'
    },
    {
      bankCodeString: '8220325',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0325',
      branchName: '員林分行',
      totalName: '0325 ╴員林分行'
    },
    {
      bankCodeString: '8220336',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0336',
      branchName: '東新竹分行',
      totalName: '0336 ╴東新竹分行'
    },
    {
      bankCodeString: '8220347',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0347',
      branchName: '公館分行',
      totalName: '0347 ╴公館分行'
    },
    {
      bankCodeString: '8220358',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0358',
      branchName: '永康分行',
      totalName: '0358 ╴永康分行'
    },
    {
      bankCodeString: '8220369',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0369',
      branchName: '土城分行',
      totalName: '0369 ╴土城分行'
    },
    {
      bankCodeString: '8220370',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0370',
      branchName: '頭份分行',
      totalName: '0370 ╴頭份分行'
    },
    {
      bankCodeString: '8220381',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0381',
      branchName: '基隆分行',
      totalName: '0381 ╴基隆分行'
    },
    {
      bankCodeString: '8220392',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0392',
      branchName: '豐原分行',
      totalName: '0392 ╴豐原分行'
    },
    {
      bankCodeString: '8220406',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0406',
      branchName: '天母分行',
      totalName: '0406 ╴天母分行'
    },
    {
      bankCodeString: '8220417',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0417',
      branchName: '板新分行',
      totalName: '0417 ╴板新分行'
    },
    {
      bankCodeString: '8220428',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0428',
      branchName: '彰化分行',
      totalName: '0428 ╴彰化分行'
    },
    {
      bankCodeString: '8220439',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0439',
      branchName: '東高雄分行',
      totalName: '0439 ╴東高雄分行'
    },
    {
      bankCodeString: '8220440',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0440',
      branchName: '中華分行',
      totalName: '0440 ╴中華分行'
    },
    {
      bankCodeString: '8220451',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0451',
      branchName: '內湖分行',
      totalName: '0451 ╴內湖分行'
    },
    {
      bankCodeString: '8220462',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0462',
      branchName: '斗六分行',
      totalName: '0462 ╴斗六分行'
    },
    {
      bankCodeString: '8220473',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0473',
      branchName: '文心分行',
      totalName: '0473 ╴文心分行'
    },
    {
      bankCodeString: '8220484',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0484',
      branchName: '重陽分行',
      totalName: '0484 ╴重陽分行'
    },
    {
      bankCodeString: '8220495',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0495',
      branchName: '永吉分行',
      totalName: '0495 ╴永吉分行'
    },
    {
      bankCodeString: '8220509',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0509',
      branchName: '高美館分行',
      totalName: '0509 ╴高美館分行'
    },
    {
      bankCodeString: '8220510',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0510',
      branchName: '南崁分行',
      totalName: '0510 ╴南崁分行'
    },
    {
      bankCodeString: '8220521',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0521',
      branchName: '永和分行',
      totalName: '0521 ╴永和分行'
    },
    {
      bankCodeString: '8220532',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0532',
      branchName: '太平分行',
      totalName: '0532 ╴太平分行'
    },
    {
      bankCodeString: '8220543',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0543',
      branchName: '市府分行',
      totalName: '0543 ╴市府分行'
    },
    {
      bankCodeString: '8220554',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0554',
      branchName: '六家庄分行',
      totalName: '0554 ╴六家庄分行'
    },
    {
      bankCodeString: '8220565',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0565',
      branchName: '右昌分行',
      totalName: '0565 ╴右昌分行'
    },
    {
      bankCodeString: '8220576',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0576',
      branchName: '江翠分行',
      totalName: '0576 ╴江翠分行'
    },
    {
      bankCodeString: '8220587',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0587',
      branchName: '東湖分行',
      totalName: '0587 ╴東湖分行'
    },
    {
      bankCodeString: '8220598',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0598',
      branchName: '中原分行',
      totalName: '0598 ╴中原分行'
    },
    {
      bankCodeString: '8220602',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0602',
      branchName: '大安分行',
      totalName: '0602 ╴大安分行'
    },
    {
      bankCodeString: '8220613',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0613',
      branchName: '北高雄分行',
      totalName: '0613 ╴北高雄分行'
    },
    {
      bankCodeString: '8220624',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0624',
      branchName: '承德分行',
      totalName: '0624 ╴承德分行'
    },
    {
      bankCodeString: '8220635',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0635',
      branchName: '復興分行',
      totalName: '0635 ╴復興分行'
    },
    {
      bankCodeString: '8220646',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0646',
      branchName: '石牌分行',
      totalName: '0646 ╴石牌分行'
    },
    {
      bankCodeString: '8220657',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0657',
      branchName: '城北分行',
      totalName: '0657 ╴城北分行'
    },
    {
      bankCodeString: '8220668',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0668',
      branchName: '萬華分行',
      totalName: '0668 ╴萬華分行'
    },
    {
      bankCodeString: '8220679',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0679',
      branchName: '西松分行',
      totalName: '0679 ╴西松分行'
    },
    {
      bankCodeString: '8220680',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0680',
      branchName: '北投分行',
      totalName: '0680 ╴北投分行'
    },
    {
      bankCodeString: '8220691',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0691',
      branchName: '安和分行',
      totalName: '0691 ╴安和分行'
    },
    {
      bankCodeString: '8220705',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0705',
      branchName: '西湖分行',
      totalName: '0705 ╴西湖分行'
    },
    {
      bankCodeString: '8220716',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0716',
      branchName: '瑞光分行',
      totalName: '0716 ╴瑞光分行'
    },
    {
      bankCodeString: '8220727',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0727',
      branchName: '宜蘭分行',
      totalName: '0727 ╴宜蘭分行'
    },
    {
      bankCodeString: '8220738',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0738',
      branchName: '民生分行',
      totalName: '0738 ╴民生分行'
    },
    {
      bankCodeString: '8220749',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0749',
      branchName: '中和分行',
      totalName: '0749 ╴中和分行'
    },
    {
      bankCodeString: '8220750',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0750',
      branchName: '重新分行',
      totalName: '0750 ╴重新分行'
    },
    {
      bankCodeString: '8220761',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0761',
      branchName: '丹鳳分行',
      totalName: '0761 ╴丹鳳分行'
    },
    {
      bankCodeString: '8220772',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0772',
      branchName: '北新店分行',
      totalName: '0772 ╴北新店分行'
    },
    {
      bankCodeString: '8220783',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0783',
      branchName: '汐止分行',
      totalName: '0783 ╴汐止分行'
    },
    {
      bankCodeString: '8220794',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0794',
      branchName: '金城分行',
      totalName: '0794 ╴金城分行'
    },
    {
      bankCodeString: '8220808',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0808',
      branchName: '樹林分行',
      totalName: '0808 ╴樹林分行'
    },
    {
      bankCodeString: '8220819',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0819',
      branchName: '南勢角分行',
      totalName: '0819 ╴南勢角分行'
    },
    {
      bankCodeString: '8220820',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0820',
      branchName: '北新莊分行',
      totalName: '0820 ╴北新莊分行'
    },
    {
      bankCodeString: '8220831',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0831',
      branchName: 'Land and Houses Bank Public Company Limited ╴HEAD OFFIC',
      totalName: '0831 ╴Land and Houses Bank Public Company Limited ╴HEAD OFFIC'
    },
    {
      bankCodeString: '8220842',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0842',
      branchName: 'Land and Houses Bank Public Company Limited -FASHION ISL',
      totalName: '0842 ╴Land and Houses Bank Public Company Limited -FASHION ISL'
    },
    {
      bankCodeString: '8220853',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0853',
      branchName: '南桃園分行',
      totalName: '0853 ╴南桃園分行'
    },
    {
      bankCodeString: '8220864',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0864',
      branchName: '北桃園分行',
      totalName: '0864 ╴北桃園分行'
    },
    {
      bankCodeString: '8220875',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0875',
      branchName: '竹科分行',
      totalName: '0875 ╴竹科分行'
    },
    {
      bankCodeString: '8220886',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0886',
      branchName: 'Land and Houses Bank Public Company Limited ╴THE MALL B',
      totalName: '0886 ╴Land and Houses Bank Public Company Limited ╴THE MALL B'
    },
    {
      bankCodeString: '8220897',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0897',
      branchName: 'Land and Houses Bank Public Company Limited ╴THE OLD SI',
      totalName: '0897 ╴Land and Houses Bank Public Company Limited ╴THE OLD SI'
    },
    {
      bankCodeString: '8220901',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0901',
      branchName: '營業部',
      totalName: '0901 ╴營業部'
    },
    {
      bankCodeString: '8220912',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0912',
      branchName: 'Land and Houses Bank Public Company Limited ╴MAHBOONKRO',
      totalName: '0912 ╴Land and Houses Bank Public Company Limited ╴MAHBOONKRO'
    },
    {
      bankCodeString: '8220923',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0923',
      branchName: 'Land and Houses Bank Public Company Limited ╴BIG C BANG',
      totalName: '0923 ╴Land and Houses Bank Public Company Limited ╴BIG C BANG'
    },
    {
      bankCodeString: '8220934',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0934',
      branchName: 'Land and Houses Bank Public Company Limited ╴HOME PRO P',
      totalName: '0934 ╴Land and Houses Bank Public Company Limited ╴HOME PRO P'
    },
    {
      bankCodeString: '8220945',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0945',
      branchName: 'Land and Houses Bank Public Company Limited ╴THE MALL B',
      totalName: '0945 ╴Land and Houses Bank Public Company Limited ╴THE MALL B'
    },
    {
      bankCodeString: '8220956',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0956',
      branchName: 'Land and Houses Bank Public Company Limited ╴HOME PRO C',
      totalName: '0956 ╴Land and Houses Bank Public Company Limited ╴HOME PRO C'
    },
    {
      bankCodeString: '8220967',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0967',
      branchName: 'Land and Houses Bank Public Company Limited ╴BIG C HANG',
      totalName: '0967 ╴Land and Houses Bank Public Company Limited ╴BIG C HANG'
    },
    {
      bankCodeString: '8220978',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0978',
      branchName: 'Land and Houses Bank Public Company Limited ╴FUTURE PAR',
      totalName: '0978 ╴Land and Houses Bank Public Company Limited ╴FUTURE PAR'
    },
    {
      bankCodeString: '8220989',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0989',
      branchName: 'Land and Houses Bank Public Company Limited ╴THE MALL T',
      totalName: '0989 ╴Land and Houses Bank Public Company Limited ╴THE MALL T'
    },
    {
      bankCodeString: '8220990',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '0990',
      branchName: 'Land and Houses Bank Public Company Limited ╴SRIRACHA B',
      totalName: '0990 ╴Land and Houses Bank Public Company Limited ╴SRIRACHA B'
    },
    {
      bankCodeString: '8221001',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1001',
      branchName: 'Land and Houses Bank Public Company Limited ╴YAOWARAJ B',
      totalName: '1001 ╴Land and Houses Bank Public Company Limited ╴YAOWARAJ B'
    },
    {
      bankCodeString: '8221012',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1012',
      branchName: '八德分行',
      totalName: '1012 ╴八德分行'
    },
    {
      bankCodeString: '8221023',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1023',
      branchName: '林口分行',
      totalName: '1023 ╴林口分行'
    },
    {
      bankCodeString: '8221034',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1034',
      branchName: '竹北分行',
      totalName: '1034 ╴竹北分行'
    },
    {
      bankCodeString: '8221045',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1045',
      branchName: '南屯分行',
      totalName: '1045 ╴南屯分行'
    },
    {
      bankCodeString: '8221056',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1056',
      branchName: '大里分行',
      totalName: '1056 ╴大里分行'
    },
    {
      bankCodeString: '8221067',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1067',
      branchName: '洲際分行',
      totalName: '1067 ╴洲際分行'
    },
    {
      bankCodeString: '8221078',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1078',
      branchName: '南投分行',
      totalName: '1078 ╴南投分行'
    },
    {
      bankCodeString: '8221089',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1089',
      branchName: 'Land and Houses Bank Public Company Limited ╴BIG C RAT',
      totalName: '1089 ╴Land and Houses Bank Public Company Limited ╴BIG C RAT'
    },
    {
      bankCodeString: '8221090',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1090',
      branchName: '仁德分行',
      totalName: '1090 ╴仁德分行'
    },
    {
      bankCodeString: '8221104',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1104',
      branchName: '中台南分行',
      totalName: '1104 ╴中台南分行'
    },
    {
      bankCodeString: '8221115',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1115',
      branchName: '東台南分行',
      totalName: '1115 ╴東台南分行'
    },
    {
      bankCodeString: '8221126',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1126',
      branchName: '台東分行',
      totalName: '1126 ╴台東分行'
    },
    {
      bankCodeString: '8221137',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1137',
      branchName: '南台南分行',
      totalName: '1137 ╴南台南分行'
    },
    {
      bankCodeString: '8221148',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1148',
      branchName: '新營分行',
      totalName: '1148 ╴新營分行'
    },
    {
      bankCodeString: '8221159',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1159',
      branchName: '科博館分行',
      totalName: '1159 ╴科博館分行'
    },
    {
      bankCodeString: '8221160',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1160',
      branchName: '西屯分行',
      totalName: '1160 ╴西屯分行'
    },
    {
      bankCodeString: '8221171',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1171',
      branchName: '民族分行',
      totalName: '1171 ╴民族分行'
    },
    {
      bankCodeString: '8221182',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1182',
      branchName: '南高雄分行',
      totalName: '1182 ╴南高雄分行'
    },
    {
      bankCodeString: '8221193',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1193',
      branchName: '九如分行',
      totalName: '1193 ╴九如分行'
    },
    {
      bankCodeString: '8221207',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1207',
      branchName: '屏東分行',
      totalName: '1207 ╴屏東分行'
    },
    {
      bankCodeString: '8221218',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1218',
      branchName: '復北分行',
      totalName: '1218 ╴復北分行'
    },
    {
      bankCodeString: '8221229',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1229',
      branchName: '鳳山分行',
      totalName: '1229 ╴鳳山分行'
    },
    {
      bankCodeString: '8221230',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1230',
      branchName: '五甲分行',
      totalName: '1230 ╴五甲分行'
    },
    {
      bankCodeString: '8221241',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1241',
      branchName: '延吉分行',
      totalName: '1241 ╴延吉分行'
    },
    {
      bankCodeString: '8221252',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1252',
      branchName: '信義分行',
      totalName: '1252 ╴信義分行'
    },
    {
      bankCodeString: '8221263',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1263',
      branchName: '青年分行',
      totalName: '1263 ╴青年分行'
    },
    {
      bankCodeString: '8221274',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1274',
      branchName: '岡山分行',
      totalName: '1274 ╴岡山分行'
    },
    {
      bankCodeString: '8221285',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1285',
      branchName: '士林分行',
      totalName: '1285 ╴士林分行'
    },
    {
      bankCodeString: '8221296',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1296',
      branchName: '東民生分行',
      totalName: '1296 ╴東民生分行'
    },
    {
      bankCodeString: '8221300',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1300',
      branchName: '文山分行',
      totalName: '1300 ╴文山分行'
    },
    {
      bankCodeString: '8221311',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1311',
      branchName: 'Land and Houses Bank Public Company Limited ╴THE MALL N',
      totalName: '1311 ╴Land and Houses Bank Public Company Limited ╴THE MALL N'
    },
    {
      bankCodeString: '8221322',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1322',
      branchName: 'Land and Houses Bank Public Company Limited ╴LOTUS PHUK',
      totalName: '1322 ╴Land and Houses Bank Public Company Limited ╴LOTUS PHUK'
    },
    {
      bankCodeString: '8221333',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1333',
      branchName: 'Land and Houses Bank Public Company Limited ╴BANGRAK BR',
      totalName: '1333 ╴Land and Houses Bank Public Company Limited ╴BANGRAK BR'
    },
    {
      bankCodeString: '8221344',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1344',
      branchName: 'Land and Houses Bank Public Company Limited ╴BIG C ONNU',
      totalName: '1344 ╴Land and Houses Bank Public Company Limited ╴BIG C ONNU'
    },
    {
      bankCodeString: '8221355',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1355',
      branchName: 'Land and Houses Bank Public Company Limited ╴SAPHANKHWA',
      totalName: '1355 ╴Land and Houses Bank Public Company Limited ╴SAPHANKHWA'
    },
    {
      bankCodeString: '8221366',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1366',
      branchName: 'Land and Houses Bank Public Company Limited ╴TALAT WARO',
      totalName: '1366 ╴Land and Houses Bank Public Company Limited ╴TALAT WARO'
    },
    {
      bankCodeString: '8221377',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1377',
      branchName: 'Land and Houses Bank Public Company Limited ╴WORACHAK',
      totalName: '1377 ╴Land and Houses Bank Public Company Limited ╴WORACHAK'
    },
    {
      bankCodeString: '8221388',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1388',
      branchName: 'Land and Houses Bank Public Company Limited ╴TERMINAL 2',
      totalName: '1388 ╴Land and Houses Bank Public Company Limited ╴TERMINAL 2'
    },
    {
      bankCodeString: '8221399',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1399',
      branchName: 'Land and Houses Bank Public Company Limited ╴KHONKAEN B',
      totalName: '1399 ╴Land and Houses Bank Public Company Limited ╴KHONKAEN B'
    },
    {
      bankCodeString: '8221403',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1403',
      branchName: 'Land and Houses Bank Public Company Limited ╴THE CIRCLE',
      totalName: '1403 ╴Land and Houses Bank Public Company Limited ╴THE CIRCLE'
    },
    {
      bankCodeString: '8221414',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1414',
      branchName: 'Land and Houses Bank Public Company Limited ╴TERMINAL 2',
      totalName: '1414 ╴Land and Houses Bank Public Company Limited ╴TERMINAL 2'
    },
    {
      bankCodeString: '8221425',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1425',
      branchName: 'Land and Houses Bank Public Company Limited ╴PATONG BRA',
      totalName: '1425 ╴Land and Houses Bank Public Company Limited ╴PATONG BRA'
    },
    {
      bankCodeString: '8221436',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1436',
      branchName: 'Land and Houses Bank Public Company Limited ╴SAMUTSAKHO',
      totalName: '1436 ╴Land and Houses Bank Public Company Limited ╴SAMUTSAKHO'
    },
    {
      bankCodeString: '8221447',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1447',
      branchName: 'Land and Houses Bank Public Company Limited ╴CENTRAL RA',
      totalName: '1447 ╴Land and Houses Bank Public Company Limited ╴CENTRAL RA'
    },
    {
      bankCodeString: '8221458',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1458',
      branchName: 'Land and Houses Bank Public Company Limited ╴SUKSAWAT B',
      totalName: '1458 ╴Land and Houses Bank Public Company Limited ╴SUKSAWAT B'
    },
    {
      bankCodeString: '8221469',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1469',
      branchName: 'Land and Houses Bank Public Company Limited ╴PARK LANE',
      totalName: '1469 ╴Land and Houses Bank Public Company Limited ╴PARK LANE'
    },
    {
      bankCodeString: '8221470',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1470',
      branchName: 'Land and Houses Bank Public Company Limited ╴SEACON BAN',
      totalName: '1470 ╴Land and Houses Bank Public Company Limited ╴SEACON BAN'
    },
    {
      bankCodeString: '8221481',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1481',
      branchName: 'Land and Houses Bank Public Company Limited ╴SURATTHANI',
      totalName: '1481 ╴Land and Houses Bank Public Company Limited ╴SURATTHANI'
    },
    {
      bankCodeString: '8221492',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1492',
      branchName: 'Land and Houses Bank Public Company Limited ╴LOTUS RAMA',
      totalName: '1492 ╴Land and Houses Bank Public Company Limited ╴LOTUS RAMA'
    },
    {
      bankCodeString: '8221506',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1506',
      branchName: 'Land and Houses Bank Public Company Limited ╴NAKHONPATH',
      totalName: '1506 ╴Land and Houses Bank Public Company Limited ╴NAKHONPATH'
    },
    {
      bankCodeString: '8221517',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1517',
      branchName: 'Land and Houses Bank Public Company Limited ╴HUA HIN BR',
      totalName: '1517 ╴Land and Houses Bank Public Company Limited ╴HUA HIN BR'
    },
    {
      bankCodeString: '8221528',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1528',
      branchName: 'Land and Houses Bank Public Company Limited ╴CENTRAL PI',
      totalName: '1528 ╴Land and Houses Bank Public Company Limited ╴CENTRAL PI'
    },
    {
      bankCodeString: '8221539',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1539',
      branchName: 'Land and Houses Bank Public Company Limited ╴RAYONG BRA',
      totalName: '1539 ╴Land and Houses Bank Public Company Limited ╴RAYONG BRA'
    },
    {
      bankCodeString: '8221540',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1540',
      branchName: 'Land and Houses Bank Public Company Limited ╴CENTRAL K',
      totalName: '1540 ╴Land and Houses Bank Public Company Limited ╴CENTRAL K'
    },
    {
      bankCodeString: '8221551',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1551',
      branchName: 'Land and Houses Bank Public Company Limited ╴BIG C HATY',
      totalName: '1551 ╴Land and Houses Bank Public Company Limited ╴BIG C HATY'
    },
    {
      bankCodeString: '8221562',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1562',
      branchName: 'Land and Houses Bank Public Company Limited ╴PHITSANULO',
      totalName: '1562 ╴Land and Houses Bank Public Company Limited ╴PHITSANULO'
    },
    {
      bankCodeString: '8221573',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1573',
      branchName: 'Land and Houses Bank Public Company Limited ╴HATYAI BRA',
      totalName: '1573 ╴Land and Houses Bank Public Company Limited ╴HATYAI BRA'
    },
    {
      bankCodeString: '8221595',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1595',
      branchName: 'Land and Houses Bank Public Company Limited ╴UBON RATCH',
      totalName: '1595 ╴Land and Houses Bank Public Company Limited ╴UBON RATCH'
    },
    {
      bankCodeString: '8221609',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1609',
      branchName: 'Land and Houses Bank Public Company Limited ╴NAKHONSAWA',
      totalName: '1609 ╴Land and Houses Bank Public Company Limited ╴NAKHONSAWA'
    },
    {
      bankCodeString: '8221610',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1610',
      branchName: 'Land and Houses Bank Public Company Limited ╴HOMEPRO KA',
      totalName: '1610 ╴Land and Houses Bank Public Company Limited ╴HOMEPRO KA'
    },
    {
      bankCodeString: '8221621',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1621',
      branchName: 'Land and Houses Bank Public Company Limited ╴LOTUS RUAM',
      totalName: '1621 ╴Land and Houses Bank Public Company Limited ╴LOTUS RUAM'
    },
    {
      bankCodeString: '8221632',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1632',
      branchName: 'Land and Houses Bank Public Company Limited ╴CENTRAL SA',
      totalName: '1632 ╴Land and Houses Bank Public Company Limited ╴CENTRAL SA'
    },
    {
      bankCodeString: '8221643',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1643',
      branchName: 'Land and Houses Bank Public Company Limited ╴HOMEPRO CH',
      totalName: '1643 ╴Land and Houses Bank Public Company Limited ╴HOMEPRO CH'
    },
    {
      bankCodeString: '8221654',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1654',
      branchName: 'Land and Houses Bank Public Company Limited ╴HOMEPRO SA',
      totalName: '1654 ╴Land and Houses Bank Public Company Limited ╴HOMEPRO SA'
    },
    {
      bankCodeString: '8221665',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1665',
      branchName: 'Land and Houses Bank Public Company Limited ╴HOMEPRO CH',
      totalName: '1665 ╴Land and Houses Bank Public Company Limited ╴HOMEPRO CH'
    },
    {
      bankCodeString: '8221687',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1687',
      branchName: 'Land and Houses Bank Public Company Limited ╴HOMEPRO TR',
      totalName: '1687 ╴Land and Houses Bank Public Company Limited ╴HOMEPRO TR'
    },
    {
      bankCodeString: '8221698',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1698',
      branchName: 'Land and Houses Bank Public Company Limited ╴HOMEPRO CH',
      totalName: '1698 ╴Land and Houses Bank Public Company Limited ╴HOMEPRO CH'
    },
    {
      bankCodeString: '8221702',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1702',
      branchName: 'Land and Houses Bank Public Company Limited ╴NAKHON SI',
      totalName: '1702 ╴Land and Houses Bank Public Company Limited ╴NAKHON SI'
    },
    {
      bankCodeString: '8221713',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1713',
      branchName: 'Land and Houses Bank Public Company Limited ╴HOMEPRO RO',
      totalName: '1713 ╴Land and Houses Bank Public Company Limited ╴HOMEPRO RO'
    },
    {
      bankCodeString: '8221724',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1724',
      branchName: 'Land and Houses Bank Public Company Limited ╴ROBINSON R',
      totalName: '1724 ╴Land and Houses Bank Public Company Limited ╴ROBINSON R'
    },
    {
      bankCodeString: '8221735',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1735',
      branchName: 'Land and Houses Bank Public Company Limited ╴HOMEPRO BU',
      totalName: '1735 ╴Land and Houses Bank Public Company Limited ╴HOMEPRO BU'
    },
    {
      bankCodeString: '8221746',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1746',
      branchName: 'Land and Houses Bank Public Company Limited ╴PHUKET BRA',
      totalName: '1746 ╴Land and Houses Bank Public Company Limited ╴PHUKET BRA'
    },
    {
      bankCodeString: '8221757',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1757',
      branchName: 'Land and Houses Bank Public Company Limited ╴KRABI BRAN',
      totalName: '1757 ╴Land and Houses Bank Public Company Limited ╴KRABI BRAN'
    },
    {
      bankCodeString: '8221768',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1768',
      branchName: 'Land and Houses Bank Public Company Limited ╴LOTUS SAMU',
      totalName: '1768 ╴Land and Houses Bank Public Company Limited ╴LOTUS SAMU'
    },
    {
      bankCodeString: '8221779',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1779',
      branchName: 'Land and Houses Bank Public Company Limited ╴LOTUS SRIN',
      totalName: '1779 ╴Land and Houses Bank Public Company Limited ╴LOTUS SRIN'
    },
    {
      bankCodeString: '8221780',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1780',
      branchName: 'Land and Houses Bank Public Company Limited ╴THANON CHA',
      totalName: '1780 ╴Land and Houses Bank Public Company Limited ╴THANON CHA'
    },
    {
      bankCodeString: '8221791',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1791',
      branchName: 'Land and Houses Bank Public Company Limited ╴CHIANGRAI',
      totalName: '1791 ╴Land and Houses Bank Public Company Limited ╴CHIANGRAI'
    },
    {
      bankCodeString: '8221805',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1805',
      branchName: 'Land and Houses Bank Public Company Limited ╴HOMEPRO LA',
      totalName: '1805 ╴Land and Houses Bank Public Company Limited ╴HOMEPRO LA'
    },
    {
      bankCodeString: '8221816',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1816',
      branchName: 'Land and Houses Bank Public Company Limited ╴Q. HOUSE S',
      totalName: '1816 ╴Land and Houses Bank Public Company Limited ╴Q. HOUSE S'
    },
    {
      bankCodeString: '8221827',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1827',
      branchName: 'Land and Houses Bank Public Company Limited ╴HOMEPRO SU',
      totalName: '1827 ╴Land and Houses Bank Public Company Limited ╴HOMEPRO SU'
    },
    {
      bankCodeString: '8221838',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1838',
      branchName: 'Land and Houses Bank Public Company Limited ╴UD TOWN UD',
      totalName: '1838 ╴Land and Houses Bank Public Company Limited ╴UD TOWN UD'
    },
    {
      bankCodeString: '8221849',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1849',
      branchName: 'Land and Houses Bank Public Company Limited ╴HOMEPRO SU',
      totalName: '1849 ╴Land and Houses Bank Public Company Limited ╴HOMEPRO SU'
    },
    {
      bankCodeString: '8221850',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1850',
      branchName: 'Land and Houses Bank Public Company Limited ╴HOMEPRO PH',
      totalName: '1850 ╴Land and Houses Bank Public Company Limited ╴HOMEPRO PH'
    },
    {
      bankCodeString: '8221872',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1872',
      branchName: 'Land and Houses Bank Public Company Limited ╴AYUTTHAYA',
      totalName: '1872 ╴Land and Houses Bank Public Company Limited ╴AYUTTHAYA'
    },
    {
      bankCodeString: '8221894',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1894',
      branchName: 'Land and Houses Bank Public Company Limited ╴THE PASEO',
      totalName: '1894 ╴Land and Houses Bank Public Company Limited ╴THE PASEO'
    },
    {
      bankCodeString: '8221908',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1908',
      branchName: 'Land and Houses Bank Public Company Limited ╴PAK CHONG',
      totalName: '1908 ╴Land and Houses Bank Public Company Limited ╴PAK CHONG'
    },
    {
      bankCodeString: '8221919',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1919',
      branchName: 'Land and Houses Bank Public Company Limited ╴TERMINAL 2',
      totalName: '1919 ╴Land and Houses Bank Public Company Limited ╴TERMINAL 2'
    },
    {
      bankCodeString: '8221920',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1920',
      branchName: 'Land and Houses Bank Public Company Limited ╴PARADISE P',
      totalName: '1920 ╴Land and Houses Bank Public Company Limited ╴PARADISE P'
    },
    {
      bankCodeString: '8221931',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1931',
      branchName: 'Land and Houses Bank Public Company Limited ╴THE CRYSTA',
      totalName: '1931 ╴Land and Houses Bank Public Company Limited ╴THE CRYSTA'
    },
    {
      bankCodeString: '8221942',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1942',
      branchName: 'Land and Houses Advisory Company Limited ╴Headquarter',
      totalName: '1942 ╴Land and Houses Advisory Company Limited ╴Headquarter'
    },
    {
      bankCodeString: '8221953',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1953',
      branchName: 'Land and Houses Fund Management Company Limited ╴Headqu',
      totalName: '1953 ╴Land and Houses Fund Management Company Limited ╴Headqu'
    },
    {
      bankCodeString: '8221964',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1964',
      branchName: 'Land and Houses Securities Public Company Limited ╴Head',
      totalName: '1964 ╴Land and Houses Securities Public Company Limited ╴Head'
    },
    {
      bankCodeString: '8221975',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1975',
      branchName: 'Land and Houses Securities Public Company Limited ╴Udon',
      totalName: '1975 ╴Land and Houses Securities Public Company Limited ╴Udon'
    },
    {
      bankCodeString: '8221986',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1986',
      branchName: 'Land and Houses Securities Public Company Limited ╴Nakh',
      totalName: '1986 ╴Land and Houses Securities Public Company Limited ╴Nakh'
    },
    {
      bankCodeString: '8221997',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '1997',
      branchName: 'Land and Houses Securities Public Company Limited ╴Chi',
      totalName: '1997 ╴Land and Houses Securities Public Company Limited ╴Chi'
    },
    {
      bankCodeString: '8222008',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '2008',
      branchName: 'Land and Houses Securities Public Company Limited ╴Phet',
      totalName: '2008 ╴Land and Houses Securities Public Company Limited ╴Phet'
    },
    {
      bankCodeString: '8222019',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '2019',
      branchName: 'Land and Houses Securities Public Company Limited ╴Chum',
      totalName: '2019 ╴Land and Houses Securities Public Company Limited ╴Chum'
    },
    {
      bankCodeString: '8222020',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '2020',
      branchName: 'Land and Houses Securities Public Company Limited ╴Sara',
      totalName: '2020 ╴Land and Houses Securities Public Company Limited ╴Sara'
    },
    {
      bankCodeString: '8222031',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '2031',
      branchName: 'Land and Houses Securities Public Company Limited ╴Ratc',
      totalName: '2031 ╴Land and Houses Securities Public Company Limited ╴Ratc'
    },
    {
      bankCodeString: '8222042',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '2042',
      branchName: 'Land and Houses Securities Public Company Limited ╴Ngam',
      totalName: '2042 ╴Land and Houses Securities Public Company Limited ╴Ngam'
    },
    {
      bankCodeString: '8222053',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '2053',
      branchName: 'Land and Houses Securities Public Company Limited ╴Lamp',
      totalName: '2053 ╴Land and Houses Securities Public Company Limited ╴Lamp'
    },
    {
      bankCodeString: '8222069',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '2069',
      branchName: '證券部',
      totalName: '2069 ╴證券部'
    },
    {
      bankCodeString: '8222075',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '2075',
      branchName: 'Land and Houses Securities Public Company Limited ╴Nakh',
      totalName: '2075 ╴Land and Houses Securities Public Company Limited ╴Nakh'
    },
    {
      bankCodeString: '8222086',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '2086',
      branchName: 'Land and Houses Securities Public Company Limited ╴Khon',
      totalName: '2086 ╴Land and Houses Securities Public Company Limited ╴Khon'
    },
    {
      bankCodeString: '8222097',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '2097',
      branchName: 'Land and Houses Securities Public Company Limited ╴Krab',
      totalName: '2097 ╴Land and Houses Securities Public Company Limited ╴Krab'
    },
    {
      bankCodeString: '8222101',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '2101',
      branchName: '淡水分行',
      totalName: '2101 ╴淡水分行'
    },
    {
      bankCodeString: '8222112',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '2112',
      branchName: '民安分行',
      totalName: '2112 ╴民安分行'
    },
    {
      bankCodeString: '8222123',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '2123',
      branchName: '二重埔分行',
      totalName: '2123 ╴二重埔分行'
    },
    {
      bankCodeString: '8222134',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '2134',
      branchName: '富錦分行',
      totalName: '2134 ╴富錦分行'
    },
    {
      bankCodeString: '8222145',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '2145',
      branchName: '站前分行',
      totalName: '2145 ╴站前分行'
    },
    {
      bankCodeString: '8222156',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '2156',
      branchName: '惠中分行',
      totalName: '2156 ╴惠中分行'
    },
    {
      bankCodeString: '8222167',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '2167',
      branchName: '東蘆洲分行',
      totalName: '2167 ╴東蘆洲分行'
    },
    {
      bankCodeString: '8222178',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '2178',
      branchName: '木柵分行',
      totalName: '2178 ╴木柵分行'
    },
    {
      bankCodeString: '8222189',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '2189',
      branchName: '東花蓮分行',
      totalName: '2189 ╴東花蓮分行'
    },
    {
      bankCodeString: '8222190',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '2190',
      branchName: '寶山分行',
      totalName: '2190 ╴寶山分行'
    },
    {
      bankCodeString: '8222204',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '2204',
      branchName: '藝文分行',
      totalName: '2204 ╴藝文分行'
    },
    {
      bankCodeString: '8222215',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '2215',
      branchName: '東門分行',
      totalName: '2215 ╴東門分行'
    },
    {
      bankCodeString: '8222226',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '2226',
      branchName: '新板特區分行',
      totalName: '2226 ╴新板特區分行'
    },
    {
      bankCodeString: '8222237',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '2237',
      branchName: '三和分行',
      totalName: '2237 ╴三和分行'
    },
    {
      bankCodeString: '8222240',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '2240',
      branchName: '兼營證券商',
      totalName: '2240 ╴兼營證券商'
    },
    {
      bankCodeString: '8222248',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '2248',
      branchName: '劍潭分行',
      totalName: '2248 ╴劍潭分行'
    },
    {
      bankCodeString: '8222259',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '2259',
      branchName: '北蘆洲分行',
      totalName: '2259 ╴北蘆洲分行'
    },
    {
      bankCodeString: '8222260',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '2260',
      branchName: '寶強分行',
      totalName: '2260 ╴寶強分行'
    },
    {
      bankCodeString: '8222271',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '2271',
      branchName: '華山分行',
      totalName: '2271 ╴華山分行'
    },
    {
      bankCodeString: '8222282',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '2282',
      branchName: '龍江分行',
      totalName: '2282 ╴龍江分行'
    },
    {
      bankCodeString: '8222293',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '2293',
      branchName: '成功分行',
      totalName: '2293 ╴成功分行'
    },
    {
      bankCodeString: '8222307',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '2307',
      branchName: 'Land and Houses Securities Public Company Limited ╴Samu',
      totalName: '2307 ╴Land and Houses Securities Public Company Limited ╴Samu'
    },
    {
      bankCodeString: '8222318',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '2318',
      branchName: 'Land and Houses Securities Public Company Limited ╴Chia',
      totalName: '2318 ╴Land and Houses Securities Public Company Limited ╴Chia'
    },
    {
      bankCodeString: '8222329',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '2329',
      branchName: 'Land and Houses Securities Public Company Limited ╴Ubon',
      totalName: '2329 ╴Land and Houses Securities Public Company Limited ╴Ubon'
    },
    {
      bankCodeString: '8222330',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '2330',
      branchName: 'Land and Houses Securities Public Company Limited ╴Phit',
      totalName: '2330 ╴Land and Houses Securities Public Company Limited ╴Phit'
    },
    {
      bankCodeString: '8222341',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '2341',
      branchName: '美國中信銀行-Irvine Jeffrey',
      totalName: '2341 ╴美國中信銀行-Irvine Jeffrey'
    },
    {
      bankCodeString: '8222352',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '2352',
      branchName: '美國中信銀行-Diamond Bar',
      totalName: '2352 ╴美國中信銀行-Diamond Bar'
    },
    {
      bankCodeString: '8223108',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '3108',
      branchName: '市政分行',
      totalName: '3108 ╴市政分行'
    },
    {
      bankCodeString: '8223119',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '3119',
      branchName: '大直分行',
      totalName: '3119 ╴大直分行'
    },
    {
      bankCodeString: '8223120',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '3120',
      branchName: '景美分行',
      totalName: '3120 ╴景美分行'
    },
    {
      bankCodeString: '8223131',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '3131',
      branchName: '內壢分行',
      totalName: '3131 ╴內壢分行'
    },
    {
      bankCodeString: '8223142',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '3142',
      branchName: '重慶分行',
      totalName: '3142 ╴重慶分行'
    },
    {
      bankCodeString: '8223153',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '3153',
      branchName: '長安分行',
      totalName: '3153 ╴長安分行'
    },
    {
      bankCodeString: '8223164',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '3164',
      branchName: '羅東分行',
      totalName: '3164 ╴羅東分行'
    },
    {
      bankCodeString: '8223175',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '3175',
      branchName: '南中壢分行',
      totalName: '3175 ╴南中壢分行'
    },
    {
      bankCodeString: '8223186',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '3186',
      branchName: '板和分行',
      totalName: '3186 ╴板和分行'
    },
    {
      bankCodeString: '8223197',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '3197',
      branchName: '花蓮分行',
      totalName: '3197 ╴花蓮分行'
    },
    {
      bankCodeString: '8223201',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '3201',
      branchName: '北天母分行',
      totalName: '3201 ╴北天母分行'
    },
    {
      bankCodeString: '8223212',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '3212',
      branchName: '民權西路分行',
      totalName: '3212 ╴民權西路分行'
    },
    {
      bankCodeString: '8223223',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '3223',
      branchName: '佳里分行',
      totalName: '3223 ╴佳里分行'
    },
    {
      bankCodeString: '8223234',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '3234',
      branchName: '博愛分行',
      totalName: '3234 ╴博愛分行'
    },
    {
      bankCodeString: '8223245',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '3245',
      branchName: '鹽行分行',
      totalName: '3245 ╴鹽行分行'
    },
    {
      bankCodeString: '8223267',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '3267',
      branchName: '光復分行',
      totalName: '3267 ╴光復分行'
    },
    {
      bankCodeString: '8223278',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '3278',
      branchName: '埔墘分行',
      totalName: '3278 ╴埔墘分行'
    },
    {
      bankCodeString: '8223289',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '3289',
      branchName: '黎明分行',
      totalName: '3289 ╴黎明分行'
    },
    {
      bankCodeString: '8223290',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '3290',
      branchName: '新富分行',
      totalName: '3290 ╴新富分行'
    },
    {
      bankCodeString: '8223603',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '3603',
      branchName: '三峽分行',
      totalName: '3603 ╴三峽分行'
    },
    {
      bankCodeString: '8223614',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '3614',
      branchName: '東林口分行',
      totalName: '3614 ╴東林口分行'
    },
    {
      bankCodeString: '8228549',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '8549',
      branchName: '美國中信銀行- Arcadia',
      totalName: '8549 ╴美國中信銀行- Arcadia'
    },
    {
      bankCodeString: '8228550',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '8550',
      branchName: '東京之星銀行-川口出張所',
      totalName: '8550 ╴東京之星銀行-川口出張所'
    },
    {
      bankCodeString: '8228561',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '8561',
      branchName: '東京之星銀行-?田山出張所',
      totalName: '8561 ╴東京之星銀行-?田山出張所'
    },
    {
      bankCodeString: '8228572',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '8572',
      branchName: '東京之星銀行-三軒茶屋出張所',
      totalName: '8572 ╴東京之星銀行-三軒茶屋出張所'
    },
    {
      bankCodeString: '8228583',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '8583',
      branchName: '美國子銀行-舊金山分行(San Francisco Branch)',
      totalName: '8583 ╴美國子銀行-舊金山分行(San Francisco Branch)'
    },
    {
      bankCodeString: '8228594',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '8594',
      branchName: '上海虹橋支行',
      totalName: '8594 ╴上海虹橋支行'
    },
    {
      bankCodeString: '8228608',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '8608',
      branchName: '深圳分行',
      totalName: '8608 ╴深圳分行'
    },
    {
      bankCodeString: '8228619',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '8619',
      branchName: '東京之星銀行－代代木上原出張所',
      totalName: '8619 ╴東京之星銀行－代代木上原出張所'
    },
    {
      bankCodeString: '8228620',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '8620',
      branchName: '東京之星銀行-南砂町出張所',
      totalName: '8620 ╴東京之星銀行-南砂町出張所'
    },
    {
      bankCodeString: '8228631',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '8631',
      branchName: '菲律賓子行-Iloilo Branch',
      totalName: '8631 ╴菲律賓子行-Iloilo Branch'
    },
    {
      bankCodeString: '8228642',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '8642',
      branchName: '仰光代表人辦事處',
      totalName: '8642 ╴仰光代表人辦事處'
    },
    {
      bankCodeString: '8228653',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '8653',
      branchName: '雪梨代表人辦事處',
      totalName: '8653 ╴雪梨代表人辦事處'
    },
    {
      bankCodeString: '8228664',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '8664',
      branchName: '吉隆坡代表人辦事處',
      totalName: '8664 ╴吉隆坡代表人辦事處'
    },
    {
      bankCodeString: '8228675',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '8675',
      branchName: '東京之星銀行-梅田分行',
      totalName: '8675 ╴東京之星銀行-梅田分行'
    },
    {
      bankCodeString: '8228686',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '8686',
      branchName: '廈門分行',
      totalName: '8686 ╴廈門分行'
    },
    {
      bankCodeString: '8228697',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '8697',
      branchName: '廣州分行',
      totalName: '8697 ╴廣州分行'
    },
    {
      bankCodeString: '8228701',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '8701',
      branchName: '上海分行上海自貿試驗區支行',
      totalName: '8701 ╴上海分行上海自貿試驗區支行'
    },
    {
      bankCodeString: '8228712',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '8712',
      branchName: '印尼子行-Jakarta雅加達分行',
      totalName: '8712 ╴印尼子行-Jakarta雅加達分行'
    },
    {
      bankCodeString: '8228723',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '8723',
      branchName: '加拿大子行-Vancouver Branch',
      totalName: '8723 ╴加拿大子行-Vancouver Branch'
    },
    {
      bankCodeString: '8228734',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '8734',
      branchName: '東京之星銀行-本店',
      totalName: '8734 ╴東京之星銀行-本店'
    },
    {
      bankCodeString: '8228745',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '8745',
      branchName: '東京之星銀行-難波分行',
      totalName: '8745 ╴東京之星銀行-難波分行'
    },
    {
      bankCodeString: '8228756',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '8756',
      branchName: '東京之星銀行-名古屋分行',
      totalName: '8756 ╴東京之星銀行-名古屋分行'
    },
    {
      bankCodeString: '8228767',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '8767',
      branchName: '東京之星銀行-廣島分行',
      totalName: '8767 ╴東京之星銀行-廣島分行'
    },
    {
      bankCodeString: '8228778',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '8778',
      branchName: '東京之星銀行-福岡分行',
      totalName: '8778 ╴東京之星銀行-福岡分行'
    },
    {
      bankCodeString: '8228789',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '8789',
      branchName: '東京之星銀行-神戶分行',
      totalName: '8789 ╴東京之星銀行-神戶分行'
    },
    {
      bankCodeString: '8228790',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '8790',
      branchName: '東京之星銀行-仙台分行',
      totalName: '8790 ╴東京之星銀行-仙台分行'
    },
    {
      bankCodeString: '8228804',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '8804',
      branchName: '東京之星-札幌分行',
      totalName: '8804 ╴東京之星-札幌分行'
    },
    {
      bankCodeString: '8228815',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '8815',
      branchName: '東京之星銀行-大宮分行',
      totalName: '8815 ╴東京之星銀行-大宮分行'
    },
    {
      bankCodeString: '8228826',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '8826',
      branchName: '東京之星銀行-浦和分行',
      totalName: '8826 ╴東京之星銀行-浦和分行'
    },
    {
      bankCodeString: '8228837',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '8837',
      branchName: '東京之星銀行-船橋分行',
      totalName: '8837 ╴東京之星銀行-船橋分行'
    },
    {
      bankCodeString: '8228848',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '8848',
      branchName: '東京之星銀行-松戶分行',
      totalName: '8848 ╴東京之星銀行-松戶分行'
    },
    {
      bankCodeString: '8228859',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '8859',
      branchName: '東京之星銀行-千葉分行',
      totalName: '8859 ╴東京之星銀行-千葉分行'
    },
    {
      bankCodeString: '8228871',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '8871',
      branchName: '東京之星銀行-港南台分行',
      totalName: '8871 ╴東京之星銀行-港南台分行'
    },
    {
      bankCodeString: '8228882',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '8882',
      branchName: '東京之星銀行-涉谷站前分行',
      totalName: '8882 ╴東京之星銀行-涉谷站前分行'
    },
    {
      bankCodeString: '8228893',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '8893',
      branchName: '東京之星銀行-藤澤分行',
      totalName: '8893 ╴東京之星銀行-藤澤分行'
    },
    {
      bankCodeString: '8228907',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '8907',
      branchName: '東京之星銀行-橫濱分行',
      totalName: '8907 ╴東京之星銀行-橫濱分行'
    },
    {
      bankCodeString: '8228918',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '8918',
      branchName: '東京之星銀行-調布分行',
      totalName: '8918 ╴東京之星銀行-調布分行'
    },
    {
      bankCodeString: '8228929',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '8929',
      branchName: '東京之星銀行-自由之丘分行',
      totalName: '8929 ╴東京之星銀行-自由之丘分行'
    },
    {
      bankCodeString: '8228930',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '8930',
      branchName: '東京之星銀行-日比谷分行',
      totalName: '8930 ╴東京之星銀行-日比谷分行'
    },
    {
      bankCodeString: '8228941',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '8941',
      branchName: '東京之星銀行-高島平分行',
      totalName: '8941 ╴東京之星銀行-高島平分行'
    },
    {
      bankCodeString: '8228952',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '8952',
      branchName: '東京之星銀行-新宿分行',
      totalName: '8952 ╴東京之星銀行-新宿分行'
    },
    {
      bankCodeString: '8228963',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '8963',
      branchName: '東京之星銀行-小平分行',
      totalName: '8963 ╴東京之星銀行-小平分行'
    },
    {
      bankCodeString: '8228974',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '8974',
      branchName: '東京之星銀行-立川分行',
      totalName: '8974 ╴東京之星銀行-立川分行'
    },
    {
      bankCodeString: '8228985',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '8985',
      branchName: '東京之星銀行-吉祥寺分行',
      totalName: '8985 ╴東京之星銀行-吉祥寺分行'
    },
    {
      bankCodeString: '8228996',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '8996',
      branchName: '東京之星銀行-小岩分行',
      totalName: '8996 ╴東京之星銀行-小岩分行'
    },
    {
      bankCodeString: '8229007',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9007',
      branchName: '東京之星銀行-上野分行',
      totalName: '9007 ╴東京之星銀行-上野分行'
    },
    {
      bankCodeString: '8229018',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9018',
      branchName: '東京之星銀行-渋谷分行',
      totalName: '9018 ╴東京之星銀行-渋谷分行'
    },
    {
      bankCodeString: '8229029',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9029',
      branchName: '新德里分行',
      totalName: '9029 ╴新德里分行'
    },
    {
      bankCodeString: '8229041',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9041',
      branchName: '香港分行',
      totalName: '9041 ╴香港分行'
    },
    {
      bankCodeString: '8229052',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9052',
      branchName: '紐約分行',
      totalName: '9052 ╴紐約分行'
    },
    {
      bankCodeString: '8229063',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9063',
      branchName: '東京分行',
      totalName: '9063 ╴東京分行'
    },
    {
      bankCodeString: '8229074',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9074',
      branchName: '胡志明市分行',
      totalName: '9074 ╴胡志明市分行'
    },
    {
      bankCodeString: '8229085',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9085',
      branchName: '印尼子銀行-Karawaci Sub-branch',
      totalName: '9085 ╴印尼子銀行-Karawaci Sub-branch'
    },
    {
      bankCodeString: '8229096',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9096',
      branchName: '斯里伯魯德(清奈)分行',
      totalName: '9096 ╴斯里伯魯德(清奈)分行'
    },
    {
      bankCodeString: '8229100',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9100',
      branchName: '上海分行',
      totalName: '9100 ╴上海分行'
    },
    {
      bankCodeString: '8229111',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9111',
      branchName: '東京之星銀行-銀座分行',
      totalName: '9111 ╴東京之星銀行-銀座分行'
    },
    {
      bankCodeString: '8229122',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9122',
      branchName: '東京之星銀行-池袋分行',
      totalName: '9122 ╴東京之星銀行-池袋分行'
    },
    {
      bankCodeString: '8229133',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9133',
      branchName: '東京之星銀行',
      totalName: '9133 ╴東京之星銀行'
    },
    {
      bankCodeString: '8229166',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9166',
      branchName: '東京之星銀行-子公司Tokyo Star Business Finance, Ltd.',
      totalName: '9166 ╴東京之星銀行-子公司Tokyo Star Business Finance, Ltd.'
    },
    {
      bankCodeString: '8229177',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9177',
      branchName: '加拿大子行-Toronto Branch',
      totalName: '9177 ╴加拿大子行-Toronto Branch'
    },
    {
      bankCodeString: '8229188',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9188',
      branchName: '印尼子銀行-Puri Kencana Sub-branch',
      totalName: '9188 ╴印尼子銀行-Puri Kencana Sub-branch'
    },
    {
      bankCodeString: '8229199',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9199',
      branchName: '菲律賓子銀行,Bonifacio Global City Branch',
      totalName: '9199 ╴菲律賓子銀行,Bonifacio Global City Branch'
    },
    {
      bankCodeString: '8229203',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9203',
      branchName: '印尼子銀行-Darmo泗水支行',
      totalName: '9203 ╴印尼子銀行-Darmo泗水支行'
    },
    {
      bankCodeString: '8229225',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9225',
      branchName: '印尼子銀行-Pluit支行',
      totalName: '9225 ╴印尼子銀行-Pluit支行'
    },
    {
      bankCodeString: '8229236',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9236',
      branchName: '印尼子銀行Dago萬隆支行',
      totalName: '9236 ╴印尼子銀行Dago萬隆支行'
    },
    {
      bankCodeString: '8229247',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9247',
      branchName: '新加坡分行',
      totalName: '9247 ╴新加坡分行'
    },
    {
      bankCodeString: '8229292',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9292',
      branchName: '菲律賓子銀行,Las Pinas Branch',
      totalName: '9292 ╴菲律賓子銀行,Las Pinas Branch'
    },
    {
      bankCodeString: '8229306',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9306',
      branchName: '菲律賓子銀行,Cebu-Magallanes Branch',
      totalName: '9306 ╴菲律賓子銀行,Cebu-Magallanes Branch'
    },
    {
      bankCodeString: '8229317',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9317',
      branchName: '菲律賓子行-Cebu-Mandaue',
      totalName: '9317 ╴菲律賓子行-Cebu-Mandaue'
    },
    {
      bankCodeString: '8229340',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9340',
      branchName: '菲律賓子銀行,Davao Branch',
      totalName: '9340 ╴菲律賓子銀行,Davao Branch'
    },
    {
      bankCodeString: '8229351',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9351',
      branchName: '菲律賓子銀行,Angeles Branch',
      totalName: '9351 ╴菲律賓子銀行,Angeles Branch'
    },
    {
      bankCodeString: '8229362',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9362',
      branchName: 'CTBC Capital Corp.',
      totalName: '9362 ╴CTBC Capital Corp.'
    },
    {
      bankCodeString: '8229373',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9373',
      branchName: '洛杉磯代表人辦事處',
      totalName: '9373 ╴洛杉磯代表人辦事處'
    },
    {
      bankCodeString: '8229409',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9409',
      branchName: '美國子銀行-羅蘭崗分行(Rowland Heights Branch)',
      totalName: '9409 ╴美國子銀行-羅蘭崗分行(Rowland Heights Branch)'
    },
    {
      bankCodeString: '8229410',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9410',
      branchName: '加拿大子銀行-Burnaby branch',
      totalName: '9410 ╴加拿大子銀行-Burnaby branch'
    },
    {
      bankCodeString: '8229421',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9421',
      branchName: '九龍分行(支行)',
      totalName: '9421 ╴九龍分行(支行)'
    },
    {
      bankCodeString: '8229432',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9432',
      branchName: '印尼子銀行, Mangga-dua sub-branch',
      totalName: '9432 ╴印尼子銀行, Mangga-dua sub-branch'
    },
    {
      bankCodeString: '8229443',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9443',
      branchName: '加拿大子銀行,利其蒙分行(Richmond Branch)',
      totalName: '9443 ╴加拿大子銀行,利其蒙分行(Richmond Branch)'
    },
    {
      bankCodeString: '8229454',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9454',
      branchName: '印尼子銀行,Kelapa Gading Sub-branch',
      totalName: '9454 ╴印尼子銀行,Kelapa Gading Sub-branch'
    },
    {
      bankCodeString: '8229465',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9465',
      branchName: '印尼子銀行,Cikarang Sub-branch',
      totalName: '9465 ╴印尼子銀行,Cikarang Sub-branch'
    },
    {
      bankCodeString: '8229476',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9476',
      branchName: '印尼子銀行,泗水分行(Surabaya Branch)',
      totalName: '9476 ╴印尼子銀行,泗水分行(Surabaya Branch)'
    },
    {
      bankCodeString: '8229487',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9487',
      branchName: '印尼子銀行,萬隆分行(Bandung Branch)',
      totalName: '9487 ╴印尼子銀行,萬隆分行(Bandung Branch)'
    },
    {
      bankCodeString: '8229568',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9568',
      branchName: '曼谷代表人辦事處',
      totalName: '9568 ╴曼谷代表人辦事處'
    },
    {
      bankCodeString: '8229579',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9579',
      branchName: '北京代表人辦事處',
      totalName: '9579 ╴北京代表人辦事處'
    },
    {
      bankCodeString: '8229580',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9580',
      branchName: '菲律賓子銀行, Del Monte Branch',
      totalName: '9580 ╴菲律賓子銀行, Del Monte Branch'
    },
    {
      bankCodeString: '8229591',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9591',
      branchName: '菲律賓子銀行, Cagayan de Oro Branch',
      totalName: '9591 ╴菲律賓子銀行, Cagayan de Oro Branch'
    },
    {
      bankCodeString: '8229605',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9605',
      branchName: '菲律賓子銀行, Sucat Branch',
      totalName: '9605 ╴菲律賓子銀行, Sucat Branch'
    },
    {
      bankCodeString: '8229616',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9616',
      branchName: '菲律賓子銀行, Subic Bay Branch',
      totalName: '9616 ╴菲律賓子銀行, Subic Bay Branch'
    },
    {
      bankCodeString: '8229627',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9627',
      branchName: '菲律賓子銀行, Dela Costa Branch',
      totalName: '9627 ╴菲律賓子銀行, Dela Costa Branch'
    },
    {
      bankCodeString: '8229638',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9638',
      branchName: '菲律賓子銀行, Buendia Pasong Tamo Branch',
      totalName: '9638 ╴菲律賓子銀行, Buendia Pasong Tamo Branch'
    },
    {
      bankCodeString: '8229649',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9649',
      branchName: '菲律賓子銀行, Ortigas Branch',
      totalName: '9649 ╴菲律賓子銀行, Ortigas Branch'
    },
    {
      bankCodeString: '8229650',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9650',
      branchName: '菲律賓子銀行, Marikina Branch',
      totalName: '9650 ╴菲律賓子銀行, Marikina Branch'
    },
    {
      bankCodeString: '8229661',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9661',
      branchName: '菲律賓子銀行, Mabini Branch',
      totalName: '9661 ╴菲律賓子銀行, Mabini Branch'
    },
    {
      bankCodeString: '8229672',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9672',
      branchName: '菲律賓子銀行, Taytay Branch',
      totalName: '9672 ╴菲律賓子銀行, Taytay Branch'
    },
    {
      bankCodeString: '8229683',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9683',
      branchName: '菲律賓子銀行, Greenhills Branch',
      totalName: '9683 ╴菲律賓子銀行, Greenhills Branch'
    },
    {
      bankCodeString: '8229694',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9694',
      branchName: '菲律賓子銀行, Cebu Banilad Branch',
      totalName: '9694 ╴菲律賓子銀行, Cebu Banilad Branch'
    },
    {
      bankCodeString: '8229708',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9708',
      branchName: '菲律賓子銀行, Cavite Branch',
      totalName: '9708 ╴菲律賓子銀行, Cavite Branch'
    },
    {
      bankCodeString: '8229719',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9719',
      branchName: '菲律賓子銀行,Carmona Branch',
      totalName: '9719 ╴菲律賓子銀行,Carmona Branch'
    },
    {
      bankCodeString: '8229720',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9720',
      branchName: '菲律賓子銀行, Binondo Branch',
      totalName: '9720 ╴菲律賓子銀行, Binondo Branch'
    },
    {
      bankCodeString: '8229731',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9731',
      branchName: '菲律賓子銀行, Ayala Branch',
      totalName: '9731 ╴菲律賓子銀行, Ayala Branch'
    },
    {
      bankCodeString: '8229742',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9742',
      branchName: '菲律賓子銀行, Rada Branch',
      totalName: '9742 ╴菲律賓子銀行, Rada Branch'
    },
    {
      bankCodeString: '8229753',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9753',
      branchName: '菲律賓子銀行, Alabang Branch',
      totalName: '9753 ╴菲律賓子銀行, Alabang Branch'
    },
    {
      bankCodeString: '8229797',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9797',
      branchName: '美國子銀行-法拉盛分行(Flushing Branch)',
      totalName: '9797 ╴美國子銀行-法拉盛分行(Flushing Branch)'
    },
    {
      bankCodeString: '8229823',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9823',
      branchName: '美國子銀行-布碌崙分行(Brooklyn Branch)',
      totalName: '9823 ╴美國子銀行-布碌崙分行(Brooklyn Branch)'
    },
    {
      bankCodeString: '8229834',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9834',
      branchName: '美國子銀行-愛迪生分行(Edison Branch)',
      totalName: '9834 ╴美國子銀行-愛迪生分行(Edison Branch)'
    },
    {
      bankCodeString: '8229845',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9845',
      branchName: '美國子銀行-洛杉磯分行(Los Angeles Branch)',
      totalName: '9845 ╴美國子銀行-洛杉磯分行(Los Angeles Branch)'
    },
    {
      bankCodeString: '8229856',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9856',
      branchName: '美國子銀行-工業市分行(City of Industry Branch)',
      totalName: '9856 ╴美國子銀行-工業市分行(City of Industry Branch)'
    },
    {
      bankCodeString: '8229867',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9867',
      branchName: '美國子銀行-聖馬利諾分行(San Marino Branch)',
      totalName: '9867 ╴美國子銀行-聖馬利諾分行(San Marino Branch)'
    },
    {
      bankCodeString: '8229878',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9878',
      branchName: '美國子銀行-庫柏蒂諾分行(Cupertino Branch)',
      totalName: '9878 ╴美國子銀行-庫柏蒂諾分行(Cupertino Branch)'
    },
    {
      bankCodeString: '8229889',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9889',
      branchName: '美國子銀行-蒙市分行(Monterey Park Branch)',
      totalName: '9889 ╴美國子銀行-蒙市分行(Monterey Park Branch)'
    },
    {
      bankCodeString: '8229904',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9904',
      branchName: '美國子銀行-矽谷分行(Milpitas Branch)',
      totalName: '9904 ╴美國子銀行-矽谷分行(Milpitas Branch)'
    },
    {
      bankCodeString: '8229915',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9915',
      branchName: '中國信託(菲律賓)商業銀行',
      totalName: '9915 ╴中國信託(菲律賓)商業銀行'
    },
    {
      bankCodeString: '8229926',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9926',
      branchName: '中國信託商業銀行(加拿大)',
      totalName: '9926 ╴中國信託商業銀行(加拿大)'
    },
    {
      bankCodeString: '8229937',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9937',
      branchName: '中國信託商業銀行(印尼)',
      totalName: '9937 ╴中國信託商業銀行(印尼)'
    },
    {
      bankCodeString: '8229948',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9948',
      branchName: '美國中信銀行',
      totalName: '9948 ╴美國中信銀行'
    },
    {
      bankCodeString: '8229959',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9959',
      branchName: '河內代表人辦事處',
      totalName: '9959 ╴河內代表人辦事處'
    },
    {
      bankCodeString: '8229971',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9971',
      branchName: '美國子銀行-爾灣分行(Irvine Branch)',
      totalName: '9971 ╴美國子銀行-爾灣分行(Irvine Branch)'
    },
    {
      bankCodeString: '8229982',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9982',
      branchName: '美國子銀行-聖蓋博分行(San Gabriel Branch)',
      totalName: '9982 ╴美國子銀行-聖蓋博分行(San Gabriel Branch)'
    },
    {
      bankCodeString: '8229999',
      bankCode: '822',
      bankName: '中國信託商業銀行',
      branchCode: '9999',
      branchName: '全球法金作業暨資訊處',
      totalName: '9999 ╴全球法金作業暨資訊處'
    }
  ],
  '823': [
    {
      bankCodeString: '8231688',
      bankCode: '823',
      bankName: '將來商業銀行',
      branchCode: '1688',
      branchName: '營業部',
      totalName: '1688 ╴營業部'
    }
  ],
  '824': [
    {
      bankCodeString: '8246880',
      bankCode: '824',
      bankName: '連線商業銀行',
      branchCode: '6880',
      branchName: '營業部',
      totalName: '6880 ╴營業部'
    }
  ],
  '826': [
    {
      bankCodeString: '8260019',
      bankCode: '826',
      bankName: '樂天國際商業銀行',
      branchCode: '0019',
      branchName: '營業部',
      totalName: '0019 ╴營業部'
    }
  ],
  '004': [
    {
      bankCodeString: '0040001',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0001',
      branchName: '董事會',
      totalName: '0001 ╴董事會'
    },
    {
      bankCodeString: '0040014',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0014',
      branchName: '政風處',
      totalName: '0014 ╴政風處'
    },
    {
      bankCodeString: '0040016',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0016',
      branchName: '會計處',
      totalName: '0016 ╴會計處'
    },
    {
      bankCodeString: '0040017',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0017',
      branchName: '董事會稽核處',
      totalName: '0017 ╴董事會稽核處'
    },
    {
      bankCodeString: '0040018',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0018',
      branchName: '資訊處',
      totalName: '0018 ╴資訊處'
    },
    {
      bankCodeString: '0040019',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0019',
      branchName: '企劃部',
      totalName: '0019 ╴企劃部'
    },
    {
      bankCodeString: '0040037',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0037',
      branchName: '營業部',
      totalName: '0037 ╴營業部'
    },
    {
      bankCodeString: '0040048',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0048',
      branchName: '發行部',
      totalName: '0048 ╴發行部'
    },
    {
      bankCodeString: '0040059',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0059',
      branchName: '公庫部',
      totalName: '0059 ╴公庫部'
    },
    {
      bankCodeString: '0040071',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0071',
      branchName: '館前分行',
      totalName: '0071 ╴館前分行'
    },
    {
      bankCodeString: '0040082',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0082',
      branchName: '信託部',
      totalName: '0082 ╴信託部'
    },
    {
      bankCodeString: '0040093',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0093',
      branchName: '臺南分行',
      totalName: '0093 ╴臺南分行'
    },
    {
      bankCodeString: '0040107',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0107',
      branchName: '臺中分行',
      totalName: '0107 ╴臺中分行'
    },
    {
      bankCodeString: '0040118',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0118',
      branchName: '高雄分行',
      totalName: '0118 ╴高雄分行'
    },
    {
      bankCodeString: '0040129',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0129',
      branchName: '基隆分行',
      totalName: '0129 ╴基隆分行'
    },
    {
      bankCodeString: '0040130',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0130',
      branchName: '中興新村分行',
      totalName: '0130 ╴中興新村分行'
    },
    {
      bankCodeString: '0040141',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0141',
      branchName: '嘉義分行',
      totalName: '0141 ╴嘉義分行'
    },
    {
      bankCodeString: '0040152',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0152',
      branchName: '新竹分行',
      totalName: '0152 ╴新竹分行'
    },
    {
      bankCodeString: '0040163',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0163',
      branchName: '彰化分行',
      totalName: '0163 ╴彰化分行'
    },
    {
      bankCodeString: '0040174',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0174',
      branchName: '屏東分行',
      totalName: '0174 ╴屏東分行'
    },
    {
      bankCodeString: '0040185',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0185',
      branchName: '花蓮分行',
      totalName: '0185 ╴花蓮分行'
    },
    {
      bankCodeString: '0040196',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0196',
      branchName: '延平分行',
      totalName: '0196 ╴延平分行'
    },
    {
      bankCodeString: '0040200',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0200',
      branchName: '中山分行',
      totalName: '0200 ╴中山分行'
    },
    {
      bankCodeString: '0040211',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0211',
      branchName: '高雄加工出口區分行',
      totalName: '0211 ╴高雄加工出口區分行'
    },
    {
      bankCodeString: '0040222',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0222',
      branchName: '宜蘭分行',
      totalName: '0222 ╴宜蘭分行'
    },
    {
      bankCodeString: '0040233',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0233',
      branchName: '臺東分行',
      totalName: '0233 ╴臺東分行'
    },
    {
      bankCodeString: '0040244',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0244',
      branchName: '澎湖分行',
      totalName: '0244 ╴澎湖分行'
    },
    {
      bankCodeString: '0040255',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0255',
      branchName: '鳳山分行',
      totalName: '0255 ╴鳳山分行'
    },
    {
      bankCodeString: '0040266',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0266',
      branchName: '桃園分行',
      totalName: '0266 ╴桃園分行'
    },
    {
      bankCodeString: '0040277',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0277',
      branchName: '板橋分行',
      totalName: '0277 ╴板橋分行'
    },
    {
      bankCodeString: '0040288',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0288',
      branchName: '新營分行',
      totalName: '0288 ╴新營分行'
    },
    {
      bankCodeString: '0040299',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0299',
      branchName: '苗栗分行',
      totalName: '0299 ╴苗栗分行'
    },
    {
      bankCodeString: '0040303',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0303',
      branchName: '豐原分行',
      totalName: '0303 ╴豐原分行'
    },
    {
      bankCodeString: '0040314',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0314',
      branchName: '斗六分行',
      totalName: '0314 ╴斗六分行'
    },
    {
      bankCodeString: '0040325',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0325',
      branchName: '南投分行',
      totalName: '0325 ╴南投分行'
    },
    {
      bankCodeString: '0040336',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0336',
      branchName: '南門分行',
      totalName: '0336 ╴南門分行'
    },
    {
      bankCodeString: '0040347',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0347',
      branchName: '公館分行',
      totalName: '0347 ╴公館分行'
    },
    {
      bankCodeString: '0040358',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0358',
      branchName: '左營分行',
      totalName: '0358 ╴左營分行'
    },
    {
      bankCodeString: '0040369',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0369',
      branchName: '北投分行',
      totalName: '0369 ╴北投分行'
    },
    {
      bankCodeString: '0040370',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0370',
      branchName: '霧峰分行',
      totalName: '0370 ╴霧峰分行'
    },
    {
      bankCodeString: '0040381',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0381',
      branchName: '金門分行',
      totalName: '0381 ╴金門分行'
    },
    {
      bankCodeString: '0040392',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0392',
      branchName: '馬祖分行',
      totalName: '0392 ╴馬祖分行'
    },
    {
      bankCodeString: '0040406',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0406',
      branchName: '安平分行',
      totalName: '0406 ╴安平分行'
    },
    {
      bankCodeString: '0040417',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0417',
      branchName: '中壢分行',
      totalName: '0417 ╴中壢分行'
    },
    {
      bankCodeString: '0040428',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0428',
      branchName: '三重分行',
      totalName: '0428 ╴三重分行'
    },
    {
      bankCodeString: '0040439',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0439',
      branchName: '頭份分行',
      totalName: '0439 ╴頭份分行'
    },
    {
      bankCodeString: '0040440',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0440',
      branchName: '前鎮分行',
      totalName: '0440 ╴前鎮分行'
    },
    {
      bankCodeString: '0040451',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0451',
      branchName: '城中分行',
      totalName: '0451 ╴城中分行'
    },
    {
      bankCodeString: '0040462',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0462',
      branchName: '民權分行',
      totalName: '0462 ╴民權分行'
    },
    {
      bankCodeString: '0040473',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0473',
      branchName: '潭子分行',
      totalName: '0473 ╴潭子分行'
    },
    {
      bankCodeString: '0040484',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0484',
      branchName: '連城分行',
      totalName: '0484 ╴連城分行'
    },
    {
      bankCodeString: '0040495',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0495',
      branchName: '員林分行',
      totalName: '0495 ╴員林分行'
    },
    {
      bankCodeString: '0040501',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0501',
      branchName: '董事會秘書處',
      totalName: '0501 ╴董事會秘書處'
    },
    {
      bankCodeString: '0040502',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0502',
      branchName: '人力資源處',
      totalName: '0502 ╴人力資源處'
    },
    {
      bankCodeString: '0040503',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0503',
      branchName: '經濟研究處',
      totalName: '0503 ╴經濟研究處'
    },
    {
      bankCodeString: '0040504',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0504',
      branchName: '法律事務處',
      totalName: '0504 ╴法律事務處'
    },
    {
      bankCodeString: '0040509',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0509',
      branchName: '松江分行',
      totalName: '0509 ╴松江分行'
    },
    {
      bankCodeString: '0040510',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0510',
      branchName: '鼓山分行',
      totalName: '0510 ╴鼓山分行'
    },
    {
      bankCodeString: '0040521',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0521',
      branchName: '龍山分行',
      totalName: '0521 ╴龍山分行'
    },
    {
      bankCodeString: '0040532',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0532',
      branchName: '忠孝分行',
      totalName: '0532 ╴忠孝分行'
    },
    {
      bankCodeString: '0040543',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0543',
      branchName: '信義分行',
      totalName: '0543 ╴信義分行'
    },
    {
      bankCodeString: '0040554',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0554',
      branchName: '復興分行',
      totalName: '0554 ╴復興分行'
    },
    {
      bankCodeString: '0040565',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0565',
      branchName: '三民分行',
      totalName: '0565 ╴三民分行'
    },
    {
      bankCodeString: '0040576',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0576',
      branchName: '臺中港分行',
      totalName: '0576 ╴臺中港分行'
    },
    {
      bankCodeString: '0040587',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0587',
      branchName: '羅東分行',
      totalName: '0587 ╴羅東分行'
    },
    {
      bankCodeString: '0040598',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0598',
      branchName: '埔里分行',
      totalName: '0598 ╴埔里分行'
    },
    {
      bankCodeString: '0040602',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0602',
      branchName: '岡山分行',
      totalName: '0602 ╴岡山分行'
    },
    {
      bankCodeString: '0040613',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0613',
      branchName: '新興分行',
      totalName: '0613 ╴新興分行'
    },
    {
      bankCodeString: '0040624',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0624',
      branchName: '苓雅分行',
      totalName: '0624 ╴苓雅分行'
    },
    {
      bankCodeString: '0040646',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0646',
      branchName: '松山分行',
      totalName: '0646 ╴松山分行'
    },
    {
      bankCodeString: '0040657',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0657',
      branchName: '健行分行',
      totalName: '0657 ╴健行分行'
    },
    {
      bankCodeString: '0040668',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0668',
      branchName: '中和分行',
      totalName: '0668 ╴中和分行'
    },
    {
      bankCodeString: '0040679',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0679',
      branchName: '太保分行',
      totalName: '0679 ╴太保分行'
    },
    {
      bankCodeString: '0040680',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0680',
      branchName: '竹北分行',
      totalName: '0680 ╴竹北分行'
    },
    {
      bankCodeString: '0040691',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0691',
      branchName: '國際金融業務分行',
      totalName: '0691 ╴國際金融業務分行'
    },
    {
      bankCodeString: '0040705',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0705',
      branchName: '士林分行',
      totalName: '0705 ╴士林分行'
    },
    {
      bankCodeString: '0040716',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0716',
      branchName: '新莊分行',
      totalName: '0716 ╴新莊分行'
    },
    {
      bankCodeString: '0040727',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0727',
      branchName: '大甲分行',
      totalName: '0727 ╴大甲分行'
    },
    {
      bankCodeString: '0040738',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0738',
      branchName: '新竹科學園區分行',
      totalName: '0738 ╴新竹科學園區分行'
    },
    {
      bankCodeString: '0040749',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0749',
      branchName: '樹林分行',
      totalName: '0749 ╴樹林分行'
    },
    {
      bankCodeString: '0040750',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0750',
      branchName: '新店分行',
      totalName: '0750 ╴新店分行'
    },
    {
      bankCodeString: '0040761',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0761',
      branchName: '國際部',
      totalName: '0761 ╴國際部'
    },
    {
      bankCodeString: '0040772',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0772',
      branchName: '徵信部',
      totalName: '0772 ╴徵信部'
    },
    {
      bankCodeString: '0040794',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0794',
      branchName: '黎明分行',
      totalName: '0794 ╴黎明分行'
    },
    {
      bankCodeString: '0040808',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0808',
      branchName: '民生分行',
      totalName: '0808 ╴民生分行'
    },
    {
      bankCodeString: '0040819',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0819',
      branchName: '永康分行',
      totalName: '0819 ╴永康分行'
    },
    {
      bankCodeString: '0040820',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0820',
      branchName: '三多分行',
      totalName: '0820 ╴三多分行'
    },
    {
      bankCodeString: '0040831',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0831',
      branchName: '紐約分行',
      totalName: '0831 ╴紐約分行'
    },
    {
      bankCodeString: '0040853',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0853',
      branchName: '臺北世貿中心分行',
      totalName: '0853 ╴臺北世貿中心分行'
    },
    {
      bankCodeString: '0040864',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0864',
      branchName: '大安分行',
      totalName: '0864 ╴大安分行'
    },
    {
      bankCodeString: '0040870',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0870',
      branchName: '兼營證券商',
      totalName: '0870 ╴兼營證券商'
    },
    {
      bankCodeString: '0040875',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0875',
      branchName: '華江分行',
      totalName: '0875 ╴華江分行'
    },
    {
      bankCodeString: '0040886',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0886',
      branchName: '潮州分行',
      totalName: '0886 ╴潮州分行'
    },
    {
      bankCodeString: '0040897',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0897',
      branchName: '蘇澳分行',
      totalName: '0897 ╴蘇澳分行'
    },
    {
      bankCodeString: '0040901',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0901',
      branchName: '大雅分行',
      totalName: '0901 ╴大雅分行'
    },
    {
      bankCodeString: '0040912',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0912',
      branchName: '楠梓分行',
      totalName: '0912 ╴楠梓分行'
    },
    {
      bankCodeString: '0040923',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0923',
      branchName: '臺中工業區分行',
      totalName: '0923 ╴臺中工業區分行'
    },
    {
      bankCodeString: '0040945',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '0945',
      branchName: '洛杉磯分行',
      totalName: '0945 ╴洛杉磯分行'
    },
    {
      bankCodeString: '0041067',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1067',
      branchName: '敦化分行',
      totalName: '1067 ╴敦化分行'
    },
    {
      bankCodeString: '0041078',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1078',
      branchName: '南港分行',
      totalName: '1078 ╴南港分行'
    },
    {
      bankCodeString: '0041089',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1089',
      branchName: '和平分行',
      totalName: '1089 ╴和平分行'
    },
    {
      bankCodeString: '0041090',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1090',
      branchName: '水湳分行',
      totalName: '1090 ╴水湳分行'
    },
    {
      bankCodeString: '0041104',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1104',
      branchName: '中崙分行',
      totalName: '1104 ╴中崙分行'
    },
    {
      bankCodeString: '0041115',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1115',
      branchName: '土城分行',
      totalName: '1115 ╴土城分行'
    },
    {
      bankCodeString: '0041148',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1148',
      branchName: '香港分行',
      totalName: '1148 ╴香港分行'
    },
    {
      bankCodeString: '0041159',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1159',
      branchName: '桃園國際機場分行',
      totalName: '1159 ╴桃園國際機場分行'
    },
    {
      bankCodeString: '0041160',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1160',
      branchName: '大昌分行',
      totalName: '1160 ╴大昌分行'
    },
    {
      bankCodeString: '0041171',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1171',
      branchName: '東京分行',
      totalName: '1171 ╴東京分行'
    },
    {
      bankCodeString: '0041182',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1182',
      branchName: '五甲分行',
      totalName: '1182 ╴五甲分行'
    },
    {
      bankCodeString: '0041193',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1193',
      branchName: '博愛分行',
      totalName: '1193 ╴博愛分行'
    },
    {
      bankCodeString: '0041207',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1207',
      branchName: '中庄分行',
      totalName: '1207 ╴中庄分行'
    },
    {
      bankCodeString: '0041218',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1218',
      branchName: '平鎮分行',
      totalName: '1218 ╴平鎮分行'
    },
    {
      bankCodeString: '0041229',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1229',
      branchName: '仁愛分行',
      totalName: '1229 ╴仁愛分行'
    },
    {
      bankCodeString: '0041230',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1230',
      branchName: '南崁分行',
      totalName: '1230 ╴南崁分行'
    },
    {
      bankCodeString: '0041241',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1241',
      branchName: '圓山分行',
      totalName: '1241 ╴圓山分行'
    },
    {
      bankCodeString: '0041333',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1333',
      branchName: '總務處',
      totalName: '1333 ╴總務處'
    },
    {
      bankCodeString: '0041355',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1355',
      branchName: '五股分行',
      totalName: '1355 ╴五股分行'
    },
    {
      bankCodeString: '0041366',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1366',
      branchName: '大里分行',
      totalName: '1366 ╴大里分行'
    },
    {
      bankCodeString: '0041377',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1377',
      branchName: '安南分行',
      totalName: '1377 ╴安南分行'
    },
    {
      bankCodeString: '0041388',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1388',
      branchName: '新加坡分行',
      totalName: '1388 ╴新加坡分行'
    },
    {
      bankCodeString: '0041414',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1414',
      branchName: '西屯分行',
      totalName: '1414 ╴西屯分行'
    },
    {
      bankCodeString: '0041425',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1425',
      branchName: '天母分行',
      totalName: '1425 ╴天母分行'
    },
    {
      bankCodeString: '0041436',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1436',
      branchName: '鹿港分行',
      totalName: '1436 ╴鹿港分行'
    },
    {
      bankCodeString: '0041447',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1447',
      branchName: '內壢分行',
      totalName: '1447 ╴內壢分行'
    },
    {
      bankCodeString: '0041469',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1469',
      branchName: '臺南科學園區分行',
      totalName: '1469 ╴臺南科學園區分行'
    },
    {
      bankCodeString: '0041470',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1470',
      branchName: '虎尾分行',
      totalName: '1470 ╴虎尾分行'
    },
    {
      bankCodeString: '0041481',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1481',
      branchName: '淡水分行',
      totalName: '1481 ╴淡水分行'
    },
    {
      bankCodeString: '0041492',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1492',
      branchName: '授信審查部',
      totalName: '1492 ╴授信審查部'
    },
    {
      bankCodeString: '0041506',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1506',
      branchName: '消費金融部',
      totalName: '1506 ╴消費金融部'
    },
    {
      bankCodeString: '0041517',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1517',
      branchName: '財務部',
      totalName: '1517 ╴財務部'
    },
    {
      bankCodeString: '0041528',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1528',
      branchName: '債權管理部',
      totalName: '1528 ╴債權管理部'
    },
    {
      bankCodeString: '0041539',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1539',
      branchName: '內湖分行',
      totalName: '1539 ╴內湖分行'
    },
    {
      bankCodeString: '0041540',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1540',
      branchName: '嘉北分行',
      totalName: '1540 ╴嘉北分行'
    },
    {
      bankCodeString: '0041551',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1551',
      branchName: '東港分行',
      totalName: '1551 ╴東港分行'
    },
    {
      bankCodeString: '0041562',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1562',
      branchName: '汐止分行',
      totalName: '1562 ╴汐止分行'
    },
    {
      bankCodeString: '0041573',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1573',
      branchName: '梧棲分行',
      totalName: '1573 ╴梧棲分行'
    },
    {
      bankCodeString: '0041595',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1595',
      branchName: '小港分行',
      totalName: '1595 ╴小港分行'
    },
    {
      bankCodeString: '0041609',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1609',
      branchName: '中屏分行',
      totalName: '1609 ╴中屏分行'
    },
    {
      bankCodeString: '0041610',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1610',
      branchName: '南非分行',
      totalName: '1610 ╴南非分行'
    },
    {
      bankCodeString: '0041621',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1621',
      branchName: '群賢分行',
      totalName: '1621 ╴群賢分行'
    },
    {
      bankCodeString: '0041643',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1643',
      branchName: '北大路分行',
      totalName: '1643 ╴北大路分行'
    },
    {
      bankCodeString: '0041654',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1654',
      branchName: '文山分行',
      totalName: '1654 ╴文山分行'
    },
    {
      bankCodeString: '0041702',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1702',
      branchName: '太平分行',
      totalName: '1702 ╴太平分行'
    },
    {
      bankCodeString: '0041713',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1713',
      branchName: '德芳分行',
      totalName: '1713 ╴德芳分行'
    },
    {
      bankCodeString: '0041724',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1724',
      branchName: '建國分行',
      totalName: '1724 ╴建國分行'
    },
    {
      bankCodeString: '0041768',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1768',
      branchName: '屏東農科園區分行',
      totalName: '1768 ╴屏東農科園區分行'
    },
    {
      bankCodeString: '0041850',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1850',
      branchName: '電子金融部',
      totalName: '1850 ╴電子金融部'
    },
    {
      bankCodeString: '0041861',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1861',
      branchName: '東桃園分行',
      totalName: '1861 ╴東桃園分行'
    },
    {
      bankCodeString: '0041872',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1872',
      branchName: '蘆洲分行',
      totalName: '1872 ╴蘆洲分行'
    },
    {
      bankCodeString: '0041919',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1919',
      branchName: '高雄國際機場分行',
      totalName: '1919 ╴高雄國際機場分行'
    },
    {
      bankCodeString: '0041931',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1931',
      branchName: '永吉簡易型分行',
      totalName: '1931 ╴永吉簡易型分行'
    },
    {
      bankCodeString: '0041942',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1942',
      branchName: '東門簡易型分行',
      totalName: '1942 ╴東門簡易型分行'
    },
    {
      bankCodeString: '0041953',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1953',
      branchName: '愛國簡易型分行',
      totalName: '1953 ╴愛國簡易型分行'
    },
    {
      bankCodeString: '0041986',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '1986',
      branchName: '臺電簡易型分行',
      totalName: '1986 ╴臺電簡易型分行'
    },
    {
      bankCodeString: '0042053',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2053',
      branchName: '北府簡易型分行',
      totalName: '2053 ╴北府簡易型分行'
    },
    {
      bankCodeString: '0042101',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2101',
      branchName: '倫敦分行',
      totalName: '2101 ╴倫敦分行'
    },
    {
      bankCodeString: '0042156',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2156',
      branchName: '風險管理部',
      totalName: '2156 ╴風險管理部'
    },
    {
      bankCodeString: '0042189',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2189',
      branchName: '臺北港分行',
      totalName: '2189 ╴臺北港分行'
    },
    {
      bankCodeString: '0042204',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2204',
      branchName: '臺中科學園區分行',
      totalName: '2204 ╴臺中科學園區分行'
    },
    {
      bankCodeString: '0042215',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2215',
      branchName: '高雄科學園區分行',
      totalName: '2215 ╴高雄科學園區分行'
    },
    {
      bankCodeString: '0042237',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2237',
      branchName: '東湖分行',
      totalName: '2237 ╴東湖分行'
    },
    {
      bankCodeString: '0042248',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2248',
      branchName: '高榮分行',
      totalName: '2248 ╴高榮分行'
    },
    {
      bankCodeString: '0042259',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2259',
      branchName: '南港軟體園區分行',
      totalName: '2259 ╴南港軟體園區分行'
    },
    {
      bankCodeString: '0042260',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2260',
      branchName: '龍潭分行',
      totalName: '2260 ╴龍潭分行'
    },
    {
      bankCodeString: '0042271',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2271',
      branchName: '仁德分行',
      totalName: '2271 ╴仁德分行'
    },
    {
      bankCodeString: '0042282',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2282',
      branchName: '林口分行',
      totalName: '2282 ╴林口分行'
    },
    {
      bankCodeString: '0042293',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2293',
      branchName: '木柵分行',
      totalName: '2293 ╴木柵分行'
    },
    {
      bankCodeString: '0042307',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2307',
      branchName: '臺南創新園區分行',
      totalName: '2307 ╴臺南創新園區分行'
    },
    {
      bankCodeString: '0042318',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2318',
      branchName: '財富管理部',
      totalName: '2318 ╴財富管理部'
    },
    {
      bankCodeString: '0042329',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2329',
      branchName: '採購部',
      totalName: '2329 ╴採購部'
    },
    {
      bankCodeString: '0042330',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2330',
      branchName: '貴金屬部',
      totalName: '2330 ╴貴金屬部'
    },
    {
      bankCodeString: '0042352',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2352',
      branchName: '公教保險部',
      totalName: '2352 ╴公教保險部'
    },
    {
      bankCodeString: '0042363',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2363',
      branchName: '武昌分行',
      totalName: '2363 ╴武昌分行'
    },
    {
      bankCodeString: '0042385',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2385',
      branchName: '臺北分行',
      totalName: '2385 ╴臺北分行'
    },
    {
      bankCodeString: '0042396',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2396',
      branchName: '金山分行',
      totalName: '2396 ╴金山分行'
    },
    {
      bankCodeString: '0042400',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2400',
      branchName: '信安分行',
      totalName: '2400 ╴信安分行'
    },
    {
      bankCodeString: '0042411',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2411',
      branchName: '劍潭分行',
      totalName: '2411 ╴劍潭分行'
    },
    {
      bankCodeString: '0042422',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2422',
      branchName: '萬華分行',
      totalName: '2422 ╴萬華分行'
    },
    {
      bankCodeString: '0042433',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2433',
      branchName: '板新分行',
      totalName: '2433 ╴板新分行'
    },
    {
      bankCodeString: '0042444',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2444',
      branchName: '新永和分行',
      totalName: '2444 ╴新永和分行'
    },
    {
      bankCodeString: '0042455',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2455',
      branchName: '南新莊分行',
      totalName: '2455 ╴南新莊分行'
    },
    {
      bankCodeString: '0042477',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2477',
      branchName: '新明分行',
      totalName: '2477 ╴新明分行'
    },
    {
      bankCodeString: '0042488',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2488',
      branchName: '六家分行',
      totalName: '2488 ╴六家分行'
    },
    {
      bankCodeString: '0042499',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2499',
      branchName: '北臺中分行',
      totalName: '2499 ╴北臺中分行'
    },
    {
      bankCodeString: '0042525',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2525',
      branchName: '嘉南分行',
      totalName: '2525 ╴嘉南分行'
    },
    {
      bankCodeString: '0042536',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2536',
      branchName: '南都分行',
      totalName: '2536 ╴南都分行'
    },
    {
      bankCodeString: '0042558',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2558',
      branchName: '北高雄分行',
      totalName: '2558 ╴北高雄分行'
    },
    {
      bankCodeString: '0042569',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2569',
      branchName: '成功分行',
      totalName: '2569 ╴成功分行'
    },
    {
      bankCodeString: '0042570',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2570',
      branchName: '北花蓮分行',
      totalName: '2570 ╴北花蓮分行'
    },
    {
      bankCodeString: '0042673',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2673',
      branchName: '國內營運部',
      totalName: '2673 ╴國內營運部'
    },
    {
      bankCodeString: '0042695',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2695',
      branchName: '企業金融部',
      totalName: '2695 ╴企業金融部'
    },
    {
      bankCodeString: '0042709',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2709',
      branchName: '新湖分行',
      totalName: '2709 ╴新湖分行'
    },
    {
      bankCodeString: '0042710',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2710',
      branchName: '五福分行',
      totalName: '2710 ╴五福分行'
    },
    {
      bankCodeString: '0042721',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2721',
      branchName: '六甲頂分行',
      totalName: '2721 ╴六甲頂分行'
    },
    {
      bankCodeString: '0042776',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2776',
      branchName: '上海分行',
      totalName: '2776 ╴上海分行'
    },
    {
      bankCodeString: '0042787',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2787',
      branchName: '中都分行',
      totalName: '2787 ╴中都分行'
    },
    {
      bankCodeString: '0042798',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2798',
      branchName: '台北國際機場分行',
      totalName: '2798 ╴台北國際機場分行'
    },
    {
      bankCodeString: '0042802',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2802',
      branchName: '新莊副都心分行',
      totalName: '2802 ╴新莊副都心分行'
    },
    {
      bankCodeString: '0042813',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2813',
      branchName: '孟買代表人辦事處',
      totalName: '2813 ╴孟買代表人辦事處'
    },
    {
      bankCodeString: '0042824',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2824',
      branchName: '雪梨分行',
      totalName: '2824 ╴雪梨分行'
    },
    {
      bankCodeString: '0042835',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2835',
      branchName: '仁武分行',
      totalName: '2835 ╴仁武分行'
    },
    {
      bankCodeString: '0042846',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2846',
      branchName: '上海嘉定支行',
      totalName: '2846 ╴上海嘉定支行'
    },
    {
      bankCodeString: '0042868',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2868',
      branchName: '仰光代表人辦事處',
      totalName: '2868 ╴仰光代表人辦事處'
    },
    {
      bankCodeString: '0042879',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2879',
      branchName: '廣州分行',
      totalName: '2879 ╴廣州分行'
    },
    {
      bankCodeString: '0042880',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2880',
      branchName: '福州分行',
      totalName: '2880 ╴福州分行'
    },
    {
      bankCodeString: '0042891',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2891',
      branchName: '臺中國際機場分行',
      totalName: '2891 ╴臺中國際機場分行'
    },
    {
      bankCodeString: '0042905',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2905',
      branchName: '臺中精密園區分行',
      totalName: '2905 ╴臺中精密園區分行'
    },
    {
      bankCodeString: '0042916',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2916',
      branchName: '胡志明市代表人辦事處',
      totalName: '2916 ╴胡志明市代表人辦事處'
    },
    {
      bankCodeString: '0042927',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2927',
      branchName: '曼谷代表人辦事處',
      totalName: '2927 ╴曼谷代表人辦事處'
    },
    {
      bankCodeString: '0042930',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2930',
      branchName: '雅加達代表人辦事處',
      totalName: '2930 ╴雅加達代表人辦事處'
    },
    {
      bankCodeString: '0042949',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2949',
      branchName: '馬尼拉代表人辦事處',
      totalName: '2949 ╴馬尼拉代表人辦事處'
    },
    {
      bankCodeString: '0042950',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2950',
      branchName: '矽谷代表人辦事處',
      totalName: '2950 ╴矽谷代表人辦事處'
    },
    {
      bankCodeString: '0042961',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2961',
      branchName: '法蘭克福代表人辦事處',
      totalName: '2961 ╴法蘭克福代表人辦事處'
    },
    {
      bankCodeString: '0042972',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '2972',
      branchName: '亞矽創新分行',
      totalName: '2972 ╴亞矽創新分行'
    },
    {
      bankCodeString: '0043005',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '3005',
      branchName: '吉隆坡代表人辦事處',
      totalName: '3005 ╴吉隆坡代表人辦事處'
    },
    {
      bankCodeString: '0043027',
      bankCode: '004',
      bankName: '臺灣銀行',
      branchCode: '3027',
      branchName: '鳳凰城代表人辦事處',
      totalName: '3027 ╴鳳凰城代表人辦事處'
    }
  ],
  '005': [
    {
      bankCodeString: '0050001',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0001',
      branchName: '總務室',
      totalName: '0001 ╴總務室'
    },
    {
      bankCodeString: '0050002',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0002',
      branchName: '人事室',
      totalName: '0002 ╴人事室'
    },
    {
      bankCodeString: '0050003',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0003',
      branchName: '秘書室',
      totalName: '0003 ╴秘書室'
    },
    {
      bankCodeString: '0050004',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0004',
      branchName: '資訊室',
      totalName: '0004 ╴資訊室'
    },
    {
      bankCodeString: '0050005',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0005',
      branchName: '會計室',
      totalName: '0005 ╴會計室'
    },
    {
      bankCodeString: '0050006',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0006',
      branchName: '業務部',
      totalName: '0006 ╴業務部'
    },
    {
      bankCodeString: '0050007',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0007',
      branchName: '消費金融部',
      totalName: '0007 ╴消費金融部'
    },
    {
      bankCodeString: '0050008',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0008',
      branchName: '稽核室',
      totalName: '0008 ╴稽核室'
    },
    {
      bankCodeString: '0050009',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0009',
      branchName: '企劃部',
      totalName: '0009 ╴企劃部'
    },
    {
      bankCodeString: '0050010',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0010',
      branchName: '董事會',
      totalName: '0010 ╴董事會'
    },
    {
      bankCodeString: '0050011',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0011',
      branchName: '不動產經營開發部',
      totalName: '0011 ╴不動產經營開發部'
    },
    {
      bankCodeString: '0050012',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0012',
      branchName: '法律事務室',
      totalName: '0012 ╴法律事務室'
    },
    {
      bankCodeString: '0050013',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0013',
      branchName: '債權管理部',
      totalName: '0013 ╴債權管理部'
    },
    {
      bankCodeString: '0050014',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0014',
      branchName: '員工訓練所',
      totalName: '0014 ╴員工訓練所'
    },
    {
      bankCodeString: '0050015',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0015',
      branchName: '財務部',
      totalName: '0015 ╴財務部'
    },
    {
      bankCodeString: '0050016',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0016',
      branchName: '風險管理中心',
      totalName: '0016 ╴風險管理中心'
    },
    {
      bankCodeString: '0050018',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0018',
      branchName: '電子金融發展中心',
      totalName: '0018 ╴電子金融發展中心'
    },
    {
      bankCodeString: '0050019',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0019',
      branchName: '財富管理中心',
      totalName: '0019 ╴財富管理中心'
    },
    {
      bankCodeString: '0050027',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0027',
      branchName: '基隆分行',
      totalName: '0027 ╴基隆分行'
    },
    {
      bankCodeString: '0050038',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0038',
      branchName: '中和分行',
      totalName: '0038 ╴中和分行'
    },
    {
      bankCodeString: '0050049',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0049',
      branchName: '南港分行',
      totalName: '0049 ╴南港分行'
    },
    {
      bankCodeString: '0050050',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0050',
      branchName: '台北分行',
      totalName: '0050 ╴台北分行'
    },
    {
      bankCodeString: '0050061',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0061',
      branchName: '民權分行',
      totalName: '0061 ╴民權分行'
    },
    {
      bankCodeString: '0050072',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0072',
      branchName: '古亭分行',
      totalName: '0072 ╴古亭分行'
    },
    {
      bankCodeString: '0050083',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0083',
      branchName: '長安分行',
      totalName: '0083 ╴長安分行'
    },
    {
      bankCodeString: '0050094',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0094',
      branchName: '士林分行',
      totalName: '0094 ╴士林分行'
    },
    {
      bankCodeString: '0050108',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0108',
      branchName: '三重分行',
      totalName: '0108 ╴三重分行'
    },
    {
      bankCodeString: '0050119',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0119',
      branchName: '宜蘭分行',
      totalName: '0119 ╴宜蘭分行'
    },
    {
      bankCodeString: '0050120',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0120',
      branchName: '羅東分行',
      totalName: '0120 ╴羅東分行'
    },
    {
      bankCodeString: '0050131',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0131',
      branchName: '桃園分行',
      totalName: '0131 ╴桃園分行'
    },
    {
      bankCodeString: '0050142',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0142',
      branchName: '中壢分行',
      totalName: '0142 ╴中壢分行'
    },
    {
      bankCodeString: '0050153',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0153',
      branchName: '石門分行',
      totalName: '0153 ╴石門分行'
    },
    {
      bankCodeString: '0050164',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0164',
      branchName: '新竹分行',
      totalName: '0164 ╴新竹分行'
    },
    {
      bankCodeString: '0050175',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0175',
      branchName: '竹東分行',
      totalName: '0175 ╴竹東分行'
    },
    {
      bankCodeString: '0050186',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0186',
      branchName: '花蓮分行',
      totalName: '0186 ╴花蓮分行'
    },
    {
      bankCodeString: '0050197',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0197',
      branchName: '玉里分行',
      totalName: '0197 ╴玉里分行'
    },
    {
      bankCodeString: '0050201',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0201',
      branchName: '苗栗分行',
      totalName: '0201 ╴苗栗分行'
    },
    {
      bankCodeString: '0050212',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0212',
      branchName: '頭份分行',
      totalName: '0212 ╴頭份分行'
    },
    {
      bankCodeString: '0050223',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0223',
      branchName: '豐原分行',
      totalName: '0223 ╴豐原分行'
    },
    {
      bankCodeString: '0050234',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0234',
      branchName: '大甲分行',
      totalName: '0234 ╴大甲分行'
    },
    {
      bankCodeString: '0050245',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0245',
      branchName: '台中分行',
      totalName: '0245 ╴台中分行'
    },
    {
      bankCodeString: '0050256',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0256',
      branchName: '南投分行',
      totalName: '0256 ╴南投分行'
    },
    {
      bankCodeString: '0050267',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0267',
      branchName: '員林分行',
      totalName: '0267 ╴員林分行'
    },
    {
      bankCodeString: '0050278',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0278',
      branchName: '斗六分行',
      totalName: '0278 ╴斗六分行'
    },
    {
      bankCodeString: '0050289',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0289',
      branchName: '北港分行',
      totalName: '0289 ╴北港分行'
    },
    {
      bankCodeString: '0050290',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0290',
      branchName: '嘉義分行',
      totalName: '0290 ╴嘉義分行'
    },
    {
      bankCodeString: '0050304',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0304',
      branchName: '新營分行',
      totalName: '0304 ╴新營分行'
    },
    {
      bankCodeString: '0050315',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0315',
      branchName: '永康分行',
      totalName: '0315 ╴永康分行'
    },
    {
      bankCodeString: '0050326',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0326',
      branchName: '台南分行',
      totalName: '0326 ╴台南分行'
    },
    {
      bankCodeString: '0050337',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0337',
      branchName: '高雄分行',
      totalName: '0337 ╴高雄分行'
    },
    {
      bankCodeString: '0050348',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0348',
      branchName: '岡山分行',
      totalName: '0348 ╴岡山分行'
    },
    {
      bankCodeString: '0050359',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0359',
      branchName: '美濃分行',
      totalName: '0359 ╴美濃分行'
    },
    {
      bankCodeString: '0050360',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0360',
      branchName: '屏東分行',
      totalName: '0360 ╴屏東分行'
    },
    {
      bankCodeString: '0050371',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0371',
      branchName: '台東分行',
      totalName: '0371 ╴台東分行'
    },
    {
      bankCodeString: '0050382',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0382',
      branchName: '青年分行',
      totalName: '0382 ╴青年分行'
    },
    {
      bankCodeString: '0050393',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0393',
      branchName: '金門分行',
      totalName: '0393 ╴金門分行'
    },
    {
      bankCodeString: '0050395',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0395',
      branchName: '新市里市場辦事處',
      totalName: '0395 ╴新市里市場辦事處'
    },
    {
      bankCodeString: '0050407',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0407',
      branchName: '澎湖分行',
      totalName: '0407 ╴澎湖分行'
    },
    {
      bankCodeString: '0050418',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0418',
      branchName: '營業部',
      totalName: '0418 ╴營業部'
    },
    {
      bankCodeString: '0050429',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0429',
      branchName: '企業金融部',
      totalName: '0429 ╴企業金融部'
    },
    {
      bankCodeString: '0050441',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0441',
      branchName: '授信審查部',
      totalName: '0441 ╴授信審查部'
    },
    {
      bankCodeString: '0050452',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0452',
      branchName: '和平分行',
      totalName: '0452 ╴和平分行'
    },
    {
      bankCodeString: '0050463',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0463',
      branchName: '潮州分行',
      totalName: '0463 ╴潮州分行'
    },
    {
      bankCodeString: '0050474',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0474',
      branchName: '彰化分行',
      totalName: '0474 ╴彰化分行'
    },
    {
      bankCodeString: '0050485',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0485',
      branchName: '中山分行',
      totalName: '0485 ╴中山分行'
    },
    {
      bankCodeString: '0050496',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0496',
      branchName: '永和分行',
      totalName: '0496 ╴永和分行'
    },
    {
      bankCodeString: '0050500',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0500',
      branchName: '板橋分行',
      totalName: '0500 ╴板橋分行'
    },
    {
      bankCodeString: '0050511',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0511',
      branchName: '鳳山分行',
      totalName: '0511 ╴鳳山分行'
    },
    {
      bankCodeString: '0050522',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0522',
      branchName: '湖口分行',
      totalName: '0522 ╴湖口分行'
    },
    {
      bankCodeString: '0050533',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0533',
      branchName: '蘇澳分行',
      totalName: '0533 ╴蘇澳分行'
    },
    {
      bankCodeString: '0050544',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0544',
      branchName: '新興分行',
      totalName: '0544 ╴新興分行'
    },
    {
      bankCodeString: '0050555',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0555',
      branchName: '西台中分行',
      totalName: '0555 ╴西台中分行'
    },
    {
      bankCodeString: '0050566',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0566',
      branchName: '虎尾分行',
      totalName: '0566 ╴虎尾分行'
    },
    {
      bankCodeString: '0050577',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0577',
      branchName: '仁愛分行',
      totalName: '0577 ╴仁愛分行'
    },
    {
      bankCodeString: '0050588',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0588',
      branchName: '忠孝分行',
      totalName: '0588 ╴忠孝分行'
    },
    {
      bankCodeString: '0050599',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0599',
      branchName: '中正分行',
      totalName: '0599 ╴中正分行'
    },
    {
      bankCodeString: '0050603',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0603',
      branchName: '調查研究室',
      totalName: '0603 ╴調查研究室'
    },
    {
      bankCodeString: '0050614',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0614',
      branchName: '新店分行',
      totalName: '0614 ╴新店分行'
    },
    {
      bankCodeString: '0050625',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0625',
      branchName: '北台南分行',
      totalName: '0625 ╴北台南分行'
    },
    {
      bankCodeString: '0050636',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0636',
      branchName: '松山分行',
      totalName: '0636 ╴松山分行'
    },
    {
      bankCodeString: '0050647',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0647',
      branchName: '內湖分行',
      totalName: '0647 ╴內湖分行'
    },
    {
      bankCodeString: '0050658',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0658',
      branchName: '三民分行',
      totalName: '0658 ╴三民分行'
    },
    {
      bankCodeString: '0050669',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0669',
      branchName: '民雄分行',
      totalName: '0669 ╴民雄分行'
    },
    {
      bankCodeString: '0050670',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0670',
      branchName: '大社分行',
      totalName: '0670 ╴大社分行'
    },
    {
      bankCodeString: '0050692',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0692',
      branchName: '前鎮分行',
      totalName: '0692 ╴前鎮分行'
    },
    {
      bankCodeString: '0050706',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0706',
      branchName: '路竹分行',
      totalName: '0706 ╴路竹分行'
    },
    {
      bankCodeString: '0050717',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0717',
      branchName: '五甲分行',
      totalName: '0717 ╴五甲分行'
    },
    {
      bankCodeString: '0050728',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0728',
      branchName: '太平分行',
      totalName: '0728 ╴太平分行'
    },
    {
      bankCodeString: '0050739',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0739',
      branchName: '正濱分行',
      totalName: '0739 ╴正濱分行'
    },
    {
      bankCodeString: '0050740',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0740',
      branchName: '敦化分行',
      totalName: '0740 ╴敦化分行'
    },
    {
      bankCodeString: '0050751',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0751',
      branchName: '信託部',
      totalName: '0751 ╴信託部'
    },
    {
      bankCodeString: '0050762',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0762',
      branchName: '蘆洲分行',
      totalName: '0762 ╴蘆洲分行'
    },
    {
      bankCodeString: '0050773',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0773',
      branchName: '北台中分行',
      totalName: '0773 ╴北台中分行'
    },
    {
      bankCodeString: '0050784',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0784',
      branchName: '苓雅分行',
      totalName: '0784 ╴苓雅分行'
    },
    {
      bankCodeString: '0050795',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0795',
      branchName: '信義分行',
      totalName: '0795 ╴信義分行'
    },
    {
      bankCodeString: '0050809',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0809',
      branchName: '土城分行',
      totalName: '0809 ╴土城分行'
    },
    {
      bankCodeString: '0050810',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0810',
      branchName: '淡水分行',
      totalName: '0810 ╴淡水分行'
    },
    {
      bankCodeString: '0050821',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0821',
      branchName: '草屯分行',
      totalName: '0821 ╴草屯分行'
    },
    {
      bankCodeString: '0050832',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0832',
      branchName: '東台南分行',
      totalName: '0832 ╴東台南分行'
    },
    {
      bankCodeString: '0050843',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0843',
      branchName: '通霄分行',
      totalName: '0843 ╴通霄分行'
    },
    {
      bankCodeString: '0050854',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0854',
      branchName: '學甲分行',
      totalName: '0854 ╴學甲分行'
    },
    {
      bankCodeString: '0050865',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0865',
      branchName: '新莊分行',
      totalName: '0865 ╴新莊分行'
    },
    {
      bankCodeString: '0050876',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0876',
      branchName: '雙和分行',
      totalName: '0876 ╴雙和分行'
    },
    {
      bankCodeString: '0050887',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0887',
      branchName: '國外部',
      totalName: '0887 ╴國外部'
    },
    {
      bankCodeString: '0050898',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0898',
      branchName: '白河分行',
      totalName: '0898 ╴白河分行'
    },
    {
      bankCodeString: '0050902',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0902',
      branchName: '復興分行',
      totalName: '0902 ╴復興分行'
    },
    {
      bankCodeString: '0050913',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0913',
      branchName: '平鎮分行',
      totalName: '0913 ╴平鎮分行'
    },
    {
      bankCodeString: '0050924',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0924',
      branchName: '國際金融業務分行',
      totalName: '0924 ╴國際金融業務分行'
    },
    {
      bankCodeString: '0050935',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0935',
      branchName: '文山分行',
      totalName: '0935 ╴文山分行'
    },
    {
      bankCodeString: '0050946',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0946',
      branchName: '中港分行',
      totalName: '0946 ╴中港分行'
    },
    {
      bankCodeString: '0050957',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0957',
      branchName: '東板橋分行',
      totalName: '0957 ╴東板橋分行'
    },
    {
      bankCodeString: '0050968',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0968',
      branchName: '南崁分行',
      totalName: '0968 ╴南崁分行'
    },
    {
      bankCodeString: '0050979',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0979',
      branchName: '建國分行',
      totalName: '0979 ╴建國分行'
    },
    {
      bankCodeString: '0050980',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0980',
      branchName: '樹林分行',
      totalName: '0980 ╴樹林分行'
    },
    {
      bankCodeString: '0050991',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '0991',
      branchName: '東台北分行',
      totalName: '0991 ╴東台北分行'
    },
    {
      bankCodeString: '0051002',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1002',
      branchName: '西三重分行',
      totalName: '1002 ╴西三重分行'
    },
    {
      bankCodeString: '0051013',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1013',
      branchName: '南台中分行',
      totalName: '1013 ╴南台中分行'
    },
    {
      bankCodeString: '0051024',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1024',
      branchName: '長春分行',
      totalName: '1024 ╴長春分行'
    },
    {
      bankCodeString: '0051030',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1030',
      branchName: '兼營證券商',
      totalName: '1030 ╴兼營證券商'
    },
    {
      bankCodeString: '0051031',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1031',
      branchName: '證券商台中分行',
      totalName: '1031 ╴證券商台中分行'
    },
    {
      bankCodeString: '0051032',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1032',
      branchName: '證券商台南分行',
      totalName: '1032 ╴證券商台南分行'
    },
    {
      bankCodeString: '0051033',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1033',
      branchName: '證券商高雄分行',
      totalName: '1033 ╴證券商高雄分行'
    },
    {
      bankCodeString: '0051034',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1034',
      branchName: '證券商嘉義分行',
      totalName: '1034 ╴證券商嘉義分行'
    },
    {
      bankCodeString: '0051035',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1035',
      branchName: '東新竹分行',
      totalName: '1035 ╴東新竹分行'
    },
    {
      bankCodeString: '0051036',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1036',
      branchName: '證券商玉里分行',
      totalName: '1036 ╴證券商玉里分行'
    },
    {
      bankCodeString: '0051037',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1037',
      branchName: '證券商花蓮分行',
      totalName: '1037 ╴證券商花蓮分行'
    },
    {
      bankCodeString: '0051038',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1038',
      branchName: '證券商和平分行',
      totalName: '1038 ╴證券商和平分行'
    },
    {
      bankCodeString: '0051039',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1039',
      branchName: '證券商士林分行',
      totalName: '1039 ╴證券商士林分行'
    },
    {
      bankCodeString: '005103A',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '103A',
      branchName: '證券商建國分行',
      totalName: '103A ╴證券商建國分行'
    },
    {
      bankCodeString: '005103B',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '103B',
      branchName: '證券商彰化分行',
      totalName: '103B ╴證券商彰化分行'
    },
    {
      bankCodeString: '005103C',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '103C',
      branchName: '證券商白河分行',
      totalName: '103C ╴證券商白河分行'
    },
    {
      bankCodeString: '005103D',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '103D',
      branchName: '證券商湖口分行',
      totalName: '103D ╴證券商湖口分行'
    },
    {
      bankCodeString: '005103F',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '103F',
      branchName: '證券商南港分行',
      totalName: '103F ╴證券商南港分行'
    },
    {
      bankCodeString: '005103Y',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '103Y',
      branchName: '證券商新竹分行',
      totalName: '103Y ╴證券商新竹分行'
    },
    {
      bankCodeString: '0051046',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1046',
      branchName: '新市分行',
      totalName: '1046 ╴新市分行'
    },
    {
      bankCodeString: '0051057',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1057',
      branchName: '博愛分行',
      totalName: '1057 ╴博愛分行'
    },
    {
      bankCodeString: '0051068',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1068',
      branchName: '中崙分行',
      totalName: '1068 ╴中崙分行'
    },
    {
      bankCodeString: '0051079',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1079',
      branchName: '華江分行',
      totalName: '1079 ╴華江分行'
    },
    {
      bankCodeString: '0051080',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1080',
      branchName: '竹北分行',
      totalName: '1080 ╴竹北分行'
    },
    {
      bankCodeString: '0051091',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1091',
      branchName: '安平分行',
      totalName: '1091 ╴安平分行'
    },
    {
      bankCodeString: '0051105',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1105',
      branchName: '嘉興分行',
      totalName: '1105 ╴嘉興分行'
    },
    {
      bankCodeString: '0051116',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1116',
      branchName: '南新莊分行',
      totalName: '1116 ╴南新莊分行'
    },
    {
      bankCodeString: '0051127',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1127',
      branchName: '三峽分行',
      totalName: '1127 ╴三峽分行'
    },
    {
      bankCodeString: '0051138',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1138',
      branchName: '沙鹿分行',
      totalName: '1138 ╴沙鹿分行'
    },
    {
      bankCodeString: '0051149',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1149',
      branchName: '南桃園分行',
      totalName: '1149 ╴南桃園分行'
    },
    {
      bankCodeString: '0051150',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1150',
      branchName: '汐止分行',
      totalName: '1150 ╴汐止分行'
    },
    {
      bankCodeString: '0051161',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1161',
      branchName: '萬華分行',
      totalName: '1161 ╴萬華分行'
    },
    {
      bankCodeString: '0051172',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1172',
      branchName: '小港分行',
      totalName: '1172 ╴小港分行'
    },
    {
      bankCodeString: '0051183',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1183',
      branchName: '新工分行',
      totalName: '1183 ╴新工分行'
    },
    {
      bankCodeString: '0051194',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1194',
      branchName: '烏日分行',
      totalName: '1194 ╴烏日分行'
    },
    {
      bankCodeString: '0051208',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1208',
      branchName: '西湖分行',
      totalName: '1208 ╴西湖分行'
    },
    {
      bankCodeString: '0051219',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1219',
      branchName: '八德分行',
      totalName: '1219 ╴八德分行'
    },
    {
      bankCodeString: '0051220',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1220',
      branchName: '北屯分行',
      totalName: '1220 ╴北屯分行'
    },
    {
      bankCodeString: '0051231',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1231',
      branchName: '大安分行',
      totalName: '1231 ╴大安分行'
    },
    {
      bankCodeString: '0051242',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1242',
      branchName: '北中壢分行',
      totalName: '1242 ╴北中壢分行'
    },
    {
      bankCodeString: '0051253',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1253',
      branchName: '高樹分行',
      totalName: '1253 ╴高樹分行'
    },
    {
      bankCodeString: '0051264',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1264',
      branchName: '枋寮分行',
      totalName: '1264 ╴枋寮分行'
    },
    {
      bankCodeString: '0051286',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1286',
      branchName: '金城分行',
      totalName: '1286 ╴金城分行'
    },
    {
      bankCodeString: '0051297',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1297',
      branchName: '光復分行',
      totalName: '1297 ╴光復分行'
    },
    {
      bankCodeString: '0051301',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1301',
      branchName: '左營分行',
      totalName: '1301 ╴左營分行'
    },
    {
      bankCodeString: '0051312',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1312',
      branchName: '北桃園分行',
      totalName: '1312 ╴北桃園分行'
    },
    {
      bankCodeString: '0051323',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1323',
      branchName: '東港分行',
      totalName: '1323 ╴東港分行'
    },
    {
      bankCodeString: '0051334',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1334',
      branchName: '天母分行',
      totalName: '1334 ╴天母分行'
    },
    {
      bankCodeString: '0051345',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1345',
      branchName: '泰山分行',
      totalName: '1345 ╴泰山分行'
    },
    {
      bankCodeString: '0051356',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1356',
      branchName: '中科分行',
      totalName: '1356 ╴中科分行'
    },
    {
      bankCodeString: '0051367',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1367',
      branchName: '大園分行',
      totalName: '1367 ╴大園分行'
    },
    {
      bankCodeString: '0051378',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1378',
      branchName: '楊梅分行',
      totalName: '1378 ╴楊梅分行'
    },
    {
      bankCodeString: '0051404',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1404',
      branchName: '城東分行',
      totalName: '1404 ╴城東分行'
    },
    {
      bankCodeString: '0051415',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1415',
      branchName: '松南分行',
      totalName: '1415 ╴松南分行'
    },
    {
      bankCodeString: '0051426',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1426',
      branchName: '福興分行',
      totalName: '1426 ╴福興分行'
    },
    {
      bankCodeString: '0051437',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1437',
      branchName: '林口分行',
      totalName: '1437 ╴林口分行'
    },
    {
      bankCodeString: '0051448',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1448',
      branchName: '西屯分行',
      totalName: '1448 ╴西屯分行'
    },
    {
      bankCodeString: '0051459',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1459',
      branchName: '內壢分行',
      totalName: '1459 ╴內壢分行'
    },
    {
      bankCodeString: '0051460',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1460',
      branchName: '竹南分行',
      totalName: '1460 ╴竹南分行'
    },
    {
      bankCodeString: '0051471',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1471',
      branchName: '安南分行',
      totalName: '1471 ╴安南分行'
    },
    {
      bankCodeString: '0051482',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1482',
      branchName: '汐科分行',
      totalName: '1482 ╴汐科分行'
    },
    {
      bankCodeString: '0051493',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1493',
      branchName: '楠梓分行',
      totalName: '1493 ╴楠梓分行'
    },
    {
      bankCodeString: '0051507',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1507',
      branchName: '大里分行',
      totalName: '1507 ╴大里分行'
    },
    {
      bankCodeString: '0051518',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1518',
      branchName: '大灣分行',
      totalName: '1518 ╴大灣分行'
    },
    {
      bankCodeString: '0051530',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1530',
      branchName: '大發分行',
      totalName: '1530 ╴大發分行'
    },
    {
      bankCodeString: '0051563',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1563',
      branchName: '工研院分行',
      totalName: '1563 ╴工研院分行'
    },
    {
      bankCodeString: '0051574',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1574',
      branchName: '北三重分行',
      totalName: '1574 ╴北三重分行'
    },
    {
      bankCodeString: '0051585',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1585',
      branchName: '圓通分行',
      totalName: '1585 ╴圓通分行'
    },
    {
      bankCodeString: '0051600',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1600',
      branchName: '大直分行',
      totalName: '1600 ╴大直分行'
    },
    {
      bankCodeString: '0051611',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1611',
      branchName: '南屯分行',
      totalName: '1611 ╴南屯分行'
    },
    {
      bankCodeString: '0051622',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1622',
      branchName: '證券部',
      totalName: '1622 ╴證券部'
    },
    {
      bankCodeString: '0051633',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1633',
      branchName: '寶中分行',
      totalName: '1633 ╴寶中分行'
    },
    {
      bankCodeString: '0051644',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1644',
      branchName: '中清分行',
      totalName: '1644 ╴中清分行'
    },
    {
      bankCodeString: '0051655',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1655',
      branchName: '南京東路分行',
      totalName: '1655 ╴南京東路分行'
    },
    {
      bankCodeString: '0051666',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1666',
      branchName: '仁武分行',
      totalName: '1666 ╴仁武分行'
    },
    {
      bankCodeString: '0051677',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '1677',
      branchName: '青埔分行',
      totalName: '1677 ╴青埔分行'
    },
    {
      bankCodeString: '0055011',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '5011',
      branchName: '洛杉磯分行',
      totalName: '5011 ╴洛杉磯分行'
    },
    {
      bankCodeString: '0055022',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '5022',
      branchName: '香港分行',
      totalName: '5022 ╴香港分行'
    },
    {
      bankCodeString: '0055033',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '5033',
      branchName: '新加坡分行',
      totalName: '5033 ╴新加坡分行'
    },
    {
      bankCodeString: '0055044',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '5044',
      branchName: '上海分行',
      totalName: '5044 ╴上海分行'
    },
    {
      bankCodeString: '0055066',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '5066',
      branchName: '紐約分行',
      totalName: '5066 ╴紐約分行'
    },
    {
      bankCodeString: '0055077',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '5077',
      branchName: '天津分行',
      totalName: '5077 ╴天津分行'
    },
    {
      bankCodeString: '0055088',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '5088',
      branchName: '武漢分行',
      totalName: '5088 ╴武漢分行'
    },
    {
      bankCodeString: '0055099',
      bankCode: '005',
      bankName: '臺灣土地銀行',
      branchCode: '5099',
      branchName: '吉隆坡代表人辦事處',
      totalName: '5099 ╴吉隆坡代表人辦事處'
    }
  ],
  '006': [
    {
      bankCodeString: '0060001',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0001',
      branchName: '董事會',
      totalName: '0001 ╴董事會'
    },
    {
      bankCodeString: '0060011',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0011',
      branchName: '行政管理部',
      totalName: '0011 ╴行政管理部'
    },
    {
      bankCodeString: '0060012',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0012',
      branchName: '董事會稽核部',
      totalName: '0012 ╴董事會稽核部'
    },
    {
      bankCodeString: '0060014',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0014',
      branchName: '資訊部',
      totalName: '0014 ╴資訊部'
    },
    {
      bankCodeString: '0060015',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0015',
      branchName: '法人金融部',
      totalName: '0015 ╴法人金融部'
    },
    {
      bankCodeString: '0060017',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0017',
      branchName: '債權管理部',
      totalName: '0017 ╴債權管理部'
    },
    {
      bankCodeString: '0060018',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0018',
      branchName: '電子金融部',
      totalName: '0018 ╴電子金融部'
    },
    {
      bankCodeString: '0060019',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0019',
      branchName: '法律事務部',
      totalName: '0019 ╴法律事務部'
    },
    {
      bankCodeString: '0060020',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0020',
      branchName: '人力資源部',
      totalName: '0020 ╴人力資源部'
    },
    {
      bankCodeString: '0060023',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0023',
      branchName: '個人金融部',
      totalName: '0023 ╴個人金融部'
    },
    {
      bankCodeString: '0060024',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0024',
      branchName: '信用卡部',
      totalName: '0024 ╴信用卡部'
    },
    {
      bankCodeString: '0060026',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0026',
      branchName: '風險管理部',
      totalName: '0026 ╴風險管理部'
    },
    {
      bankCodeString: '0060028',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0028',
      branchName: '館前分行',
      totalName: '0028 ╴館前分行'
    },
    {
      bankCodeString: '0060039',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0039',
      branchName: '西門分行',
      totalName: '0039 ╴西門分行'
    },
    {
      bankCodeString: '0060040',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0040',
      branchName: '延平分行',
      totalName: '0040 ╴延平分行'
    },
    {
      bankCodeString: '0060051',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0051',
      branchName: '大稻埕分行',
      totalName: '0051 ╴大稻埕分行'
    },
    {
      bankCodeString: '0060062',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0062',
      branchName: '東門分行',
      totalName: '0062 ╴東門分行'
    },
    {
      bankCodeString: '0060073',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0073',
      branchName: '松山分行',
      totalName: '0073 ╴松山分行'
    },
    {
      bankCodeString: '0060084',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0084',
      branchName: '新店分行',
      totalName: '0084 ╴新店分行'
    },
    {
      bankCodeString: '0060095',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0095',
      branchName: '永和分行',
      totalName: '0095 ╴永和分行'
    },
    {
      bankCodeString: '0060109',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0109',
      branchName: '三重分行',
      totalName: '0109 ╴三重分行'
    },
    {
      bankCodeString: '0060110',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0110',
      branchName: '板橋分行',
      totalName: '0110 ╴板橋分行'
    },
    {
      bankCodeString: '0060121',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0121',
      branchName: '基隆分行',
      totalName: '0121 ╴基隆分行'
    },
    {
      bankCodeString: '0060132',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0132',
      branchName: '宜蘭分行',
      totalName: '0132 ╴宜蘭分行'
    },
    {
      bankCodeString: '0060143',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0143',
      branchName: '蘇澳分行',
      totalName: '0143 ╴蘇澳分行'
    },
    {
      bankCodeString: '0060154',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0154',
      branchName: '桃園分行',
      totalName: '0154 ╴桃園分行'
    },
    {
      bankCodeString: '0060165',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0165',
      branchName: '中壢分行',
      totalName: '0165 ╴中壢分行'
    },
    {
      bankCodeString: '0060176',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0176',
      branchName: '新竹分行',
      totalName: '0176 ╴新竹分行'
    },
    {
      bankCodeString: '0060187',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0187',
      branchName: '苗栗分行',
      totalName: '0187 ╴苗栗分行'
    },
    {
      bankCodeString: '0060198',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0198',
      branchName: '頭份分行',
      totalName: '0198 ╴頭份分行'
    },
    {
      bankCodeString: '0060202',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0202',
      branchName: '豐原分行',
      totalName: '0202 ╴豐原分行'
    },
    {
      bankCodeString: '0060213',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0213',
      branchName: '沙鹿分行',
      totalName: '0213 ╴沙鹿分行'
    },
    {
      bankCodeString: '0060224',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0224',
      branchName: '台中分行',
      totalName: '0224 ╴台中分行'
    },
    {
      bankCodeString: '0060235',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0235',
      branchName: '彰化分行',
      totalName: '0235 ╴彰化分行'
    },
    {
      bankCodeString: '0060246',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0246',
      branchName: '員林分行',
      totalName: '0246 ╴員林分行'
    },
    {
      bankCodeString: '0060257',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0257',
      branchName: '南投分行',
      totalName: '0257 ╴南投分行'
    },
    {
      bankCodeString: '0060268',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0268',
      branchName: '斗六分行',
      totalName: '0268 ╴斗六分行'
    },
    {
      bankCodeString: '0060279',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0279',
      branchName: '北港分行',
      totalName: '0279 ╴北港分行'
    },
    {
      bankCodeString: '0060280',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0280',
      branchName: '嘉義分行',
      totalName: '0280 ╴嘉義分行'
    },
    {
      bankCodeString: '0060291',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0291',
      branchName: '新營分行',
      totalName: '0291 ╴新營分行'
    },
    {
      bankCodeString: '0060305',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0305',
      branchName: '台南分行',
      totalName: '0305 ╴台南分行'
    },
    {
      bankCodeString: '0060316',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0316',
      branchName: '成功分行',
      totalName: '0316 ╴成功分行'
    },
    {
      bankCodeString: '0060327',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0327',
      branchName: '鳳山分行',
      totalName: '0327 ╴鳳山分行'
    },
    {
      bankCodeString: '0060338',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0338',
      branchName: '岡山分行',
      totalName: '0338 ╴岡山分行'
    },
    {
      bankCodeString: '0060349',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0349',
      branchName: '高雄分行',
      totalName: '0349 ╴高雄分行'
    },
    {
      bankCodeString: '0060350',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0350',
      branchName: '新興分行',
      totalName: '0350 ╴新興分行'
    },
    {
      bankCodeString: '0060361',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0361',
      branchName: '屏東分行',
      totalName: '0361 ╴屏東分行'
    },
    {
      bankCodeString: '0060372',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0372',
      branchName: '潮州分行',
      totalName: '0372 ╴潮州分行'
    },
    {
      bankCodeString: '0060383',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0383',
      branchName: '花蓮分行',
      totalName: '0383 ╴花蓮分行'
    },
    {
      bankCodeString: '0060394',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0394',
      branchName: '台東分行',
      totalName: '0394 ╴台東分行'
    },
    {
      bankCodeString: '0060408',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0408',
      branchName: '澎湖分行',
      totalName: '0408 ╴澎湖分行'
    },
    {
      bankCodeString: '0060419',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0419',
      branchName: '南京東路分行',
      totalName: '0419 ╴南京東路分行'
    },
    {
      bankCodeString: '0060420',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0420',
      branchName: '北高雄分行',
      totalName: '0420 ╴北高雄分行'
    },
    {
      bankCodeString: '0060431',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0431',
      branchName: '大同分行',
      totalName: '0431 ╴大同分行'
    },
    {
      bankCodeString: '0060453',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0453',
      branchName: '忠孝分行',
      totalName: '0453 ╴忠孝分行'
    },
    {
      bankCodeString: '0060464',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0464',
      branchName: '景美分行',
      totalName: '0464 ╴景美分行'
    },
    {
      bankCodeString: '0060475',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0475',
      branchName: '士林分行',
      totalName: '0475 ╴士林分行'
    },
    {
      bankCodeString: '0060486',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0486',
      branchName: '汐止分行',
      totalName: '0486 ╴汐止分行'
    },
    {
      bankCodeString: '0060497',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0497',
      branchName: '新莊分行',
      totalName: '0497 ╴新莊分行'
    },
    {
      bankCodeString: '0060501',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0501',
      branchName: '中興分行',
      totalName: '0501 ╴中興分行'
    },
    {
      bankCodeString: '0060512',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0512',
      branchName: '苓雅分行',
      totalName: '0512 ╴苓雅分行'
    },
    {
      bankCodeString: '0060523',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0523',
      branchName: '業務發展部',
      totalName: '0523 ╴業務發展部'
    },
    {
      bankCodeString: '0060545',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0545',
      branchName: '台北分行',
      totalName: '0545 ╴台北分行'
    },
    {
      bankCodeString: '0060567',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0567',
      branchName: '營業部',
      totalName: '0567 ╴營業部'
    },
    {
      bankCodeString: '0060568',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0568',
      branchName: '集中作業中心',
      totalName: '0568 ╴集中作業中心'
    },
    {
      bankCodeString: '0060578',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0578',
      branchName: '南豐原分行',
      totalName: '0578 ╴南豐原分行'
    },
    {
      bankCodeString: '0060589',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0589',
      branchName: '羅東分行',
      totalName: '0589 ╴羅東分行'
    },
    {
      bankCodeString: '0060590',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0590',
      branchName: '三民分行',
      totalName: '0590 ╴三民分行'
    },
    {
      bankCodeString: '0060604',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0604',
      branchName: '城東分行',
      totalName: '0604 ╴城東分行'
    },
    {
      bankCodeString: '0060615',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0615',
      branchName: '佳里分行',
      totalName: '0615 ╴佳里分行'
    },
    {
      bankCodeString: '0060626',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0626',
      branchName: '中和分行',
      totalName: '0626 ╴中和分行'
    },
    {
      bankCodeString: '0060637',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0637',
      branchName: '南高雄分行',
      totalName: '0637 ╴南高雄分行'
    },
    {
      bankCodeString: '0060648',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0648',
      branchName: '南嘉義分行',
      totalName: '0648 ╴南嘉義分行'
    },
    {
      bankCodeString: '0060659',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0659',
      branchName: '竹東分行',
      totalName: '0659 ╴竹東分行'
    },
    {
      bankCodeString: '0060660',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0660',
      branchName: '林口文化分行',
      totalName: '0660 ╴林口文化分行'
    },
    {
      bankCodeString: '0060671',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0671',
      branchName: '東三重分行',
      totalName: '0671 ╴東三重分行'
    },
    {
      bankCodeString: '0060682',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0682',
      branchName: '南興分行',
      totalName: '0682 ╴南興分行'
    },
    {
      bankCodeString: '0060693',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0693',
      branchName: '五權分行',
      totalName: '0693 ╴五權分行'
    },
    {
      bankCodeString: '0060707',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0707',
      branchName: '埔里分行',
      totalName: '0707 ╴埔里分行'
    },
    {
      bankCodeString: '0060718',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0718',
      branchName: '大順分行',
      totalName: '0718 ╴大順分行'
    },
    {
      bankCodeString: '0060729',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0729',
      branchName: '南勢角分行',
      totalName: '0729 ╴南勢角分行'
    },
    {
      bankCodeString: '0060730',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0730',
      branchName: '朴子分行',
      totalName: '0730 ╴朴子分行'
    },
    {
      bankCodeString: '0060741',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0741',
      branchName: '授信管理部',
      totalName: '0741 ╴授信管理部'
    },
    {
      bankCodeString: '0060752',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0752',
      branchName: '調查研究部',
      totalName: '0752 ╴調查研究部'
    },
    {
      bankCodeString: '0060763',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0763',
      branchName: '大安分行',
      totalName: '0763 ╴大安分行'
    },
    {
      bankCodeString: '0060774',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0774',
      branchName: '民權分行',
      totalName: '0774 ╴民權分行'
    },
    {
      bankCodeString: '0060785',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0785',
      branchName: '東高雄分行',
      totalName: '0785 ╴東高雄分行'
    },
    {
      bankCodeString: '0060796',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0796',
      branchName: '東台北分行',
      totalName: '0796 ╴東台北分行'
    },
    {
      bankCodeString: '0060800',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0800',
      branchName: '城內分行',
      totalName: '0800 ╴城內分行'
    },
    {
      bankCodeString: '0060811',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0811',
      branchName: '建國分行',
      totalName: '0811 ╴建國分行'
    },
    {
      bankCodeString: '0060822',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0822',
      branchName: '圓山分行',
      totalName: '0822 ╴圓山分行'
    },
    {
      bankCodeString: '0060833',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0833',
      branchName: '信義分行',
      totalName: '0833 ╴信義分行'
    },
    {
      bankCodeString: '0060844',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0844',
      branchName: '長春分行',
      totalName: '0844 ╴長春分行'
    },
    {
      bankCodeString: '0060855',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0855',
      branchName: '仁愛分行',
      totalName: '0855 ╴仁愛分行'
    },
    {
      bankCodeString: '0060866',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0866',
      branchName: '玉成分行',
      totalName: '0866 ╴玉成分行'
    },
    {
      bankCodeString: '0060877',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0877',
      branchName: '古亭分行',
      totalName: '0877 ╴古亭分行'
    },
    {
      bankCodeString: '0060888',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0888',
      branchName: '長安分行',
      totalName: '0888 ╴長安分行'
    },
    {
      bankCodeString: '0060899',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0899',
      branchName: '松興分行',
      totalName: '0899 ╴松興分行'
    },
    {
      bankCodeString: '0060903',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0903',
      branchName: '民族分行',
      totalName: '0903 ╴民族分行'
    },
    {
      bankCodeString: '0060914',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0914',
      branchName: '復興分行',
      totalName: '0914 ╴復興分行'
    },
    {
      bankCodeString: '0060925',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0925',
      branchName: '雙連分行',
      totalName: '0925 ╴雙連分行'
    },
    {
      bankCodeString: '0060936',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0936',
      branchName: '民生分行',
      totalName: '0936 ╴民生分行'
    },
    {
      bankCodeString: '0060947',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0947',
      branchName: '新生分行',
      totalName: '0947 ╴新生分行'
    },
    {
      bankCodeString: '0060958',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0958',
      branchName: '松江分行',
      totalName: '0958 ╴松江分行'
    },
    {
      bankCodeString: '0060969',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0969',
      branchName: '永吉分行',
      totalName: '0969 ╴永吉分行'
    },
    {
      bankCodeString: '0060970',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0970',
      branchName: '會計部',
      totalName: '0970 ╴會計部'
    },
    {
      bankCodeString: '0060981',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0981',
      branchName: '東新莊分行',
      totalName: '0981 ╴東新莊分行'
    },
    {
      bankCodeString: '0060992',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '0992',
      branchName: '蘆洲分行',
      totalName: '0992 ╴蘆洲分行'
    },
    {
      bankCodeString: '0061003',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1003',
      branchName: '前金分行',
      totalName: '1003 ╴前金分行'
    },
    {
      bankCodeString: '0061014',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1014',
      branchName: '成大分行',
      totalName: '1014 ╴成大分行'
    },
    {
      bankCodeString: '0061025',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1025',
      branchName: '大里分行',
      totalName: '1025 ╴大里分行'
    },
    {
      bankCodeString: '0061036',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1036',
      branchName: '海山分行',
      totalName: '1036 ╴海山分行'
    },
    {
      bankCodeString: '0061047',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1047',
      branchName: '信託部',
      totalName: '1047 ╴信託部'
    },
    {
      bankCodeString: '0061058',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1058',
      branchName: '國外部',
      totalName: '1058 ╴國外部'
    },
    {
      bankCodeString: '0061069',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1069',
      branchName: '國際金融業務分行',
      totalName: '1069 ╴國際金融業務分行'
    },
    {
      bankCodeString: '0061070',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1070',
      branchName: '南台中分行',
      totalName: '1070 ╴南台中分行'
    },
    {
      bankCodeString: '0061081',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1081',
      branchName: '埔墘分行',
      totalName: '1081 ╴埔墘分行'
    },
    {
      bankCodeString: '0061092',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1092',
      branchName: '慈文分行',
      totalName: '1092 ╴慈文分行'
    },
    {
      bankCodeString: '0061106',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1106',
      branchName: '北寧分行',
      totalName: '1106 ╴北寧分行'
    },
    {
      bankCodeString: '0061117',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1117',
      branchName: '迴龍分行',
      totalName: '1117 ╴迴龍分行'
    },
    {
      bankCodeString: '0061128',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1128',
      branchName: '太平分行',
      totalName: '1128 ╴太平分行'
    },
    {
      bankCodeString: '0061139',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1139',
      branchName: '彰營分行',
      totalName: '1139 ╴彰營分行'
    },
    {
      bankCodeString: '0061140',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1140',
      branchName: '彰儲分行',
      totalName: '1140 ╴彰儲分行'
    },
    {
      bankCodeString: '0061146',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1146',
      branchName: '財務部',
      totalName: '1146 ╴財務部'
    },
    {
      bankCodeString: '0061151',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1151',
      branchName: '虎尾分行',
      totalName: '1151 ╴虎尾分行'
    },
    {
      bankCodeString: '0061162',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1162',
      branchName: '南屯分行',
      totalName: '1162 ╴南屯分行'
    },
    {
      bankCodeString: '0061173',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1173',
      branchName: '西台中分行',
      totalName: '1173 ╴西台中分行'
    },
    {
      bankCodeString: '0061184',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1184',
      branchName: '溪湖分行',
      totalName: '1184 ╴溪湖分行'
    },
    {
      bankCodeString: '0061195',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1195',
      branchName: '烏日分行',
      totalName: '1195 ╴烏日分行'
    },
    {
      bankCodeString: '0061209',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1209',
      branchName: '和美分行',
      totalName: '1209 ╴和美分行'
    },
    {
      bankCodeString: '0061210',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1210',
      branchName: '南桃園分行',
      totalName: '1210 ╴南桃園分行'
    },
    {
      bankCodeString: '0061221',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1221',
      branchName: '屏南分行',
      totalName: '1221 ╴屏南分行'
    },
    {
      bankCodeString: '0061232',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1232',
      branchName: '東台南分行',
      totalName: '1232 ╴東台南分行'
    },
    {
      bankCodeString: '0061243',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1243',
      branchName: '北新竹分行',
      totalName: '1243 ╴北新竹分行'
    },
    {
      bankCodeString: '0061254',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1254',
      branchName: '復旦分行',
      totalName: '1254 ╴復旦分行'
    },
    {
      bankCodeString: '0061265',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1265',
      branchName: '竹山分行',
      totalName: '1265 ╴竹山分行'
    },
    {
      bankCodeString: '0061276',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1276',
      branchName: '前鎮分行',
      totalName: '1276 ╴前鎮分行'
    },
    {
      bankCodeString: '0061287',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1287',
      branchName: '灣內分行',
      totalName: '1287 ╴灣內分行'
    },
    {
      bankCodeString: '0061298',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1298',
      branchName: '路竹分行',
      totalName: '1298 ╴路竹分行'
    },
    {
      bankCodeString: '0061302',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1302',
      branchName: '憲德分行',
      totalName: '1302 ╴憲德分行'
    },
    {
      bankCodeString: '0061313',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1313',
      branchName: '竹北分行',
      totalName: '1313 ╴竹北分行'
    },
    {
      bankCodeString: '0061324',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1324',
      branchName: '北新分行',
      totalName: '1324 ╴北新分行'
    },
    {
      bankCodeString: '0061335',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1335',
      branchName: '五洲分行',
      totalName: '1335 ╴五洲分行'
    },
    {
      bankCodeString: '0061346',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1346',
      branchName: '台大分行',
      totalName: '1346 ╴台大分行'
    },
    {
      bankCodeString: '0061368',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1368',
      branchName: '龜山分行',
      totalName: '1368 ╴龜山分行'
    },
    {
      bankCodeString: '0061379',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1379',
      branchName: '大溪分行',
      totalName: '1379 ╴大溪分行'
    },
    {
      bankCodeString: '0061380',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1380',
      branchName: '龍潭分行',
      totalName: '1380 ╴龍潭分行'
    },
    {
      bankCodeString: '0061391',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1391',
      branchName: '中原分行',
      totalName: '1391 ╴中原分行'
    },
    {
      bankCodeString: '0061405',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1405',
      branchName: '三興分行',
      totalName: '1405 ╴三興分行'
    },
    {
      bankCodeString: '0061427',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1427',
      branchName: '石牌分行',
      totalName: '1427 ╴石牌分行'
    },
    {
      bankCodeString: '0061438',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1438',
      branchName: '西屯分行',
      totalName: '1438 ╴西屯分行'
    },
    {
      bankCodeString: '0061449',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1449',
      branchName: '雙和分行',
      totalName: '1449 ╴雙和分行'
    },
    {
      bankCodeString: '0061450',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1450',
      branchName: '土城分行',
      totalName: '1450 ╴土城分行'
    },
    {
      bankCodeString: '0061461',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1461',
      branchName: '光華分行',
      totalName: '1461 ╴光華分行'
    },
    {
      bankCodeString: '0061472',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1472',
      branchName: '北台南分行',
      totalName: '1472 ╴北台南分行'
    },
    {
      bankCodeString: '0061483',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1483',
      branchName: '興鳳分行',
      totalName: '1483 ╴興鳳分行'
    },
    {
      bankCodeString: '0061494',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1494',
      branchName: '北屯分行',
      totalName: '1494 ╴北屯分行'
    },
    {
      bankCodeString: '0061508',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1508',
      branchName: '一心路分行',
      totalName: '1508 ╴一心路分行'
    },
    {
      bankCodeString: '0061519',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1519',
      branchName: '三峽分行',
      totalName: '1519 ╴三峽分行'
    },
    {
      bankCodeString: '0061520',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1520',
      branchName: '北嘉義分行',
      totalName: '1520 ╴北嘉義分行'
    },
    {
      bankCodeString: '0061531',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1531',
      branchName: '永康分行',
      totalName: '1531 ╴永康分行'
    },
    {
      bankCodeString: '0061542',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1542',
      branchName: '平鎮分行',
      totalName: '1542 ╴平鎮分行'
    },
    {
      bankCodeString: '0061553',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1553',
      branchName: '大發分行',
      totalName: '1553 ╴大發分行'
    },
    {
      bankCodeString: '0061564',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1564',
      branchName: '內湖分行',
      totalName: '1564 ╴內湖分行'
    },
    {
      bankCodeString: '0061575',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1575',
      branchName: '光復分行',
      totalName: '1575 ╴光復分行'
    },
    {
      bankCodeString: '0061586',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1586',
      branchName: '二重分行',
      totalName: '1586 ╴二重分行'
    },
    {
      bankCodeString: '0061601',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1601',
      branchName: '北台中分行',
      totalName: '1601 ╴北台中分行'
    },
    {
      bankCodeString: '0061656',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1656',
      branchName: '軍功分行',
      totalName: '1656 ╴軍功分行'
    },
    {
      bankCodeString: '0061715',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1715',
      branchName: '永安分行',
      totalName: '1715 ╴永安分行'
    },
    {
      bankCodeString: '0061726',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1726',
      branchName: '中權分行',
      totalName: '1726 ╴中權分行'
    },
    {
      bankCodeString: '0061759',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1759',
      branchName: '太原分行',
      totalName: '1759 ╴太原分行'
    },
    {
      bankCodeString: '0061760',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1760',
      branchName: '松竹分行',
      totalName: '1760 ╴松竹分行'
    },
    {
      bankCodeString: '0061852',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1852',
      branchName: '中清分行',
      totalName: '1852 ╴中清分行'
    },
    {
      bankCodeString: '0061885',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1885',
      branchName: '建成分行',
      totalName: '1885 ╴建成分行'
    },
    {
      bankCodeString: '0061900',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1900',
      branchName: '朝馬分行',
      totalName: '1900 ╴朝馬分行'
    },
    {
      bankCodeString: '0061966',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1966',
      branchName: '東台中分行',
      totalName: '1966 ╴東台中分行'
    },
    {
      bankCodeString: '0061988',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '1988',
      branchName: '美村分行',
      totalName: '1988 ╴美村分行'
    },
    {
      bankCodeString: '0062000',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '2000',
      branchName: '黎明分行',
      totalName: '2000 ╴黎明分行'
    },
    {
      bankCodeString: '0062033',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '2033',
      branchName: '昌平分行',
      totalName: '2033 ╴昌平分行'
    },
    {
      bankCodeString: '0062044',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '2044',
      branchName: '精武分行',
      totalName: '2044 ╴精武分行'
    },
    {
      bankCodeString: '0062055',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '2055',
      branchName: '文心分行',
      totalName: '2055 ╴文心分行'
    },
    {
      bankCodeString: '0062077',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '2077',
      branchName: '神岡分行',
      totalName: '2077 ╴神岡分行'
    },
    {
      bankCodeString: '0063030',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '3030',
      branchName: '大竹分行',
      totalName: '3030 ╴大竹分行'
    },
    {
      bankCodeString: '0063074',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '3074',
      branchName: '林內分行',
      totalName: '3074 ╴林內分行'
    },
    {
      bankCodeString: '0063100',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '3100',
      branchName: '南港分行',
      totalName: '3100 ╴南港分行'
    },
    {
      bankCodeString: '0063111',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '3111',
      branchName: '伸港分行',
      totalName: '3111 ╴伸港分行'
    },
    {
      bankCodeString: '0063122',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '3122',
      branchName: '五股分行',
      totalName: '3122 ╴五股分行'
    },
    {
      bankCodeString: '0063133',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '3133',
      branchName: '樹林分行',
      totalName: '3133 ╴樹林分行'
    },
    {
      bankCodeString: '0063155',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '3155',
      branchName: '林口分行',
      totalName: '3155 ╴林口分行'
    },
    {
      bankCodeString: '0063166',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '3166',
      branchName: '左營分行',
      totalName: '3166 ╴左營分行'
    },
    {
      bankCodeString: '0063177',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '3177',
      branchName: '泰山分行',
      totalName: '3177 ╴泰山分行'
    },
    {
      bankCodeString: '0063199',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '3199',
      branchName: '大坪林分行',
      totalName: '3199 ╴大坪林分行'
    },
    {
      bankCodeString: '0063373',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '3373',
      branchName: '鶯歌分行',
      totalName: '3373 ╴鶯歌分行'
    },
    {
      bankCodeString: '0063395',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '3395',
      branchName: '鹿港分行',
      totalName: '3395 ╴鹿港分行'
    },
    {
      bankCodeString: '0063409',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '3409',
      branchName: '新樹分行',
      totalName: '3409 ╴新樹分行'
    },
    {
      bankCodeString: '0063410',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '3410',
      branchName: '六家分行',
      totalName: '3410 ╴六家分行'
    },
    {
      bankCodeString: '0063421',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '3421',
      branchName: '大雅分行',
      totalName: '3421 ╴大雅分行'
    },
    {
      bankCodeString: '0063443',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '3443',
      branchName: '八德分行',
      totalName: '3443 ╴八德分行'
    },
    {
      bankCodeString: '0063465',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '3465',
      branchName: '鼓山分行',
      totalName: '3465 ╴鼓山分行'
    },
    {
      bankCodeString: '0063476',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '3476',
      branchName: '大社分行',
      totalName: '3476 ╴大社分行'
    },
    {
      bankCodeString: '0063498',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '3498',
      branchName: '逢甲分行',
      totalName: '3498 ╴逢甲分行'
    },
    {
      bankCodeString: '0063502',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '3502',
      branchName: '立德分行',
      totalName: '3502 ╴立德分行'
    },
    {
      bankCodeString: '0063513',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '3513',
      branchName: '林園分行',
      totalName: '3513 ╴林園分行'
    },
    {
      bankCodeString: '0063524',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '3524',
      branchName: '高雄科技園區分行',
      totalName: '3524 ╴高雄科技園區分行'
    },
    {
      bankCodeString: '0063535',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '3535',
      branchName: '蘆竹分行',
      totalName: '3535 ╴蘆竹分行'
    },
    {
      bankCodeString: '0063546',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '3546',
      branchName: '北中和分行',
      totalName: '3546 ╴北中和分行'
    },
    {
      bankCodeString: '0063557',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '3557',
      branchName: '新湖分行',
      totalName: '3557 ╴新湖分行'
    },
    {
      bankCodeString: '0063568',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '3568',
      branchName: '丹鳳分行',
      totalName: '3568 ╴丹鳳分行'
    },
    {
      bankCodeString: '0063580',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '3580',
      branchName: '旗山分行',
      totalName: '3580 ╴旗山分行'
    },
    {
      bankCodeString: '0063591',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '3591',
      branchName: '大園分行',
      totalName: '3591 ╴大園分行'
    },
    {
      bankCodeString: '0063605',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '3605',
      branchName: '南土城分行',
      totalName: '3605 ╴南土城分行'
    },
    {
      bankCodeString: '0063616',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '3616',
      branchName: '潭子分行',
      totalName: '3616 ╴潭子分行'
    },
    {
      bankCodeString: '0063627',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '3627',
      branchName: '后里分行',
      totalName: '3627 ╴后里分行'
    },
    {
      bankCodeString: '0063638',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '3638',
      branchName: '長庚分行',
      totalName: '3638 ╴長庚分行'
    },
    {
      bankCodeString: '0063649',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '3649',
      branchName: '楠梓分行',
      totalName: '3649 ╴楠梓分行'
    },
    {
      bankCodeString: '0065012',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5012',
      branchName: '自強分行',
      totalName: '5012 ╴自強分行'
    },
    {
      bankCodeString: '0065023',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5023',
      branchName: '中山分行',
      totalName: '5023 ╴中山分行'
    },
    {
      bankCodeString: '0065034',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5034',
      branchName: '敦南分行',
      totalName: '5034 ╴敦南分行'
    },
    {
      bankCodeString: '0065056',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5056',
      branchName: '世貿分行',
      totalName: '5056 ╴世貿分行'
    },
    {
      bankCodeString: '0065078',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5078',
      branchName: '光復南路分行',
      totalName: '5078 ╴光復南路分行'
    },
    {
      bankCodeString: '0065089',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5089',
      branchName: '北士林分行',
      totalName: '5089 ╴北士林分行'
    },
    {
      bankCodeString: '0065090',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5090',
      branchName: '信維分行',
      totalName: '5090 ╴信維分行'
    },
    {
      bankCodeString: '0065104',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5104',
      branchName: '西湖分行',
      totalName: '5104 ╴西湖分行'
    },
    {
      bankCodeString: '0065115',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5115',
      branchName: '大湖分行',
      totalName: '5115 ╴大湖分行'
    },
    {
      bankCodeString: '0065137',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5137',
      branchName: '國醫中心分行',
      totalName: '5137 ╴國醫中心分行'
    },
    {
      bankCodeString: '0065159',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5159',
      branchName: '南門分行',
      totalName: '5159 ╴南門分行'
    },
    {
      bankCodeString: '0065160',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5160',
      branchName: '新中分行',
      totalName: '5160 ╴新中分行'
    },
    {
      bankCodeString: '0065171',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5171',
      branchName: '中港分行',
      totalName: '5171 ╴中港分行'
    },
    {
      bankCodeString: '0065182',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5182',
      branchName: '衛道分行',
      totalName: '5182 ╴衛道分行'
    },
    {
      bankCodeString: '0065193',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5193',
      branchName: '忠明南路分行',
      totalName: '5193 ╴忠明南路分行'
    },
    {
      bankCodeString: '0065207',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5207',
      branchName: '東基隆分行',
      totalName: '5207 ╴東基隆分行'
    },
    {
      bankCodeString: '0065218',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5218',
      branchName: '赤崁分行',
      totalName: '5218 ╴赤崁分行'
    },
    {
      bankCodeString: '0065229',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5229',
      branchName: '府城分行',
      totalName: '5229 ╴府城分行'
    },
    {
      bankCodeString: '0065230',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5230',
      branchName: '開元分行',
      totalName: '5230 ╴開元分行'
    },
    {
      bankCodeString: '0065241',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5241',
      branchName: '港都分行',
      totalName: '5241 ╴港都分行'
    },
    {
      bankCodeString: '0065252',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5252',
      branchName: '七賢分行',
      totalName: '5252 ╴七賢分行'
    },
    {
      bankCodeString: '0065274',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5274',
      branchName: '十全分行',
      totalName: '5274 ╴十全分行'
    },
    {
      bankCodeString: '0065285',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5285',
      branchName: '九如分行',
      totalName: '5285 ╴九如分行'
    },
    {
      bankCodeString: '0065296',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5296',
      branchName: '小港分行',
      totalName: '5296 ╴小港分行'
    },
    {
      bankCodeString: '0065300',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5300',
      branchName: '北三峽分行',
      totalName: '5300 ╴北三峽分行'
    },
    {
      bankCodeString: '0065311',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5311',
      branchName: '板新分行',
      totalName: '5311 ╴板新分行'
    },
    {
      bankCodeString: '0065322',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5322',
      branchName: '南三重分行',
      totalName: '5322 ╴南三重分行'
    },
    {
      bankCodeString: '0065355',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5355',
      branchName: '寶橋分行',
      totalName: '5355 ╴寶橋分行'
    },
    {
      bankCodeString: '0065366',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5366',
      branchName: '新泰分行',
      totalName: '5366 ╴新泰分行'
    },
    {
      bankCodeString: '0065377',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5377',
      branchName: '北土城分行',
      totalName: '5377 ╴北土城分行'
    },
    {
      bankCodeString: '0065388',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5388',
      branchName: '南汐止分行',
      totalName: '5388 ╴南汐止分行'
    },
    {
      bankCodeString: '0065399',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5399',
      branchName: '北樹林分行',
      totalName: '5399 ╴北樹林分行'
    },
    {
      bankCodeString: '0065403',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5403',
      branchName: '五股工業區分行',
      totalName: '5403 ╴五股工業區分行'
    },
    {
      bankCodeString: '0065414',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5414',
      branchName: '北羅東分行',
      totalName: '5414 ╴北羅東分行'
    },
    {
      bankCodeString: '0065425',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5425',
      branchName: '礁溪分行',
      totalName: '5425 ╴礁溪分行'
    },
    {
      bankCodeString: '0065436',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5436',
      branchName: '東桃園分行',
      totalName: '5436 ╴東桃園分行'
    },
    {
      bankCodeString: '0065447',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5447',
      branchName: '壢新分行',
      totalName: '5447 ╴壢新分行'
    },
    {
      bankCodeString: '0065458',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5458',
      branchName: '南崁分行',
      totalName: '5458 ╴南崁分行'
    },
    {
      bankCodeString: '0065470',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5470',
      branchName: '新明分行',
      totalName: '5470 ╴新明分行'
    },
    {
      bankCodeString: '0065481',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5481',
      branchName: '楊梅分行',
      totalName: '5481 ╴楊梅分行'
    },
    {
      bankCodeString: '0065492',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5492',
      branchName: '東台東分行',
      totalName: '5492 ╴東台東分行'
    },
    {
      bankCodeString: '0065517',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5517',
      branchName: '東竹北分行',
      totalName: '5517 ╴東竹北分行'
    },
    {
      bankCodeString: '0065528',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5528',
      branchName: '北苗栗分行',
      totalName: '5528 ╴北苗栗分行'
    },
    {
      bankCodeString: '0065539',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5539',
      branchName: '竹南分行',
      totalName: '5539 ╴竹南分行'
    },
    {
      bankCodeString: '0065540',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5540',
      branchName: '豐中分行',
      totalName: '5540 ╴豐中分行'
    },
    {
      bankCodeString: '0065551',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5551',
      branchName: '北大里分行',
      totalName: '5551 ╴北大里分行'
    },
    {
      bankCodeString: '0065562',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5562',
      branchName: '東沙鹿分行',
      totalName: '5562 ╴東沙鹿分行'
    },
    {
      bankCodeString: '0065573',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5573',
      branchName: '草屯分行',
      totalName: '5573 ╴草屯分行'
    },
    {
      bankCodeString: '0065584',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5584',
      branchName: '集集分行',
      totalName: '5584 ╴集集分行'
    },
    {
      bankCodeString: '0065595',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5595',
      branchName: '東埔里分行',
      totalName: '5595 ╴東埔里分行'
    },
    {
      bankCodeString: '0065609',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5609',
      branchName: '員新分行',
      totalName: '5609 ╴員新分行'
    },
    {
      bankCodeString: '0065610',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5610',
      branchName: '南彰化分行',
      totalName: '5610 ╴南彰化分行'
    },
    {
      bankCodeString: '0065621',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5621',
      branchName: '北斗分行',
      totalName: '5621 ╴北斗分行'
    },
    {
      bankCodeString: '0065632',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5632',
      branchName: '竹塹分行',
      totalName: '5632 ╴竹塹分行'
    },
    {
      bankCodeString: '0065643',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5643',
      branchName: '新竹科學園區分行',
      totalName: '5643 ╴新竹科學園區分行'
    },
    {
      bankCodeString: '0065665',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5665',
      branchName: '雲林分行',
      totalName: '5665 ╴雲林分行'
    },
    {
      bankCodeString: '0065687',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5687',
      branchName: '北朴子分行',
      totalName: '5687 ╴北朴子分行'
    },
    {
      bankCodeString: '0065698',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5698',
      branchName: '北新營分行',
      totalName: '5698 ╴北新營分行'
    },
    {
      bankCodeString: '0065702',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5702',
      branchName: '仁德分行',
      totalName: '5702 ╴仁德分行'
    },
    {
      bankCodeString: '0065713',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5713',
      branchName: '南永康分行',
      totalName: '5713 ╴南永康分行'
    },
    {
      bankCodeString: '0065735',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5735',
      branchName: '鳳松分行',
      totalName: '5735 ╴鳳松分行'
    },
    {
      bankCodeString: '0065746',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5746',
      branchName: '北岡山分行',
      totalName: '5746 ╴北岡山分行'
    },
    {
      bankCodeString: '0065757',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5757',
      branchName: '美濃分行',
      totalName: '5757 ╴美濃分行'
    },
    {
      bankCodeString: '0065768',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5768',
      branchName: '大樹分行',
      totalName: '5768 ╴大樹分行'
    },
    {
      bankCodeString: '0065816',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5816',
      branchName: '東港分行',
      totalName: '5816 ╴東港分行'
    },
    {
      bankCodeString: '0065827',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5827',
      branchName: '萬丹分行',
      totalName: '5827 ╴萬丹分行'
    },
    {
      bankCodeString: '0065838',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5838',
      branchName: '枋寮分行',
      totalName: '5838 ╴枋寮分行'
    },
    {
      bankCodeString: '0065849',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5849',
      branchName: '東嘉義分行',
      totalName: '5849 ╴東嘉義分行'
    },
    {
      bankCodeString: '0065850',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5850',
      branchName: '北花蓮分行',
      totalName: '5850 ╴北花蓮分行'
    },
    {
      bankCodeString: '0065919',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5919',
      branchName: '大直分行',
      totalName: '5919 ╴大直分行'
    },
    {
      bankCodeString: '0065942',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '5942',
      branchName: '水湳分行',
      totalName: '5942 ╴水湳分行'
    },
    {
      bankCodeString: '0066064',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '6064',
      branchName: '仁美分行',
      totalName: '6064 ╴仁美分行'
    },
    {
      bankCodeString: '0066097',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '6097',
      branchName: '社皮分行',
      totalName: '6097 ╴社皮分行'
    },
    {
      bankCodeString: '0066123',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '6123',
      branchName: '港湖分行',
      totalName: '6123 ╴港湖分行'
    },
    {
      bankCodeString: '0069009',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '9009',
      branchName: '馬尼拉分行',
      totalName: '9009 ╴馬尼拉分行'
    },
    {
      bankCodeString: '0069021',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '9021',
      branchName: '五甲分行',
      totalName: '9021 ╴五甲分行'
    },
    {
      bankCodeString: '0069043',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '9043',
      branchName: '淡水分行',
      totalName: '9043 ╴淡水分行'
    },
    {
      bankCodeString: '0069054',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '9054',
      branchName: '臺灣聯合銀行(UNITED TAIWAN BANK)',
      totalName: '9054 ╴臺灣聯合銀行(UNITED TAIWAN BANK)'
    },
    {
      bankCodeString: '0069065',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '9065',
      branchName: '香港分行',
      totalName: '9065 ╴香港分行'
    },
    {
      bankCodeString: '0069076',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '9076',
      branchName: '北京代表人辦事處',
      totalName: '9076 ╴北京代表人辦事處'
    },
    {
      bankCodeString: '0069087',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '9087',
      branchName: '雪梨分行',
      totalName: '9087 ╴雪梨分行'
    },
    {
      bankCodeString: '0069098',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '9098',
      branchName: '蘇州分行',
      totalName: '9098 ╴蘇州分行'
    },
    {
      bankCodeString: '0069102',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '9102',
      branchName: '蘇州高新支行',
      totalName: '9102 ╴蘇州高新支行'
    },
    {
      bankCodeString: '0069113',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '9113',
      branchName: '天津分行',
      totalName: '9113 ╴天津分行'
    },
    {
      bankCodeString: '0069124',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '9124',
      branchName: '福州分行',
      totalName: '9124 ╴福州分行'
    },
    {
      bankCodeString: '0069135',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '9135',
      branchName: '金邊分行德他拉支行',
      totalName: '9135 ╴金邊分行德他拉支行'
    },
    {
      bankCodeString: '0069146',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '9146',
      branchName: '金邊分行菩森芷支行',
      totalName: '9146 ╴金邊分行菩森芷支行'
    },
    {
      bankCodeString: '0069157',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '9157',
      branchName: '紐約分行',
      totalName: '9157 ╴紐約分行'
    },
    {
      bankCodeString: '0069168',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '9168',
      branchName: '金邊分行暹粒支行',
      totalName: '9168 ╴金邊分行暹粒支行'
    },
    {
      bankCodeString: '0069179',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '9179',
      branchName: '仰光代表人辦事處',
      totalName: '9179 ╴仰光代表人辦事處'
    },
    {
      bankCodeString: '0069180',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '9180',
      branchName: '長沙分行',
      totalName: '9180 ╴長沙分行'
    },
    {
      bankCodeString: '0069191',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '9191',
      branchName: '金邊分行永盛支行',
      totalName: '9191 ╴金邊分行永盛支行'
    },
    {
      bankCodeString: '0069205',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '9205',
      branchName: '金邊分行西哈努克支行',
      totalName: '9205 ╴金邊分行西哈努克支行'
    },
    {
      bankCodeString: '0069216',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '9216',
      branchName: '金邊分行堆谷支行',
      totalName: '9216 ╴金邊分行堆谷支行'
    },
    {
      bankCodeString: '0069227',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '9227',
      branchName: '永珍分行',
      totalName: '9227 ╴永珍分行'
    },
    {
      bankCodeString: '0069238',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '9238',
      branchName: '休士頓分行',
      totalName: '9238 ╴休士頓分行'
    },
    {
      bankCodeString: '0069951',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '9951',
      branchName: '金邊分行市中心支行',
      totalName: '9951 ╴金邊分行市中心支行'
    },
    {
      bankCodeString: '0069962',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '9962',
      branchName: '墨爾本分行',
      totalName: '9962 ╴墨爾本分行'
    },
    {
      bankCodeString: '0069973',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '9973',
      branchName: '金邊分行',
      totalName: '9973 ╴金邊分行'
    },
    {
      bankCodeString: '0069984',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '9984',
      branchName: '洛杉磯分行',
      totalName: '9984 ╴洛杉磯分行'
    },
    {
      bankCodeString: '0069995',
      bankCode: '006',
      bankName: '合作金庫商業銀行',
      branchCode: '9995',
      branchName: '西雅圖分行',
      totalName: '9995 ╴西雅圖分行'
    }
  ],
  '007': [
    {
      bankCodeString: '0070030',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '0030',
      branchName: '董事會',
      totalName: '0030 ╴董事會'
    },
    {
      bankCodeString: '0070050',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '0050',
      branchName: '稽核處',
      totalName: '0050 ╴稽核處'
    },
    {
      bankCodeString: '0070515',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '0515',
      branchName: '台北北區區域中心',
      totalName: '0515 ╴台北北區區域中心'
    },
    {
      bankCodeString: '0070580',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '0580',
      branchName: '票據作業部',
      totalName: '0580 ╴票據作業部'
    },
    {
      bankCodeString: '0070590',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '0590',
      branchName: '後台作業部',
      totalName: '0590 ╴後台作業部'
    },
    {
      bankCodeString: '0070600',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '0600',
      branchName: '覆審及預警管理處',
      totalName: '0600 ╴覆審及預警管理處'
    },
    {
      bankCodeString: '0070610',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '0610',
      branchName: '風險管理處',
      totalName: '0610 ╴風險管理處'
    },
    {
      bankCodeString: '0070620',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '0620',
      branchName: '擔保鑑價處',
      totalName: '0620 ╴擔保鑑價處'
    },
    {
      bankCodeString: '0070640',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '0640',
      branchName: '法人金融業務處',
      totalName: '0640 ╴法人金融業務處'
    },
    {
      bankCodeString: '0070670',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '0670',
      branchName: '消費金融業務處',
      totalName: '0670 ╴消費金融業務處'
    },
    {
      bankCodeString: '0070672',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '0672',
      branchName: '消金商品處',
      totalName: '0672 ╴消金商品處'
    },
    {
      bankCodeString: '0070680',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '0680',
      branchName: '信用卡處',
      totalName: '0680 ╴信用卡處'
    },
    {
      bankCodeString: '0070700',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '0700',
      branchName: '策略行政處',
      totalName: '0700 ╴策略行政處'
    },
    {
      bankCodeString: '0070720',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '0720',
      branchName: '理財業務處',
      totalName: '0720 ╴理財業務處'
    },
    {
      bankCodeString: '0070731',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '0731',
      branchName: '信託處',
      totalName: '0731 ╴信託處'
    },
    {
      bankCodeString: '0070740',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '0740',
      branchName: '中小企業審核處',
      totalName: '0740 ╴中小企業審核處'
    },
    {
      bankCodeString: '0070750',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '0750',
      branchName: '兼營證券商',
      totalName: '0750 ╴兼營證券商'
    },
    {
      bankCodeString: '0070753',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '0753',
      branchName: '營運規劃處',
      totalName: '0753 ╴營運規劃處'
    },
    {
      bankCodeString: '0070764',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '0764',
      branchName: '企金審核處',
      totalName: '0764 ╴企金審核處'
    },
    {
      bankCodeString: '0070797',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '0797',
      branchName: '資訊中心',
      totalName: '0797 ╴資訊中心'
    },
    {
      bankCodeString: '0070801',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '0801',
      branchName: '債權管理處',
      totalName: '0801 ╴債權管理處'
    },
    {
      bankCodeString: '0070812',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '0812',
      branchName: '徵信處',
      totalName: '0812 ╴徵信處'
    },
    {
      bankCodeString: '0070840',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '0840',
      branchName: '行政管理處',
      totalName: '0840 ╴行政管理處'
    },
    {
      bankCodeString: '0070856',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '0856',
      branchName: '總務處',
      totalName: '0856 ╴總務處'
    },
    {
      bankCodeString: '0070878',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '0878',
      branchName: '會計處',
      totalName: '0878 ╴會計處'
    },
    {
      bankCodeString: '0070889',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '0889',
      branchName: '安全處',
      totalName: '0889 ╴安全處'
    },
    {
      bankCodeString: '0070915',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '0915',
      branchName: '外匯營運處',
      totalName: '0915 ╴外匯營運處'
    },
    {
      bankCodeString: '0070937',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '0937',
      branchName: '營業部',
      totalName: '0937 ╴營業部'
    },
    {
      bankCodeString: '0070948',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '0948',
      branchName: '安和分行',
      totalName: '0948 ╴安和分行'
    },
    {
      bankCodeString: '0070959',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '0959',
      branchName: '國際金融業務分行',
      totalName: '0959 ╴國際金融業務分行'
    },
    {
      bankCodeString: '0070960',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '0960',
      branchName: '資金交易處',
      totalName: '0960 ╴資金交易處'
    },
    {
      bankCodeString: '0070970',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '0970',
      branchName: '資金管理處',
      totalName: '0970 ╴資金管理處'
    },
    {
      bankCodeString: '0070980',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '0980',
      branchName: '法人金融行銷處',
      totalName: '0980 ╴法人金融行銷處'
    },
    {
      bankCodeString: '0070982',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '0982',
      branchName: '交割作業部',
      totalName: '0982 ╴交割作業部'
    },
    {
      bankCodeString: '0070990',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '0990',
      branchName: '金融交易商品處',
      totalName: '0990 ╴金融交易商品處'
    },
    {
      bankCodeString: '0071015',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1015',
      branchName: '南港分行',
      totalName: '1015 ╴南港分行'
    },
    {
      bankCodeString: '0071026',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1026',
      branchName: '西門分行',
      totalName: '1026 ╴西門分行'
    },
    {
      bankCodeString: '0071037',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1037',
      branchName: '忠孝路分行',
      totalName: '1037 ╴忠孝路分行'
    },
    {
      bankCodeString: '0071059',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1059',
      branchName: '東湖分行',
      totalName: '1059 ╴東湖分行'
    },
    {
      bankCodeString: '0071060',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1060',
      branchName: '景美分行',
      totalName: '1060 ╴景美分行'
    },
    {
      bankCodeString: '0071071',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1071',
      branchName: '大直分行',
      totalName: '1071 ╴大直分行'
    },
    {
      bankCodeString: '0071118',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1118',
      branchName: '大稻埕分行',
      totalName: '1118 ╴大稻埕分行'
    },
    {
      bankCodeString: '0071130',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1130',
      branchName: '信維分行',
      totalName: '1130 ╴信維分行'
    },
    {
      bankCodeString: '0071211',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1211',
      branchName: '建成分行',
      totalName: '1211 ╴建成分行'
    },
    {
      bankCodeString: '0071299',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1299',
      branchName: '華山分行',
      totalName: '1299 ╴華山分行'
    },
    {
      bankCodeString: '0071314',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1314',
      branchName: '大同分行',
      totalName: '1314 ╴大同分行'
    },
    {
      bankCodeString: '0071336',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1336',
      branchName: '新生分行',
      totalName: '1336 ╴新生分行'
    },
    {
      bankCodeString: '0071358',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1358',
      branchName: '劍潭分行',
      totalName: '1358 ╴劍潭分行'
    },
    {
      bankCodeString: '0071406',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1406',
      branchName: '圓山分行',
      totalName: '1406 ╴圓山分行'
    },
    {
      bankCodeString: '0071417',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1417',
      branchName: '中山分行',
      totalName: '1417 ╴中山分行'
    },
    {
      bankCodeString: '0071428',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1428',
      branchName: '中崙分行',
      totalName: '1428 ╴中崙分行'
    },
    {
      bankCodeString: '0071439',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1439',
      branchName: '南京東路分行',
      totalName: '1439 ╴南京東路分行'
    },
    {
      bankCodeString: '0071440',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1440',
      branchName: '城東分行',
      totalName: '1440 ╴城東分行'
    },
    {
      bankCodeString: '0071451',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1451',
      branchName: '民生分行',
      totalName: '1451 ╴民生分行'
    },
    {
      bankCodeString: '0071462',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1462',
      branchName: '松江分行',
      totalName: '1462 ╴松江分行'
    },
    {
      bankCodeString: '0071473',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1473',
      branchName: '民權分行',
      totalName: '1473 ╴民權分行'
    },
    {
      bankCodeString: '0071484',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1484',
      branchName: '八德分行',
      totalName: '1484 ╴八德分行'
    },
    {
      bankCodeString: '0071495',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1495',
      branchName: '長春分行',
      totalName: '1495 ╴長春分行'
    },
    {
      bankCodeString: '0071509',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1509',
      branchName: '內湖分行',
      totalName: '1509 ╴內湖分行'
    },
    {
      bankCodeString: '0071510',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1510',
      branchName: '松山分行',
      totalName: '1510 ╴松山分行'
    },
    {
      bankCodeString: '0071521',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1521',
      branchName: '延吉分行',
      totalName: '1521 ╴延吉分行'
    },
    {
      bankCodeString: '0071532',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1532',
      branchName: '光復分行',
      totalName: '1532 ╴光復分行'
    },
    {
      bankCodeString: '0071554',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1554',
      branchName: '興雅分行',
      totalName: '1554 ╴興雅分行'
    },
    {
      bankCodeString: '0071576',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1576',
      branchName: '永春分行',
      totalName: '1576 ╴永春分行'
    },
    {
      bankCodeString: '0071587',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1587',
      branchName: '內科園區分行',
      totalName: '1587 ╴內科園區分行'
    },
    {
      bankCodeString: '0071598',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1598',
      branchName: '吉林分行',
      totalName: '1598 ╴吉林分行'
    },
    {
      bankCodeString: '0071602',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1602',
      branchName: '仁愛分行',
      totalName: '1602 ╴仁愛分行'
    },
    {
      bankCodeString: '0071613',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1613',
      branchName: '大安分行',
      totalName: '1613 ╴大安分行'
    },
    {
      bankCodeString: '0071624',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1624',
      branchName: '信義分行',
      totalName: '1624 ╴信義分行'
    },
    {
      bankCodeString: '0071635',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1635',
      branchName: '復興分行',
      totalName: '1635 ╴復興分行'
    },
    {
      bankCodeString: '0071646',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1646',
      branchName: '敦化分行',
      totalName: '1646 ╴敦化分行'
    },
    {
      bankCodeString: '0071657',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1657',
      branchName: '仁和分行',
      totalName: '1657 ╴仁和分行'
    },
    {
      bankCodeString: '0071668',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1668',
      branchName: '世貿分行',
      totalName: '1668 ╴世貿分行'
    },
    {
      bankCodeString: '0071679',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1679',
      branchName: '木柵分行',
      totalName: '1679 ╴木柵分行'
    },
    {
      bankCodeString: '0071680',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1680',
      branchName: '松貿分行',
      totalName: '1680 ╴松貿分行'
    },
    {
      bankCodeString: '0071691',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1691',
      branchName: '新湖分行',
      totalName: '1691 ╴新湖分行'
    },
    {
      bankCodeString: '0071716',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1716',
      branchName: '古亭分行',
      totalName: '1716 ╴古亭分行'
    },
    {
      bankCodeString: '0071727',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1727',
      branchName: '南門分行',
      totalName: '1727 ╴南門分行'
    },
    {
      bankCodeString: '0071738',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1738',
      branchName: '公館分行',
      totalName: '1738 ╴公館分行'
    },
    {
      bankCodeString: '0071761',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1761',
      branchName: '和平分行',
      totalName: '1761 ╴和平分行'
    },
    {
      bankCodeString: '0071819',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1819',
      branchName: '萬華分行',
      totalName: '1819 ╴萬華分行'
    },
    {
      bankCodeString: '0071831',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1831',
      branchName: '雙園分行',
      totalName: '1831 ╴雙園分行'
    },
    {
      bankCodeString: '0071901',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1901',
      branchName: '天母分行',
      totalName: '1901 ╴天母分行'
    },
    {
      bankCodeString: '0071912',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1912',
      branchName: '北投分行',
      totalName: '1912 ╴北投分行'
    },
    {
      bankCodeString: '0071923',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1923',
      branchName: '士林分行',
      totalName: '1923 ╴士林分行'
    },
    {
      bankCodeString: '0071934',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1934',
      branchName: '建國分行',
      totalName: '1934 ╴建國分行'
    },
    {
      bankCodeString: '0071956',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '1956',
      branchName: '石牌分行',
      totalName: '1956 ╴石牌分行'
    },
    {
      bankCodeString: '0072012',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2012',
      branchName: '板橋分行',
      totalName: '2012 ╴板橋分行'
    },
    {
      bankCodeString: '0072023',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2023',
      branchName: '華江分行',
      totalName: '2023 ╴華江分行'
    },
    {
      bankCodeString: '0072034',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2034',
      branchName: '樹林分行',
      totalName: '2034 ╴樹林分行'
    },
    {
      bankCodeString: '0072056',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2056',
      branchName: '土城分行',
      totalName: '2056 ╴土城分行'
    },
    {
      bankCodeString: '0072067',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2067',
      branchName: '江子翠分行',
      totalName: '2067 ╴江子翠分行'
    },
    {
      bankCodeString: '0072078',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2078',
      branchName: '北土城分行',
      totalName: '2078 ╴北土城分行'
    },
    {
      bankCodeString: '0072089',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2089',
      branchName: '林口工二分行',
      totalName: '2089 ╴林口工二分行'
    },
    {
      bankCodeString: '0072115',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2115',
      branchName: '三重埔分行',
      totalName: '2115 ╴三重埔分行'
    },
    {
      bankCodeString: '0072126',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2126',
      branchName: '長泰分行',
      totalName: '2126 ╴長泰分行'
    },
    {
      bankCodeString: '0072137',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2137',
      branchName: '蘆洲分行',
      totalName: '2137 ╴蘆洲分行'
    },
    {
      bankCodeString: '0072148',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2148',
      branchName: '頭前分行',
      totalName: '2148 ╴頭前分行'
    },
    {
      bankCodeString: '0072159',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2159',
      branchName: '五股分行',
      totalName: '2159 ╴五股分行'
    },
    {
      bankCodeString: '0072160',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2160',
      branchName: '重陽分行',
      totalName: '2160 ╴重陽分行'
    },
    {
      bankCodeString: '0072171',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2171',
      branchName: '五股工業區分行',
      totalName: '2171 ╴五股工業區分行'
    },
    {
      bankCodeString: '0072182',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2182',
      branchName: '淡水分行',
      totalName: '2182 ╴淡水分行'
    },
    {
      bankCodeString: '0072218',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2218',
      branchName: '新店分行',
      totalName: '2218 ╴新店分行'
    },
    {
      bankCodeString: '0072229',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2229',
      branchName: '大坪林分行',
      totalName: '2229 ╴大坪林分行'
    },
    {
      bankCodeString: '0072300',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2300',
      branchName: '泰山分行',
      totalName: '2300 ╴泰山分行'
    },
    {
      bankCodeString: '0072311',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2311',
      branchName: '新莊分行',
      totalName: '2311 ╴新莊分行'
    },
    {
      bankCodeString: '0072322',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2322',
      branchName: '鶯歌分行',
      totalName: '2322 ╴鶯歌分行'
    },
    {
      bankCodeString: '0072333',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2333',
      branchName: '中和分行',
      totalName: '2333 ╴中和分行'
    },
    {
      bankCodeString: '0072344',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2344',
      branchName: '永和分行',
      totalName: '2344 ╴永和分行'
    },
    {
      bankCodeString: '0072355',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2355',
      branchName: '雙和分行',
      totalName: '2355 ╴雙和分行'
    },
    {
      bankCodeString: '0072366',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2366',
      branchName: '連城分行',
      totalName: '2366 ╴連城分行'
    },
    {
      bankCodeString: '0072377',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2377',
      branchName: '瑞芳分行',
      totalName: '2377 ╴瑞芳分行'
    },
    {
      bankCodeString: '0072388',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2388',
      branchName: '埔墘分行',
      totalName: '2388 ╴埔墘分行'
    },
    {
      bankCodeString: '0072399',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2399',
      branchName: '丹鳳分行',
      totalName: '2399 ╴丹鳳分行'
    },
    {
      bankCodeString: '0072414',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2414',
      branchName: '基隆分行',
      totalName: '2414 ╴基隆分行'
    },
    {
      bankCodeString: '0072436',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2436',
      branchName: '哨船頭分行',
      totalName: '2436 ╴哨船頭分行'
    },
    {
      bankCodeString: '0072458',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2458',
      branchName: '汐止分行',
      totalName: '2458 ╴汐止分行'
    },
    {
      bankCodeString: '0072469',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2469',
      branchName: '汐科分行',
      totalName: '2469 ╴汐科分行'
    },
    {
      bankCodeString: '0072470',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2470',
      branchName: '幸福分行',
      totalName: '2470 ╴幸福分行'
    },
    {
      bankCodeString: '0072517',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2517',
      branchName: '宜蘭分行',
      totalName: '2517 ╴宜蘭分行'
    },
    {
      bankCodeString: '0072610',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2610',
      branchName: '羅東分行',
      totalName: '2610 ╴羅東分行'
    },
    {
      bankCodeString: '0072621',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2621',
      branchName: '蘇澳分行',
      totalName: '2621 ╴蘇澳分行'
    },
    {
      bankCodeString: '0072713',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2713',
      branchName: '桃園分行',
      totalName: '2713 ╴桃園分行'
    },
    {
      bankCodeString: '0072724',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2724',
      branchName: '北桃分行',
      totalName: '2724 ╴北桃分行'
    },
    {
      bankCodeString: '0072791',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2791',
      branchName: '大湳分行',
      totalName: '2791 ╴大湳分行'
    },
    {
      bankCodeString: '0072805',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2805',
      branchName: '內壢分行',
      totalName: '2805 ╴內壢分行'
    },
    {
      bankCodeString: '0072816',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2816',
      branchName: '中壢分行',
      totalName: '2816 ╴中壢分行'
    },
    {
      bankCodeString: '0072827',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2827',
      branchName: '西壢分行',
      totalName: '2827 ╴西壢分行'
    },
    {
      bankCodeString: '0072838',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2838',
      branchName: '平鎮分行',
      totalName: '2838 ╴平鎮分行'
    },
    {
      bankCodeString: '0072849',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2849',
      branchName: '大園分行',
      totalName: '2849 ╴大園分行'
    },
    {
      bankCodeString: '0072850',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2850',
      branchName: '南崁分行',
      totalName: '2850 ╴南崁分行'
    },
    {
      bankCodeString: '0072872',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2872',
      branchName: '迴龍分行',
      totalName: '2872 ╴迴龍分行'
    },
    {
      bankCodeString: '0072908',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2908',
      branchName: '林口分行',
      totalName: '2908 ╴林口分行'
    },
    {
      bankCodeString: '0072919',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2919',
      branchName: '大溪分行',
      totalName: '2919 ╴大溪分行'
    },
    {
      bankCodeString: '0072920',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '2920',
      branchName: '龍潭分行',
      totalName: '2920 ╴龍潭分行'
    },
    {
      bankCodeString: '0073019',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '3019',
      branchName: '新竹分行',
      totalName: '3019 ╴新竹分行'
    },
    {
      bankCodeString: '0073020',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '3020',
      branchName: '東門分行',
      totalName: '3020 ╴東門分行'
    },
    {
      bankCodeString: '0073031',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '3031',
      branchName: '竹科分行',
      totalName: '3031 ╴竹科分行'
    },
    {
      bankCodeString: '0073112',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '3112',
      branchName: '竹東分行',
      totalName: '3112 ╴竹東分行'
    },
    {
      bankCodeString: '0073123',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '3123',
      branchName: '關西分行',
      totalName: '3123 ╴關西分行'
    },
    {
      bankCodeString: '0073134',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '3134',
      branchName: '竹北分行',
      totalName: '3134 ╴竹北分行'
    },
    {
      bankCodeString: '0073215',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '3215',
      branchName: '苗栗分行',
      totalName: '3215 ╴苗栗分行'
    },
    {
      bankCodeString: '0073318',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '3318',
      branchName: '竹南分行',
      totalName: '3318 ╴竹南分行'
    },
    {
      bankCodeString: '0073329',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '3329',
      branchName: '頭份分行',
      totalName: '3329 ╴頭份分行'
    },
    {
      bankCodeString: '0074016',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '4016',
      branchName: '台中分行',
      totalName: '4016 ╴台中分行'
    },
    {
      bankCodeString: '0074027',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '4027',
      branchName: '南台中分行',
      totalName: '4027 ╴南台中分行'
    },
    {
      bankCodeString: '0074038',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '4038',
      branchName: '北台中分行',
      totalName: '4038 ╴北台中分行'
    },
    {
      bankCodeString: '0074049',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '4049',
      branchName: '中港分行',
      totalName: '4049 ╴中港分行'
    },
    {
      bankCodeString: '0074050',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '4050',
      branchName: '北屯分行',
      totalName: '4050 ╴北屯分行'
    },
    {
      bankCodeString: '0074061',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '4061',
      branchName: '進化分行',
      totalName: '4061 ╴進化分行'
    },
    {
      bankCodeString: '0074072',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '4072',
      branchName: '南屯分行',
      totalName: '4072 ╴南屯分行'
    },
    {
      bankCodeString: '0074119',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '4119',
      branchName: '豐原分行',
      totalName: '4119 ╴豐原分行'
    },
    {
      bankCodeString: '0074120',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '4120',
      branchName: '大里分行',
      totalName: '4120 ╴大里分行'
    },
    {
      bankCodeString: '0074153',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '4153',
      branchName: '中科分行',
      totalName: '4153 ╴中科分行'
    },
    {
      bankCodeString: '0074212',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '4212',
      branchName: '東勢分行',
      totalName: '4212 ╴東勢分行'
    },
    {
      bankCodeString: '0074223',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '4223',
      branchName: '沙鹿分行',
      totalName: '4223 ╴沙鹿分行'
    },
    {
      bankCodeString: '0074234',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '4234',
      branchName: '大甲分行',
      totalName: '4234 ╴大甲分行'
    },
    {
      bankCodeString: '0074245',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '4245',
      branchName: '太平分行',
      totalName: '4245 ╴太平分行'
    },
    {
      bankCodeString: '0074256',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '4256',
      branchName: '清水分行',
      totalName: '4256 ╴清水分行'
    },
    {
      bankCodeString: '0074267',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '4267',
      branchName: '大雅分行',
      totalName: '4267 ╴大雅分行'
    },
    {
      bankCodeString: '0074315',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '4315',
      branchName: '南投分行',
      totalName: '4315 ╴南投分行'
    },
    {
      bankCodeString: '0074418',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '4418',
      branchName: '草屯分行',
      totalName: '4418 ╴草屯分行'
    },
    {
      bankCodeString: '0074429',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '4429',
      branchName: '埔里分行',
      totalName: '4429 ╴埔里分行'
    },
    {
      bankCodeString: '0074511',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '4511',
      branchName: '彰化分行',
      totalName: '4511 ╴彰化分行'
    },
    {
      bankCodeString: '0074614',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '4614',
      branchName: '員林分行',
      totalName: '4614 ╴員林分行'
    },
    {
      bankCodeString: '0074625',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '4625',
      branchName: '鹿港分行',
      totalName: '4625 ╴鹿港分行'
    },
    {
      bankCodeString: '0074636',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '4636',
      branchName: '溪湖分行',
      totalName: '4636 ╴溪湖分行'
    },
    {
      bankCodeString: '0074647',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '4647',
      branchName: '北斗分行',
      totalName: '4647 ╴北斗分行'
    },
    {
      bankCodeString: '0074717',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '4717',
      branchName: '和美分行',
      totalName: '4717 ╴和美分行'
    },
    {
      bankCodeString: '0075013',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '5013',
      branchName: '嘉義分行',
      totalName: '5013 ╴嘉義分行'
    },
    {
      bankCodeString: '0075035',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '5035',
      branchName: '興嘉分行',
      totalName: '5035 ╴興嘉分行'
    },
    {
      bankCodeString: '0075116',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '5116',
      branchName: '朴子分行',
      totalName: '5116 ╴朴子分行'
    },
    {
      bankCodeString: '0075219',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '5219',
      branchName: '斗六分行',
      totalName: '5219 ╴斗六分行'
    },
    {
      bankCodeString: '0075312',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '5312',
      branchName: '北港分行',
      totalName: '5312 ╴北港分行'
    },
    {
      bankCodeString: '0075323',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '5323',
      branchName: '西螺分行',
      totalName: '5323 ╴西螺分行'
    },
    {
      bankCodeString: '0075334',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '5334',
      branchName: '虎尾分行',
      totalName: '5334 ╴虎尾分行'
    },
    {
      bankCodeString: '0076010',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '6010',
      branchName: '台南分行',
      totalName: '6010 ╴台南分行'
    },
    {
      bankCodeString: '0076043',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '6043',
      branchName: '富強分行',
      totalName: '6043 ╴富強分行'
    },
    {
      bankCodeString: '0076054',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '6054',
      branchName: '赤崁分行',
      totalName: '6054 ╴赤崁分行'
    },
    {
      bankCodeString: '0076065',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '6065',
      branchName: '竹溪分行',
      totalName: '6065 ╴竹溪分行'
    },
    {
      bankCodeString: '0076076',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '6076',
      branchName: '金城分行',
      totalName: '6076 ╴金城分行'
    },
    {
      bankCodeString: '0076087',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '6087',
      branchName: '安南分行',
      totalName: '6087 ╴安南分行'
    },
    {
      bankCodeString: '0076113',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '6113',
      branchName: '新營分行',
      totalName: '6113 ╴新營分行'
    },
    {
      bankCodeString: '0076216',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '6216',
      branchName: '鹽水分行',
      totalName: '6216 ╴鹽水分行'
    },
    {
      bankCodeString: '0076227',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '6227',
      branchName: '麻豆分行',
      totalName: '6227 ╴麻豆分行'
    },
    {
      bankCodeString: '0076238',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '6238',
      branchName: '善化分行',
      totalName: '6238 ╴善化分行'
    },
    {
      bankCodeString: '0076249',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '6249',
      branchName: '佳里分行',
      totalName: '6249 ╴佳里分行'
    },
    {
      bankCodeString: '0076250',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '6250',
      branchName: '新化分行',
      totalName: '6250 ╴新化分行'
    },
    {
      bankCodeString: '0076261',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '6261',
      branchName: '大灣分行',
      totalName: '6261 ╴大灣分行'
    },
    {
      bankCodeString: '0076283',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '6283',
      branchName: '南科園區分行',
      totalName: '6283 ╴南科園區分行'
    },
    {
      bankCodeString: '0076294',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '6294',
      branchName: '歸仁分行',
      totalName: '6294 ╴歸仁分行'
    },
    {
      bankCodeString: '0076308',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '6308',
      branchName: '永康分行',
      totalName: '6308 ╴永康分行'
    },
    {
      bankCodeString: '0077017',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '7017',
      branchName: '高雄分行',
      totalName: '7017 ╴高雄分行'
    },
    {
      bankCodeString: '0077028',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '7028',
      branchName: '鹽埕分行',
      totalName: '7028 ╴鹽埕分行'
    },
    {
      bankCodeString: '0077039',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '7039',
      branchName: '新興分行',
      totalName: '7039 ╴新興分行'
    },
    {
      bankCodeString: '0077040',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '7040',
      branchName: '三民分行',
      totalName: '7040 ╴三民分行'
    },
    {
      bankCodeString: '0077051',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '7051',
      branchName: '苓雅分行',
      totalName: '7051 ╴苓雅分行'
    },
    {
      bankCodeString: '0077062',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '7062',
      branchName: '左營分行',
      totalName: '7062 ╴左營分行'
    },
    {
      bankCodeString: '0077073',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '7073',
      branchName: '楠梓分行',
      totalName: '7073 ╴楠梓分行'
    },
    {
      bankCodeString: '0077084',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '7084',
      branchName: '五福分行',
      totalName: '7084 ╴五福分行'
    },
    {
      bankCodeString: '0077095',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '7095',
      branchName: '十全分行',
      totalName: '7095 ╴十全分行'
    },
    {
      bankCodeString: '0077109',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '7109',
      branchName: '前鎮分行',
      totalName: '7109 ╴前鎮分行'
    },
    {
      bankCodeString: '0077110',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '7110',
      branchName: '灣內分行',
      totalName: '7110 ╴灣內分行'
    },
    {
      bankCodeString: '0077121',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '7121',
      branchName: '博愛分行',
      totalName: '7121 ╴博愛分行'
    },
    {
      bankCodeString: '0077143',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '7143',
      branchName: '小港分行',
      totalName: '7143 ╴小港分行'
    },
    {
      bankCodeString: '0077202',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '7202',
      branchName: '五甲分行',
      totalName: '7202 ╴五甲分行'
    },
    {
      bankCodeString: '0077213',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '7213',
      branchName: '鳳山分行',
      totalName: '7213 ╴鳳山分行'
    },
    {
      bankCodeString: '0077305',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '7305',
      branchName: '路竹分行',
      totalName: '7305 ╴路竹分行'
    },
    {
      bankCodeString: '0077316',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '7316',
      branchName: '岡山分行',
      totalName: '7316 ╴岡山分行'
    },
    {
      bankCodeString: '0077327',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '7327',
      branchName: '旗山分行',
      totalName: '7327 ╴旗山分行'
    },
    {
      bankCodeString: '0077338',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '7338',
      branchName: '林園分行',
      totalName: '7338 ╴林園分行'
    },
    {
      bankCodeString: '0077419',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '7419',
      branchName: '屏東分行',
      totalName: '7419 ╴屏東分行'
    },
    {
      bankCodeString: '0077512',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '7512',
      branchName: '潮州分行',
      totalName: '7512 ╴潮州分行'
    },
    {
      bankCodeString: '0077523',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '7523',
      branchName: '東港分行',
      totalName: '7523 ╴東港分行'
    },
    {
      bankCodeString: '0077534',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '7534',
      branchName: '恆春分行',
      totalName: '7534 ╴恆春分行'
    },
    {
      bankCodeString: '0077785',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '7785',
      branchName: '梓本分行',
      totalName: '7785 ╴梓本分行'
    },
    {
      bankCodeString: '0077855',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '7855',
      branchName: '萬巒分行',
      totalName: '7855 ╴萬巒分行'
    },
    {
      bankCodeString: '0078014',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '8014',
      branchName: '花蓮分行',
      totalName: '8014 ╴花蓮分行'
    },
    {
      bankCodeString: '0078117',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '8117',
      branchName: '台東分行',
      totalName: '8117 ╴台東分行'
    },
    {
      bankCodeString: '0078210',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '8210',
      branchName: '澎湖分行',
      totalName: '8210 ╴澎湖分行'
    },
    {
      bankCodeString: '0078438',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '8438',
      branchName: '上海自貿試驗區支行',
      totalName: '8438 ╴上海自貿試驗區支行'
    },
    {
      bankCodeString: '0078807',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '8807',
      branchName: '金邊分行新速支行',
      totalName: '8807 ╴金邊分行新速支行'
    },
    {
      bankCodeString: '0078829',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '8829',
      branchName: '金邊分行中洲支行',
      totalName: '8829 ╴金邊分行中洲支行'
    },
    {
      bankCodeString: '0078830',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '8830',
      branchName: '金邊分行奧林匹克支行',
      totalName: '8830 ╴金邊分行奧林匹克支行'
    },
    {
      bankCodeString: '0078841',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '8841',
      branchName: '金邊分行桑園支行',
      totalName: '8841 ╴金邊分行桑園支行'
    },
    {
      bankCodeString: '0078852',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '8852',
      branchName: '金邊分行堆谷支行',
      totalName: '8852 ╴金邊分行堆谷支行'
    },
    {
      bankCodeString: '0078863',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '8863',
      branchName: '金邊分行水淨華支行',
      totalName: '8863 ╴金邊分行水淨華支行'
    },
    {
      bankCodeString: '0078874',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '8874',
      branchName: '金邊分行永盛支行',
      totalName: '8874 ╴金邊分行永盛支行'
    },
    {
      bankCodeString: '0078885',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '8885',
      branchName: '金邊分行暹粒支行',
      totalName: '8885 ╴金邊分行暹粒支行'
    },
    {
      bankCodeString: '0078896',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '8896',
      branchName: '金邊分行登高支行',
      totalName: '8896 ╴金邊分行登高支行'
    },
    {
      bankCodeString: '0079011',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '9011',
      branchName: '關島分行',
      totalName: '9011 ╴關島分行'
    },
    {
      bankCodeString: '0079022',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '9022',
      branchName: '紐約分行',
      totalName: '9022 ╴紐約分行'
    },
    {
      bankCodeString: '0079033',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '9033',
      branchName: '洛杉磯分行',
      totalName: '9033 ╴洛杉磯分行'
    },
    {
      bankCodeString: '0079044',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '9044',
      branchName: '美國第一銀行 First Commercial Bank(USA)',
      totalName: '9044 ╴美國第一銀行 First Commercial Bank(USA)'
    },
    {
      bankCodeString: '0079055',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '9055',
      branchName: 'First Commercial Bank(USA), City of Industry Branch',
      totalName: '9055 ╴First Commercial Bank(USA), City of Industry Branch'
    },
    {
      bankCodeString: '0079066',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '9066',
      branchName: 'First Commercial Bank(USA),Silicon Valley Branch',
      totalName: '9066 ╴First Commercial Bank(USA),Silicon Valley Branch'
    },
    {
      bankCodeString: '0079077',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '9077',
      branchName: 'First Commercial Bank(USA),Irvine Branch',
      totalName: '9077 ╴First Commercial Bank(USA),Irvine Branch'
    },
    {
      bankCodeString: '0079088',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '9088',
      branchName: '溫哥華分行',
      totalName: '9088 ╴溫哥華分行'
    },
    {
      bankCodeString: '0079099',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '9099',
      branchName: '多倫多分行',
      totalName: '9099 ╴多倫多分行'
    },
    {
      bankCodeString: '0079114',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '9114',
      branchName: '新加坡分行',
      totalName: '9114 ╴新加坡分行'
    },
    {
      bankCodeString: '0079136',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '9136',
      branchName: '曼谷代表辦事處',
      totalName: '9136 ╴曼谷代表辦事處'
    },
    {
      bankCodeString: '0079147',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '9147',
      branchName: '河內市分行',
      totalName: '9147 ╴河內市分行'
    },
    {
      bankCodeString: '0079158',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '9158',
      branchName: '仰光代表辦事處',
      totalName: '9158 ╴仰光代表辦事處'
    },
    {
      bankCodeString: '0079181',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '9181',
      branchName: '金邊分行',
      totalName: '9181 ╴金邊分行'
    },
    {
      bankCodeString: '0079206',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '9206',
      branchName: '胡志明市分行',
      totalName: '9206 ╴胡志明市分行'
    },
    {
      bankCodeString: '0079217',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '9217',
      branchName: '倫敦分行',
      totalName: '9217 ╴倫敦分行'
    },
    {
      bankCodeString: '0079239',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '9239',
      branchName: '馬尼拉分行',
      totalName: '9239 ╴馬尼拉分行'
    },
    {
      bankCodeString: '0079273',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '9273',
      branchName: '休士頓分行',
      totalName: '9273 ╴休士頓分行'
    },
    {
      bankCodeString: '0079284',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '9284',
      branchName: '永珍分行',
      totalName: '9284 ╴永珍分行'
    },
    {
      bankCodeString: '0079309',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '9309',
      branchName: 'First Commercial Bank(USA), Fremont Branch',
      totalName: '9309 ╴First Commercial Bank(USA), Fremont Branch'
    },
    {
      bankCodeString: '0079332',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '9332',
      branchName: '雅加達代表人辦事處',
      totalName: '9332 ╴雅加達代表人辦事處'
    },
    {
      bankCodeString: '0079413',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '9413',
      branchName: '香港分行',
      totalName: '9413 ╴香港分行'
    },
    {
      bankCodeString: '0079424',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '9424',
      branchName: '上海分行',
      totalName: '9424 ╴上海分行'
    },
    {
      bankCodeString: '0079457',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '9457',
      branchName: '澳門分行',
      totalName: '9457 ╴澳門分行'
    },
    {
      bankCodeString: '0079468',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '9468',
      branchName: '成都分行',
      totalName: '9468 ╴成都分行'
    },
    {
      bankCodeString: '0079480',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '9480',
      branchName: '廈門分行',
      totalName: '9480 ╴廈門分行'
    },
    {
      bankCodeString: '0079516',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '9516',
      branchName: '東京分行',
      totalName: '9516 ╴東京分行'
    },
    {
      bankCodeString: '0079527',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '9527',
      branchName: '美國第一銀行奇諾崗分行',
      totalName: '9527 ╴美國第一銀行奇諾崗分行'
    },
    {
      bankCodeString: '0079620',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '9620',
      branchName: '布里斯本分行',
      totalName: '9620 ╴布里斯本分行'
    },
    {
      bankCodeString: '0079664',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '9664',
      branchName: '美國第一銀行聖馬特奧分行',
      totalName: '9664 ╴美國第一銀行聖馬特奧分行'
    },
    {
      bankCodeString: '0079996',
      bankCode: '007',
      bankName: '第一商業銀行',
      branchCode: '9996',
      branchName: 'First Commercial Bank(USA), Arcadia Branch',
      totalName: '9996 ╴First Commercial Bank(USA), Arcadia Branch'
    }
  ],
  '008': [
    {
      bankCodeString: '0080069',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '0069',
      branchName: '信託部',
      totalName: '0069 ╴信託部'
    },
    {
      bankCodeString: '0080514',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '0514',
      branchName: '董事會',
      totalName: '0514 ╴董事會'
    },
    {
      bankCodeString: '0080558',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '0558',
      branchName: '行政管理部',
      totalName: '0558 ╴行政管理部'
    },
    {
      bankCodeString: '0080569',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '0569',
      branchName: '經營企劃部',
      totalName: '0569 ╴經營企劃部'
    },
    {
      bankCodeString: '0080570',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '0570',
      branchName: '營運管理部',
      totalName: '0570 ╴營運管理部'
    },
    {
      bankCodeString: '0080581',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '0581',
      branchName: '企金行銷部',
      totalName: '0581 ╴企金行銷部'
    },
    {
      bankCodeString: '0080592',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '0592',
      branchName: '董事會稽核部',
      totalName: '0592 ╴董事會稽核部'
    },
    {
      bankCodeString: '0080606',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '0606',
      branchName: '財務會計部',
      totalName: '0606 ╴財務會計部'
    },
    {
      bankCodeString: '0080617',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '0617',
      branchName: '人力資源部',
      totalName: '0617 ╴人力資源部'
    },
    {
      bankCodeString: '0080628',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '0628',
      branchName: '徵信部',
      totalName: '0628 ╴徵信部'
    },
    {
      bankCodeString: '0080640',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '0640',
      branchName: '資訊作業服務部',
      totalName: '0640 ╴資訊作業服務部'
    },
    {
      bankCodeString: '0080643',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '0643',
      branchName: '金融市場行銷部',
      totalName: '0643 ╴金融市場行銷部'
    },
    {
      bankCodeString: '0080644',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '0644',
      branchName: '客戶服務部',
      totalName: '0644 ╴客戶服務部'
    },
    {
      bankCodeString: '0080651',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '0651',
      branchName: '資訊規劃開發部',
      totalName: '0651 ╴資訊規劃開發部'
    },
    {
      bankCodeString: '0080652',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '0652',
      branchName: '風險管理部',
      totalName: '0652 ╴風險管理部'
    },
    {
      bankCodeString: '0080709',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '0709',
      branchName: '國際金融業務分行',
      totalName: '0709 ╴國際金融業務分行'
    },
    {
      bankCodeString: '0080710',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '0710',
      branchName: '作業管理部',
      totalName: '0710 ╴作業管理部'
    },
    {
      bankCodeString: '0080743',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '0743',
      branchName: '債權管理部',
      totalName: '0743 ╴債權管理部'
    },
    {
      bankCodeString: '0080754',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '0754',
      branchName: '金融交易部',
      totalName: '0754 ╴金融交易部'
    },
    {
      bankCodeString: '0080765',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '0765',
      branchName: '個金行銷部',
      totalName: '0765 ╴個金行銷部'
    },
    {
      bankCodeString: '0080787',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '0787',
      branchName: '財富管理部',
      totalName: '0787 ╴財富管理部'
    },
    {
      bankCodeString: '0080846',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '0846',
      branchName: '信用卡部',
      totalName: '0846 ╴信用卡部'
    },
    {
      bankCodeString: '0081005',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1005',
      branchName: '營業部',
      totalName: '1005 ╴營業部'
    },
    {
      bankCodeString: '0081016',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1016',
      branchName: '儲蓄分行',
      totalName: '1016 ╴儲蓄分行'
    },
    {
      bankCodeString: '0081027',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1027',
      branchName: '國際金融部',
      totalName: '1027 ╴國際金融部'
    },
    {
      bankCodeString: '0081038',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1038',
      branchName: '城內分行',
      totalName: '1038 ╴城內分行'
    },
    {
      bankCodeString: '0081049',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1049',
      branchName: '大稻埕分行',
      totalName: '1049 ╴大稻埕分行'
    },
    {
      bankCodeString: '0081050',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1050',
      branchName: '建成分行',
      totalName: '1050 ╴建成分行'
    },
    {
      bankCodeString: '0081061',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1061',
      branchName: '中山分行',
      totalName: '1061 ╴中山分行'
    },
    {
      bankCodeString: '0081072',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1072',
      branchName: '圓山分行',
      totalName: '1072 ╴圓山分行'
    },
    {
      bankCodeString: '0081083',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1083',
      branchName: '城東分行',
      totalName: '1083 ╴城東分行'
    },
    {
      bankCodeString: '0081094',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1094',
      branchName: '西門分行',
      totalName: '1094 ╴西門分行'
    },
    {
      bankCodeString: '0081108',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1108',
      branchName: '南松山分行',
      totalName: '1108 ╴南松山分行'
    },
    {
      bankCodeString: '0081119',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1119',
      branchName: '仁愛路分行',
      totalName: '1119 ╴仁愛路分行'
    },
    {
      bankCodeString: '0081120',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1120',
      branchName: '南京東路分行',
      totalName: '1120 ╴南京東路分行'
    },
    {
      bankCodeString: '0081131',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1131',
      branchName: '新生分行',
      totalName: '1131 ╴新生分行'
    },
    {
      bankCodeString: '0081142',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1142',
      branchName: '大同分行',
      totalName: '1142 ╴大同分行'
    },
    {
      bankCodeString: '0081153',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1153',
      branchName: '松山分行',
      totalName: '1153 ╴松山分行'
    },
    {
      bankCodeString: '0081164',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1164',
      branchName: '中崙分行',
      totalName: '1164 ╴中崙分行'
    },
    {
      bankCodeString: '0081175',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1175',
      branchName: '台北南門分行',
      totalName: '1175 ╴台北南門分行'
    },
    {
      bankCodeString: '0081186',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1186',
      branchName: '公館分行',
      totalName: '1186 ╴公館分行'
    },
    {
      bankCodeString: '0081197',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1197',
      branchName: '信義分行',
      totalName: '1197 ╴信義分行'
    },
    {
      bankCodeString: '0081201',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1201',
      branchName: '忠孝東路分行',
      totalName: '1201 ╴忠孝東路分行'
    },
    {
      bankCodeString: '0081212',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1212',
      branchName: '和平分行',
      totalName: '1212 ╴和平分行'
    },
    {
      bankCodeString: '0081223',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1223',
      branchName: '雙園分行',
      totalName: '1223 ╴雙園分行'
    },
    {
      bankCodeString: '0081234',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1234',
      branchName: '士林分行',
      totalName: '1234 ╴士林分行'
    },
    {
      bankCodeString: '0081245',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1245',
      branchName: '東台北分行',
      totalName: '1245 ╴東台北分行'
    },
    {
      bankCodeString: '0081256',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1256',
      branchName: '大安分行',
      totalName: '1256 ╴大安分行'
    },
    {
      bankCodeString: '0081267',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1267',
      branchName: '民生分行',
      totalName: '1267 ╴民生分行'
    },
    {
      bankCodeString: '0081278',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1278',
      branchName: '復興分行',
      totalName: '1278 ╴復興分行'
    },
    {
      bankCodeString: '0081289',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1289',
      branchName: '龍江分行',
      totalName: '1289 ╴龍江分行'
    },
    {
      bankCodeString: '0081290',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1290',
      branchName: '永吉分行',
      totalName: '1290 ╴永吉分行'
    },
    {
      bankCodeString: '0081304',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1304',
      branchName: '敦化分行',
      totalName: '1304 ╴敦化分行'
    },
    {
      bankCodeString: '0081326',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1326',
      branchName: '大直分行',
      totalName: '1326 ╴大直分行'
    },
    {
      bankCodeString: '0081337',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1337',
      branchName: '敦和分行',
      totalName: '1337 ╴敦和分行'
    },
    {
      bankCodeString: '0081348',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1348',
      branchName: '東湖分行',
      totalName: '1348 ╴東湖分行'
    },
    {
      bankCodeString: '0081360',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1360',
      branchName: '東興分行',
      totalName: '1360 ╴東興分行'
    },
    {
      bankCodeString: '0081371',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1371',
      branchName: '北南港分行',
      totalName: '1371 ╴北南港分行'
    },
    {
      bankCodeString: '0081382',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1382',
      branchName: '木柵分行',
      totalName: '1382 ╴木柵分行'
    },
    {
      bankCodeString: '0081393',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1393',
      branchName: '板橋文化分行',
      totalName: '1393 ╴板橋文化分行'
    },
    {
      bankCodeString: '0081430',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1430',
      branchName: '南內湖分行',
      totalName: '1430 ╴南內湖分行'
    },
    {
      bankCodeString: '0081452',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1452',
      branchName: '長安分行',
      totalName: '1452 ╴長安分行'
    },
    {
      bankCodeString: '0081474',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1474',
      branchName: '懷生分行',
      totalName: '1474 ╴懷生分行'
    },
    {
      bankCodeString: '0081485',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1485',
      branchName: '中華路分行',
      totalName: '1485 ╴中華路分行'
    },
    {
      bankCodeString: '0081496',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1496',
      branchName: '信維分行',
      totalName: '1496 ╴信維分行'
    },
    {
      bankCodeString: '0081511',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1511',
      branchName: '埔墘分行',
      totalName: '1511 ╴埔墘分行'
    },
    {
      bankCodeString: '0081522',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1522',
      branchName: '石牌分行',
      totalName: '1522 ╴石牌分行'
    },
    {
      bankCodeString: '0081533',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1533',
      branchName: '瑞祥分行',
      totalName: '1533 ╴瑞祥分行'
    },
    {
      bankCodeString: '0081544',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1544',
      branchName: '台大分行',
      totalName: '1544 ╴台大分行'
    },
    {
      bankCodeString: '0081566',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1566',
      branchName: '世貿分行',
      totalName: '1566 ╴世貿分行'
    },
    {
      bankCodeString: '0081577',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1577',
      branchName: '萬華分行',
      totalName: '1577 ╴萬華分行'
    },
    {
      bankCodeString: '0081588',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1588',
      branchName: '南港分行',
      totalName: '1588 ╴南港分行'
    },
    {
      bankCodeString: '0081599',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1599',
      branchName: '華江分行',
      totalName: '1599 ╴華江分行'
    },
    {
      bankCodeString: '0081603',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1603',
      branchName: '板橋分行',
      totalName: '1603 ╴板橋分行'
    },
    {
      bankCodeString: '0081614',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1614',
      branchName: '三重分行',
      totalName: '1614 ╴三重分行'
    },
    {
      bankCodeString: '0081625',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1625',
      branchName: '北三重分行',
      totalName: '1625 ╴北三重分行'
    },
    {
      bankCodeString: '0081636',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1636',
      branchName: '新莊分行',
      totalName: '1636 ╴新莊分行'
    },
    {
      bankCodeString: '0081647',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1647',
      branchName: '永和分行',
      totalName: '1647 ╴永和分行'
    },
    {
      bankCodeString: '0081658',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1658',
      branchName: '中和分行',
      totalName: '1658 ╴中和分行'
    },
    {
      bankCodeString: '0081669',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1669',
      branchName: '新店分行',
      totalName: '1669 ╴新店分行'
    },
    {
      bankCodeString: '0081670',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1670',
      branchName: '淡水分行',
      totalName: '1670 ╴淡水分行'
    },
    {
      bankCodeString: '0081681',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1681',
      branchName: '汐止分行',
      totalName: '1681 ╴汐止分行'
    },
    {
      bankCodeString: '0081692',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1692',
      branchName: '南永和分行',
      totalName: '1692 ╴南永和分行'
    },
    {
      bankCodeString: '0081706',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1706',
      branchName: '西三重分行',
      totalName: '1706 ╴西三重分行'
    },
    {
      bankCodeString: '0081717',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1717',
      branchName: '南三重分行',
      totalName: '1717 ╴南三重分行'
    },
    {
      bankCodeString: '0081728',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1728',
      branchName: '雙和分行',
      totalName: '1728 ╴雙和分行'
    },
    {
      bankCodeString: '0081739',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1739',
      branchName: '新泰分行',
      totalName: '1739 ╴新泰分行'
    },
    {
      bankCodeString: '0081740',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1740',
      branchName: '二重分行',
      totalName: '1740 ╴二重分行'
    },
    {
      bankCodeString: '0081751',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1751',
      branchName: '板新分行',
      totalName: '1751 ╴板新分行'
    },
    {
      bankCodeString: '0081762',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1762',
      branchName: '五股分行',
      totalName: '1762 ╴五股分行'
    },
    {
      bankCodeString: '0081784',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1784',
      branchName: '北投分行',
      totalName: '1784 ╴北投分行'
    },
    {
      bankCodeString: '0081795',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1795',
      branchName: '西湖分行',
      totalName: '1795 ╴西湖分行'
    },
    {
      bankCodeString: '0081809',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1809',
      branchName: '積穗分行',
      totalName: '1809 ╴積穗分行'
    },
    {
      bankCodeString: '0081821',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1821',
      branchName: '福和分行',
      totalName: '1821 ╴福和分行'
    },
    {
      bankCodeString: '0081832',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1832',
      branchName: '南勢角分行',
      totalName: '1832 ╴南勢角分行'
    },
    {
      bankCodeString: '0081843',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1843',
      branchName: '北蘆洲分行',
      totalName: '1843 ╴北蘆洲分行'
    },
    {
      bankCodeString: '0081854',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1854',
      branchName: '蘆洲分行',
      totalName: '1854 ╴蘆洲分行'
    },
    {
      bankCodeString: '0081865',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1865',
      branchName: '土城分行',
      totalName: '1865 ╴土城分行'
    },
    {
      bankCodeString: '0081876',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1876',
      branchName: '北新分行',
      totalName: '1876 ╴北新分行'
    },
    {
      bankCodeString: '0081898',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1898',
      branchName: '天母分行',
      totalName: '1898 ╴天母分行'
    },
    {
      bankCodeString: '0081902',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1902',
      branchName: '內湖分行',
      totalName: '1902 ╴內湖分行'
    },
    {
      bankCodeString: '0081913',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1913',
      branchName: '樹林分行',
      totalName: '1913 ╴樹林分行'
    },
    {
      bankCodeString: '0081924',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1924',
      branchName: '樟樹灣分行',
      totalName: '1924 ╴樟樹灣分行'
    },
    {
      bankCodeString: '0081935',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1935',
      branchName: '泰山分行',
      totalName: '1935 ╴泰山分行'
    },
    {
      bankCodeString: '0081946',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1946',
      branchName: '三峽分行',
      totalName: '1946 ╴三峽分行'
    },
    {
      bankCodeString: '0081957',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1957',
      branchName: '文山分行',
      totalName: '1957 ╴文山分行'
    },
    {
      bankCodeString: '0081968',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1968',
      branchName: '鶯歌分行',
      totalName: '1968 ╴鶯歌分行'
    },
    {
      bankCodeString: '0081979',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1979',
      branchName: '北新莊分行',
      totalName: '1979 ╴北新莊分行'
    },
    {
      bankCodeString: '0081980',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1980',
      branchName: '北土城分行',
      totalName: '1980 ╴北土城分行'
    },
    {
      bankCodeString: '0081991',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '1991',
      branchName: '林口站前分行',
      totalName: '1991 ╴林口站前分行'
    },
    {
      bankCodeString: '0082002',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '2002',
      branchName: '基隆分行',
      totalName: '2002 ╴基隆分行'
    },
    {
      bankCodeString: '0082013',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '2013',
      branchName: '基隆港口分行',
      totalName: '2013 ╴基隆港口分行'
    },
    {
      bankCodeString: '0082116',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '2116',
      branchName: '七堵分行',
      totalName: '2116 ╴七堵分行'
    },
    {
      bankCodeString: '0082208',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '2208',
      branchName: '羅東分行',
      totalName: '2208 ╴羅東分行'
    },
    {
      bankCodeString: '0082219',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '2219',
      branchName: '宜蘭分行',
      totalName: '2219 ╴宜蘭分行'
    },
    {
      bankCodeString: '0082404',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '2404',
      branchName: '桃園分行',
      totalName: '2404 ╴桃園分行'
    },
    {
      bankCodeString: '0082415',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '2415',
      branchName: '中壢分行',
      totalName: '2415 ╴中壢分行'
    },
    {
      bankCodeString: '0082426',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '2426',
      branchName: '楊梅分行',
      totalName: '2426 ╴楊梅分行'
    },
    {
      bankCodeString: '0082437',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '2437',
      branchName: '壢昌分行',
      totalName: '2437 ╴壢昌分行'
    },
    {
      bankCodeString: '0082448',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '2448',
      branchName: '北桃園分行',
      totalName: '2448 ╴北桃園分行'
    },
    {
      bankCodeString: '0082459',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '2459',
      branchName: '南崁分行',
      totalName: '2459 ╴南崁分行'
    },
    {
      bankCodeString: '0082460',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '2460',
      branchName: '平鎮分行',
      totalName: '2460 ╴平鎮分行'
    },
    {
      bankCodeString: '0082471',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '2471',
      branchName: '八德分行',
      totalName: '2471 ╴八德分行'
    },
    {
      bankCodeString: '0082482',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '2482',
      branchName: '龜山分行',
      totalName: '2482 ╴龜山分行'
    },
    {
      bankCodeString: '0082493',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '2493',
      branchName: '龍潭分行',
      totalName: '2493 ╴龍潭分行'
    },
    {
      bankCodeString: '0082507',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '2507',
      branchName: '大溪分行',
      totalName: '2507 ╴大溪分行'
    },
    {
      bankCodeString: '0082518',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '2518',
      branchName: '內壢分行',
      totalName: '2518 ╴內壢分行'
    },
    {
      bankCodeString: '0082529',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '2529',
      branchName: '林口分行',
      totalName: '2529 ╴林口分行'
    },
    {
      bankCodeString: '0082600',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '2600',
      branchName: '觀音分行',
      totalName: '2600 ╴觀音分行'
    },
    {
      bankCodeString: '0082622',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '2622',
      branchName: '大園分行',
      totalName: '2622 ╴大園分行'
    },
    {
      bankCodeString: '0083009',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '3009',
      branchName: '新竹分行',
      totalName: '3009 ╴新竹分行'
    },
    {
      bankCodeString: '0083010',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '3010',
      branchName: '竹東分行',
      totalName: '3010 ╴竹東分行'
    },
    {
      bankCodeString: '0083021',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '3021',
      branchName: '竹科分行',
      totalName: '3021 ╴竹科分行'
    },
    {
      bankCodeString: '0083102',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '3102',
      branchName: '新豐分行',
      totalName: '3102 ╴新豐分行'
    },
    {
      bankCodeString: '0083135',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '3135',
      branchName: '六家分行',
      totalName: '3135 ╴六家分行'
    },
    {
      bankCodeString: '0083205',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '3205',
      branchName: '竹南分行',
      totalName: '3205 ╴竹南分行'
    },
    {
      bankCodeString: '0083216',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '3216',
      branchName: '頭份分行',
      totalName: '3216 ╴頭份分行'
    },
    {
      bankCodeString: '0083227',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '3227',
      branchName: '苗栗分行',
      totalName: '3227 ╴苗栗分行'
    },
    {
      bankCodeString: '0083238',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '3238',
      branchName: '竹北分行',
      totalName: '3238 ╴竹北分行'
    },
    {
      bankCodeString: '0083515',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '3515',
      branchName: '大眾分行',
      totalName: '3515 ╴大眾分行'
    },
    {
      bankCodeString: '0084006',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '4006',
      branchName: '豐原分行',
      totalName: '4006 ╴豐原分行'
    },
    {
      bankCodeString: '0084017',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '4017',
      branchName: '東勢分行',
      totalName: '4017 ╴東勢分行'
    },
    {
      bankCodeString: '0084028',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '4028',
      branchName: '清水分行',
      totalName: '4028 ╴清水分行'
    },
    {
      bankCodeString: '0084039',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '4039',
      branchName: '西豐原分行',
      totalName: '4039 ╴西豐原分行'
    },
    {
      bankCodeString: '0084202',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '4202',
      branchName: '台中分行',
      totalName: '4202 ╴台中分行'
    },
    {
      bankCodeString: '0084224',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '4224',
      branchName: '南台中分行',
      totalName: '4224 ╴南台中分行'
    },
    {
      bankCodeString: '0084235',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '4235',
      branchName: '北台中分行',
      totalName: '4235 ╴北台中分行'
    },
    {
      bankCodeString: '0084246',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '4246',
      branchName: '台中港路分行',
      totalName: '4246 ╴台中港路分行'
    },
    {
      bankCodeString: '0084257',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '4257',
      branchName: '大里分行',
      totalName: '4257 ╴大里分行'
    },
    {
      bankCodeString: '0084268',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '4268',
      branchName: '水湳分行',
      totalName: '4268 ╴水湳分行'
    },
    {
      bankCodeString: '0084279',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '4279',
      branchName: '五權分行',
      totalName: '4279 ╴五權分行'
    },
    {
      bankCodeString: '0084291',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '4291',
      branchName: '大甲分行',
      totalName: '4291 ╴大甲分行'
    },
    {
      bankCodeString: '0084305',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '4305',
      branchName: '太平分行',
      totalName: '4305 ╴太平分行'
    },
    {
      bankCodeString: '0084316',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '4316',
      branchName: '中科分行',
      totalName: '4316 ╴中科分行'
    },
    {
      bankCodeString: '0084512',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '4512',
      branchName: '沙鹿分行',
      totalName: '4512 ╴沙鹿分行'
    },
    {
      bankCodeString: '0085003',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '5003',
      branchName: '草屯分行',
      totalName: '5003 ╴草屯分行'
    },
    {
      bankCodeString: '0085014',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '5014',
      branchName: '南投分行',
      totalName: '5014 ╴南投分行'
    },
    {
      bankCodeString: '0085209',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '5209',
      branchName: '彰化分行',
      totalName: '5209 ╴彰化分行'
    },
    {
      bankCodeString: '0085210',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '5210',
      branchName: '和美分行',
      totalName: '5210 ╴和美分行'
    },
    {
      bankCodeString: '0085221',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '5221',
      branchName: '員林分行',
      totalName: '5221 ╴員林分行'
    },
    {
      bankCodeString: '0085232',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '5232',
      branchName: '鹿港分行',
      totalName: '5232 ╴鹿港分行'
    },
    {
      bankCodeString: '0085243',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '5243',
      branchName: '溪湖分行',
      totalName: '5243 ╴溪湖分行'
    },
    {
      bankCodeString: '0085405',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '5405',
      branchName: '斗六分行',
      totalName: '5405 ╴斗六分行'
    },
    {
      bankCodeString: '0085416',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '5416',
      branchName: '虎尾分行',
      totalName: '5416 ╴虎尾分行'
    },
    {
      bankCodeString: '0085427',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '5427',
      branchName: '西螺分行',
      totalName: '5427 ╴西螺分行'
    },
    {
      bankCodeString: '0086000',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '6000',
      branchName: '嘉義分行',
      totalName: '6000 ╴嘉義分行'
    },
    {
      bankCodeString: '0086011',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '6011',
      branchName: '嘉南分行',
      totalName: '6011 ╴嘉南分行'
    },
    {
      bankCodeString: '0086022',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '6022',
      branchName: '朴子分行',
      totalName: '6022 ╴朴子分行'
    },
    {
      bankCodeString: '0086206',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '6206',
      branchName: '新營分行',
      totalName: '6206 ╴新營分行'
    },
    {
      bankCodeString: '0086217',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '6217',
      branchName: '麻豆分行',
      totalName: '6217 ╴麻豆分行'
    },
    {
      bankCodeString: '0086228',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '6228',
      branchName: '永康分行',
      totalName: '6228 ╴永康分行'
    },
    {
      bankCodeString: '0086402',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '6402',
      branchName: '台南分行',
      totalName: '6402 ╴台南分行'
    },
    {
      bankCodeString: '0086424',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '6424',
      branchName: '東台南分行',
      totalName: '6424 ╴東台南分行'
    },
    {
      bankCodeString: '0086435',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '6435',
      branchName: '西台南分行',
      totalName: '6435 ╴西台南分行'
    },
    {
      bankCodeString: '0086446',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '6446',
      branchName: '北臺南分行',
      totalName: '6446 ╴北臺南分行'
    },
    {
      bankCodeString: '0086457',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '6457',
      branchName: '南都分行',
      totalName: '6457 ╴南都分行'
    },
    {
      bankCodeString: '0086468',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '6468',
      branchName: '安南分行',
      totalName: '6468 ╴安南分行'
    },
    {
      bankCodeString: '0086479',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '6479',
      branchName: '仁德分行',
      totalName: '6479 ╴仁德分行'
    },
    {
      bankCodeString: '0086480',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '6480',
      branchName: '新市分行',
      totalName: '6480 ╴新市分行'
    },
    {
      bankCodeString: '0086815',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '6815',
      branchName: '金華分行',
      totalName: '6815 ╴金華分行'
    },
    {
      bankCodeString: '0087007',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '7007',
      branchName: '高雄分行',
      totalName: '7007 ╴高雄分行'
    },
    {
      bankCodeString: '0087018',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '7018',
      branchName: '東苓分行',
      totalName: '7018 ╴東苓分行'
    },
    {
      bankCodeString: '0087029',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '7029',
      branchName: '新興分行',
      totalName: '7029 ╴新興分行'
    },
    {
      bankCodeString: '0087030',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '7030',
      branchName: '高雄三民分行',
      totalName: '7030 ╴高雄三民分行'
    },
    {
      bankCodeString: '0087041',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '7041',
      branchName: '苓雅分行',
      totalName: '7041 ╴苓雅分行'
    },
    {
      bankCodeString: '0087052',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '7052',
      branchName: '前鎮分行',
      totalName: '7052 ╴前鎮分行'
    },
    {
      bankCodeString: '0087063',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '7063',
      branchName: '高雄博愛分行',
      totalName: '7063 ╴高雄博愛分行'
    },
    {
      bankCodeString: '0087074',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '7074',
      branchName: '南高雄分行',
      totalName: '7074 ╴南高雄分行'
    },
    {
      bankCodeString: '0087085',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '7085',
      branchName: '東高雄分行',
      totalName: '7085 ╴東高雄分行'
    },
    {
      bankCodeString: '0087096',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '7096',
      branchName: '大昌分行',
      totalName: '7096 ╴大昌分行'
    },
    {
      bankCodeString: '0087100',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '7100',
      branchName: '北高雄分行',
      totalName: '7100 ╴北高雄分行'
    },
    {
      bankCodeString: '0087111',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '7111',
      branchName: '楠梓分行',
      totalName: '7111 ╴楠梓分行'
    },
    {
      bankCodeString: '0087122',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '7122',
      branchName: '左營分行',
      totalName: '7122 ╴左營分行'
    },
    {
      bankCodeString: '0087199',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '7199',
      branchName: '岡山分行',
      totalName: '7199 ╴岡山分行'
    },
    {
      bankCodeString: '0087203',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '7203',
      branchName: '鳳山分行',
      totalName: '7203 ╴鳳山分行'
    },
    {
      bankCodeString: '0087214',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '7214',
      branchName: '路竹分行',
      totalName: '7214 ╴路竹分行'
    },
    {
      bankCodeString: '0087225',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '7225',
      branchName: '仁武分行',
      totalName: '7225 ╴仁武分行'
    },
    {
      bankCodeString: '0087513',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '7513',
      branchName: '籬仔內分行',
      totalName: '7513 ╴籬仔內分行'
    },
    {
      bankCodeString: '0087524',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '7524',
      branchName: '五甲分行',
      totalName: '7524 ╴五甲分行'
    },
    {
      bankCodeString: '0087535',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '7535',
      branchName: '光華分行',
      totalName: '7535 ╴光華分行'
    },
    {
      bankCodeString: '0087605',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '7605',
      branchName: '小港分行',
      totalName: '7605 ╴小港分行'
    },
    {
      bankCodeString: '0087650',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '7650',
      branchName: '高雄桂林分行',
      totalName: '7650 ╴高雄桂林分行'
    },
    {
      bankCodeString: '0088004',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '8004',
      branchName: '屏東分行',
      totalName: '8004 ╴屏東分行'
    },
    {
      bankCodeString: '0088015',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '8015',
      branchName: '內埔分行',
      totalName: '8015 ╴內埔分行'
    },
    {
      bankCodeString: '0088026',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '8026',
      branchName: '潮州分行',
      totalName: '8026 ╴潮州分行'
    },
    {
      bankCodeString: '0088130',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '8130',
      branchName: '佳冬分行',
      totalName: '8130 ╴佳冬分行'
    },
    {
      bankCodeString: '0088200',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '8200',
      branchName: '花蓮分行',
      totalName: '8200 ╴花蓮分行'
    },
    {
      bankCodeString: '0088303',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '8303',
      branchName: '台東分行',
      totalName: '8303 ╴台東分行'
    },
    {
      bankCodeString: '0088886',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '8886',
      branchName: '營運總部分行',
      totalName: '8886 ╴營運總部分行'
    },
    {
      bankCodeString: '0089012',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '9012',
      branchName: '洛杉磯分行',
      totalName: '9012 ╴洛杉磯分行'
    },
    {
      bankCodeString: '0089023',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '9023',
      branchName: '紐約分行',
      totalName: '9023 ╴紐約分行'
    },
    {
      bankCodeString: '0089034',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '9034',
      branchName: '倫敦分行',
      totalName: '9034 ╴倫敦分行'
    },
    {
      bankCodeString: '0089056',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '9056',
      branchName: '香港分行',
      totalName: '9056 ╴香港分行'
    },
    {
      bankCodeString: '0089078',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '9078',
      branchName: '新加坡分行',
      totalName: '9078 ╴新加坡分行'
    },
    {
      bankCodeString: '0089090',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '9090',
      branchName: '深圳分行',
      totalName: '9090 ╴深圳分行'
    },
    {
      bankCodeString: '0089104',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '9104',
      branchName: '胡志明市分行',
      totalName: '9104 ╴胡志明市分行'
    },
    {
      bankCodeString: '0089126',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '9126',
      branchName: '河內代表辦事處',
      totalName: '9126 ╴河內代表辦事處'
    },
    {
      bankCodeString: '0089137',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '9137',
      branchName: '澳門分行',
      totalName: '9137 ╴澳門分行'
    },
    {
      bankCodeString: '0089148',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '9148',
      branchName: '雪梨分行',
      totalName: '9148 ╴雪梨分行'
    },
    {
      bankCodeString: '0089159',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '9159',
      branchName: '深圳寶安支行',
      totalName: '9159 ╴深圳寶安支行'
    },
    {
      bankCodeString: '0089160',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '9160',
      branchName: '上海分行',
      totalName: '9160 ╴上海分行'
    },
    {
      bankCodeString: '0089171',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '9171',
      branchName: '福州分行',
      totalName: '9171 ╴福州分行'
    },
    {
      bankCodeString: '0089182',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '9182',
      branchName: '馬尼拉分行',
      totalName: '9182 ╴馬尼拉分行'
    },
    {
      bankCodeString: '0089193',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '9193',
      branchName: '仰光代表人辦事處',
      totalName: '9193 ╴仰光代表人辦事處'
    },
    {
      bankCodeString: '0089207',
      bankCode: '008',
      bankName: '華南商業銀行',
      branchCode: '9207',
      branchName: '曼谷代表人辦事處',
      totalName: '9207 ╴曼谷代表人辦事處'
    }
  ],
  '009': [
    {
      bankCodeString: '0090101',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '0101',
      branchName: '董事會',
      totalName: '0101 ╴董事會'
    },
    {
      bankCodeString: '0090102',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '0102',
      branchName: '監察人會',
      totalName: '0102 ╴監察人會'
    },
    {
      bankCodeString: '0090104',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '0104',
      branchName: '秘書處',
      totalName: '0104 ╴秘書處'
    },
    {
      bankCodeString: '0090111',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '0111',
      branchName: '稽核處',
      totalName: '0111 ╴稽核處'
    },
    {
      bankCodeString: '0090128',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '0128',
      branchName: '總務處',
      totalName: '0128 ╴總務處'
    },
    {
      bankCodeString: '0090142',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '0142',
      branchName: '國內營運處',
      totalName: '0142 ╴國內營運處'
    },
    {
      bankCodeString: '0090159',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '0159',
      branchName: '授信管理處',
      totalName: '0159 ╴授信管理處'
    },
    {
      bankCodeString: '0090173',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '0173',
      branchName: '資金營運處',
      totalName: '0173 ╴資金營運處'
    },
    {
      bankCodeString: '0090180',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '0180',
      branchName: '人力資源處',
      totalName: '0180 ╴人力資源處'
    },
    {
      bankCodeString: '0090197',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '0197',
      branchName: '財務管理處',
      totalName: '0197 ╴財務管理處'
    },
    {
      bankCodeString: '0090208',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '0208',
      branchName: '資訊處',
      totalName: '0208 ╴資訊處'
    },
    {
      bankCodeString: '0090215',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '0215',
      branchName: '商品策劃處',
      totalName: '0215 ╴商品策劃處'
    },
    {
      bankCodeString: '0090222',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '0222',
      branchName: '債權管理處',
      totalName: '0222 ╴債權管理處'
    },
    {
      bankCodeString: '0090239',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '0239',
      branchName: '電子營運處',
      totalName: '0239 ╴電子營運處'
    },
    {
      bankCodeString: '0090246',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '0246',
      branchName: '風險管理處',
      totalName: '0246 ╴風險管理處'
    },
    {
      bankCodeString: '0090247',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '0247',
      branchName: '風險管理處',
      totalName: '0247 ╴風險管理處'
    },
    {
      bankCodeString: '0090277',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '0277',
      branchName: '作業處',
      totalName: '0277 ╴作業處'
    },
    {
      bankCodeString: '0090319',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '0319',
      branchName: '資訊安全中心',
      totalName: '0319 ╴資訊安全中心'
    },
    {
      bankCodeString: '0090888',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '0888',
      branchName: '彰銀商業銀行有限公司',
      totalName: '0888 ╴彰銀商業銀行有限公司'
    },
    {
      bankCodeString: '0091001',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '1001',
      branchName: '兼營證券商',
      totalName: '1001 ╴兼營證券商'
    },
    {
      bankCodeString: '0091025',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '1025',
      branchName: '證券商七賢分公司',
      totalName: '1025 ╴證券商七賢分公司'
    },
    {
      bankCodeString: '0091032',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '1032',
      branchName: '證券商台中分公司',
      totalName: '1032 ╴證券商台中分公司'
    },
    {
      bankCodeString: '0091094',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '1094',
      branchName: '信託處',
      totalName: '1094 ╴信託處'
    },
    {
      bankCodeString: '0092026',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '2026',
      branchName: '新加坡分行',
      totalName: '2026 ╴新加坡分行'
    },
    {
      bankCodeString: '0092033',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '2033',
      branchName: '彰銀商業銀行有限公司昆山分行',
      totalName: '2033 ╴彰銀商業銀行有限公司昆山分行'
    },
    {
      bankCodeString: '0092040',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '2040',
      branchName: '馬尼拉分行',
      totalName: '2040 ╴馬尼拉分行'
    },
    {
      bankCodeString: '0092057',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '2057',
      branchName: '彰銀商業銀行有限公司昆山花橋支行',
      totalName: '2057 ╴彰銀商業銀行有限公司昆山花橋支行'
    },
    {
      bankCodeString: '0092071',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '2071',
      branchName: '彰銀商業銀行有限公司東莞分行',
      totalName: '2071 ╴彰銀商業銀行有限公司東莞分行'
    },
    {
      bankCodeString: '0092088',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '2088',
      branchName: '彰銀商業銀行有限公司福州分行',
      totalName: '2088 ╴彰銀商業銀行有限公司福州分行'
    },
    {
      bankCodeString: '0092095',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '2095',
      branchName: '仰光代表人辦事處',
      totalName: '2095 ╴仰光代表人辦事處'
    },
    {
      bankCodeString: '0092113',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '2113',
      branchName: '國際營運處',
      totalName: '2113 ╴國際營運處'
    },
    {
      bankCodeString: '0092120',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '2120',
      branchName: '國際金融業務分行',
      totalName: '2120 ╴國際金融業務分行'
    },
    {
      bankCodeString: '0092144',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '2144',
      branchName: '紐約分行',
      totalName: '2144 ╴紐約分行'
    },
    {
      bankCodeString: '0092151',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '2151',
      branchName: '洛杉磯分行',
      totalName: '2151 ╴洛杉磯分行'
    },
    {
      bankCodeString: '0092168',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '2168',
      branchName: '東京分行',
      totalName: '2168 ╴東京分行'
    },
    {
      bankCodeString: '0092175',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '2175',
      branchName: '倫敦分行',
      totalName: '2175 ╴倫敦分行'
    },
    {
      bankCodeString: '0092199',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '2199',
      branchName: '香港分行',
      totalName: '2199 ╴香港分行'
    },
    {
      bankCodeString: '0092200',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '2200',
      branchName: '營業部',
      totalName: '2200 ╴營業部'
    },
    {
      bankCodeString: '0092606',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '2606',
      branchName: '彰銀商業銀行有限公司南京分行',
      totalName: '2606 ╴彰銀商業銀行有限公司南京分行'
    },
    {
      bankCodeString: '0093003',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '3003',
      branchName: '吉林分行',
      totalName: '3003 ╴吉林分行'
    },
    {
      bankCodeString: '0093010',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '3010',
      branchName: '城內分行',
      totalName: '3010 ╴城內分行'
    },
    {
      bankCodeString: '0093027',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '3027',
      branchName: '北一區營運處',
      totalName: '3027 ╴北一區營運處'
    },
    {
      bankCodeString: '0093041',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '3041',
      branchName: '北二區營運處',
      totalName: '3041 ╴北二區營運處'
    },
    {
      bankCodeString: '0093058',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '3058',
      branchName: '中區營運處',
      totalName: '3058 ╴中區營運處'
    },
    {
      bankCodeString: '0093072',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '3072',
      branchName: '南區營運處',
      totalName: '3072 ╴南區營運處'
    },
    {
      bankCodeString: '0094004',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '4004',
      branchName: '北台中分行',
      totalName: '4004 ╴北台中分行'
    },
    {
      bankCodeString: '0094011',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '4011',
      branchName: '南台中分行',
      totalName: '4011 ╴南台中分行'
    },
    {
      bankCodeString: '0094028',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '4028',
      branchName: '北屯分行',
      totalName: '4028 ╴北屯分行'
    },
    {
      bankCodeString: '0094035',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '4035',
      branchName: '水湳分行',
      totalName: '4035 ╴水湳分行'
    },
    {
      bankCodeString: '0094042',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '4042',
      branchName: '南屯分行',
      totalName: '4042 ╴南屯分行'
    },
    {
      bankCodeString: '0094059',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '4059',
      branchName: '台中分行',
      totalName: '4059 ╴台中分行'
    },
    {
      bankCodeString: '0094108',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '4108',
      branchName: '基隆分行',
      totalName: '4108 ╴基隆分行'
    },
    {
      bankCodeString: '0094115',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '4115',
      branchName: '仁愛分行',
      totalName: '4115 ╴仁愛分行'
    },
    {
      bankCodeString: '0094122',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '4122',
      branchName: '東基隆分行',
      totalName: '4122 ╴東基隆分行'
    },
    {
      bankCodeString: '0094202',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '4202',
      branchName: '宜蘭分行',
      totalName: '4202 ╴宜蘭分行'
    },
    {
      bankCodeString: '0094219',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '4219',
      branchName: '羅東分行',
      totalName: '4219 ╴羅東分行'
    },
    {
      bankCodeString: '0095005',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5005',
      branchName: '北門分行',
      totalName: '5005 ╴北門分行'
    },
    {
      bankCodeString: '0095012',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5012',
      branchName: '台北分行',
      totalName: '5012 ╴台北分行'
    },
    {
      bankCodeString: '0095029',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5029',
      branchName: '萬華分行',
      totalName: '5029 ╴萬華分行'
    },
    {
      bankCodeString: '0095036',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5036',
      branchName: '西門分行',
      totalName: '5036 ╴西門分行'
    },
    {
      bankCodeString: '0095043',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5043',
      branchName: '永樂分行',
      totalName: '5043 ╴永樂分行'
    },
    {
      bankCodeString: '0095050',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5050',
      branchName: '建成分行',
      totalName: '5050 ╴建成分行'
    },
    {
      bankCodeString: '0095067',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5067',
      branchName: '大同分行',
      totalName: '5067 ╴大同分行'
    },
    {
      bankCodeString: '0095074',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5074',
      branchName: '承德分行',
      totalName: '5074 ╴承德分行'
    },
    {
      bankCodeString: '0095081',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5081',
      branchName: '中山北路分行',
      totalName: '5081 ╴中山北路分行'
    },
    {
      bankCodeString: '0095109',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5109',
      branchName: '東門分行',
      totalName: '5109 ╴東門分行'
    },
    {
      bankCodeString: '0095116',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5116',
      branchName: '古亭分行',
      totalName: '5116 ╴古亭分行'
    },
    {
      bankCodeString: '0095123',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5123',
      branchName: '五分埔分行',
      totalName: '5123 ╴五分埔分行'
    },
    {
      bankCodeString: '0095130',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5130',
      branchName: '大安分行',
      totalName: '5130 ╴大安分行'
    },
    {
      bankCodeString: '0095147',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5147',
      branchName: '城東分行',
      totalName: '5147 ╴城東分行'
    },
    {
      bankCodeString: '0095154',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5154',
      branchName: '中崙分行',
      totalName: '5154 ╴中崙分行'
    },
    {
      bankCodeString: '0095161',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5161',
      branchName: '雙園分行',
      totalName: '5161 ╴雙園分行'
    },
    {
      bankCodeString: '0095178',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5178',
      branchName: '士林分行',
      totalName: '5178 ╴士林分行'
    },
    {
      bankCodeString: '0095185',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5185',
      branchName: '總部分行',
      totalName: '5185 ╴總部分行'
    },
    {
      bankCodeString: '0095192',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5192',
      branchName: '松山分行',
      totalName: '5192 ╴松山分行'
    },
    {
      bankCodeString: '0095203',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5203',
      branchName: '忠孝東路分行',
      totalName: '5203 ╴忠孝東路分行'
    },
    {
      bankCodeString: '0095210',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5210',
      branchName: '信義分行',
      totalName: '5210 ╴信義分行'
    },
    {
      bankCodeString: '0095227',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5227',
      branchName: '東台北分行',
      totalName: '5227 ╴東台北分行'
    },
    {
      bankCodeString: '0095234',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5234',
      branchName: '民生分行',
      totalName: '5234 ╴民生分行'
    },
    {
      bankCodeString: '0095241',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5241',
      branchName: '西松分行',
      totalName: '5241 ╴西松分行'
    },
    {
      bankCodeString: '0095258',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5258',
      branchName: '復興分行',
      totalName: '5258 ╴復興分行'
    },
    {
      bankCodeString: '0095265',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5265',
      branchName: '台北世貿中心分行',
      totalName: '5265 ╴台北世貿中心分行'
    },
    {
      bankCodeString: '0095272',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5272',
      branchName: '敦化分行',
      totalName: '5272 ╴敦化分行'
    },
    {
      bankCodeString: '0095289',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5289',
      branchName: '建國分行',
      totalName: '5289 ╴建國分行'
    },
    {
      bankCodeString: '0095296',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5296',
      branchName: '內湖分行',
      totalName: '5296 ╴內湖分行'
    },
    {
      bankCodeString: '0095307',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5307',
      branchName: '天母分行',
      totalName: '5307 ╴天母分行'
    },
    {
      bankCodeString: '0095314',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5314',
      branchName: '中正分行',
      totalName: '5314 ╴中正分行'
    },
    {
      bankCodeString: '0095321',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5321',
      branchName: '仁和分行',
      totalName: '5321 ╴仁和分行'
    },
    {
      bankCodeString: '0095338',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5338',
      branchName: '永春分行',
      totalName: '5338 ╴永春分行'
    },
    {
      bankCodeString: '0095345',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5345',
      branchName: '光隆分行',
      totalName: '5345 ╴光隆分行'
    },
    {
      bankCodeString: '0095352',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5352',
      branchName: '木柵分行',
      totalName: '5352 ╴木柵分行'
    },
    {
      bankCodeString: '0095369',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5369',
      branchName: '新湖分行',
      totalName: '5369 ╴新湖分行'
    },
    {
      bankCodeString: '0095376',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5376',
      branchName: '東湖分行',
      totalName: '5376 ╴東湖分行'
    },
    {
      bankCodeString: '0095383',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5383',
      branchName: '南港科學園區分行',
      totalName: '5383 ╴南港科學園區分行'
    },
    {
      bankCodeString: '0095390',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5390',
      branchName: '北投分行',
      totalName: '5390 ╴北投分行'
    },
    {
      bankCodeString: '0095401',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5401',
      branchName: '和平分行',
      totalName: '5401 ╴和平分行'
    },
    {
      bankCodeString: '0095418',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5418',
      branchName: '東興分行',
      totalName: '5418 ╴東興分行'
    },
    {
      bankCodeString: '0095425',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5425',
      branchName: '龍潭分行',
      totalName: '5425 ╴龍潭分行'
    },
    {
      bankCodeString: '0095432',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5432',
      branchName: '汐科分行',
      totalName: '5432 ╴汐科分行'
    },
    {
      bankCodeString: '0095456',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5456',
      branchName: '新樹分行',
      totalName: '5456 ╴新樹分行'
    },
    {
      bankCodeString: '0095463',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5463',
      branchName: '立德分行',
      totalName: '5463 ╴立德分行'
    },
    {
      bankCodeString: '0095470',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5470',
      branchName: '泰山分行',
      totalName: '5470 ╴泰山分行'
    },
    {
      bankCodeString: '0095487',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5487',
      branchName: '五股工業區分行',
      totalName: '5487 ╴五股工業區分行'
    },
    {
      bankCodeString: '0095494',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5494',
      branchName: '南新莊分行',
      totalName: '5494 ╴南新莊分行'
    },
    {
      bankCodeString: '0095505',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5505',
      branchName: '三重埔分行',
      totalName: '5505 ╴三重埔分行'
    },
    {
      bankCodeString: '0095512',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5512',
      branchName: '北三重埔分行',
      totalName: '5512 ╴北三重埔分行'
    },
    {
      bankCodeString: '0095529',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5529',
      branchName: '新店分行',
      totalName: '5529 ╴新店分行'
    },
    {
      bankCodeString: '0095536',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5536',
      branchName: '永和分行',
      totalName: '5536 ╴永和分行'
    },
    {
      bankCodeString: '0095543',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5543',
      branchName: '瑞芳分行',
      totalName: '5543 ╴瑞芳分行'
    },
    {
      bankCodeString: '0095550',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5550',
      branchName: '新莊分行',
      totalName: '5550 ╴新莊分行'
    },
    {
      bankCodeString: '0095567',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5567',
      branchName: '樹林分行',
      totalName: '5567 ╴樹林分行'
    },
    {
      bankCodeString: '0095574',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5574',
      branchName: '板橋分行',
      totalName: '5574 ╴板橋分行'
    },
    {
      bankCodeString: '0095581',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5581',
      branchName: '三峽分行',
      totalName: '5581 ╴三峽分行'
    },
    {
      bankCodeString: '0095598',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5598',
      branchName: '福和分行',
      totalName: '5598 ╴福和分行'
    },
    {
      bankCodeString: '0095609',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5609',
      branchName: '光復分行',
      totalName: '5609 ╴光復分行'
    },
    {
      bankCodeString: '0095616',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5616',
      branchName: '東三重分行',
      totalName: '5616 ╴東三重分行'
    },
    {
      bankCodeString: '0095623',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5623',
      branchName: '北新分行',
      totalName: '5623 ╴北新分行'
    },
    {
      bankCodeString: '0095630',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5630',
      branchName: '南三重分行',
      totalName: '5630 ╴南三重分行'
    },
    {
      bankCodeString: '0095647',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5647',
      branchName: '中和分行',
      totalName: '5647 ╴中和分行'
    },
    {
      bankCodeString: '0095654',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5654',
      branchName: '西三重分行',
      totalName: '5654 ╴西三重分行'
    },
    {
      bankCodeString: '0095661',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5661',
      branchName: '汐止分行',
      totalName: '5661 ╴汐止分行'
    },
    {
      bankCodeString: '0095678',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5678',
      branchName: '雙和分行',
      totalName: '5678 ╴雙和分行'
    },
    {
      bankCodeString: '0095685',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5685',
      branchName: '淡水分行',
      totalName: '5685 ╴淡水分行'
    },
    {
      bankCodeString: '0095692',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5692',
      branchName: '楊梅分行',
      totalName: '5692 ╴楊梅分行'
    },
    {
      bankCodeString: '0095703',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5703',
      branchName: '桃園分行',
      totalName: '5703 ╴桃園分行'
    },
    {
      bankCodeString: '0095710',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5710',
      branchName: '中壢分行',
      totalName: '5710 ╴中壢分行'
    },
    {
      bankCodeString: '0095727',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5727',
      branchName: '新竹分行',
      totalName: '5727 ╴新竹分行'
    },
    {
      bankCodeString: '0095734',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5734',
      branchName: '竹東分行',
      totalName: '5734 ╴竹東分行'
    },
    {
      bankCodeString: '0095741',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5741',
      branchName: '苗栗分行',
      totalName: '5741 ╴苗栗分行'
    },
    {
      bankCodeString: '0095758',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5758',
      branchName: '新竹科學園區分行',
      totalName: '5758 ╴新竹科學園區分行'
    },
    {
      bankCodeString: '0095765',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5765',
      branchName: '北中壢分行',
      totalName: '5765 ╴北中壢分行'
    },
    {
      bankCodeString: '0095772',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5772',
      branchName: '八德分行',
      totalName: '5772 ╴八德分行'
    },
    {
      bankCodeString: '0095789',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5789',
      branchName: '竹南分行',
      totalName: '5789 ╴竹南分行'
    },
    {
      bankCodeString: '0095796',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5796',
      branchName: '南崁分行',
      totalName: '5796 ╴南崁分行'
    },
    {
      bankCodeString: '0095807',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5807',
      branchName: '苑裡分行',
      totalName: '5807 ╴苑裡分行'
    },
    {
      bankCodeString: '0095814',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5814',
      branchName: '大甲分行',
      totalName: '5814 ╴大甲分行'
    },
    {
      bankCodeString: '0095821',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5821',
      branchName: '清水分行',
      totalName: '5821 ╴清水分行'
    },
    {
      bankCodeString: '0095838',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5838',
      branchName: '豐原分行',
      totalName: '5838 ╴豐原分行'
    },
    {
      bankCodeString: '0095845',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5845',
      branchName: '東勢分行',
      totalName: '5845 ╴東勢分行'
    },
    {
      bankCodeString: '0095852',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5852',
      branchName: '霧峰分行',
      totalName: '5852 ╴霧峰分行'
    },
    {
      bankCodeString: '0095869',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5869',
      branchName: '大雅分行',
      totalName: '5869 ╴大雅分行'
    },
    {
      bankCodeString: '0095876',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5876',
      branchName: '沙鹿分行',
      totalName: '5876 ╴沙鹿分行'
    },
    {
      bankCodeString: '0095890',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5890',
      branchName: '太平分行',
      totalName: '5890 ╴太平分行'
    },
    {
      bankCodeString: '0095901',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5901',
      branchName: '草屯分行',
      totalName: '5901 ╴草屯分行'
    },
    {
      bankCodeString: '0095918',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5918',
      branchName: '南投分行',
      totalName: '5918 ╴南投分行'
    },
    {
      bankCodeString: '0095925',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5925',
      branchName: '埔里分行',
      totalName: '5925 ╴埔里分行'
    },
    {
      bankCodeString: '0095932',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5932',
      branchName: '水裡坑分行',
      totalName: '5932 ╴水裡坑分行'
    },
    {
      bankCodeString: '0095963',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5963',
      branchName: '大肚分行',
      totalName: '5963 ╴大肚分行'
    },
    {
      bankCodeString: '0095987',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '5987',
      branchName: '竹北分行',
      totalName: '5987 ╴竹北分行'
    },
    {
      bankCodeString: '0096006',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '6006',
      branchName: '彰化分行',
      totalName: '6006 ╴彰化分行'
    },
    {
      bankCodeString: '0096013',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '6013',
      branchName: '鹿港分行',
      totalName: '6013 ╴鹿港分行'
    },
    {
      bankCodeString: '0096020',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '6020',
      branchName: '員林分行',
      totalName: '6020 ╴員林分行'
    },
    {
      bankCodeString: '0096037',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '6037',
      branchName: '溪湖分行',
      totalName: '6037 ╴溪湖分行'
    },
    {
      bankCodeString: '0096044',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '6044',
      branchName: '北斗分行',
      totalName: '6044 ╴北斗分行'
    },
    {
      bankCodeString: '0096051',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '6051',
      branchName: '西螺分行',
      totalName: '6051 ╴西螺分行'
    },
    {
      bankCodeString: '0096068',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '6068',
      branchName: '和美分行',
      totalName: '6068 ╴和美分行'
    },
    {
      bankCodeString: '0096075',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '6075',
      branchName: '大里分行',
      totalName: '6075 ╴大里分行'
    },
    {
      bankCodeString: '0096099',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '6099',
      branchName: '二林分行',
      totalName: '6099 ╴二林分行'
    },
    {
      bankCodeString: '0096100',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '6100',
      branchName: '斗六分行',
      totalName: '6100 ╴斗六分行'
    },
    {
      bankCodeString: '0096117',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '6117',
      branchName: '斗南分行',
      totalName: '6117 ╴斗南分行'
    },
    {
      bankCodeString: '0096124',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '6124',
      branchName: '虎尾分行',
      totalName: '6124 ╴虎尾分行'
    },
    {
      bankCodeString: '0096131',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '6131',
      branchName: '北港分行',
      totalName: '6131 ╴北港分行'
    },
    {
      bankCodeString: '0096204',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '6204',
      branchName: '大林分行',
      totalName: '6204 ╴大林分行'
    },
    {
      bankCodeString: '0096211',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '6211',
      branchName: '嘉義分行',
      totalName: '6211 ╴嘉義分行'
    },
    {
      bankCodeString: '0096228',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '6228',
      branchName: '東嘉義分行',
      totalName: '6228 ╴東嘉義分行'
    },
    {
      bankCodeString: '0096235',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '6235',
      branchName: '新營分行',
      totalName: '6235 ╴新營分行'
    },
    {
      bankCodeString: '0096242',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '6242',
      branchName: '永康分行',
      totalName: '6242 ╴永康分行'
    },
    {
      bankCodeString: '0096266',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '6266',
      branchName: '歸仁分行',
      totalName: '6266 ╴歸仁分行'
    },
    {
      bankCodeString: '0096402',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '6402',
      branchName: '台南分行',
      totalName: '6402 ╴台南分行'
    },
    {
      bankCodeString: '0096419',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '6419',
      branchName: '延平分行',
      totalName: '6419 ╴延平分行'
    },
    {
      bankCodeString: '0096426',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '6426',
      branchName: '西台南分行',
      totalName: '6426 ╴西台南分行'
    },
    {
      bankCodeString: '0096433',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '6433',
      branchName: '東台南分行',
      totalName: '6433 ╴東台南分行'
    },
    {
      bankCodeString: '0096440',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '6440',
      branchName: '南台南分行',
      totalName: '6440 ╴南台南分行'
    },
    {
      bankCodeString: '0096457',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '6457',
      branchName: '安南分行',
      totalName: '6457 ╴安南分行'
    },
    {
      bankCodeString: '0096506',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '6506',
      branchName: '旗山分行',
      totalName: '6506 ╴旗山分行'
    },
    {
      bankCodeString: '0096513',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '6513',
      branchName: '岡山分行',
      totalName: '6513 ╴岡山分行'
    },
    {
      bankCodeString: '0096520',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '6520',
      branchName: '鳳山分行',
      totalName: '6520 ╴鳳山分行'
    },
    {
      bankCodeString: '0098119',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '8119',
      branchName: '高雄分行',
      totalName: '8119 ╴高雄分行'
    },
    {
      bankCodeString: '0098126',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '8126',
      branchName: '七賢分行',
      totalName: '8126 ╴七賢分行'
    },
    {
      bankCodeString: '0098133',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '8133',
      branchName: '鹽埕分行',
      totalName: '8133 ╴鹽埕分行'
    },
    {
      bankCodeString: '0098140',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '8140',
      branchName: '東高雄分行',
      totalName: '8140 ╴東高雄分行'
    },
    {
      bankCodeString: '0098157',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '8157',
      branchName: '南高雄分行',
      totalName: '8157 ╴南高雄分行'
    },
    {
      bankCodeString: '0098164',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '8164',
      branchName: '北高雄分行',
      totalName: '8164 ╴北高雄分行'
    },
    {
      bankCodeString: '0098171',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '8171',
      branchName: '三民分行',
      totalName: '8171 ╴三民分行'
    },
    {
      bankCodeString: '0098195',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '8195',
      branchName: '前鎮分行',
      totalName: '8195 ╴前鎮分行'
    },
    {
      bankCodeString: '0098206',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '8206',
      branchName: '九如路分行',
      totalName: '8206 ╴九如路分行'
    },
    {
      bankCodeString: '0098213',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '8213',
      branchName: '新興分行',
      totalName: '8213 ╴新興分行'
    },
    {
      bankCodeString: '0098220',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '8220',
      branchName: '苓雅分行',
      totalName: '8220 ╴苓雅分行'
    },
    {
      bankCodeString: '0098237',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '8237',
      branchName: '大順分行',
      totalName: '8237 ╴大順分行'
    },
    {
      bankCodeString: '0098244',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '8244',
      branchName: '博愛分行',
      totalName: '8244 ╴博愛分行'
    },
    {
      bankCodeString: '0098251',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '8251',
      branchName: '左營分行',
      totalName: '8251 ╴左營分行'
    },
    {
      bankCodeString: '0098300',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '8300',
      branchName: '屏東分行',
      totalName: '8300 ╴屏東分行'
    },
    {
      bankCodeString: '0098317',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '8317',
      branchName: '東港分行',
      totalName: '8317 ╴東港分行'
    },
    {
      bankCodeString: '0098324',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '8324',
      branchName: '潮州分行',
      totalName: '8324 ╴潮州分行'
    },
    {
      bankCodeString: '0098348',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '8348',
      branchName: '恆春分行',
      totalName: '8348 ╴恆春分行'
    },
    {
      bankCodeString: '0098404',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '8404',
      branchName: '花蓮分行',
      totalName: '8404 ╴花蓮分行'
    },
    {
      bankCodeString: '0098508',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '8508',
      branchName: '台東分行',
      totalName: '8508 ╴台東分行'
    },
    {
      bankCodeString: '0099269',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '9269',
      branchName: '思源分行',
      totalName: '9269 ╴思源分行'
    },
    {
      bankCodeString: '0099283',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '9283',
      branchName: '土城分行',
      totalName: '9283 ╴土城分行'
    },
    {
      bankCodeString: '0099290',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '9290',
      branchName: '北桃園分行',
      totalName: '9290 ╴北桃園分行'
    },
    {
      bankCodeString: '0099301',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '9301',
      branchName: '新明分行',
      totalName: '9301 ╴新明分行'
    },
    {
      bankCodeString: '0099318',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '9318',
      branchName: '埔心分行',
      totalName: '9318 ╴埔心分行'
    },
    {
      bankCodeString: '0099332',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '9332',
      branchName: '西屯分行',
      totalName: '9332 ╴西屯分行'
    },
    {
      bankCodeString: '0099349',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '9349',
      branchName: '大發分行',
      totalName: '9349 ╴大發分行'
    },
    {
      bankCodeString: '0099356',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '9356',
      branchName: '東林口分行',
      totalName: '9356 ╴東林口分行'
    },
    {
      bankCodeString: '0099363',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '9363',
      branchName: '新林口分行',
      totalName: '9363 ╴新林口分行'
    },
    {
      bankCodeString: '0099523',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '9523',
      branchName: '土庫分行',
      totalName: '9523 ╴土庫分行'
    },
    {
      bankCodeString: '0099547',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '9547',
      branchName: '路竹分行',
      totalName: '9547 ╴路竹分行'
    },
    {
      bankCodeString: '0099554',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '9554',
      branchName: '蘇澳分行',
      totalName: '9554 ╴蘇澳分行'
    },
    {
      bankCodeString: '0099561',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '9561',
      branchName: '北台南分行',
      totalName: '9561 ╴北台南分行'
    },
    {
      bankCodeString: '0099603',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '9603',
      branchName: '潭子分行',
      totalName: '9603 ╴潭子分行'
    },
    {
      bankCodeString: '0099610',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '9610',
      branchName: '北嘉義分行',
      totalName: '9610 ╴北嘉義分行'
    },
    {
      bankCodeString: '0099627',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '9627',
      branchName: '北新竹分行',
      totalName: '9627 ╴北新竹分行'
    },
    {
      bankCodeString: '0099634',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '9634',
      branchName: '建興分行',
      totalName: '9634 ╴建興分行'
    },
    {
      bankCodeString: '0099641',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '9641',
      branchName: '三和路分行',
      totalName: '9641 ╴三和路分行'
    },
    {
      bankCodeString: '0099658',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '9658',
      branchName: '吉成分行',
      totalName: '9658 ╴吉成分行'
    },
    {
      bankCodeString: '0099689',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '9689',
      branchName: '林口分行',
      totalName: '9689 ╴林口分行'
    },
    {
      bankCodeString: '0099696',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '9696',
      branchName: '江翠分行',
      totalName: '9696 ╴江翠分行'
    },
    {
      bankCodeString: '0099714',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '9714',
      branchName: '長安東路分行',
      totalName: '9714 ╴長安東路分行'
    },
    {
      bankCodeString: '0099721',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '9721',
      branchName: '晴光分行',
      totalName: '9721 ╴晴光分行'
    },
    {
      bankCodeString: '0099738',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '9738',
      branchName: '大直分行',
      totalName: '9738 ╴大直分行'
    },
    {
      bankCodeString: '0099752',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '9752',
      branchName: '松江分行',
      totalName: '9752 ╴松江分行'
    },
    {
      bankCodeString: '0099790',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '9790',
      branchName: '西內湖分行',
      totalName: '9790 ╴西內湖分行'
    },
    {
      bankCodeString: '0099801',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '9801',
      branchName: '南港分行',
      totalName: '9801 ╴南港分行'
    },
    {
      bankCodeString: '0099818',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '9818',
      branchName: '中港分行',
      totalName: '9818 ╴中港分行'
    },
    {
      bankCodeString: '0099832',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '9832',
      branchName: '蘆洲分行',
      totalName: '9832 ╴蘆洲分行'
    },
    {
      bankCodeString: '0099849',
      bankCode: '009',
      bankName: '彰化商業銀行',
      branchCode: '9849',
      branchName: '中華路分行',
      totalName: '9849 ╴中華路分行'
    }
  ],
  '011': [
    {
      bankCodeString: '0110001',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0001',
      branchName: '總管理處',
      totalName: '0001 ╴總管理處'
    },
    {
      bankCodeString: '0110002',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0002',
      branchName: '行政管理處',
      totalName: '0002 ╴行政管理處'
    },
    {
      bankCodeString: '0110003',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0003',
      branchName: '資訊總處',
      totalName: '0003 ╴資訊總處'
    },
    {
      bankCodeString: '0110004',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0004',
      branchName: '法遵暨法務處',
      totalName: '0004 ╴法遵暨法務處'
    },
    {
      bankCodeString: '0110005',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0005',
      branchName: '財務事業部',
      totalName: '0005 ╴財務事業部'
    },
    {
      bankCodeString: '0110006',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0006',
      branchName: '財務金融事業部',
      totalName: '0006 ╴財務金融事業部'
    },
    {
      bankCodeString: '0110007',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0007',
      branchName: '企業金融事業部',
      totalName: '0007 ╴企業金融事業部'
    },
    {
      bankCodeString: '0110008',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0008',
      branchName: '個人金融事業部',
      totalName: '0008 ╴個人金融事業部'
    },
    {
      bankCodeString: '0110009',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0009',
      branchName: '通路管理部',
      totalName: '0009 ╴通路管理部'
    },
    {
      bankCodeString: '0110010',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0010',
      branchName: '債權資產管理處',
      totalName: '0010 ╴債權資產管理處'
    },
    {
      bankCodeString: '0110011',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0011',
      branchName: '財富管理部',
      totalName: '0011 ╴財富管理部'
    },
    {
      bankCodeString: '0110012',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0012',
      branchName: '客戶金融事業部',
      totalName: '0012 ╴客戶金融事業部'
    },
    {
      bankCodeString: '0110013',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0013',
      branchName: '風險管理處',
      totalName: '0013 ╴風險管理處'
    },
    {
      bankCodeString: '0110014',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0014',
      branchName: '台北徵信處理中心',
      totalName: '0014 ╴台北徵信處理中心'
    },
    {
      bankCodeString: '0110015',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0015',
      branchName: '董事會秘書室',
      totalName: '0015 ╴董事會秘書室'
    },
    {
      bankCodeString: '0110016',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0016',
      branchName: '資訊研發處',
      totalName: '0016 ╴資訊研發處'
    },
    {
      bankCodeString: '0110026',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0026',
      branchName: '營業部',
      totalName: '0026 ╴營業部'
    },
    {
      bankCodeString: '0110037',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0037',
      branchName: '儲蓄部分行',
      totalName: '0037 ╴儲蓄部分行'
    },
    {
      bankCodeString: '0110048',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0048',
      branchName: '高雄分行',
      totalName: '0048 ╴高雄分行'
    },
    {
      bankCodeString: '0110059',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0059',
      branchName: '國外部',
      totalName: '0059 ╴國外部'
    },
    {
      bankCodeString: '0110060',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0060',
      branchName: '城中分行',
      totalName: '0060 ╴城中分行'
    },
    {
      bankCodeString: '0110073',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0073',
      branchName: '稽核處',
      totalName: '0073 ╴稽核處'
    },
    {
      bankCodeString: '0110074',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0074',
      branchName: '會計處',
      totalName: '0074 ╴會計處'
    },
    {
      bankCodeString: '0110075',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0075',
      branchName: '人力資源處',
      totalName: '0075 ╴人力資源處'
    },
    {
      bankCodeString: '0110081',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0081',
      branchName: '信用卡中心',
      totalName: '0081 ╴信用卡中心'
    },
    {
      bankCodeString: '0110082',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0082',
      branchName: '台中分行',
      totalName: '0082 ╴台中分行'
    },
    {
      bankCodeString: '0110093',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0093',
      branchName: '東台北分行',
      totalName: '0093 ╴東台北分行'
    },
    {
      bankCodeString: '0110107',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0107',
      branchName: '台南分行',
      totalName: '0107 ╴台南分行'
    },
    {
      bankCodeString: '0110118',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0118',
      branchName: '桃園分行',
      totalName: '0118 ╴桃園分行'
    },
    {
      bankCodeString: '0110129',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0129',
      branchName: '北高雄分行',
      totalName: '0129 ╴北高雄分行'
    },
    {
      bankCodeString: '0110130',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0130',
      branchName: '三重分行',
      totalName: '0130 ╴三重分行'
    },
    {
      bankCodeString: '0110141',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0141',
      branchName: '板橋分行',
      totalName: '0141 ╴板橋分行'
    },
    {
      bankCodeString: '0110152',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0152',
      branchName: '永和分行',
      totalName: '0152 ╴永和分行'
    },
    {
      bankCodeString: '0110163',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0163',
      branchName: '員林分行',
      totalName: '0163 ╴員林分行'
    },
    {
      bankCodeString: '0110168',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0168',
      branchName: '電子金融中心',
      totalName: '0168 ╴電子金融中心'
    },
    {
      bankCodeString: '0110174',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0174',
      branchName: '信託部',
      totalName: '0174 ╴信託部'
    },
    {
      bankCodeString: '0110185',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0185',
      branchName: '信義分行',
      totalName: '0185 ╴信義分行'
    },
    {
      bankCodeString: '0110196',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0196',
      branchName: '民生分行',
      totalName: '0196 ╴民生分行'
    },
    {
      bankCodeString: '0110200',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0200',
      branchName: '忠孝分行',
      totalName: '0200 ╴忠孝分行'
    },
    {
      bankCodeString: '0110211',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0211',
      branchName: '龍山分行',
      totalName: '0211 ╴龍山分行'
    },
    {
      bankCodeString: '0110222',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0222',
      branchName: '內湖分行',
      totalName: '0222 ╴內湖分行'
    },
    {
      bankCodeString: '0110233',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0233',
      branchName: '中山分行',
      totalName: '0233 ╴中山分行'
    },
    {
      bankCodeString: '0110244',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0244',
      branchName: '仁愛分行',
      totalName: '0244 ╴仁愛分行'
    },
    {
      bankCodeString: '0110255',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0255',
      branchName: '新莊分行',
      totalName: '0255 ╴新莊分行'
    },
    {
      bankCodeString: '0110266',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0266',
      branchName: '中壢分行',
      totalName: '0266 ╴中壢分行'
    },
    {
      bankCodeString: '0110277',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0277',
      branchName: '國際金融業務分行',
      totalName: '0277 ╴國際金融業務分行'
    },
    {
      bankCodeString: '0110288',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0288',
      branchName: '松山分行',
      totalName: '0288 ╴松山分行'
    },
    {
      bankCodeString: '0110299',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0299',
      branchName: '新店分行',
      totalName: '0299 ╴新店分行'
    },
    {
      bankCodeString: '0110303',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0303',
      branchName: '前金分行',
      totalName: '0303 ╴前金分行'
    },
    {
      bankCodeString: '0110314',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0314',
      branchName: '松南分行',
      totalName: '0314 ╴松南分行'
    },
    {
      bankCodeString: '0110325',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0325',
      branchName: '蘆洲分行',
      totalName: '0325 ╴蘆洲分行'
    },
    {
      bankCodeString: '0110336',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0336',
      branchName: '中和分行',
      totalName: '0336 ╴中和分行'
    },
    {
      bankCodeString: '0110347',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0347',
      branchName: '新竹分行',
      totalName: '0347 ╴新竹分行'
    },
    {
      bankCodeString: '0110358',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0358',
      branchName: '中港分行',
      totalName: '0358 ╴中港分行'
    },
    {
      bankCodeString: '0110369',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0369',
      branchName: '士林分行',
      totalName: '0369 ╴士林分行'
    },
    {
      bankCodeString: '0110370',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0370',
      branchName: '東台南分行',
      totalName: '0370 ╴東台南分行'
    },
    {
      bankCodeString: '0110381',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0381',
      branchName: '永康分行',
      totalName: '0381 ╴永康分行'
    },
    {
      bankCodeString: '0110392',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0392',
      branchName: '北三重分行',
      totalName: '0392 ╴北三重分行'
    },
    {
      bankCodeString: '0110406',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0406',
      branchName: '南京東路分行',
      totalName: '0406 ╴南京東路分行'
    },
    {
      bankCodeString: '0110417',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0417',
      branchName: '大里分行',
      totalName: '0417 ╴大里分行'
    },
    {
      bankCodeString: '0110428',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0428',
      branchName: '土城分行',
      totalName: '0428 ╴土城分行'
    },
    {
      bankCodeString: '0110439',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0439',
      branchName: '世貿分行',
      totalName: '0439 ╴世貿分行'
    },
    {
      bankCodeString: '0110440',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0440',
      branchName: '承德分行',
      totalName: '0440 ╴承德分行'
    },
    {
      bankCodeString: '0110451',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0451',
      branchName: '汐止分行',
      totalName: '0451 ╴汐止分行'
    },
    {
      bankCodeString: '0110462',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0462',
      branchName: '屏東分行',
      totalName: '0462 ╴屏東分行'
    },
    {
      bankCodeString: '0110473',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0473',
      branchName: '三民分行',
      totalName: '0473 ╴三民分行'
    },
    {
      bankCodeString: '0110484',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0484',
      branchName: '華江分行',
      totalName: '0484 ╴華江分行'
    },
    {
      bankCodeString: '0110495',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0495',
      branchName: '豐原分行',
      totalName: '0495 ╴豐原分行'
    },
    {
      bankCodeString: '0110509',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0509',
      branchName: '鳳山分行',
      totalName: '0509 ╴鳳山分行'
    },
    {
      bankCodeString: '0110510',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0510',
      branchName: '天母分行',
      totalName: '0510 ╴天母分行'
    },
    {
      bankCodeString: '0110521',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0521',
      branchName: '樹林分行',
      totalName: '0521 ╴樹林分行'
    },
    {
      bankCodeString: '0110532',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0532',
      branchName: '基隆分行',
      totalName: '0532 ╴基隆分行'
    },
    {
      bankCodeString: '0110543',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0543',
      branchName: '楊梅分行',
      totalName: '0543 ╴楊梅分行'
    },
    {
      bankCodeString: '0110554',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0554',
      branchName: '內湖科技園區分行',
      totalName: '0554 ╴內湖科技園區分行'
    },
    {
      bankCodeString: '0110565',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0565',
      branchName: '北中和分行',
      totalName: '0565 ╴北中和分行'
    },
    {
      bankCodeString: '0110576',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0576',
      branchName: '西湖分行',
      totalName: '0576 ╴西湖分行'
    },
    {
      bankCodeString: '0110598',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0598',
      branchName: '二重分行',
      totalName: '0598 ╴二重分行'
    },
    {
      bankCodeString: '0110602',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0602',
      branchName: '延平分行',
      totalName: '0602 ╴延平分行'
    },
    {
      bankCodeString: '0110613',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0613',
      branchName: '南崁分行',
      totalName: '0613 ╴南崁分行'
    },
    {
      bankCodeString: '0110624',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0624',
      branchName: '南港分行',
      totalName: '0624 ╴南港分行'
    },
    {
      bankCodeString: '0110635',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0635',
      branchName: '北新竹分行',
      totalName: '0635 ╴北新竹分行'
    },
    {
      bankCodeString: '0110646',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0646',
      branchName: '香港分行',
      totalName: '0646 ╴香港分行'
    },
    {
      bankCodeString: '0110657',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0657',
      branchName: '宜蘭分行',
      totalName: '0657 ╴宜蘭分行'
    },
    {
      bankCodeString: '0110668',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0668',
      branchName: '文山分行',
      totalName: '0668 ╴文山分行'
    },
    {
      bankCodeString: '0110679',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0679',
      branchName: '越南(同奈)分行',
      totalName: '0679 ╴越南(同奈)分行'
    },
    {
      bankCodeString: '0110680',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0680',
      branchName: '敦北分行',
      totalName: '0680 ╴敦北分行'
    },
    {
      bankCodeString: '0110691',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0691',
      branchName: '東高雄分行',
      totalName: '0691 ╴東高雄分行'
    },
    {
      bankCodeString: '0110705',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0705',
      branchName: '竹北分行',
      totalName: '0705 ╴竹北分行'
    },
    {
      bankCodeString: '0110716',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0716',
      branchName: '松江分行',
      totalName: '0716 ╴松江分行'
    },
    {
      bankCodeString: '0110727',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0727',
      branchName: '市政分行',
      totalName: '0727 ╴市政分行'
    },
    {
      bankCodeString: '0110738',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0738',
      branchName: '觀音分行',
      totalName: '0738 ╴觀音分行'
    },
    {
      bankCodeString: '0110749',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0749',
      branchName: '北桃園分行',
      totalName: '0749 ╴北桃園分行'
    },
    {
      bankCodeString: '0110750',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0750',
      branchName: '丹鳳分行',
      totalName: '0750 ╴丹鳳分行'
    },
    {
      bankCodeString: '0110761',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0761',
      branchName: '北新莊分行',
      totalName: '0761 ╴北新莊分行'
    },
    {
      bankCodeString: '0110772',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0772',
      branchName: '南屯分行',
      totalName: '0772 ╴南屯分行'
    },
    {
      bankCodeString: '0110783',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0783',
      branchName: '新加坡分行',
      totalName: '0783 ╴新加坡分行'
    },
    {
      bankCodeString: '0110794',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0794',
      branchName: '林口分行',
      totalName: '0794 ╴林口分行'
    },
    {
      bankCodeString: '0110808',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0808',
      branchName: '竹科分行',
      totalName: '0808 ╴竹科分行'
    },
    {
      bankCodeString: '0110819',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0819',
      branchName: '永吉分行',
      totalName: '0819 ╴永吉分行'
    },
    {
      bankCodeString: '0110820',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '0820',
      branchName: '無錫分行',
      totalName: '0820 ╴無錫分行'
    },
    {
      bankCodeString: '0112230',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '2230',
      branchName: '兼營證券商',
      totalName: '2230 ╴兼營證券商'
    },
    {
      bankCodeString: '0118712',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '8712',
      branchName: '泰國曼谷代表人辦事處',
      totalName: '8712 ╴泰國曼谷代表人辦事處'
    },
    {
      bankCodeString: '0118723',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '8723',
      branchName: '柬埔寨代表人辦事處',
      totalName: '8723 ╴柬埔寨代表人辦事處'
    },
    {
      bankCodeString: '0118734',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '8734',
      branchName: '印尼雅加達代表人辦事處',
      totalName: '8734 ╴印尼雅加達代表人辦事處'
    },
    {
      bankCodeString: '0118745',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '8745',
      branchName: '越南北寧代表人辦事處',
      totalName: '8745 ╴越南北寧代表人辦事處'
    },
    {
      bankCodeString: '0119001',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '9001',
      branchName: '台北作業中心',
      totalName: '9001 ╴台北作業中心'
    },
    {
      bankCodeString: '0119002',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '9002',
      branchName: '桃竹區作業中心',
      totalName: '9002 ╴桃竹區作業中心'
    },
    {
      bankCodeString: '0119003',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '9003',
      branchName: '中區作業中心',
      totalName: '9003 ╴中區作業中心'
    },
    {
      bankCodeString: '0119005',
      bankCode: '011',
      bankName: '上海商業儲蓄銀行',
      branchCode: '9005',
      branchName: '南區作業中心',
      totalName: '9005 ╴南區作業中心'
    }
  ],
  '012': [
    {
      bankCodeString: '0120014',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '0014',
      branchName: '採購營繕支援部',
      totalName: '0014 ╴採購營繕支援部'
    },
    {
      bankCodeString: '0120021',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '0021',
      branchName: '會計部',
      totalName: '0021 ╴會計部'
    },
    {
      bankCodeString: '0120022',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '0022',
      branchName: '人資規劃部',
      totalName: '0022 ╴人資規劃部'
    },
    {
      bankCodeString: '0120026',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '0026',
      branchName: '董事會稽核室',
      totalName: '0026 ╴董事會稽核室'
    },
    {
      bankCodeString: '0120027',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '0027',
      branchName: '法律事務部',
      totalName: '0027 ╴法律事務部'
    },
    {
      bankCodeString: '0120028',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '0028',
      branchName: '資訊服務總處',
      totalName: '0028 ╴資訊服務總處'
    },
    {
      bankCodeString: '0120030',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '0030',
      branchName: '個金客服部',
      totalName: '0030 ╴個金客服部'
    },
    {
      bankCodeString: '0120031',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '0031',
      branchName: '個人金融總處',
      totalName: '0031 ╴個人金融總處'
    },
    {
      bankCodeString: '0120033',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '0033',
      branchName: '風險管理部',
      totalName: '0033 ╴風險管理部'
    },
    {
      bankCodeString: '0120035',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '0035',
      branchName: '法金授管部',
      totalName: '0035 ╴法金授管部'
    },
    {
      bankCodeString: '0120037',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '0037',
      branchName: '法金營運管理部',
      totalName: '0037 ╴法金營運管理部'
    },
    {
      bankCodeString: '0120038',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '0038',
      branchName: '無擔保催收中心',
      totalName: '0038 ╴無擔保催收中心'
    },
    {
      bankCodeString: '0120050',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '0050',
      branchName: '彩券部',
      totalName: '0050 ╴彩券部'
    },
    {
      bankCodeString: '0120051',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '0051',
      branchName: '彩券部中區服務處',
      totalName: '0051 ╴彩券部中區服務處'
    },
    {
      bankCodeString: '0120053',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '0053',
      branchName: '彩券部東區服務處',
      totalName: '0053 ╴彩券部東區服務處'
    },
    {
      bankCodeString: '0120054',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '0054',
      branchName: '彩券部南區服務處',
      totalName: '0054 ╴彩券部南區服務處'
    },
    {
      bankCodeString: '0120108',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '0108',
      branchName: '董事會',
      totalName: '0108 ╴董事會'
    },
    {
      bankCodeString: '0120109',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '0109',
      branchName: '監察人',
      totalName: '0109 ╴監察人'
    },
    {
      bankCodeString: '0120190',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '0190',
      branchName: '法人金融總處',
      totalName: '0190 ╴法人金融總處'
    },
    {
      bankCodeString: '0120740',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '0740',
      branchName: '兼營證券商',
      totalName: '0740 ╴兼營證券商'
    },
    {
      bankCodeString: '0120809',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '0809',
      branchName: '個金作業服務部',
      totalName: '0809 ╴個金作業服務部'
    },
    {
      bankCodeString: '0121600',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '1600',
      branchName: '法人金融總處',
      totalName: '1600 ╴法人金融總處'
    },
    {
      bankCodeString: '0121703',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '1703',
      branchName: '企金債管部',
      totalName: '1703 ╴企金債管部'
    },
    {
      bankCodeString: '0121704',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '1704',
      branchName: '企劃部',
      totalName: '1704 ╴企劃部'
    },
    {
      bankCodeString: '0121840',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '1840',
      branchName: '人資營運部',
      totalName: '1840 ╴人資營運部'
    },
    {
      bankCodeString: '0122009',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '2009',
      branchName: '營業部',
      totalName: '2009 ╴營業部'
    },
    {
      bankCodeString: '0122032',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '2032',
      branchName: '長安東路分行',
      totalName: '2032 ╴長安東路分行'
    },
    {
      bankCodeString: '0122102',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '2102',
      branchName: '公庫處',
      totalName: '2102 ╴公庫處'
    },
    {
      bankCodeString: '0122205',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '2205',
      branchName: '城東分行',
      totalName: '2205 ╴城東分行'
    },
    {
      bankCodeString: '0122504',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '2504',
      branchName: '信託部',
      totalName: '2504 ╴信託部'
    },
    {
      bankCodeString: '0123006',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '3006',
      branchName: '士林分行',
      totalName: '3006 ╴士林分行'
    },
    {
      bankCodeString: '0123017',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '3017',
      branchName: '士東分行',
      totalName: '3017 ╴士東分行'
    },
    {
      bankCodeString: '0123039',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '3039',
      branchName: '玉成分行',
      totalName: '3039 ╴玉成分行'
    },
    {
      bankCodeString: '0123040',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '3040',
      branchName: '福港分行',
      totalName: '3040 ╴福港分行'
    },
    {
      bankCodeString: '0123051',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '3051',
      branchName: '忠孝分行',
      totalName: '3051 ╴忠孝分行'
    },
    {
      bankCodeString: '0123109',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '3109',
      branchName: '延平分行',
      totalName: '3109 ╴延平分行'
    },
    {
      bankCodeString: '0123202',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '3202',
      branchName: '木柵分行',
      totalName: '3202 ╴木柵分行'
    },
    {
      bankCodeString: '0123408',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '3408',
      branchName: '八德分行',
      totalName: '3408 ╴八德分行'
    },
    {
      bankCodeString: '0123419',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '3419',
      branchName: '松南分行',
      totalName: '3419 ╴松南分行'
    },
    {
      bankCodeString: '0123420',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '3420',
      branchName: '永吉分行',
      totalName: '3420 ╴永吉分行'
    },
    {
      bankCodeString: '0123501',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '3501',
      branchName: '中山分行',
      totalName: '3501 ╴中山分行'
    },
    {
      bankCodeString: '0123604',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '3604',
      branchName: '北投分行',
      totalName: '3604 ╴北投分行'
    },
    {
      bankCodeString: '0123615',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '3615',
      branchName: '石牌分行',
      totalName: '3615 ╴石牌分行'
    },
    {
      bankCodeString: '0123707',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '3707',
      branchName: '大安分行',
      totalName: '3707 ╴大安分行'
    },
    {
      bankCodeString: '0123800',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '3800',
      branchName: '大同分行',
      totalName: '3800 ╴大同分行'
    },
    {
      bankCodeString: '0123903',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '3903',
      branchName: '古亭分行',
      totalName: '3903 ╴古亭分行'
    },
    {
      bankCodeString: '0124003',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '4003',
      branchName: '雙園分行',
      totalName: '4003 ╴雙園分行'
    },
    {
      bankCodeString: '0124014',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '4014',
      branchName: '萬華分行',
      totalName: '4014 ╴萬華分行'
    },
    {
      bankCodeString: '0124106',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '4106',
      branchName: '建成分行',
      totalName: '4106 ╴建成分行'
    },
    {
      bankCodeString: '0124117',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '4117',
      branchName: '市府分行',
      totalName: '4117 ╴市府分行'
    },
    {
      bankCodeString: '0124209',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '4209',
      branchName: '南港分行',
      totalName: '4209 ╴南港分行'
    },
    {
      bankCodeString: '0124302',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '4302',
      branchName: '景美分行',
      totalName: '4302 ╴景美分行'
    },
    {
      bankCodeString: '0124313',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '4313',
      branchName: '興隆分行',
      totalName: '4313 ╴興隆分行'
    },
    {
      bankCodeString: '0124405',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '4405',
      branchName: '內湖分行',
      totalName: '4405 ╴內湖分行'
    },
    {
      bankCodeString: '0124427',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '4427',
      branchName: '文德分行',
      totalName: '4427 ╴文德分行'
    },
    {
      bankCodeString: '0124542',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '4542',
      branchName: '民生分行',
      totalName: '4542 ╴民生分行'
    },
    {
      bankCodeString: '0124601',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '4601',
      branchName: '信義分行',
      totalName: '4601 ╴信義分行'
    },
    {
      bankCodeString: '0124612',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '4612',
      branchName: '莊敬分行',
      totalName: '4612 ╴莊敬分行'
    },
    {
      bankCodeString: '0124623',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '4623',
      branchName: '東門分行',
      totalName: '4623 ╴東門分行'
    },
    {
      bankCodeString: '0124704',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '4704',
      branchName: '松江分行',
      totalName: '4704 ╴松江分行'
    },
    {
      bankCodeString: '0124807',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '4807',
      branchName: '和平分行',
      totalName: '4807 ╴和平分行'
    },
    {
      bankCodeString: '0125103',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '5103',
      branchName: '南門分行',
      totalName: '5103 ╴南門分行'
    },
    {
      bankCodeString: '0125309',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '5309',
      branchName: '西松分行',
      totalName: '5309 ╴西松分行'
    },
    {
      bankCodeString: '0125505',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '5505',
      branchName: '桂林分行',
      totalName: '5505 ╴桂林分行'
    },
    {
      bankCodeString: '0125608',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '5608',
      branchName: '國際金融業務分行',
      totalName: '5608 ╴國際金融業務分行'
    },
    {
      bankCodeString: '0125701',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '5701',
      branchName: '敦和分行',
      totalName: '5701 ╴敦和分行'
    },
    {
      bankCodeString: '0125804',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '5804',
      branchName: '臨沂分行',
      totalName: '5804 ╴臨沂分行'
    },
    {
      bankCodeString: '0125907',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '5907',
      branchName: '中崙分行',
      totalName: '5907 ╴中崙分行'
    },
    {
      bankCodeString: '0126007',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '6007',
      branchName: '基隆路分行',
      totalName: '6007 ╴基隆路分行'
    },
    {
      bankCodeString: '0126100',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '6100',
      branchName: '師大分行',
      totalName: '6100 ╴師大分行'
    },
    {
      bankCodeString: '0126203',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '6203',
      branchName: '永春分行',
      totalName: '6203 ╴永春分行'
    },
    {
      bankCodeString: '0126214',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '6214',
      branchName: '懷生分行',
      totalName: '6214 ╴懷生分行'
    },
    {
      bankCodeString: '0126306',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '6306',
      branchName: '民權分行',
      totalName: '6306 ╴民權分行'
    },
    {
      bankCodeString: '0126502',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '6502',
      branchName: '社子分行',
      totalName: '6502 ╴社子分行'
    },
    {
      bankCodeString: '0126605',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '6605',
      branchName: '港都分行',
      totalName: '6605 ╴港都分行'
    },
    {
      bankCodeString: '0126683',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '6683',
      branchName: '西湖分行',
      totalName: '6683 ╴西湖分行'
    },
    {
      bankCodeString: '0126719',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '6719',
      branchName: '中港分行',
      totalName: '6719 ╴中港分行'
    },
    {
      bankCodeString: '0126720',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '6720',
      branchName: '新莊分行',
      totalName: '6720 ╴新莊分行'
    },
    {
      bankCodeString: '0126731',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '6731',
      branchName: '桃園分行',
      totalName: '6731 ╴桃園分行'
    },
    {
      bankCodeString: '0126742',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '6742',
      branchName: '台南分行',
      totalName: '6742 ╴台南分行'
    },
    {
      bankCodeString: '0126775',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '6775',
      branchName: '埔墘分行',
      totalName: '6775 ╴埔墘分行'
    },
    {
      bankCodeString: '0126786',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '6786',
      branchName: '北中壢分行',
      totalName: '6786 ╴北中壢分行'
    },
    {
      bankCodeString: '0126797',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '6797',
      branchName: '三重分行',
      totalName: '6797 ╴三重分行'
    },
    {
      bankCodeString: '0126801',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '6801',
      branchName: '豐原分行',
      totalName: '6801 ╴豐原分行'
    },
    {
      bankCodeString: '0126812',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '6812',
      branchName: '中和分行',
      totalName: '6812 ╴中和分行'
    },
    {
      bankCodeString: '0126823',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '6823',
      branchName: '鼓山分行',
      totalName: '6823 ╴鼓山分行'
    },
    {
      bankCodeString: '0126845',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '6845',
      branchName: '風城分行',
      totalName: '6845 ╴風城分行'
    },
    {
      bankCodeString: '0126856',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '6856',
      branchName: '彰化分行',
      totalName: '6856 ╴彰化分行'
    },
    {
      bankCodeString: '0126867',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '6867',
      branchName: '東湖分行',
      totalName: '6867 ╴東湖分行'
    },
    {
      bankCodeString: '0126878',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '6878',
      branchName: '永和分行',
      totalName: '6878 ╴永和分行'
    },
    {
      bankCodeString: '0126889',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '6889',
      branchName: '岡山簡易型分行',
      totalName: '6889 ╴岡山簡易型分行'
    },
    {
      bankCodeString: '0126890',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '6890',
      branchName: '台北101分行',
      totalName: '6890 ╴台北101分行'
    },
    {
      bankCodeString: '0127026',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7026',
      branchName: '南京東路分行',
      totalName: '7026 ╴南京東路分行'
    },
    {
      bankCodeString: '0127037',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7037',
      branchName: '敦北分行',
      totalName: '7037 ╴敦北分行'
    },
    {
      bankCodeString: '0127048',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7048',
      branchName: '仁愛分行',
      totalName: '7048 ╴仁愛分行'
    },
    {
      bankCodeString: '0127059',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7059',
      branchName: '高雄分行',
      totalName: '7059 ╴高雄分行'
    },
    {
      bankCodeString: '0127060',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7060',
      branchName: '中正分行',
      totalName: '7060 ╴中正分行'
    },
    {
      bankCodeString: '0127071',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7071',
      branchName: '台中分行',
      totalName: '7071 ╴台中分行'
    },
    {
      bankCodeString: '0127093',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7093',
      branchName: '松山分行',
      totalName: '7093 ╴松山分行'
    },
    {
      bankCodeString: '0127107',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7107',
      branchName: '土城分行',
      totalName: '7107 ╴土城分行'
    },
    {
      bankCodeString: '0127118',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7118',
      branchName: '東寧分行',
      totalName: '7118 ╴東寧分行'
    },
    {
      bankCodeString: '0127129',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7129',
      branchName: '鳳山分行',
      totalName: '7129 ╴鳳山分行'
    },
    {
      bankCodeString: '0127130',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7130',
      branchName: '中壢分行',
      totalName: '7130 ╴中壢分行'
    },
    {
      bankCodeString: '0127152',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7152',
      branchName: '安和分行',
      totalName: '7152 ╴安和分行'
    },
    {
      bankCodeString: '0127163',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7163',
      branchName: '正義分行',
      totalName: '7163 ╴正義分行'
    },
    {
      bankCodeString: '0127174',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7174',
      branchName: '大湳分行',
      totalName: '7174 ╴大湳分行'
    },
    {
      bankCodeString: '0127185',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7185',
      branchName: '嘉義分行',
      totalName: '7185 ╴嘉義分行'
    },
    {
      bankCodeString: '0127211',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7211',
      branchName: '板橋分行',
      totalName: '7211 ╴板橋分行'
    },
    {
      bankCodeString: '0127222',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7222',
      branchName: '北台中分行',
      totalName: '7222 ╴北台中分行'
    },
    {
      bankCodeString: '0127233',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7233',
      branchName: '三民分行',
      totalName: '7233 ╴三民分行'
    },
    {
      bankCodeString: '0127244',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7244',
      branchName: '建國分行',
      totalName: '7244 ╴建國分行'
    },
    {
      bankCodeString: '0127255',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7255',
      branchName: '新竹分行',
      totalName: '7255 ╴新竹分行'
    },
    {
      bankCodeString: '0127266',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7266',
      branchName: '新店分行',
      totalName: '7266 ╴新店分行'
    },
    {
      bankCodeString: '0127277',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7277',
      branchName: '天母分行',
      totalName: '7277 ╴天母分行'
    },
    {
      bankCodeString: '0127288',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7288',
      branchName: '汐止分行',
      totalName: '7288 ╴汐止分行'
    },
    {
      bankCodeString: '0127303',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7303',
      branchName: '永康分行',
      totalName: '7303 ╴永康分行'
    },
    {
      bankCodeString: '0127314',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7314',
      branchName: '城中分行',
      totalName: '7314 ╴城中分行'
    },
    {
      bankCodeString: '0127347',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7347',
      branchName: '新營分行',
      totalName: '7347 ╴新營分行'
    },
    {
      bankCodeString: '0127358',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7358',
      branchName: '屏東分行',
      totalName: '7358 ╴屏東分行'
    },
    {
      bankCodeString: '0127369',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7369',
      branchName: '前鎮分行',
      totalName: '7369 ╴前鎮分行'
    },
    {
      bankCodeString: '0127370',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7370',
      branchName: '敦南分行',
      totalName: '7370 ╴敦南分行'
    },
    {
      bankCodeString: '0127381',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7381',
      branchName: '保生分行',
      totalName: '7381 ╴保生分行'
    },
    {
      bankCodeString: '0127392',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7392',
      branchName: '員林分行',
      totalName: '7392 ╴員林分行'
    },
    {
      bankCodeString: '0127406',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7406',
      branchName: '羅東分行',
      totalName: '7406 ╴羅東分行'
    },
    {
      bankCodeString: '0127417',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7417',
      branchName: '瑞湖分行',
      totalName: '7417 ╴瑞湖分行'
    },
    {
      bankCodeString: '0127451',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7451',
      branchName: '花蓮分行',
      totalName: '7451 ╴花蓮分行'
    },
    {
      bankCodeString: '0127462',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7462',
      branchName: '竹北分行',
      totalName: '7462 ╴竹北分行'
    },
    {
      bankCodeString: '0127473',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7473',
      branchName: '南台中分行',
      totalName: '7473 ╴南台中分行'
    },
    {
      bankCodeString: '0127484',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7484',
      branchName: '博愛分行',
      totalName: '7484 ╴博愛分行'
    },
    {
      bankCodeString: '0127495',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7495',
      branchName: '蘆洲分行',
      totalName: '7495 ╴蘆洲分行'
    },
    {
      bankCodeString: '0127509',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7509',
      branchName: '華江分行',
      totalName: '7509 ╴華江分行'
    },
    {
      bankCodeString: '0127510',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7510',
      branchName: '大直分行',
      totalName: '7510 ╴大直分行'
    },
    {
      bankCodeString: '0127521',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7521',
      branchName: '樹林分行',
      totalName: '7521 ╴樹林分行'
    },
    {
      bankCodeString: '0127532',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7532',
      branchName: '基隆分行',
      totalName: '7532 ╴基隆分行'
    },
    {
      bankCodeString: '0127543',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7543',
      branchName: '竹科分行',
      totalName: '7543 ╴竹科分行'
    },
    {
      bankCodeString: '0127554',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7554',
      branchName: '林口分行',
      totalName: '7554 ╴林口分行'
    },
    {
      bankCodeString: '0127565',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7565',
      branchName: '新板分行',
      totalName: '7565 ╴新板分行'
    },
    {
      bankCodeString: '0127576',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7576',
      branchName: '三峽分行',
      totalName: '7576 ╴三峽分行'
    },
    {
      bankCodeString: '0127587',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7587',
      branchName: '南崁分行',
      totalName: '7587 ╴南崁分行'
    },
    {
      bankCodeString: '0127598',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7598',
      branchName: '東台南分行',
      totalName: '7598 ╴東台南分行'
    },
    {
      bankCodeString: '0127602',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7602',
      branchName: '左營分行',
      totalName: '7602 ╴左營分行'
    },
    {
      bankCodeString: '0127613',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7613',
      branchName: '大里分行',
      totalName: '7613 ╴大里分行'
    },
    {
      bankCodeString: '0127624',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7624',
      branchName: '得和分行',
      totalName: '7624 ╴得和分行'
    },
    {
      bankCodeString: '0127635',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7635',
      branchName: '斗六分行',
      totalName: '7635 ╴斗六分行'
    },
    {
      bankCodeString: '0127646',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7646',
      branchName: '國美分行',
      totalName: '7646 ╴國美分行'
    },
    {
      bankCodeString: '0127657',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7657',
      branchName: '南勢角分行',
      totalName: '7657 ╴南勢角分行'
    },
    {
      bankCodeString: '0127668',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7668',
      branchName: '北屯分行',
      totalName: '7668 ╴北屯分行'
    },
    {
      bankCodeString: '0127679',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7679',
      branchName: '淡水分行',
      totalName: '7679 ╴淡水分行'
    },
    {
      bankCodeString: '0127680',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7680',
      branchName: '西屯分行',
      totalName: '7680 ╴西屯分行'
    },
    {
      bankCodeString: '0127691',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7691',
      branchName: '北新分行',
      totalName: '7691 ╴北新分行'
    },
    {
      bankCodeString: '0127705',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7705',
      branchName: '市政分行',
      totalName: '7705 ╴市政分行'
    },
    {
      bankCodeString: '0127716',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7716',
      branchName: '北新莊分行',
      totalName: '7716 ╴北新莊分行'
    },
    {
      bankCodeString: '0127727',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7727',
      branchName: '大雅簡易型分行',
      totalName: '7727 ╴大雅簡易型分行'
    },
    {
      bankCodeString: '0127738',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7738',
      branchName: '新北大道分行',
      totalName: '7738 ╴新北大道分行'
    },
    {
      bankCodeString: '0127750',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7750',
      branchName: '東林口分行',
      totalName: '7750 ╴東林口分行'
    },
    {
      bankCodeString: '0127761',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7761',
      branchName: '南桃園分行',
      totalName: '7761 ╴南桃園分行'
    },
    {
      bankCodeString: '0127772',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7772',
      branchName: '信安分行',
      totalName: '7772 ╴信安分行'
    },
    {
      bankCodeString: '0127783',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7783',
      branchName: '光明分行',
      totalName: '7783 ╴光明分行'
    },
    {
      bankCodeString: '0127794',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7794',
      branchName: '宜蘭分行',
      totalName: '7794 ╴宜蘭分行'
    },
    {
      bankCodeString: '0127808',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7808',
      branchName: '台東分行',
      totalName: '7808 ╴台東分行'
    },
    {
      bankCodeString: '0127819',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7819',
      branchName: '北台南分行',
      totalName: '7819 ╴北台南分行'
    },
    {
      bankCodeString: '0127820',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7820',
      branchName: '竹南分行',
      totalName: '7820 ╴竹南分行'
    },
    {
      bankCodeString: '0127831',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '7831',
      branchName: '虎尾分行',
      totalName: '7831 ╴虎尾分行'
    },
    {
      bankCodeString: '0129307',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '9307',
      branchName: '新加坡分行',
      totalName: '9307 ╴新加坡分行'
    },
    {
      bankCodeString: '0129400',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '9400',
      branchName: '雅加達代表人辦事處',
      totalName: '9400 ╴雅加達代表人辦事處'
    },
    {
      bankCodeString: '0129422',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '9422',
      branchName: '雪梨代表人辦事處',
      totalName: '9422 ╴雪梨代表人辦事處'
    },
    {
      bankCodeString: '0129503',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '9503',
      branchName: '香港分行',
      totalName: '9503 ╴香港分行'
    },
    {
      bankCodeString: '0129606',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '9606',
      branchName: '平陽分行',
      totalName: '9606 ╴平陽分行'
    },
    {
      bankCodeString: '0129628',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '9628',
      branchName: '河內分行',
      totalName: '9628 ╴河內分行'
    },
    {
      bankCodeString: '0129639',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '9639',
      branchName: '胡志明市分行',
      totalName: '9639 ╴胡志明市分行'
    },
    {
      bankCodeString: '0129710',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '9710',
      branchName: '富邦華一銀行重慶分行',
      totalName: '9710 ╴富邦華一銀行重慶分行'
    },
    {
      bankCodeString: '0129721',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '9721',
      branchName: '富邦華一銀行廣州分行',
      totalName: '9721 ╴富邦華一銀行廣州分行'
    },
    {
      bankCodeString: '0129732',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '9732',
      branchName: '富邦華一銀行西安分行',
      totalName: '9732 ╴富邦華一銀行西安分行'
    },
    {
      bankCodeString: '0129743',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '9743',
      branchName: '武漢分行',
      totalName: '9743 ╴武漢分行'
    },
    {
      bankCodeString: '0129754',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '9754',
      branchName: '蘇州昆山支行',
      totalName: '9754 ╴蘇州昆山支行'
    },
    {
      bankCodeString: '0129765',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '9765',
      branchName: '成都分行',
      totalName: '9765 ╴成都分行'
    },
    {
      bankCodeString: '0129776',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '9776',
      branchName: '北京分行',
      totalName: '9776 ╴北京分行'
    },
    {
      bankCodeString: '0129787',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '9787',
      branchName: '上海外灘支行',
      totalName: '9787 ╴上海外灘支行'
    },
    {
      bankCodeString: '0129798',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '9798',
      branchName: '深圳前海支行',
      totalName: '9798 ╴深圳前海支行'
    },
    {
      bankCodeString: '0129802',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '9802',
      branchName: '富邦華一銀行',
      totalName: '9802 ╴富邦華一銀行'
    },
    {
      bankCodeString: '0129813',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '9813',
      branchName: '上海虹橋支行',
      totalName: '9813 ╴上海虹橋支行'
    },
    {
      bankCodeString: '0129824',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '9824',
      branchName: '上海徐匯支行',
      totalName: '9824 ╴上海徐匯支行'
    },
    {
      bankCodeString: '0129835',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '9835',
      branchName: '上海世紀大道支行',
      totalName: '9835 ╴上海世紀大道支行'
    },
    {
      bankCodeString: '0129846',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '9846',
      branchName: '上海松江支行',
      totalName: '9846 ╴上海松江支行'
    },
    {
      bankCodeString: '0129857',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '9857',
      branchName: '深圳分行',
      totalName: '9857 ╴深圳分行'
    },
    {
      bankCodeString: '0129868',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '9868',
      branchName: '上海閔行支行',
      totalName: '9868 ╴上海閔行支行'
    },
    {
      bankCodeString: '0129879',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '9879',
      branchName: '天津分行',
      totalName: '9879 ╴天津分行'
    },
    {
      bankCodeString: '0129880',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '9880',
      branchName: '上海陸家嘴支行',
      totalName: '9880 ╴上海陸家嘴支行'
    },
    {
      bankCodeString: '0129881',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '9881',
      branchName: '上海新天地支行',
      totalName: '9881 ╴上海新天地支行'
    },
    {
      bankCodeString: '0129916',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '9916',
      branchName: '上海靜安支行',
      totalName: '9916 ╴上海靜安支行'
    },
    {
      bankCodeString: '0129927',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '9927',
      branchName: '天津自貿試驗區支行',
      totalName: '9927 ╴天津自貿試驗區支行'
    },
    {
      bankCodeString: '0129938',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '9938',
      branchName: '蘇州分行',
      totalName: '9938 ╴蘇州分行'
    },
    {
      bankCodeString: '0129949',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '9949',
      branchName: '上海長寧支行',
      totalName: '9949 ╴上海長寧支行'
    },
    {
      bankCodeString: '0129950',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '9950',
      branchName: '上海自由貿易試驗區支行',
      totalName: '9950 ╴上海自由貿易試驗區支行'
    },
    {
      bankCodeString: '0129961',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '9961',
      branchName: '上海日月光支行',
      totalName: '9961 ╴上海日月光支行'
    },
    {
      bankCodeString: '0129972',
      bankCode: '012',
      bankName: '台北富邦商業銀行',
      branchCode: '9972',
      branchName: '南京分行',
      totalName: '9972 ╴南京分行'
    }
  ],
  '013': [
    {
      bankCodeString: '0130001',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0001',
      branchName: '稽核室',
      totalName: '0001 ╴稽核室'
    },
    {
      bankCodeString: '0130002',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0002',
      branchName: '秘書室',
      totalName: '0002 ╴秘書室'
    },
    {
      bankCodeString: '0130004',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0004',
      branchName: '法務室',
      totalName: '0004 ╴法務室'
    },
    {
      bankCodeString: '0130005',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0005',
      branchName: '財富管理部',
      totalName: '0005 ╴財富管理部'
    },
    {
      bankCodeString: '0130006',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0006',
      branchName: '金融服務部',
      totalName: '0006 ╴金融服務部'
    },
    {
      bankCodeString: '0130008',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0008',
      branchName: '安全衛生暨總務部',
      totalName: '0008 ╴安全衛生暨總務部'
    },
    {
      bankCodeString: '0130009',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0009',
      branchName: '信用卡部',
      totalName: '0009 ╴信用卡部'
    },
    {
      bankCodeString: '0130010',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0010',
      branchName: '風險管理部',
      totalName: '0010 ╴風險管理部'
    },
    {
      bankCodeString: '0130012',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0012',
      branchName: '會計部',
      totalName: '0012 ╴會計部'
    },
    {
      bankCodeString: '0130013',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0013',
      branchName: '人力資源部',
      totalName: '0013 ╴人力資源部'
    },
    {
      bankCodeString: '0130014',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0014',
      branchName: '電子金融部',
      totalName: '0014 ╴電子金融部'
    },
    {
      bankCodeString: '0130016',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0016',
      branchName: '技術支援部',
      totalName: '0016 ╴技術支援部'
    },
    {
      bankCodeString: '0130017',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0017',
      branchName: '館前分行',
      totalName: '0017 ╴館前分行'
    },
    {
      bankCodeString: '0130018',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0018',
      branchName: '系統二部',
      totalName: '0018 ╴系統二部'
    },
    {
      bankCodeString: '0130019',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0019',
      branchName: '系統一部',
      totalName: '0019 ╴系統一部'
    },
    {
      bankCodeString: '0130020',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0020',
      branchName: '消費金融事業處',
      totalName: '0020 ╴消費金融事業處'
    },
    {
      bankCodeString: '0130021',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0021',
      branchName: '企業金融事業處',
      totalName: '0021 ╴企業金融事業處'
    },
    {
      bankCodeString: '0130022',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0022',
      branchName: '綜合企劃部',
      totalName: '0022 ╴綜合企劃部'
    },
    {
      bankCodeString: '0130028',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0028',
      branchName: '環球交易服務部',
      totalName: '0028 ╴環球交易服務部'
    },
    {
      bankCodeString: '0130039',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0039',
      branchName: '南京東路分行',
      totalName: '0039 ╴南京東路分行'
    },
    {
      bankCodeString: '0130040',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0040',
      branchName: '信託部',
      totalName: '0040 ╴信託部'
    },
    {
      bankCodeString: '0130051',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0051',
      branchName: '高雄分行',
      totalName: '0051 ╴高雄分行'
    },
    {
      bankCodeString: '0130062',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0062',
      branchName: '台中分行',
      totalName: '0062 ╴台中分行'
    },
    {
      bankCodeString: '0130073',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0073',
      branchName: '台北分行',
      totalName: '0073 ╴台北分行'
    },
    {
      bankCodeString: '0130095',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0095',
      branchName: '永和分行',
      totalName: '0095 ╴永和分行'
    },
    {
      bankCodeString: '0130109',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0109',
      branchName: '台南分行',
      totalName: '0109 ╴台南分行'
    },
    {
      bankCodeString: '0130110',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0110',
      branchName: '忠孝分行',
      totalName: '0110 ╴忠孝分行'
    },
    {
      bankCodeString: '0130121',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0121',
      branchName: '天母分行',
      totalName: '0121 ╴天母分行'
    },
    {
      bankCodeString: '0130132',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0132',
      branchName: '西台中分行',
      totalName: '0132 ╴西台中分行'
    },
    {
      bankCodeString: '0130143',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0143',
      branchName: '東高雄分行',
      totalName: '0143 ╴東高雄分行'
    },
    {
      bankCodeString: '0130154',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0154',
      branchName: '信義分行',
      totalName: '0154 ╴信義分行'
    },
    {
      bankCodeString: '0130165',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0165',
      branchName: '光復分行',
      totalName: '0165 ╴光復分行'
    },
    {
      bankCodeString: '0130176',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0176',
      branchName: '板橋分行',
      totalName: '0176 ╴板橋分行'
    },
    {
      bankCodeString: '0130187',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0187',
      branchName: '復興分行',
      totalName: '0187 ╴復興分行'
    },
    {
      bankCodeString: '0130198',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0198',
      branchName: '民權分行',
      totalName: '0198 ╴民權分行'
    },
    {
      bankCodeString: '0130202',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0202',
      branchName: '大安分行',
      totalName: '0202 ╴大安分行'
    },
    {
      bankCodeString: '0130213',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0213',
      branchName: '三重分行',
      totalName: '0213 ╴三重分行'
    },
    {
      bankCodeString: '0130224',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0224',
      branchName: '中壢分行',
      totalName: '0224 ╴中壢分行'
    },
    {
      bankCodeString: '0130235',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0235',
      branchName: '員林分行',
      totalName: '0235 ╴員林分行'
    },
    {
      bankCodeString: '0130246',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0246',
      branchName: '新店分行',
      totalName: '0246 ╴新店分行'
    },
    {
      bankCodeString: '0130257',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0257',
      branchName: '新莊分行',
      totalName: '0257 ╴新莊分行'
    },
    {
      bankCodeString: '0130268',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0268',
      branchName: '鳳山分行',
      totalName: '0268 ╴鳳山分行'
    },
    {
      bankCodeString: '0130279',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0279',
      branchName: '安和分行',
      totalName: '0279 ╴安和分行'
    },
    {
      bankCodeString: '0130280',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0280',
      branchName: '松江分行',
      totalName: '0280 ╴松江分行'
    },
    {
      bankCodeString: '0130291',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0291',
      branchName: '苓雅分行',
      totalName: '0291 ╴苓雅分行'
    },
    {
      bankCodeString: '0130305',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0305',
      branchName: '古亭分行',
      totalName: '0305 ╴古亭分行'
    },
    {
      bankCodeString: '0130316',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0316',
      branchName: '建成分行',
      totalName: '0316 ╴建成分行'
    },
    {
      bankCodeString: '0130327',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0327',
      branchName: '東門分行',
      totalName: '0327 ╴東門分行'
    },
    {
      bankCodeString: '0130338',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0338',
      branchName: '國際金融業務分行',
      totalName: '0338 ╴國際金融業務分行'
    },
    {
      bankCodeString: '0130349',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0349',
      branchName: '桃園分行',
      totalName: '0349 ╴桃園分行'
    },
    {
      bankCodeString: '0130350',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0350',
      branchName: '豐原分行',
      totalName: '0350 ╴豐原分行'
    },
    {
      bankCodeString: '0130361',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0361',
      branchName: '嘉義分行',
      totalName: '0361 ╴嘉義分行'
    },
    {
      bankCodeString: '0130372',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0372',
      branchName: '松山分行',
      totalName: '0372 ╴松山分行'
    },
    {
      bankCodeString: '0130383',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0383',
      branchName: '前金分行',
      totalName: '0383 ╴前金分行'
    },
    {
      bankCodeString: '0130394',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0394',
      branchName: '北三重分行',
      totalName: '0394 ╴北三重分行'
    },
    {
      bankCodeString: '0130408',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0408',
      branchName: '新竹分行',
      totalName: '0408 ╴新竹分行'
    },
    {
      bankCodeString: '0130419',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0419',
      branchName: '永康分行',
      totalName: '0419 ╴永康分行'
    },
    {
      bankCodeString: '0130420',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0420',
      branchName: '中山分行',
      totalName: '0420 ╴中山分行'
    },
    {
      bankCodeString: '0130431',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0431',
      branchName: '埔墘分行',
      totalName: '0431 ╴埔墘分行'
    },
    {
      bankCodeString: '0130442',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0442',
      branchName: '新生分行',
      totalName: '0442 ╴新生分行'
    },
    {
      bankCodeString: '0130453',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0453',
      branchName: '中和分行',
      totalName: '0453 ╴中和分行'
    },
    {
      bankCodeString: '0130464',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0464',
      branchName: '清水分行',
      totalName: '0464 ╴清水分行'
    },
    {
      bankCodeString: '0130475',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0475',
      branchName: '彰化分行',
      totalName: '0475 ╴彰化分行'
    },
    {
      bankCodeString: '0130486',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0486',
      branchName: '內科分行',
      totalName: '0486 ╴內科分行'
    },
    {
      bankCodeString: '0130497',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0497',
      branchName: '中正分行',
      totalName: '0497 ╴中正分行'
    },
    {
      bankCodeString: '0130501',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0501',
      branchName: '五權分行',
      totalName: '0501 ╴五權分行'
    },
    {
      bankCodeString: '0130512',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0512',
      branchName: '東台南分行',
      totalName: '0512 ╴東台南分行'
    },
    {
      bankCodeString: '0130523',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0523',
      branchName: '新興分行',
      totalName: '0523 ╴新興分行'
    },
    {
      bankCodeString: '0130534',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0534',
      branchName: '敦南分行',
      totalName: '0534 ╴敦南分行'
    },
    {
      bankCodeString: '0130545',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0545',
      branchName: '後埔分行',
      totalName: '0545 ╴後埔分行'
    },
    {
      bankCodeString: '0130556',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0556',
      branchName: '蘆洲分行',
      totalName: '0556 ╴蘆洲分行'
    },
    {
      bankCodeString: '0130567',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0567',
      branchName: '西門分行',
      totalName: '0567 ╴西門分行'
    },
    {
      bankCodeString: '0130578',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0578',
      branchName: '三民分行',
      totalName: '0578 ╴三民分行'
    },
    {
      bankCodeString: '0130589',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0589',
      branchName: '仰光分行',
      totalName: '0589 ╴仰光分行'
    },
    {
      bankCodeString: '0130590',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0590',
      branchName: '前鎮分行',
      totalName: '0590 ╴前鎮分行'
    },
    {
      bankCodeString: '0130604',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0604',
      branchName: '土城分行',
      totalName: '0604 ╴土城分行'
    },
    {
      bankCodeString: '0130615',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0615',
      branchName: '南高雄分行',
      totalName: '0615 ╴南高雄分行'
    },
    {
      bankCodeString: '0130626',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0626',
      branchName: '大同分行',
      totalName: '0626 ╴大同分行'
    },
    {
      bankCodeString: '0130637',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0637',
      branchName: '成功分行',
      totalName: '0637 ╴成功分行'
    },
    {
      bankCodeString: '0130648',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0648',
      branchName: '中港分行',
      totalName: '0648 ╴中港分行'
    },
    {
      bankCodeString: '0130659',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0659',
      branchName: '世貿分行',
      totalName: '0659 ╴世貿分行'
    },
    {
      bankCodeString: '0130660',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0660',
      branchName: '北桃園分行',
      totalName: '0660 ╴北桃園分行'
    },
    {
      bankCodeString: '0130671',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0671',
      branchName: '善化分行',
      totalName: '0671 ╴善化分行'
    },
    {
      bankCodeString: '0130682',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0682',
      branchName: '士林分行',
      totalName: '0682 ╴士林分行'
    },
    {
      bankCodeString: '0130693',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0693',
      branchName: '雙和分行',
      totalName: '0693 ╴雙和分行'
    },
    {
      bankCodeString: '0130707',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0707',
      branchName: '二重分行',
      totalName: '0707 ╴二重分行'
    },
    {
      bankCodeString: '0130729',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0729',
      branchName: '花蓮分行',
      totalName: '0729 ╴花蓮分行'
    },
    {
      bankCodeString: '0130730',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0730',
      branchName: '內湖分行',
      totalName: '0730 ╴內湖分行'
    },
    {
      bankCodeString: '0130741',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0741',
      branchName: '華江分行',
      totalName: '0741 ╴華江分行'
    },
    {
      bankCodeString: '0130752',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0752',
      branchName: '竹科分行',
      totalName: '0752 ╴竹科分行'
    },
    {
      bankCodeString: '0130763',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0763',
      branchName: '南門分行',
      totalName: '0763 ╴南門分行'
    },
    {
      bankCodeString: '0130774',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0774',
      branchName: '八德分行',
      totalName: '0774 ╴八德分行'
    },
    {
      bankCodeString: '0130785',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0785',
      branchName: '永春分行',
      totalName: '0785 ╴永春分行'
    },
    {
      bankCodeString: '0130796',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0796',
      branchName: '萬華分行',
      totalName: '0796 ╴萬華分行'
    },
    {
      bankCodeString: '0130800',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '0800',
      branchName: '財務部',
      totalName: '0800 ╴財務部'
    },
    {
      bankCodeString: '0131014',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '1014',
      branchName: '屏東分行',
      totalName: '1014 ╴屏東分行'
    },
    {
      bankCodeString: '0131025',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '1025',
      branchName: '台東分行',
      totalName: '1025 ╴台東分行'
    },
    {
      bankCodeString: '0131036',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '1036',
      branchName: '文山分行',
      totalName: '1036 ╴文山分行'
    },
    {
      bankCodeString: '0131047',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '1047',
      branchName: '新樹分行',
      totalName: '1047 ╴新樹分行'
    },
    {
      bankCodeString: '0131058',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '1058',
      branchName: '宜蘭分行',
      totalName: '1058 ╴宜蘭分行'
    },
    {
      bankCodeString: '0131069',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '1069',
      branchName: '南港分行',
      totalName: '1069 ╴南港分行'
    },
    {
      bankCodeString: '0131070',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '1070',
      branchName: '斗六分行',
      totalName: '1070 ╴斗六分行'
    },
    {
      bankCodeString: '0131081',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '1081',
      branchName: '南投分行',
      totalName: '1081 ╴南投分行'
    },
    {
      bankCodeString: '0131092',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '1092',
      branchName: '汐止分行',
      totalName: '1092 ╴汐止分行'
    },
    {
      bankCodeString: '0131106',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '1106',
      branchName: '大直分行',
      totalName: '1106 ╴大直分行'
    },
    {
      bankCodeString: '0131117',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '1117',
      branchName: '左營分行',
      totalName: '1117 ╴左營分行'
    },
    {
      bankCodeString: '0131128',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '1128',
      branchName: '新營分行',
      totalName: '1128 ╴新營分行'
    },
    {
      bankCodeString: '0131139',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '1139',
      branchName: '大甲分行',
      totalName: '1139 ╴大甲分行'
    },
    {
      bankCodeString: '0131140',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '1140',
      branchName: '苗栗分行',
      totalName: '1140 ╴苗栗分行'
    },
    {
      bankCodeString: '0131151',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '1151',
      branchName: '北投分行',
      totalName: '1151 ╴北投分行'
    },
    {
      bankCodeString: '0131162',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '1162',
      branchName: '臨安分行',
      totalName: '1162 ╴臨安分行'
    },
    {
      bankCodeString: '0131173',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '1173',
      branchName: '幸福分行',
      totalName: '1173 ╴幸福分行'
    },
    {
      bankCodeString: '0131184',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '1184',
      branchName: '華山分行',
      totalName: '1184 ╴華山分行'
    },
    {
      bankCodeString: '0131195',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '1195',
      branchName: '東湖分行',
      totalName: '1195 ╴東湖分行'
    },
    {
      bankCodeString: '0131209',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '1209',
      branchName: '岡山分行',
      totalName: '1209 ╴岡山分行'
    },
    {
      bankCodeString: '0131210',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '1210',
      branchName: '臨沂分行',
      totalName: '1210 ╴臨沂分行'
    },
    {
      bankCodeString: '0131221',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '1221',
      branchName: '新湖分行',
      totalName: '1221 ╴新湖分行'
    },
    {
      bankCodeString: '0131232',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '1232',
      branchName: '中崙分行',
      totalName: '1232 ╴中崙分行'
    },
    {
      bankCodeString: '0131243',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '1243',
      branchName: '基隆分行',
      totalName: '1243 ╴基隆分行'
    },
    {
      bankCodeString: '0131254',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '1254',
      branchName: '樹林分行',
      totalName: '1254 ╴樹林分行'
    },
    {
      bankCodeString: '0131276',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '1276',
      branchName: '淡水分行',
      totalName: '1276 ╴淡水分行'
    },
    {
      bankCodeString: '0131287',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '1287',
      branchName: '連城分行',
      totalName: '1287 ╴連城分行'
    },
    {
      bankCodeString: '0131298',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '1298',
      branchName: '石牌分行',
      totalName: '1298 ╴石牌分行'
    },
    {
      bankCodeString: '0131302',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '1302',
      branchName: '南內湖分行',
      totalName: '1302 ╴南內湖分行'
    },
    {
      bankCodeString: '0131313',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '1313',
      branchName: '景美分行',
      totalName: '1313 ╴景美分行'
    },
    {
      bankCodeString: '0131324',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '1324',
      branchName: '寶橋分行',
      totalName: '1324 ╴寶橋分行'
    },
    {
      bankCodeString: '0131335',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '1335',
      branchName: '丹鳳分行',
      totalName: '1335 ╴丹鳳分行'
    },
    {
      bankCodeString: '0131346',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '1346',
      branchName: '林口分行',
      totalName: '1346 ╴林口分行'
    },
    {
      bankCodeString: '0131357',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '1357',
      branchName: '南崁分行',
      totalName: '1357 ╴南崁分行'
    },
    {
      bankCodeString: '0131368',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '1368',
      branchName: '東林口分行',
      totalName: '1368 ╴東林口分行'
    },
    {
      bankCodeString: '0131379',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '1379',
      branchName: '大湳分行',
      totalName: '1379 ╴大湳分行'
    },
    {
      bankCodeString: '0131380',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '1380',
      branchName: '松勤分行',
      totalName: '1380 ╴松勤分行'
    },
    {
      bankCodeString: '0131391',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '1391',
      branchName: '六家分行',
      totalName: '1391 ╴六家分行'
    },
    {
      bankCodeString: '0132011',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2011',
      branchName: '仁愛分行',
      totalName: '2011 ╴仁愛分行'
    },
    {
      bankCodeString: '0132022',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2022',
      branchName: '大昌分行',
      totalName: '2022 ╴大昌分行'
    },
    {
      bankCodeString: '0132033',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2033',
      branchName: '民生分行',
      totalName: '2033 ╴民生分行'
    },
    {
      bankCodeString: '0132044',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2044',
      branchName: '篤行分行',
      totalName: '2044 ╴篤行分行'
    },
    {
      bankCodeString: '0132055',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2055',
      branchName: '板東分行',
      totalName: '2055 ╴板東分行'
    },
    {
      bankCodeString: '0132066',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2066',
      branchName: '桃興分行',
      totalName: '2066 ╴桃興分行'
    },
    {
      bankCodeString: '0132099',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2099',
      branchName: '嘉泰分行',
      totalName: '2099 ╴嘉泰分行'
    },
    {
      bankCodeString: '0132103',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2103',
      branchName: '竹城分行',
      totalName: '2103 ╴竹城分行'
    },
    {
      bankCodeString: '0132114',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2114',
      branchName: '忠誠分行',
      totalName: '2114 ╴忠誠分行'
    },
    {
      bankCodeString: '0132125',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2125',
      branchName: '敦化分行',
      totalName: '2125 ╴敦化分行'
    },
    {
      bankCodeString: '0132147',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2147',
      branchName: '新泰分行',
      totalName: '2147 ╴新泰分行'
    },
    {
      bankCodeString: '0132158',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2158',
      branchName: '文心分行',
      totalName: '2158 ╴文心分行'
    },
    {
      bankCodeString: '0132169',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2169',
      branchName: '福和分行',
      totalName: '2169 ╴福和分行'
    },
    {
      bankCodeString: '0132170',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2170',
      branchName: '四維分行',
      totalName: '2170 ╴四維分行'
    },
    {
      bankCodeString: '0132181',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2181',
      branchName: '營業部',
      totalName: '2181 ╴營業部'
    },
    {
      bankCodeString: '0132206',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2206',
      branchName: '重新分行',
      totalName: '2206 ╴重新分行'
    },
    {
      bankCodeString: '0132217',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2217',
      branchName: '北中壢分行',
      totalName: '2217 ╴北中壢分行'
    },
    {
      bankCodeString: '0132228',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2228',
      branchName: '城東分行',
      totalName: '2228 ╴城東分行'
    },
    {
      bankCodeString: '0132239',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2239',
      branchName: '建國分行',
      totalName: '2239 ╴建國分行'
    },
    {
      bankCodeString: '0132251',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2251',
      branchName: '學府分行',
      totalName: '2251 ╴學府分行'
    },
    {
      bankCodeString: '0132262',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2262',
      branchName: '北新分行',
      totalName: '2262 ╴北新分行'
    },
    {
      bankCodeString: '0132273',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2273',
      branchName: '信安分行',
      totalName: '2273 ╴信安分行'
    },
    {
      bankCodeString: '0132295',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2295',
      branchName: '文德分行',
      totalName: '2295 ╴文德分行'
    },
    {
      bankCodeString: '0132321',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2321',
      branchName: '公益分行',
      totalName: '2321 ╴公益分行'
    },
    {
      bankCodeString: '0132332',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2332',
      branchName: '水湳分行',
      totalName: '2332 ╴水湳分行'
    },
    {
      bankCodeString: '0132343',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2343',
      branchName: '市政分行',
      totalName: '2343 ╴市政分行'
    },
    {
      bankCodeString: '0132354',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2354',
      branchName: '東台中分行',
      totalName: '2354 ╴東台中分行'
    },
    {
      bankCodeString: '0132365',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2365',
      branchName: '昌平分行',
      totalName: '2365 ╴昌平分行'
    },
    {
      bankCodeString: '0132376',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2376',
      branchName: '健行分行',
      totalName: '2376 ╴健行分行'
    },
    {
      bankCodeString: '0132398',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2398',
      branchName: '西屯分行',
      totalName: '2398 ╴西屯分行'
    },
    {
      bankCodeString: '0132402',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2402',
      branchName: '南屯分行',
      totalName: '2402 ╴南屯分行'
    },
    {
      bankCodeString: '0132413',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2413',
      branchName: '崇德分行',
      totalName: '2413 ╴崇德分行'
    },
    {
      bankCodeString: '0132457',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2457',
      branchName: '香山分行',
      totalName: '2457 ╴香山分行'
    },
    {
      bankCodeString: '0132468',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2468',
      branchName: '竹北分行',
      totalName: '2468 ╴竹北分行'
    },
    {
      bankCodeString: '0132480',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2480',
      branchName: '同德分行',
      totalName: '2480 ╴同德分行'
    },
    {
      bankCodeString: '0132549',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2549',
      branchName: '彰美分行',
      totalName: '2549 ╴彰美分行'
    },
    {
      bankCodeString: '0132550',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2550',
      branchName: '太平分行',
      totalName: '2550 ╴太平分行'
    },
    {
      bankCodeString: '0132561',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2561',
      branchName: '沙鹿分行',
      totalName: '2561 ╴沙鹿分行'
    },
    {
      bankCodeString: '0132583',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2583',
      branchName: '大里分行',
      totalName: '2583 ╴大里分行'
    },
    {
      bankCodeString: '0132608',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2608',
      branchName: '逢甲分行',
      totalName: '2608 ╴逢甲分行'
    },
    {
      bankCodeString: '0132619',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2619',
      branchName: '大雅分行',
      totalName: '2619 ╴大雅分行'
    },
    {
      bankCodeString: '0132620',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2620',
      branchName: '潭子分行',
      totalName: '2620 ╴潭子分行'
    },
    {
      bankCodeString: '0132631',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2631',
      branchName: '光華分行',
      totalName: '2631 ╴光華分行'
    },
    {
      bankCodeString: '0132642',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2642',
      branchName: '西松分行',
      totalName: '2642 ╴西松分行'
    },
    {
      bankCodeString: '0132653',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2653',
      branchName: '蘭雅分行',
      totalName: '2653 ╴蘭雅分行'
    },
    {
      bankCodeString: '0132664',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2664',
      branchName: '永平分行',
      totalName: '2664 ╴永平分行'
    },
    {
      bankCodeString: '0132675',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2675',
      branchName: '和平分行',
      totalName: '2675 ╴和平分行'
    },
    {
      bankCodeString: '0132686',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2686',
      branchName: '慶城分行',
      totalName: '2686 ╴慶城分行'
    },
    {
      bankCodeString: '0132697',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2697',
      branchName: '瑞湖分行',
      totalName: '2697 ╴瑞湖分行'
    },
    {
      bankCodeString: '0132701',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2701',
      branchName: '文昌分行',
      totalName: '2701 ╴文昌分行'
    },
    {
      bankCodeString: '0132712',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2712',
      branchName: '正義分行',
      totalName: '2712 ╴正義分行'
    },
    {
      bankCodeString: '0132723',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2723',
      branchName: '新板分行',
      totalName: '2723 ╴新板分行'
    },
    {
      bankCodeString: '0132734',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2734',
      branchName: '永貞分行',
      totalName: '2734 ╴永貞分行'
    },
    {
      bankCodeString: '0132778',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '2778',
      branchName: '明誠分行',
      totalName: '2778 ╴明誠分行'
    },
    {
      bankCodeString: '0138031',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '8031',
      branchName: '企金債管部',
      totalName: '8031 ╴企金債管部'
    },
    {
      bankCodeString: '0138032',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '8032',
      branchName: '企金審查部',
      totalName: '8032 ╴企金審查部'
    },
    {
      bankCodeString: '0138033',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '8033',
      branchName: '消金審查部',
      totalName: '8033 ╴消金審查部'
    },
    {
      bankCodeString: '0138034',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '8034',
      branchName: '企金北區區域中心',
      totalName: '8034 ╴企金北區區域中心'
    },
    {
      bankCodeString: '0138035',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '8035',
      branchName: '企金中區區域中心',
      totalName: '8035 ╴企金中區區域中心'
    },
    {
      bankCodeString: '0138036',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '8036',
      branchName: '企金南區區域中心',
      totalName: '8036 ╴企金南區區域中心'
    },
    {
      bankCodeString: '0138037',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '8037',
      branchName: '外匯區域中心',
      totalName: '8037 ╴外匯區域中心'
    },
    {
      bankCodeString: '0138038',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '8038',
      branchName: '法人金融部',
      totalName: '8038 ╴法人金融部'
    },
    {
      bankCodeString: '0138051',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '8051',
      branchName: '業務控管部',
      totalName: '8051 ╴業務控管部'
    },
    {
      bankCodeString: '0138052',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '8052',
      branchName: '債權管理部',
      totalName: '8052 ╴債權管理部'
    },
    {
      bankCodeString: '0138054',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '8054',
      branchName: '企劃推廣部',
      totalName: '8054 ╴企劃推廣部'
    },
    {
      bankCodeString: '0139010',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9010',
      branchName: '納閩島分行',
      totalName: '9010 ╴納閩島分行'
    },
    {
      bankCodeString: '0139021',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9021',
      branchName: '馬尼拉分行',
      totalName: '9021 ╴馬尼拉分行'
    },
    {
      bankCodeString: '0139032',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9032',
      branchName: '泰國曼谷代表人辦事處',
      totalName: '9032 ╴泰國曼谷代表人辦事處'
    },
    {
      bankCodeString: '0139043',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9043',
      branchName: '新加坡分行',
      totalName: '9043 ╴新加坡分行'
    },
    {
      bankCodeString: '0139054',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9054',
      branchName: '子銀行世越銀行',
      totalName: '9054 ╴子銀行世越銀行'
    },
    {
      bankCodeString: '0139065',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9065',
      branchName: '國泰世華銀行(中國)有限公司上海分行',
      totalName: '9065 ╴國泰世華銀行(中國)有限公司上海分行'
    },
    {
      bankCodeString: '0139205',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9205',
      branchName: '香港分行',
      totalName: '9205 ╴香港分行'
    },
    {
      bankCodeString: '0139249',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9249',
      branchName: '國泰世華銀行(柬埔寨)股份有限公司森速分行',
      totalName: '9249 ╴國泰世華銀行(柬埔寨)股份有限公司森速分行'
    },
    {
      bankCodeString: '0139250',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9250',
      branchName: '國泰世華銀行(中國)有限公司',
      totalName: '9250 ╴國泰世華銀行(中國)有限公司'
    },
    {
      bankCodeString: '0139261',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9261',
      branchName: '國泰世華銀行(柬埔寨)股份有限公司宋仁芷分行',
      totalName: '9261 ╴國泰世華銀行(柬埔寨)股份有限公司宋仁芷分行'
    },
    {
      bankCodeString: '0139272',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9272',
      branchName: '國泰世華銀行(柬埔寨)股份有限公司舊運動場分行',
      totalName: '9272 ╴國泰世華銀行(柬埔寨)股份有限公司舊運動場分行'
    },
    {
      bankCodeString: '0139283',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9283',
      branchName: '國泰世華銀行(中國)有限公司深圳分行',
      totalName: '9283 ╴國泰世華銀行(中國)有限公司深圳分行'
    },
    {
      bankCodeString: '0139294',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9294',
      branchName: '國泰世華銀行(柬埔寨)股份有限公司鐵橋頭分行',
      totalName: '9294 ╴國泰世華銀行(柬埔寨)股份有限公司鐵橋頭分行'
    },
    {
      bankCodeString: '0139308',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9308',
      branchName: '國泰世華銀行(柬埔寨)股份有限公司中洲分行',
      totalName: '9308 ╴國泰世華銀行(柬埔寨)股份有限公司中洲分行'
    },
    {
      bankCodeString: '0139319',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9319',
      branchName: '國泰世華銀行(柬埔寨)股份有限公司興利分行',
      totalName: '9319 ╴國泰世華銀行(柬埔寨)股份有限公司興利分行'
    },
    {
      bankCodeString: '0139320',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9320',
      branchName: '國泰世華銀行(中國)有限公司上海嘉定支行',
      totalName: '9320 ╴國泰世華銀行(中國)有限公司上海嘉定支行'
    },
    {
      bankCodeString: '0139331',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9331',
      branchName: '世越銀行會安分行',
      totalName: '9331 ╴世越銀行會安分行'
    },
    {
      bankCodeString: '0139342',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9342',
      branchName: '國泰世華銀行(柬埔寨)股份有限公司巴域分行',
      totalName: '9342 ╴國泰世華銀行(柬埔寨)股份有限公司巴域分行'
    },
    {
      bankCodeString: '0139353',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9353',
      branchName: '國泰世華銀行(柬埔寨)股份有限公司磅針分行',
      totalName: '9353 ╴國泰世華銀行(柬埔寨)股份有限公司磅針分行'
    },
    {
      bankCodeString: '0139364',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9364',
      branchName: '國泰世華銀行(柬埔寨)股份有限公司馬德望分行',
      totalName: '9364 ╴國泰世華銀行(柬埔寨)股份有限公司馬德望分行'
    },
    {
      bankCodeString: '0139375',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9375',
      branchName: '雅加達代表人辦事處',
      totalName: '9375 ╴雅加達代表人辦事處'
    },
    {
      bankCodeString: '0139386',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9386',
      branchName: '永珍分行',
      totalName: '9386 ╴永珍分行'
    },
    {
      bankCodeString: '0139397',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9397',
      branchName: '國泰世華銀行(中國)有限公司青島分行',
      totalName: '9397 ╴國泰世華銀行(中國)有限公司青島分行'
    },
    {
      bankCodeString: '0139401',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9401',
      branchName: '緬甸仰光代表人辦事處',
      totalName: '9401 ╴緬甸仰光代表人辦事處'
    },
    {
      bankCodeString: '0139412',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9412',
      branchName: '國泰世華銀行(中國)有限公司上海自貿試驗區支行',
      totalName: '9412 ╴國泰世華銀行(中國)有限公司上海自貿試驗區支行'
    },
    {
      bankCodeString: '0139423',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9423',
      branchName: '國泰世華銀行(中國)有限公司上海閔行支行',
      totalName: '9423 ╴國泰世華銀行(中國)有限公司上海閔行支行'
    },
    {
      bankCodeString: '0139526',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9526',
      branchName: '國泰世華銀行(柬埔寨)股份有限公司 Monivong Exchange Office',
      totalName: '9526 ╴國泰世華銀行(柬埔寨)股份有限公司 Monivong Exchange Office'
    },
    {
      bankCodeString: '0139537',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9537',
      branchName: '國泰世華銀行(柬埔寨)股份有限公司暹粒分行',
      totalName: '9537 ╴國泰世華銀行(柬埔寨)股份有限公司暹粒分行'
    },
    {
      bankCodeString: '0139548',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9548',
      branchName: '國泰世華銀行(柬埔寨)股份有限公司西哈努克市分行',
      totalName: '9548 ╴國泰世華銀行(柬埔寨)股份有限公司西哈努克市分行'
    },
    {
      bankCodeString: '0139559',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9559',
      branchName: '國泰世華銀行(柬埔寨)股份有限公司朱德奔分行',
      totalName: '9559 ╴國泰世華銀行(柬埔寨)股份有限公司朱德奔分行'
    },
    {
      bankCodeString: '0139560',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9560',
      branchName: '國泰世華銀行(柬埔寨)股份有限公司尼赫魯分行',
      totalName: '9560 ╴國泰世華銀行(柬埔寨)股份有限公司尼赫魯分行'
    },
    {
      bankCodeString: '0139571',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9571',
      branchName: '國泰世華銀行(柬埔寨)股份有限公司毛澤東分行',
      totalName: '9571 ╴國泰世華銀行(柬埔寨)股份有限公司毛澤東分行'
    },
    {
      bankCodeString: '0139582',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9582',
      branchName: '世越銀行濱城分行',
      totalName: '9582 ╴世越銀行濱城分行'
    },
    {
      bankCodeString: '0139593',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9593',
      branchName: '國泰世華銀行(柬埔寨)股份有限公司(CUBC)',
      totalName: '9593 ╴國泰世華銀行(柬埔寨)股份有限公司(CUBC)'
    },
    {
      bankCodeString: '0139607',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9607',
      branchName: '世越銀行河內分行CIPUTRA P2支行',
      totalName: '9607 ╴世越銀行河內分行CIPUTRA P2支行'
    },
    {
      bankCodeString: '0139618',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9618',
      branchName: '世越銀行峴港分行黃耀支行',
      totalName: '9618 ╴世越銀行峴港分行黃耀支行'
    },
    {
      bankCodeString: '0139629',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9629',
      branchName: '世越銀行海防分行鴻龐支行',
      totalName: '9629 ╴世越銀行海防分行鴻龐支行'
    },
    {
      bankCodeString: '0139630',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9630',
      branchName: '世越銀行棟多分行安和支行',
      totalName: '9630 ╴世越銀行棟多分行安和支行'
    },
    {
      bankCodeString: '0139641',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9641',
      branchName: '世越銀行棟多分行吳權支行',
      totalName: '9641 ╴世越銀行棟多分行吳權支行'
    },
    {
      bankCodeString: '0139652',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9652',
      branchName: '世越銀行河內分行陳仁宗支行',
      totalName: '9652 ╴世越銀行河內分行陳仁宗支行'
    },
    {
      bankCodeString: '0139685',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9685',
      branchName: '世越銀行新平分行李常傑支行',
      totalName: '9685 ╴世越銀行新平分行李常傑支行'
    },
    {
      bankCodeString: '0139700',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9700',
      branchName: '世越銀行同奈分行展鵬支行',
      totalName: '9700 ╴世越銀行同奈分行展鵬支行'
    },
    {
      bankCodeString: '0139711',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9711',
      branchName: '世越銀行河內分行黎仲晉支行',
      totalName: '9711 ╴世越銀行河內分行黎仲晉支行'
    },
    {
      bankCodeString: '0139733',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9733',
      branchName: '世越銀行天龍分行',
      totalName: '9733 ╴世越銀行天龍分行'
    },
    {
      bankCodeString: '0139744',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9744',
      branchName: '世越銀行芹苴分行興利支行',
      totalName: '9744 ╴世越銀行芹苴分行興利支行'
    },
    {
      bankCodeString: '0139755',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9755',
      branchName: '世越銀行堤岸分行平新支行',
      totalName: '9755 ╴世越銀行堤岸分行平新支行'
    },
    {
      bankCodeString: '0139777',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9777',
      branchName: '世越銀行河內分行李太祖支行',
      totalName: '9777 ╴世越銀行河內分行李太祖支行'
    },
    {
      bankCodeString: '0139788',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9788',
      branchName: '世越銀行棟多分行黎鴻鋒支行',
      totalName: '9788 ╴世越銀行棟多分行黎鴻鋒支行'
    },
    {
      bankCodeString: '0139799',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9799',
      branchName: '世越銀行峴港分行雄王支行',
      totalName: '9799 ╴世越銀行峴港分行雄王支行'
    },
    {
      bankCodeString: '0139803',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9803',
      branchName: '世越銀行美亭分行',
      totalName: '9803 ╴世越銀行美亭分行'
    },
    {
      bankCodeString: '0139814',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9814',
      branchName: '世越銀行平陽分行美福支行',
      totalName: '9814 ╴世越銀行平陽分行美福支行'
    },
    {
      bankCodeString: '0139825',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9825',
      branchName: '世越銀行新平分行',
      totalName: '9825 ╴世越銀行新平分行'
    },
    {
      bankCodeString: '0139847',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9847',
      branchName: '世越銀行堤岸分行',
      totalName: '9847 ╴世越銀行堤岸分行'
    },
    {
      bankCodeString: '0139858',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9858',
      branchName: '世越銀行堤岸分行富美興支行',
      totalName: '9858 ╴世越銀行堤岸分行富美興支行'
    },
    {
      bankCodeString: '0139869',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9869',
      branchName: '世越銀行硯港分行',
      totalName: '9869 ╴世越銀行硯港分行'
    },
    {
      bankCodeString: '0139870',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9870',
      branchName: '世越銀行海防分行Nomura支行',
      totalName: '9870 ╴世越銀行海防分行Nomura支行'
    },
    {
      bankCodeString: '0139881',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9881',
      branchName: '世越銀行河內分行CIPUTRA G02支行',
      totalName: '9881 ╴世越銀行河內分行CIPUTRA G02支行'
    },
    {
      bankCodeString: '0139892',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9892',
      branchName: '世越銀行同奈分行仁澤支行',
      totalName: '9892 ╴世越銀行同奈分行仁澤支行'
    },
    {
      bankCodeString: '0139906',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9906',
      branchName: '吉隆坡行銷服務處',
      totalName: '9906 ╴吉隆坡行銷服務處'
    },
    {
      bankCodeString: '0139917',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9917',
      branchName: '世越銀行棟多分行',
      totalName: '9917 ╴世越銀行棟多分行'
    },
    {
      bankCodeString: '0139928',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9928',
      branchName: '世越銀行同奈分行',
      totalName: '9928 ╴世越銀行同奈分行'
    },
    {
      bankCodeString: '0139939',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9939',
      branchName: '世越銀行海防分行',
      totalName: '9939 ╴世越銀行海防分行'
    },
    {
      bankCodeString: '0139940',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9940',
      branchName: '世越銀行芹苴分行',
      totalName: '9940 ╴世越銀行芹苴分行'
    },
    {
      bankCodeString: '0139951',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9951',
      branchName: '世越銀行平陽分行',
      totalName: '9951 ╴世越銀行平陽分行'
    },
    {
      bankCodeString: '0139962',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9962',
      branchName: '世越銀行河內分行',
      totalName: '9962 ╴世越銀行河內分行'
    },
    {
      bankCodeString: '0139973',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9973',
      branchName: '茱萊分行',
      totalName: '9973 ╴茱萊分行'
    },
    {
      bankCodeString: '0139984',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9984',
      branchName: '胡志明市代表人辦事處',
      totalName: '9984 ╴胡志明市代表人辦事處'
    },
    {
      bankCodeString: '0139995',
      bankCode: '013',
      bankName: '國泰世華商業銀行',
      branchCode: '9995',
      branchName: '河內代表人辦事處',
      totalName: '9995 ╴河內代表人辦事處'
    }
  ],
  '015': [
    {
      bankCodeString: '0150019',
      bankCode: '015',
      bankName: '中國輸出入銀行',
      branchCode: '0019',
      branchName: '業務部',
      totalName: '0019 ╴業務部'
    },
    {
      bankCodeString: '0150020',
      bankCode: '015',
      bankName: '中國輸出入銀行',
      branchCode: '0020',
      branchName: '風險管理處',
      totalName: '0020 ╴風險管理處'
    },
    {
      bankCodeString: '0150031',
      bankCode: '015',
      bankName: '中國輸出入銀行',
      branchCode: '0031',
      branchName: '高雄分行',
      totalName: '0031 ╴高雄分行'
    },
    {
      bankCodeString: '0150042',
      bankCode: '015',
      bankName: '中國輸出入銀行',
      branchCode: '0042',
      branchName: '台中分行',
      totalName: '0042 ╴台中分行'
    },
    {
      bankCodeString: '0150053',
      bankCode: '015',
      bankName: '中國輸出入銀行',
      branchCode: '0053',
      branchName: '國際金融業務分行',
      totalName: '0053 ╴國際金融業務分行'
    },
    {
      bankCodeString: '0150064',
      bankCode: '015',
      bankName: '中國輸出入銀行',
      branchCode: '0064',
      branchName: '新竹分行',
      totalName: '0064 ╴新竹分行'
    },
    {
      bankCodeString: '0150086',
      bankCode: '015',
      bankName: '中國輸出入銀行',
      branchCode: '0086',
      branchName: '台南分行',
      totalName: '0086 ╴台南分行'
    },
    {
      bankCodeString: '0150501',
      bankCode: '015',
      bankName: '中國輸出入銀行',
      branchCode: '0501',
      branchName: '管理部',
      totalName: '0501 ╴管理部'
    },
    {
      bankCodeString: '0150502',
      bankCode: '015',
      bankName: '中國輸出入銀行',
      branchCode: '0502',
      branchName: '人事室',
      totalName: '0502 ╴人事室'
    },
    {
      bankCodeString: '0150503',
      bankCode: '015',
      bankName: '中國輸出入銀行',
      branchCode: '0503',
      branchName: '政風室',
      totalName: '0503 ╴政風室'
    },
    {
      bankCodeString: '0150504',
      bankCode: '015',
      bankName: '中國輸出入銀行',
      branchCode: '0504',
      branchName: '稽核室',
      totalName: '0504 ╴稽核室'
    },
    {
      bankCodeString: '0150911',
      bankCode: '015',
      bankName: '中國輸出入銀行',
      branchCode: '0911',
      branchName: '財務部',
      totalName: '0911 ╴財務部'
    },
    {
      bankCodeString: '0150922',
      bankCode: '015',
      bankName: '中國輸出入銀行',
      branchCode: '0922',
      branchName: '輸出保險部',
      totalName: '0922 ╴輸出保險部'
    },
    {
      bankCodeString: '0150933',
      bankCode: '015',
      bankName: '中國輸出入銀行',
      branchCode: '0933',
      branchName: '會計處',
      totalName: '0933 ╴會計處'
    },
    {
      bankCodeString: '0159986',
      bankCode: '015',
      bankName: '中國輸出入銀行',
      branchCode: '9986',
      branchName: '泰國曼谷代表人辦事處',
      totalName: '9986 ╴泰國曼谷代表人辦事處'
    }
  ],
  '016': [
    {
      bankCodeString: '0160001',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '0001',
      branchName: '董事會秘書處',
      totalName: '0001 ╴董事會秘書處'
    },
    {
      bankCodeString: '0160003',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '0003',
      branchName: '研究發展處',
      totalName: '0003 ╴研究發展處'
    },
    {
      bankCodeString: '0160004',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '0004',
      branchName: '授信管理處',
      totalName: '0004 ╴授信管理處'
    },
    {
      bankCodeString: '0160005',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '0005',
      branchName: '人力資源處',
      totalName: '0005 ╴人力資源處'
    },
    {
      bankCodeString: '0160006',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '0006',
      branchName: '業務管理處',
      totalName: '0006 ╴業務管理處'
    },
    {
      bankCodeString: '0160007',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '0007',
      branchName: '董事會稽核處',
      totalName: '0007 ╴董事會稽核處'
    },
    {
      bankCodeString: '0160009',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '0009',
      branchName: '財務管理處',
      totalName: '0009 ╴財務管理處'
    },
    {
      bankCodeString: '0160011',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '0011',
      branchName: '法務處',
      totalName: '0011 ╴法務處'
    },
    {
      bankCodeString: '0160012',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '0012',
      branchName: '風險管理處',
      totalName: '0012 ╴風險管理處'
    },
    {
      bankCodeString: '0160013',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '0013',
      branchName: '財富管理部',
      totalName: '0013 ╴財富管理部'
    },
    {
      bankCodeString: '0160200',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '0200',
      branchName: '行政管理處',
      totalName: '0200 ╴行政管理處'
    },
    {
      bankCodeString: '0160260',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '0260',
      branchName: '資訊處',
      totalName: '0260 ╴資訊處'
    },
    {
      bankCodeString: '0161017',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '1017',
      branchName: '營業部',
      totalName: '1017 ╴營業部'
    },
    {
      bankCodeString: '0161028',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '1028',
      branchName: '公庫部',
      totalName: '1028 ╴公庫部'
    },
    {
      bankCodeString: '0161039',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '1039',
      branchName: '岡山本洲分行',
      totalName: '1039 ╴岡山本洲分行'
    },
    {
      bankCodeString: '0161040',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '1040',
      branchName: '國外部',
      totalName: '1040 ╴國外部'
    },
    {
      bankCodeString: '0161051',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '1051',
      branchName: '信託部',
      totalName: '1051 ╴信託部'
    },
    {
      bankCodeString: '0162003',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '2003',
      branchName: '鳳山分行',
      totalName: '2003 ╴鳳山分行'
    },
    {
      bankCodeString: '0162025',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '2025',
      branchName: '前金分行',
      totalName: '2025 ╴前金分行'
    },
    {
      bankCodeString: '0162036',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '2036',
      branchName: '三民分行',
      totalName: '2036 ╴三民分行'
    },
    {
      bankCodeString: '0162081',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '2081',
      branchName: '左營分行',
      totalName: '2081 ╴左營分行'
    },
    {
      bankCodeString: '0162106',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '2106',
      branchName: '小港分行',
      totalName: '2106 ╴小港分行'
    },
    {
      bankCodeString: '0162117',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '2117',
      branchName: '桃園分行',
      totalName: '2117 ╴桃園分行'
    },
    {
      bankCodeString: '0162128',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '2128',
      branchName: '林口分行',
      totalName: '2128 ╴林口分行'
    },
    {
      bankCodeString: '0162139',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '2139',
      branchName: '右昌分行',
      totalName: '2139 ╴右昌分行'
    },
    {
      bankCodeString: '0162140',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '2140',
      branchName: '灣內分行',
      totalName: '2140 ╴灣內分行'
    },
    {
      bankCodeString: '0162151',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '2151',
      branchName: '屏東分行',
      totalName: '2151 ╴屏東分行'
    },
    {
      bankCodeString: '0162162',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '2162',
      branchName: '桂林分行',
      totalName: '2162 ╴桂林分行'
    },
    {
      bankCodeString: '0162173',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '2173',
      branchName: '北屯分行',
      totalName: '2173 ╴北屯分行'
    },
    {
      bankCodeString: '0162184',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '2184',
      branchName: '橋頭科學園區分行',
      totalName: '2184 ╴橋頭科學園區分行'
    },
    {
      bankCodeString: '0162195',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '2195',
      branchName: '鼓山分行',
      totalName: '2195 ╴鼓山分行'
    },
    {
      bankCodeString: '0162209',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '2209',
      branchName: '建國分行',
      totalName: '2209 ╴建國分行'
    },
    {
      bankCodeString: '0162210',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '2210',
      branchName: '九如分行',
      totalName: '2210 ╴九如分行'
    },
    {
      bankCodeString: '0162221',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '2221',
      branchName: '中壢分行',
      totalName: '2221 ╴中壢分行'
    },
    {
      bankCodeString: '0162232',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '2232',
      branchName: '草衙分行',
      totalName: '2232 ╴草衙分行'
    },
    {
      bankCodeString: '0162243',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '2243',
      branchName: '板橋分行',
      totalName: '2243 ╴板橋分行'
    },
    {
      bankCodeString: '0162254',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '2254',
      branchName: '台南分行',
      totalName: '2254 ╴台南分行'
    },
    {
      bankCodeString: '0162265',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '2265',
      branchName: '三多分行',
      totalName: '2265 ╴三多分行'
    },
    {
      bankCodeString: '0162276',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '2276',
      branchName: '大發分行',
      totalName: '2276 ╴大發分行'
    },
    {
      bankCodeString: '0162287',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '2287',
      branchName: '大直分行',
      totalName: '2287 ╴大直分行'
    },
    {
      bankCodeString: '0162298',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '2298',
      branchName: '台中分行',
      totalName: '2298 ╴台中分行'
    },
    {
      bankCodeString: '0162302',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '2302',
      branchName: '國際金融業務分行',
      totalName: '2302 ╴國際金融業務分行'
    },
    {
      bankCodeString: '0162313',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '2313',
      branchName: '旗津簡易型分行',
      totalName: '2313 ╴旗津簡易型分行'
    },
    {
      bankCodeString: '0162324',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '2324',
      branchName: '南高雄分行',
      totalName: '2324 ╴南高雄分行'
    },
    {
      bankCodeString: '0162335',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '2335',
      branchName: '大里分行',
      totalName: '2335 ╴大里分行'
    },
    {
      bankCodeString: '0162346',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '2346',
      branchName: '新竹分行',
      totalName: '2346 ╴新竹分行'
    },
    {
      bankCodeString: '0162357',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '2357',
      branchName: '市府分行',
      totalName: '2357 ╴市府分行'
    },
    {
      bankCodeString: '0162379',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '2379',
      branchName: '中和分行',
      totalName: '2379 ╴中和分行'
    },
    {
      bankCodeString: '0162380',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '2380',
      branchName: '台北分行',
      totalName: '2380 ╴台北分行'
    },
    {
      bankCodeString: '0162405',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '2405',
      branchName: '成大分行',
      totalName: '2405 ╴成大分行'
    },
    {
      bankCodeString: '0162416',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '2416',
      branchName: '北高雄分行',
      totalName: '2416 ╴北高雄分行'
    },
    {
      bankCodeString: '0162450',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '2450',
      branchName: '博愛分行',
      totalName: '2450 ╴博愛分行'
    },
    {
      bankCodeString: '0168750',
      bankCode: '016',
      bankName: '高雄銀行',
      branchCode: '8750',
      branchName: '兼營證券商',
      totalName: '8750 ╴兼營證券商'
    }
  ],
  '017': [
    {
      bankCodeString: '0170011',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0011',
      branchName: '會計處',
      totalName: '0011 ╴會計處'
    },
    {
      bankCodeString: '0170022',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0022',
      branchName: '港都分行',
      totalName: '0022 ╴港都分行'
    },
    {
      bankCodeString: '0170033',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0033',
      branchName: '楠梓分行',
      totalName: '0033 ╴楠梓分行'
    },
    {
      bankCodeString: '0170044',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0044',
      branchName: '台中分行',
      totalName: '0044 ╴台中分行'
    },
    {
      bankCodeString: '0170055',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0055',
      branchName: '忠孝分行',
      totalName: '0055 ╴忠孝分行'
    },
    {
      bankCodeString: '0170066',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0066',
      branchName: '府城分行',
      totalName: '0066 ╴府城分行'
    },
    {
      bankCodeString: '0170077',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0077',
      branchName: '國外部',
      totalName: '0077 ╴國外部'
    },
    {
      bankCodeString: '0170088',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0088',
      branchName: '台北復興分行',
      totalName: '0088 ╴台北復興分行'
    },
    {
      bankCodeString: '0170099',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0099',
      branchName: '財富管理處',
      totalName: '0099 ╴財富管理處'
    },
    {
      bankCodeString: '0170103',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0103',
      branchName: '蘭雅分行',
      totalName: '0103 ╴蘭雅分行'
    },
    {
      bankCodeString: '0170114',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0114',
      branchName: '財務部',
      totalName: '0114 ╴財務部'
    },
    {
      bankCodeString: '0170125',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0125',
      branchName: '三重分行',
      totalName: '0125 ╴三重分行'
    },
    {
      bankCodeString: '0170136',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0136',
      branchName: '新興分行',
      totalName: '0136 ╴新興分行'
    },
    {
      bankCodeString: '0170147',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0147',
      branchName: '桃園分行',
      totalName: '0147 ╴桃園分行'
    },
    {
      bankCodeString: '0170158',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0158',
      branchName: '中山分行',
      totalName: '0158 ╴中山分行'
    },
    {
      bankCodeString: '0170169',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0169',
      branchName: '高雄科技園區分行',
      totalName: '0169 ╴高雄科技園區分行'
    },
    {
      bankCodeString: '0170170',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0170',
      branchName: '城中分行',
      totalName: '0170 ╴城中分行'
    },
    {
      bankCodeString: '0170181',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0181',
      branchName: '北彰化分行',
      totalName: '0181 ╴北彰化分行'
    },
    {
      bankCodeString: '0170192',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0192',
      branchName: '安和分行',
      totalName: '0192 ╴安和分行'
    },
    {
      bankCodeString: '0170206',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0206',
      branchName: '竹科新安分行',
      totalName: '0206 ╴竹科新安分行'
    },
    {
      bankCodeString: '0170217',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0217',
      branchName: '天母分行',
      totalName: '0217 ╴天母分行'
    },
    {
      bankCodeString: '0170228',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0228',
      branchName: '嘉義分行',
      totalName: '0228 ╴嘉義分行'
    },
    {
      bankCodeString: '0170239',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0239',
      branchName: '花蓮分行',
      totalName: '0239 ╴花蓮分行'
    },
    {
      bankCodeString: '0170240',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0240',
      branchName: '信託部',
      totalName: '0240 ╴信託部'
    },
    {
      bankCodeString: '0170251',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0251',
      branchName: '國際金融業務分行',
      totalName: '0251 ╴國際金融業務分行'
    },
    {
      bankCodeString: '0170262',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0262',
      branchName: '北新竹分行',
      totalName: '0262 ╴北新竹分行'
    },
    {
      bankCodeString: '0170273',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0273',
      branchName: '板南分行',
      totalName: '0273 ╴板南分行'
    },
    {
      bankCodeString: '0170284',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0284',
      branchName: '潭子分行',
      totalName: '0284 ╴潭子分行'
    },
    {
      bankCodeString: '0170295',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0295',
      branchName: '桃園國際機場分行',
      totalName: '0295 ╴桃園國際機場分行'
    },
    {
      bankCodeString: '0170309',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0309',
      branchName: '南台北分行',
      totalName: '0309 ╴南台北分行'
    },
    {
      bankCodeString: '0170310',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0310',
      branchName: '敦南分行',
      totalName: '0310 ╴敦南分行'
    },
    {
      bankCodeString: '0170321',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0321',
      branchName: '員林分行',
      totalName: '0321 ╴員林分行'
    },
    {
      bankCodeString: '0170343',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0343',
      branchName: '永和分行',
      totalName: '0343 ╴永和分行'
    },
    {
      bankCodeString: '0170354',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0354',
      branchName: '豐原分行',
      totalName: '0354 ╴豐原分行'
    },
    {
      bankCodeString: '0170365',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0365',
      branchName: '民生分行',
      totalName: '0365 ╴民生分行'
    },
    {
      bankCodeString: '0170376',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0376',
      branchName: '北台中分行',
      totalName: '0376 ╴北台中分行'
    },
    {
      bankCodeString: '0170387',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0387',
      branchName: '三多分行',
      totalName: '0387 ╴三多分行'
    },
    {
      bankCodeString: '0170398',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0398',
      branchName: '中壢分行',
      totalName: '0398 ╴中壢分行'
    },
    {
      bankCodeString: '0170402',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0402',
      branchName: '三民分行',
      totalName: '0402 ╴三民分行'
    },
    {
      bankCodeString: '0170413',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0413',
      branchName: '新莊分行',
      totalName: '0413 ╴新莊分行'
    },
    {
      bankCodeString: '0170424',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0424',
      branchName: '松南分行',
      totalName: '0424 ╴松南分行'
    },
    {
      bankCodeString: '0170435',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0435',
      branchName: '大同分行',
      totalName: '0435 ╴大同分行'
    },
    {
      bankCodeString: '0170446',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0446',
      branchName: '南台中分行',
      totalName: '0446 ╴南台中分行'
    },
    {
      bankCodeString: '0170457',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0457',
      branchName: '鳳山分行',
      totalName: '0457 ╴鳳山分行'
    },
    {
      bankCodeString: '0170468',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0468',
      branchName: '新店分行',
      totalName: '0468 ╴新店分行'
    },
    {
      bankCodeString: '0170479',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0479',
      branchName: '東高雄分行',
      totalName: '0479 ╴東高雄分行'
    },
    {
      bankCodeString: '0170480',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0480',
      branchName: '信義分行',
      totalName: '0480 ╴信義分行'
    },
    {
      bankCodeString: '0170491',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0491',
      branchName: '基隆分行',
      totalName: '0491 ╴基隆分行'
    },
    {
      bankCodeString: '0170505',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0505',
      branchName: '永康分行',
      totalName: '0505 ╴永康分行'
    },
    {
      bankCodeString: '0170516',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0516',
      branchName: '內湖分行',
      totalName: '0516 ╴內湖分行'
    },
    {
      bankCodeString: '0170527',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0527',
      branchName: '岡山分行',
      totalName: '0527 ╴岡山分行'
    },
    {
      bankCodeString: '0170538',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0538',
      branchName: '屏東分行',
      totalName: '0538 ╴屏東分行'
    },
    {
      bankCodeString: '0170550',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0550',
      branchName: '松山機場分行',
      totalName: '0550 ╴松山機場分行'
    },
    {
      bankCodeString: '0170561',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0561',
      branchName: '土城分行',
      totalName: '0561 ╴土城分行'
    },
    {
      bankCodeString: '0170572',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0572',
      branchName: '大稻埕分行',
      totalName: '0572 ╴大稻埕分行'
    },
    {
      bankCodeString: '0170583',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0583',
      branchName: '頭份分行',
      totalName: '0583 ╴頭份分行'
    },
    {
      bankCodeString: '0170594',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0594',
      branchName: '苓雅分行',
      totalName: '0594 ╴苓雅分行'
    },
    {
      bankCodeString: '0170608',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0608',
      branchName: '沙鹿分行',
      totalName: '0608 ╴沙鹿分行'
    },
    {
      bankCodeString: '0170619',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0619',
      branchName: '八德分行',
      totalName: '0619 ╴八德分行'
    },
    {
      bankCodeString: '0170620',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0620',
      branchName: '宜蘭分行',
      totalName: '0620 ╴宜蘭分行'
    },
    {
      bankCodeString: '0170631',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0631',
      branchName: '斗六分行',
      totalName: '0631 ╴斗六分行'
    },
    {
      bankCodeString: '0170642',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0642',
      branchName: '南投分行',
      totalName: '0642 ╴南投分行'
    },
    {
      bankCodeString: '0170653',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0653',
      branchName: '東台南分行',
      totalName: '0653 ╴東台南分行'
    },
    {
      bankCodeString: '0170664',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0664',
      branchName: '高雄國際機場分行',
      totalName: '0664 ╴高雄國際機場分行'
    },
    {
      bankCodeString: '0170675',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0675',
      branchName: '東內湖分行',
      totalName: '0675 ╴東內湖分行'
    },
    {
      bankCodeString: '0170686',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0686',
      branchName: '太平分行',
      totalName: '0686 ╴太平分行'
    },
    {
      bankCodeString: '0170697',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0697',
      branchName: '中和分行',
      totalName: '0697 ╴中和分行'
    },
    {
      bankCodeString: '0170701',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0701',
      branchName: '南京東路分行',
      totalName: '0701 ╴南京東路分行'
    },
    {
      bankCodeString: '0170710',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0710',
      branchName: '兼營證券商',
      totalName: '0710 ╴兼營證券商'
    },
    {
      bankCodeString: '0170712',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0712',
      branchName: '東台中分行',
      totalName: '0712 ╴東台中分行'
    },
    {
      bankCodeString: '0170723',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0723',
      branchName: '北高雄分行',
      totalName: '0723 ╴北高雄分行'
    },
    {
      bankCodeString: '0170745',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0745',
      branchName: '南港分行',
      totalName: '0745 ╴南港分行'
    },
    {
      bankCodeString: '0170756',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0756',
      branchName: '竹南科學園區分行',
      totalName: '0756 ╴竹南科學園區分行'
    },
    {
      bankCodeString: '0170767',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0767',
      branchName: '中科分行',
      totalName: '0767 ╴中科分行'
    },
    {
      bankCodeString: '0170778',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0778',
      branchName: '大甲分行',
      totalName: '0778 ╴大甲分行'
    },
    {
      bankCodeString: '0170789',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0789',
      branchName: '成功簡易型分行',
      totalName: '0789 ╴成功簡易型分行'
    },
    {
      bankCodeString: '0170790',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0790',
      branchName: '金門分行',
      totalName: '0790 ╴金門分行'
    },
    {
      bankCodeString: '0170804',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0804',
      branchName: '南崁分行',
      totalName: '0804 ╴南崁分行'
    },
    {
      bankCodeString: '0170815',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0815',
      branchName: '中鋼簡易型分行',
      totalName: '0815 ╴中鋼簡易型分行'
    },
    {
      bankCodeString: '0170837',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0837',
      branchName: '向上分行',
      totalName: '0837 ╴向上分行'
    },
    {
      bankCodeString: '0170860',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '0860',
      branchName: '駐外交部簡易型分行',
      totalName: '0860 ╴駐外交部簡易型分行'
    },
    {
      bankCodeString: '0171030',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '1030',
      branchName: '仁武分行',
      totalName: '1030 ╴仁武分行'
    },
    {
      bankCodeString: '0171166',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '1166',
      branchName: '高雄漁港簡易型分行',
      totalName: '1166 ╴高雄漁港簡易型分行'
    },
    {
      bankCodeString: '0172015',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '2015',
      branchName: '金控總部分行',
      totalName: '2015 ╴金控總部分行'
    },
    {
      bankCodeString: '0172026',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '2026',
      branchName: '台北分行',
      totalName: '2026 ╴台北分行'
    },
    {
      bankCodeString: '0172037',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '2037',
      branchName: '新竹分行',
      totalName: '2037 ╴新竹分行'
    },
    {
      bankCodeString: '0172048',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '2048',
      branchName: '中台中分行',
      totalName: '2048 ╴中台中分行'
    },
    {
      bankCodeString: '0172059',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '2059',
      branchName: '高雄分行',
      totalName: '2059 ╴高雄分行'
    },
    {
      bankCodeString: '0172060',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '2060',
      branchName: '板橋分行',
      totalName: '2060 ╴板橋分行'
    },
    {
      bankCodeString: '0172071',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '2071',
      branchName: '桃興分行',
      totalName: '2071 ╴桃興分行'
    },
    {
      bankCodeString: '0172082',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '2082',
      branchName: '竹北分行',
      totalName: '2082 ╴竹北分行'
    },
    {
      bankCodeString: '0172107',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '2107',
      branchName: '敦化分行',
      totalName: '2107 ╴敦化分行'
    },
    {
      bankCodeString: '0172129',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '2129',
      branchName: '嘉興分行',
      totalName: '2129 ╴嘉興分行'
    },
    {
      bankCodeString: '0172130',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '2130',
      branchName: '台南分行',
      totalName: '2130 ╴台南分行'
    },
    {
      bankCodeString: '0172141',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '2141',
      branchName: '寶成分行',
      totalName: '2141 ╴寶成分行'
    },
    {
      bankCodeString: '0172152',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '2152',
      branchName: '竹科竹村分行',
      totalName: '2152 ╴竹科竹村分行'
    },
    {
      bankCodeString: '0172163',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '2163',
      branchName: '世貿分行',
      totalName: '2163 ╴世貿分行'
    },
    {
      bankCodeString: '0172196',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '2196',
      branchName: '南三重分行',
      totalName: '2196 ╴南三重分行'
    },
    {
      bankCodeString: '0172200',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '2200',
      branchName: '南彰化分行',
      totalName: '2200 ╴南彰化分行'
    },
    {
      bankCodeString: '0172266',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '2266',
      branchName: '城東分行',
      totalName: '2266 ╴城東分行'
    },
    {
      bankCodeString: '0172277',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '2277',
      branchName: '五福分行',
      totalName: '2277 ╴五福分行'
    },
    {
      bankCodeString: '0172288',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '2288',
      branchName: '羅東分行',
      totalName: '2288 ╴羅東分行'
    },
    {
      bankCodeString: '0172299',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '2299',
      branchName: '大安分行',
      totalName: '2299 ╴大安分行'
    },
    {
      bankCodeString: '0172314',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '2314',
      branchName: '林口分行',
      totalName: '2314 ╴林口分行'
    },
    {
      bankCodeString: '0172325',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '2325',
      branchName: '后里分行',
      totalName: '2325 ╴后里分行'
    },
    {
      bankCodeString: '0172336',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '2336',
      branchName: '鹿港分行',
      totalName: '2336 ╴鹿港分行'
    },
    {
      bankCodeString: '0172347',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '2347',
      branchName: '圓山分行',
      totalName: '2347 ╴圓山分行'
    },
    {
      bankCodeString: '0172358',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '2358',
      branchName: '思源分行',
      totalName: '2358 ╴思源分行'
    },
    {
      bankCodeString: '0172369',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '2369',
      branchName: '北中壢分行',
      totalName: '2369 ╴北中壢分行'
    },
    {
      bankCodeString: '0172370',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '2370',
      branchName: '內湖科學園區分行',
      totalName: '2370 ╴內湖科學園區分行'
    },
    {
      bankCodeString: '0172381',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '2381',
      branchName: '雙和分行',
      totalName: '2381 ╴雙和分行'
    },
    {
      bankCodeString: '0172406',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '2406',
      branchName: '衡陽分行',
      totalName: '2406 ╴衡陽分行'
    },
    {
      bankCodeString: '0172417',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '2417',
      branchName: '大里分行',
      totalName: '2417 ╴大里分行'
    },
    {
      bankCodeString: '0172428',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '2428',
      branchName: '台南科學園區分行',
      totalName: '2428 ╴台南科學園區分行'
    },
    {
      bankCodeString: '0172439',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '2439',
      branchName: '投資部',
      totalName: '2439 ╴投資部'
    },
    {
      bankCodeString: '0178006',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '8006',
      branchName: '紐約分行',
      totalName: '8006 ╴紐約分行'
    },
    {
      bankCodeString: '0178017',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '8017',
      branchName: '芝加哥分行',
      totalName: '8017 ╴芝加哥分行'
    },
    {
      bankCodeString: '0178028',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '8028',
      branchName: '洛杉磯分行',
      totalName: '8028 ╴洛杉磯分行'
    },
    {
      bankCodeString: '0178039',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '8039',
      branchName: '巴拿馬分行',
      totalName: '8039 ╴巴拿馬分行'
    },
    {
      bankCodeString: '0178213',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '8213',
      branchName: '大眾股份有限公司曼谷總行',
      totalName: '8213 ╴大眾股份有限公司曼谷總行'
    },
    {
      bankCodeString: '0178224',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '8224',
      branchName: '東京分行',
      totalName: '8224 ╴東京分行'
    },
    {
      bankCodeString: '0178235',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '8235',
      branchName: '大阪分行',
      totalName: '8235 ╴大阪分行'
    },
    {
      bankCodeString: '0178246',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '8246',
      branchName: '新加坡分行',
      totalName: '8246 ╴新加坡分行'
    },
    {
      bankCodeString: '0178257',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '8257',
      branchName: '馬尼拉分行',
      totalName: '8257 ╴馬尼拉分行'
    },
    {
      bankCodeString: '0178280',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '8280',
      branchName: '胡志明市分行',
      totalName: '8280 ╴胡志明市分行'
    },
    {
      bankCodeString: '0178291',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '8291',
      branchName: '納閩分行',
      totalName: '8291 ╴納閩分行'
    },
    {
      bankCodeString: '0178305',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '8305',
      branchName: '蘇州分行',
      totalName: '8305 ╴蘇州分行'
    },
    {
      bankCodeString: '0178327',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '8327',
      branchName: '金邊分行',
      totalName: '8327 ╴金邊分行'
    },
    {
      bankCodeString: '0178338',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '8338',
      branchName: '金邊機場支行',
      totalName: '8338 ╴金邊機場支行'
    },
    {
      bankCodeString: '0178349',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '8349',
      branchName: '蘇州吳江支行',
      totalName: '8349 ╴蘇州吳江支行'
    },
    {
      bankCodeString: '0178350',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '8350',
      branchName: '金邊奧林匹克支行',
      totalName: '8350 ╴金邊奧林匹克支行'
    },
    {
      bankCodeString: '0178361',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '8361',
      branchName: '寧波分行',
      totalName: '8361 ╴寧波分行'
    },
    {
      bankCodeString: '0178372',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '8372',
      branchName: '金邊堆谷支行',
      totalName: '8372 ╴金邊堆谷支行'
    },
    {
      bankCodeString: '0178383',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '8383',
      branchName: '崑山支行',
      totalName: '8383 ╴崑山支行'
    },
    {
      bankCodeString: '0178394',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '8394',
      branchName: '金邊桑園支行',
      totalName: '8394 ╴金邊桑園支行'
    },
    {
      bankCodeString: '0178453',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '8453',
      branchName: '仰光分行',
      totalName: '8453 ╴仰光分行'
    },
    {
      bankCodeString: '0178464',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '8464',
      branchName: '雪梨分行',
      totalName: '8464 ╴雪梨分行'
    },
    {
      bankCodeString: '0178475',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '8475',
      branchName: '布里斯本分行',
      totalName: '8475 ╴布里斯本分行'
    },
    {
      bankCodeString: '0178486',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '8486',
      branchName: '墨爾本分行',
      totalName: '8486 ╴墨爾本分行'
    },
    {
      bankCodeString: '0178497',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '8497',
      branchName: '倫敦分行',
      totalName: '8497 ╴倫敦分行'
    },
    {
      bankCodeString: '0178615',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '8615',
      branchName: '巴黎分行',
      totalName: '8615 ╴巴黎分行'
    },
    {
      bankCodeString: '0178626',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '8626',
      branchName: '阿姆斯特丹分行',
      totalName: '8626 ╴阿姆斯特丹分行'
    },
    {
      bankCodeString: '0178763',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '8763',
      branchName: '多倫多分行',
      totalName: '8763 ╴多倫多分行'
    },
    {
      bankCodeString: '0178774',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '8774',
      branchName: '溫哥華分行',
      totalName: '8774 ╴溫哥華分行'
    },
    {
      bankCodeString: '0178822',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '8822',
      branchName: '大眾股份有限公司春武里分行',
      totalName: '8822 ╴大眾股份有限公司春武里分行'
    },
    {
      bankCodeString: '0178833',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '8833',
      branchName: '大眾股份有限公司挽那分行',
      totalName: '8833 ╴大眾股份有限公司挽那分行'
    },
    {
      bankCodeString: '0178844',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '8844',
      branchName: '泰國子行羅勇分行',
      totalName: '8844 ╴泰國子行羅勇分行'
    },
    {
      bankCodeString: '0178947',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '8947',
      branchName: '吉隆坡行銷服務處',
      totalName: '8947 ╴吉隆坡行銷服務處'
    },
    {
      bankCodeString: '0178958',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '8958',
      branchName: '香港分行',
      totalName: '8958 ╴香港分行'
    },
    {
      bankCodeString: '0178981',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '8981',
      branchName: '矽谷分行',
      totalName: '8981 ╴矽谷分行'
    },
    {
      bankCodeString: '0178992',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '8992',
      branchName: '孟買代表人辦事處',
      totalName: '8992 ╴孟買代表人辦事處'
    },
    {
      bankCodeString: '0179004',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '9004',
      branchName: '資訊處',
      totalName: '9004 ╴資訊處'
    },
    {
      bankCodeString: '0179014',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '9014',
      branchName: '大眾股份有限公司萬磅分行',
      totalName: '9014 ╴大眾股份有限公司萬磅分行'
    },
    {
      bankCodeString: '0179025',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '9025',
      branchName: '總務處',
      totalName: '9025 ╴總務處'
    },
    {
      bankCodeString: '0179069',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '9069',
      branchName: '董事會稽核室',
      totalName: '9069 ╴董事會稽核室'
    },
    {
      bankCodeString: '0179081',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '9081',
      branchName: '徵信處',
      totalName: '9081 ╴徵信處'
    },
    {
      bankCodeString: '0179106',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '9106',
      branchName: '人力資源處',
      totalName: '9106 ╴人力資源處'
    },
    {
      bankCodeString: '0179128',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '9128',
      branchName: '風險控管處',
      totalName: '9128 ╴風險控管處'
    },
    {
      bankCodeString: '0179140',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '9140',
      branchName: '法律事務處',
      totalName: '9140 ╴法律事務處'
    },
    {
      bankCodeString: '0179162',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '9162',
      branchName: '企劃處',
      totalName: '9162 ╴企劃處'
    },
    {
      bankCodeString: '0179180',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '9180',
      branchName: '授信管理處',
      totalName: '9180 ╴授信管理處'
    },
    {
      bankCodeString: '0179243',
      bankCode: '017',
      bankName: '兆豐國際商業銀行',
      branchCode: '9243',
      branchName: '債權管理處',
      totalName: '9243 ╴債權管理處'
    }
  ],
  '018': [
    {
      bankCodeString: '0180012',
      bankCode: '018',
      bankName: '全國農業金庫',
      branchCode: '0012',
      branchName: '營業部',
      totalName: '0012 ╴營業部'
    },
    {
      bankCodeString: '0180013',
      bankCode: '018',
      bankName: '全國農業金庫',
      branchCode: '0013',
      branchName: '風險管理室',
      totalName: '0013 ╴風險管理室'
    },
    {
      bankCodeString: '0180020',
      bankCode: '018',
      bankName: '全國農業金庫',
      branchCode: '0020',
      branchName: '信託部',
      totalName: '0020 ╴信託部'
    },
    {
      bankCodeString: '0180034',
      bankCode: '018',
      bankName: '全國農業金庫',
      branchCode: '0034',
      branchName: '台中分行',
      totalName: '0034 ╴台中分行'
    },
    {
      bankCodeString: '0180045',
      bankCode: '018',
      bankName: '全國農業金庫',
      branchCode: '0045',
      branchName: '高雄分行',
      totalName: '0045 ╴高雄分行'
    },
    {
      bankCodeString: '0180056',
      bankCode: '018',
      bankName: '全國農業金庫',
      branchCode: '0056',
      branchName: '國外部',
      totalName: '0056 ╴國外部'
    },
    {
      bankCodeString: '0180067',
      bankCode: '018',
      bankName: '全國農業金庫',
      branchCode: '0067',
      branchName: '新竹分行',
      totalName: '0067 ╴新竹分行'
    },
    {
      bankCodeString: '0180078',
      bankCode: '018',
      bankName: '全國農業金庫',
      branchCode: '0078',
      branchName: '嘉義分行',
      totalName: '0078 ╴嘉義分行'
    },
    {
      bankCodeString: '0180090',
      bankCode: '018',
      bankName: '全國農業金庫',
      branchCode: '0090',
      branchName: '桃園分行',
      totalName: '0090 ╴桃園分行'
    },
    {
      bankCodeString: '0180104',
      bankCode: '018',
      bankName: '全國農業金庫',
      branchCode: '0104',
      branchName: '臺南分行',
      totalName: '0104 ╴臺南分行'
    },
    {
      bankCodeString: '0180800',
      bankCode: '018',
      bankName: '全國農業金庫',
      branchCode: '0800',
      branchName: '董事會',
      totalName: '0800 ╴董事會'
    },
    {
      bankCodeString: '0180801',
      bankCode: '018',
      bankName: '全國農業金庫',
      branchCode: '0801',
      branchName: '秘書室',
      totalName: '0801 ╴秘書室'
    },
    {
      bankCodeString: '0180802',
      bankCode: '018',
      bankName: '全國農業金庫',
      branchCode: '0802',
      branchName: '會計室',
      totalName: '0802 ╴會計室'
    },
    {
      bankCodeString: '0180803',
      bankCode: '018',
      bankName: '全國農業金庫',
      branchCode: '0803',
      branchName: '資訊室',
      totalName: '0803 ╴資訊室'
    },
    {
      bankCodeString: '0180804',
      bankCode: '018',
      bankName: '全國農業金庫',
      branchCode: '0804',
      branchName: '業務企劃部',
      totalName: '0804 ╴業務企劃部'
    },
    {
      bankCodeString: '0180805',
      bankCode: '018',
      bankName: '全國農業金庫',
      branchCode: '0805',
      branchName: '財務部',
      totalName: '0805 ╴財務部'
    },
    {
      bankCodeString: '0180806',
      bankCode: '018',
      bankName: '全國農業金庫',
      branchCode: '0806',
      branchName: '專業金融部',
      totalName: '0806 ╴專業金融部'
    },
    {
      bankCodeString: '0180807',
      bankCode: '018',
      bankName: '全國農業金庫',
      branchCode: '0807',
      branchName: '稽核室',
      totalName: '0807 ╴稽核室'
    },
    {
      bankCodeString: '0180808',
      bankCode: '018',
      bankName: '全國農業金庫',
      branchCode: '0808',
      branchName: '資訊室',
      totalName: '0808 ╴資訊室'
    },
    {
      bankCodeString: '0180810',
      bankCode: '018',
      bankName: '全國農業金庫',
      branchCode: '0810',
      branchName: '兼營證券商',
      totalName: '0810 ╴兼營證券商'
    },
    {
      bankCodeString: '0180811',
      bankCode: '018',
      bankName: '全國農業金庫',
      branchCode: '0811',
      branchName: '業務部',
      totalName: '0811 ╴業務部'
    },
    {
      bankCodeString: '0180812',
      bankCode: '018',
      bankName: '全國農業金庫',
      branchCode: '0812',
      branchName: '企劃部',
      totalName: '0812 ╴企劃部'
    }
  ],
  '021': [
    {
      bankCodeString: '0210018',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0018',
      branchName: '營業部',
      totalName: '0018 ╴營業部'
    },
    {
      bankCodeString: '0210030',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0030',
      branchName: '高雄分行',
      totalName: '0030 ╴高雄分行'
    },
    {
      bankCodeString: '0210041',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0041',
      branchName: '台中分行',
      totalName: '0041 ╴台中分行'
    },
    {
      bankCodeString: '0210052',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0052',
      branchName: '新竹分行',
      totalName: '0052 ╴新竹分行'
    },
    {
      bankCodeString: '0210063',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0063',
      branchName: '台南分行',
      totalName: '0063 ╴台南分行'
    },
    {
      bankCodeString: '0210074',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0074',
      branchName: '桃園分行',
      totalName: '0074 ╴桃園分行'
    },
    {
      bankCodeString: '0210085',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0085',
      branchName: '松江分行',
      totalName: '0085 ╴松江分行'
    },
    {
      bankCodeString: '0210096',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0096',
      branchName: '板橋分行',
      totalName: '0096 ╴板橋分行'
    },
    {
      bankCodeString: '0210100',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0100',
      branchName: '文心分行',
      totalName: '0100 ╴文心分行'
    },
    {
      bankCodeString: '0210111',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0111',
      branchName: '信義分行',
      totalName: '0111 ╴信義分行'
    },
    {
      bankCodeString: '0210122',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0122',
      branchName: '天母分行',
      totalName: '0122 ╴天母分行'
    },
    {
      bankCodeString: '0210133',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0133',
      branchName: '建成分行',
      totalName: '0133 ╴建成分行'
    },
    {
      bankCodeString: '0210144',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0144',
      branchName: '中山分行',
      totalName: '0144 ╴中山分行'
    },
    {
      bankCodeString: '0210166',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0166',
      branchName: '永福分行',
      totalName: '0166 ╴永福分行'
    },
    {
      bankCodeString: '0210177',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0177',
      branchName: '港都分行',
      totalName: '0177 ╴港都分行'
    },
    {
      bankCodeString: '0210188',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0188',
      branchName: '中港分行',
      totalName: '0188 ╴中港分行'
    },
    {
      bankCodeString: '0210199',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0199',
      branchName: '襄陽分行',
      totalName: '0199 ╴襄陽分行'
    },
    {
      bankCodeString: '0210203',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0203',
      branchName: '復興分行',
      totalName: '0203 ╴復興分行'
    },
    {
      bankCodeString: '0210225',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0225',
      branchName: '基隆分行',
      totalName: '0225 ╴基隆分行'
    },
    {
      bankCodeString: '0210236',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0236',
      branchName: '北桃園分行',
      totalName: '0236 ╴北桃園分行'
    },
    {
      bankCodeString: '0210247',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0247',
      branchName: '永和分行',
      totalName: '0247 ╴永和分行'
    },
    {
      bankCodeString: '0210258',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0258',
      branchName: '豐原分行',
      totalName: '0258 ╴豐原分行'
    },
    {
      bankCodeString: '0210269',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0269',
      branchName: '大益分行',
      totalName: '0269 ╴大益分行'
    },
    {
      bankCodeString: '0210281',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0281',
      branchName: '苓雅分行',
      totalName: '0281 ╴苓雅分行'
    },
    {
      bankCodeString: '0210292',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0292',
      branchName: '松山分行',
      totalName: '0292 ╴松山分行'
    },
    {
      bankCodeString: '0210306',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0306',
      branchName: '三重分行',
      totalName: '0306 ╴三重分行'
    },
    {
      bankCodeString: '0210317',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0317',
      branchName: '員林分行',
      totalName: '0317 ╴員林分行'
    },
    {
      bankCodeString: '0210328',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0328',
      branchName: '士林分行',
      totalName: '0328 ╴士林分行'
    },
    {
      bankCodeString: '0210340',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0340',
      branchName: '中壢分行',
      totalName: '0340 ╴中壢分行'
    },
    {
      bankCodeString: '0210351',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0351',
      branchName: '大安分行',
      totalName: '0351 ╴大安分行'
    },
    {
      bankCodeString: '0210362',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0362',
      branchName: '竹城分行',
      totalName: '0362 ╴竹城分行'
    },
    {
      bankCodeString: '0210373',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0373',
      branchName: '嘉義分行',
      totalName: '0373 ╴嘉義分行'
    },
    {
      bankCodeString: '0210384',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0384',
      branchName: '北台中分行',
      totalName: '0384 ╴北台中分行'
    },
    {
      bankCodeString: '0210395',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0395',
      branchName: '北高雄分行',
      totalName: '0395 ╴北高雄分行'
    },
    {
      bankCodeString: '0210409',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0409',
      branchName: '新莊分行',
      totalName: '0409 ╴新莊分行'
    },
    {
      bankCodeString: '0210421',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0421',
      branchName: '彰化分行',
      totalName: '0421 ╴彰化分行'
    },
    {
      bankCodeString: '0210443',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0443',
      branchName: '新店分行',
      totalName: '0443 ╴新店分行'
    },
    {
      bankCodeString: '0210476',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0476',
      branchName: '南京分行',
      totalName: '0476 ╴南京分行'
    },
    {
      bankCodeString: '0210502',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0502',
      branchName: '中正分行',
      totalName: '0502 ╴中正分行'
    },
    {
      bankCodeString: '0210524',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0524',
      branchName: '羅東分行',
      totalName: '0524 ╴羅東分行'
    },
    {
      bankCodeString: '0210535',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0535',
      branchName: '敦化分行',
      totalName: '0535 ╴敦化分行'
    },
    {
      bankCodeString: '0210579',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0579',
      branchName: '內湖分行',
      totalName: '0579 ╴內湖分行'
    },
    {
      bankCodeString: '0210580',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0580',
      branchName: '南港分行',
      totalName: '0580 ╴南港分行'
    },
    {
      bankCodeString: '0210627',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0627',
      branchName: '斗六分行',
      totalName: '0627 ╴斗六分行'
    },
    {
      bankCodeString: '0210650',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0650',
      branchName: '土城分行',
      totalName: '0650 ╴土城分行'
    },
    {
      bankCodeString: '0210683',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0683',
      branchName: '國際金融業務分行',
      totalName: '0683 ╴國際金融業務分行'
    },
    {
      bankCodeString: '0210694',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0694',
      branchName: '信託部',
      totalName: '0694 ╴信託部'
    },
    {
      bankCodeString: '0210708',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0708',
      branchName: '國外部',
      totalName: '0708 ╴國外部'
    },
    {
      bankCodeString: '0210748',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0748',
      branchName: '徵信審核處',
      totalName: '0748 ╴徵信審核處'
    },
    {
      bankCodeString: '0210790',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0790',
      branchName: '兼營證券商',
      totalName: '0790 ╴兼營證券商'
    },
    {
      bankCodeString: '0210896',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '0896',
      branchName: '證券部',
      totalName: '0896 ╴證券部'
    },
    {
      bankCodeString: '0215001',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '5001',
      branchName: '董事會秘書處議事科',
      totalName: '5001 ╴董事會秘書處議事科'
    },
    {
      bankCodeString: '0215002',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '5002',
      branchName: '人力資源處',
      totalName: '5002 ╴人力資源處'
    },
    {
      bankCodeString: '0215004',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '5004',
      branchName: '董事會秘書處股務科',
      totalName: '5004 ╴董事會秘書處股務科'
    },
    {
      bankCodeString: '0215010',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '5010',
      branchName: '信用卡風險管理處',
      totalName: '5010 ╴信用卡風險管理處'
    },
    {
      bankCodeString: '0215015',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '5015',
      branchName: '法務處',
      totalName: '5015 ╴法務處'
    },
    {
      bankCodeString: '0215016',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '5016',
      branchName: '授信管理處',
      totalName: '5016 ╴授信管理處'
    },
    {
      bankCodeString: '0215017',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '5017',
      branchName: '作業管理處',
      totalName: '5017 ╴作業管理處'
    },
    {
      bankCodeString: '0215018',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '5018',
      branchName: '金融交易服務處',
      totalName: '5018 ╴金融交易服務處'
    },
    {
      bankCodeString: '0215019',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '5019',
      branchName: '決策管理處',
      totalName: '5019 ╴決策管理處'
    },
    {
      bankCodeString: '0215020',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '5020',
      branchName: '債權管理處',
      totalName: '5020 ╴債權管理處'
    },
    {
      bankCodeString: '0215021',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '5021',
      branchName: '金融交易部',
      totalName: '5021 ╴金融交易部'
    },
    {
      bankCodeString: '0215022',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '5022',
      branchName: '財富管理暨投資事業處',
      totalName: '5022 ╴財富管理暨投資事業處'
    },
    {
      bankCodeString: '0215024',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '5024',
      branchName: '電話理財服務中心',
      totalName: '5024 ╴電話理財服務中心'
    },
    {
      bankCodeString: '0215025',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '5025',
      branchName: '個人金融處',
      totalName: '5025 ╴個人金融處'
    },
    {
      bankCodeString: '0215026',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '5026',
      branchName: '企業金融處',
      totalName: '5026 ╴企業金融處'
    },
    {
      bankCodeString: '0215027',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '5027',
      branchName: '零售銀行業風險管理處',
      totalName: '5027 ╴零售銀行業風險管理處'
    },
    {
      bankCodeString: '0215028',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '5028',
      branchName: '分行業務處',
      totalName: '5028 ╴分行業務處'
    },
    {
      bankCodeString: '0215029',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '5029',
      branchName: '金融同業處',
      totalName: '5029 ╴金融同業處'
    },
    {
      bankCodeString: '0215030',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '5030',
      branchName: '信用卡作業管理處',
      totalName: '5030 ╴信用卡作業管理處'
    },
    {
      bankCodeString: '0215032',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '5032',
      branchName: '中小企業處',
      totalName: '5032 ╴中小企業處'
    },
    {
      bankCodeString: '0215033',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '5033',
      branchName: '外匯作業管理處',
      totalName: '5033 ╴外匯作業管理處'
    },
    {
      bankCodeString: '0215034',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '5034',
      branchName: '環球金融交易服務處',
      totalName: '5034 ╴環球金融交易服務處'
    },
    {
      bankCodeString: '0215035',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '5035',
      branchName: '貸後管理處',
      totalName: '5035 ╴貸後管理處'
    },
    {
      bankCodeString: '0215036',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '5036',
      branchName: '跨國企業處',
      totalName: '5036 ╴跨國企業處'
    },
    {
      bankCodeString: '0215037',
      bankCode: '021',
      bankName: '花旗(台灣)商業銀行',
      branchCode: '5037',
      branchName: '全方位金融服務處',
      totalName: '5037 ╴全方位金融服務處'
    }
  ],
  '048': [
    {
      bankCodeString: '0480001',
      bankCode: '048',
      bankName: '王道商業銀行',
      branchCode: '0001',
      branchName: '董事會秘書部',
      totalName: '0001 ╴董事會秘書部'
    },
    {
      bankCodeString: '0480002',
      bankCode: '048',
      bankName: '王道商業銀行',
      branchCode: '0002',
      branchName: '稽核部',
      totalName: '0002 ╴稽核部'
    },
    {
      bankCodeString: '0480003',
      bankCode: '048',
      bankName: '王道商業銀行',
      branchCode: '0003',
      branchName: '公共關係部',
      totalName: '0003 ╴公共關係部'
    },
    {
      bankCodeString: '0480004',
      bankCode: '048',
      bankName: '王道商業銀行',
      branchCode: '0004',
      branchName: '戰略企劃部',
      totalName: '0004 ╴戰略企劃部'
    },
    {
      bankCodeString: '0480005',
      bankCode: '048',
      bankName: '王道商業銀行',
      branchCode: '0005',
      branchName: '企業理財部',
      totalName: '0005 ╴企業理財部'
    },
    {
      bankCodeString: '0480006',
      bankCode: '048',
      bankName: '王道商業銀行',
      branchCode: '0006',
      branchName: '投資部',
      totalName: '0006 ╴投資部'
    },
    {
      bankCodeString: '0480007',
      bankCode: '048',
      bankName: '王道商業銀行',
      branchCode: '0007',
      branchName: '企業金融業務部',
      totalName: '0007 ╴企業金融業務部'
    },
    {
      bankCodeString: '0480008',
      bankCode: '048',
      bankName: '王道商業銀行',
      branchCode: '0008',
      branchName: '證券部',
      totalName: '0008 ╴證券部'
    },
    {
      bankCodeString: '0480009',
      bankCode: '048',
      bankName: '王道商業銀行',
      branchCode: '0009',
      branchName: '金融交易部',
      totalName: '0009 ╴金融交易部'
    },
    {
      bankCodeString: '0480010',
      bankCode: '048',
      bankName: '王道商業銀行',
      branchCode: '0010',
      branchName: '國際事務部',
      totalName: '0010 ╴國際事務部'
    },
    {
      bankCodeString: '0480011',
      bankCode: '048',
      bankName: '王道商業銀行',
      branchCode: '0011',
      branchName: '營業部',
      totalName: '0011 ╴營業部'
    },
    {
      bankCodeString: '0480012',
      bankCode: '048',
      bankName: '王道商業銀行',
      branchCode: '0012',
      branchName: '財務管理部',
      totalName: '0012 ╴財務管理部'
    },
    {
      bankCodeString: '0480013',
      bankCode: '048',
      bankName: '王道商業銀行',
      branchCode: '0013',
      branchName: '人力資源部',
      totalName: '0013 ╴人力資源部'
    },
    {
      bankCodeString: '0480015',
      bankCode: '048',
      bankName: '王道商業銀行',
      branchCode: '0015',
      branchName: '總務部',
      totalName: '0015 ╴總務部'
    },
    {
      bankCodeString: '0480016',
      bankCode: '048',
      bankName: '王道商業銀行',
      branchCode: '0016',
      branchName: '資訊部',
      totalName: '0016 ╴資訊部'
    },
    {
      bankCodeString: '0480017',
      bankCode: '048',
      bankName: '王道商業銀行',
      branchCode: '0017',
      branchName: '法務暨法遵部',
      totalName: '0017 ╴法務暨法遵部'
    },
    {
      bankCodeString: '0480018',
      bankCode: '048',
      bankName: '王道商業銀行',
      branchCode: '0018',
      branchName: '風險管理部',
      totalName: '0018 ╴風險管理部'
    },
    {
      bankCodeString: '0480019',
      bankCode: '048',
      bankName: '王道商業銀行',
      branchCode: '0019',
      branchName: '現金管理暨電子金融部',
      totalName: '0019 ╴現金管理暨電子金融部'
    },
    {
      bankCodeString: '0480021',
      bankCode: '048',
      bankName: '王道商業銀行',
      branchCode: '0021',
      branchName: '信託部',
      totalName: '0021 ╴信託部'
    },
    {
      bankCodeString: '0480022',
      bankCode: '048',
      bankName: '王道商業銀行',
      branchCode: '0022',
      branchName: '桃園分行',
      totalName: '0022 ╴桃園分行'
    },
    {
      bankCodeString: '0480024',
      bankCode: '048',
      bankName: '王道商業銀行',
      branchCode: '0024',
      branchName: '作業服務部',
      totalName: '0024 ╴作業服務部'
    },
    {
      bankCodeString: '0480033',
      bankCode: '048',
      bankName: '王道商業銀行',
      branchCode: '0033',
      branchName: '高雄分行',
      totalName: '0033 ╴高雄分行'
    },
    {
      bankCodeString: '0480055',
      bankCode: '048',
      bankName: '王道商業銀行',
      branchCode: '0055',
      branchName: '台中分行',
      totalName: '0055 ╴台中分行'
    },
    {
      bankCodeString: '0480066',
      bankCode: '048',
      bankName: '王道商業銀行',
      branchCode: '0066',
      branchName: '新竹分行',
      totalName: '0066 ╴新竹分行'
    },
    {
      bankCodeString: '0480077',
      bankCode: '048',
      bankName: '王道商業銀行',
      branchCode: '0077',
      branchName: '忠孝敦化分行',
      totalName: '0077 ╴忠孝敦化分行'
    },
    {
      bankCodeString: '0480088',
      bankCode: '048',
      bankName: '王道商業銀行',
      branchCode: '0088',
      branchName: '國際金融業務分行',
      totalName: '0088 ╴國際金融業務分行'
    },
    {
      bankCodeString: '0480540',
      bankCode: '048',
      bankName: '王道商業銀行',
      branchCode: '0540',
      branchName: '兼營證券商',
      totalName: '0540 ╴兼營證券商'
    },
    {
      bankCodeString: '0489896',
      bankCode: '048',
      bankName: '王道商業銀行',
      branchCode: '9896',
      branchName: '美國華信銀行Milpitas分行',
      totalName: '9896 ╴美國華信銀行Milpitas分行'
    },
    {
      bankCodeString: '0489900',
      bankCode: '048',
      bankName: '王道商業銀行',
      branchCode: '9900',
      branchName: '美國華信銀行Pasadena分行',
      totalName: '9900 ╴美國華信銀行Pasadena分行'
    },
    {
      bankCodeString: '0489911',
      bankCode: '048',
      bankName: '王道商業銀行',
      branchCode: '9911',
      branchName: '天津代表人辦事處',
      totalName: '9911 ╴天津代表人辦事處'
    },
    {
      bankCodeString: '0489922',
      bankCode: '048',
      bankName: '王道商業銀行',
      branchCode: '9922',
      branchName: '美國華信銀行Industry分行',
      totalName: '9922 ╴美國華信銀行Industry分行'
    },
    {
      bankCodeString: '0489933',
      bankCode: '048',
      bankName: '王道商業銀行',
      branchCode: '9933',
      branchName: '美國華信銀行Arcadia分行',
      totalName: '9933 ╴美國華信銀行Arcadia分行'
    },
    {
      bankCodeString: '0489944',
      bankCode: '048',
      bankName: '王道商業銀行',
      branchCode: '9944',
      branchName: '美國華信銀行Cupertino分行',
      totalName: '9944 ╴美國華信銀行Cupertino分行'
    },
    {
      bankCodeString: '0489955',
      bankCode: '048',
      bankName: '王道商業銀行',
      branchCode: '9955',
      branchName: '美國華信銀行Cerritos分行',
      totalName: '9955 ╴美國華信銀行Cerritos分行'
    },
    {
      bankCodeString: '0489966',
      bankCode: '048',
      bankName: '王道商業銀行',
      branchCode: '9966',
      branchName: '美國華信銀行Irvine分行',
      totalName: '9966 ╴美國華信銀行Irvine分行'
    },
    {
      bankCodeString: '0489977',
      bankCode: '048',
      bankName: '王道商業銀行',
      branchCode: '9977',
      branchName: '美國華信銀行Alhambra分行',
      totalName: '9977 ╴美國華信銀行Alhambra分行'
    },
    {
      bankCodeString: '0489988',
      bankCode: '048',
      bankName: '王道商業銀行',
      branchCode: '9988',
      branchName: '香港分行',
      totalName: '9988 ╴香港分行'
    },
    {
      bankCodeString: '0489999',
      bankCode: '048',
      bankName: '王道商業銀行',
      branchCode: '9999',
      branchName: '美國華信銀行(EverTrust Bank)',
      totalName: '9999 ╴美國華信銀行(EverTrust Bank)'
    },
    {
      bankCodeString: '048BS12',
      bankCode: '048',
      bankName: '王道商業銀行',
      branchCode: 'BS12',
      branchName: '營業部桃園區',
      totalName: 'BS12 ╴營業部桃園區'
    }
  ],
  '050': [
    {
      bankCodeString: '0500001',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '0001',
      branchName: '董事會',
      totalName: '0001 ╴董事會'
    },
    {
      bankCodeString: '0500005',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '0005',
      branchName: '會計處',
      totalName: '0005 ╴會計處'
    },
    {
      bankCodeString: '0500006',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '0006',
      branchName: '財務部',
      totalName: '0006 ╴財務部'
    },
    {
      bankCodeString: '0500007',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '0007',
      branchName: '人力資源處',
      totalName: '0007 ╴人力資源處'
    },
    {
      bankCodeString: '0500008',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '0008',
      branchName: '行政管理處',
      totalName: '0008 ╴行政管理處'
    },
    {
      bankCodeString: '0500009',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '0009',
      branchName: '風險管理部',
      totalName: '0009 ╴風險管理部'
    },
    {
      bankCodeString: '0500010',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '0010',
      branchName: '信用卡部',
      totalName: '0010 ╴信用卡部'
    },
    {
      bankCodeString: '0500011',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '0011',
      branchName: '企業金融部',
      totalName: '0011 ╴企業金融部'
    },
    {
      bankCodeString: '0500012',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '0012',
      branchName: '財務管理部',
      totalName: '0012 ╴財務管理部'
    },
    {
      bankCodeString: '0500013',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '0013',
      branchName: '財富管理部',
      totalName: '0013 ╴財富管理部'
    },
    {
      bankCodeString: '0500027',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '0027',
      branchName: '中和分行',
      totalName: '0027 ╴中和分行'
    },
    {
      bankCodeString: '0500038',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '0038',
      branchName: '博愛分行',
      totalName: '0038 ╴博愛分行'
    },
    {
      bankCodeString: '0500049',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '0049',
      branchName: '北桃園分行',
      totalName: '0049 ╴北桃園分行'
    },
    {
      bankCodeString: '0500050',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '0050',
      branchName: '南崁分行',
      totalName: '0050 ╴南崁分行'
    },
    {
      bankCodeString: '0500061',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '0061',
      branchName: '西屯分行',
      totalName: '0061 ╴西屯分行'
    },
    {
      bankCodeString: '0500072',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '0072',
      branchName: '忠明分行',
      totalName: '0072 ╴忠明分行'
    },
    {
      bankCodeString: '0500083',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '0083',
      branchName: '授信管理部',
      totalName: '0083 ╴授信管理部'
    },
    {
      bankCodeString: '0500094',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '0094',
      branchName: '金門分行',
      totalName: '0094 ╴金門分行'
    },
    {
      bankCodeString: '0500108',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '0108',
      branchName: '營業部',
      totalName: '0108 ╴營業部'
    },
    {
      bankCodeString: '0500109',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '0109',
      branchName: '總務處',
      totalName: '0109 ╴總務處'
    },
    {
      bankCodeString: '0500111',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '0111',
      branchName: '董事會稽核處',
      totalName: '0111 ╴董事會稽核處'
    },
    {
      bankCodeString: '0500119',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '0119',
      branchName: '大雅分行',
      totalName: '0119 ╴大雅分行'
    },
    {
      bankCodeString: '0500120',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '0120',
      branchName: '仁大分行',
      totalName: '0120 ╴仁大分行'
    },
    {
      bankCodeString: '0500153',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '0153',
      branchName: '資訊部',
      totalName: '0153 ╴資訊部'
    },
    {
      bankCodeString: '0500201',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '0201',
      branchName: '仁愛分行',
      totalName: '0201 ╴仁愛分行'
    },
    {
      bankCodeString: '0500212',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '0212',
      branchName: '松山分行',
      totalName: '0212 ╴松山分行'
    },
    {
      bankCodeString: '0500223',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '0223',
      branchName: '建成分行',
      totalName: '0223 ╴建成分行'
    },
    {
      bankCodeString: '0500234',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '0234',
      branchName: '士林分行',
      totalName: '0234 ╴士林分行'
    },
    {
      bankCodeString: '0500245',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '0245',
      branchName: '永和分行',
      totalName: '0245 ╴永和分行'
    },
    {
      bankCodeString: '0500256',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '0256',
      branchName: '新店分行',
      totalName: '0256 ╴新店分行'
    },
    {
      bankCodeString: '0500267',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '0267',
      branchName: '新莊分行',
      totalName: '0267 ╴新莊分行'
    },
    {
      bankCodeString: '0500278',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '0278',
      branchName: '化成分行',
      totalName: '0278 ╴化成分行'
    },
    {
      bankCodeString: '0500304',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '0304',
      branchName: '信託部',
      totalName: '0304 ╴信託部'
    },
    {
      bankCodeString: '0500359',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '0359',
      branchName: '證券部',
      totalName: '0359 ╴證券部'
    },
    {
      bankCodeString: '0500407',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '0407',
      branchName: '松江分行',
      totalName: '0407 ╴松江分行'
    },
    {
      bankCodeString: '0500418',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '0418',
      branchName: '國際部',
      totalName: '0418 ╴國際部'
    },
    {
      bankCodeString: '0500500',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '0500',
      branchName: '台北分行',
      totalName: '0500 ╴台北分行'
    },
    {
      bankCodeString: '0500603',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '0603',
      branchName: '萬華分行',
      totalName: '0603 ╴萬華分行'
    },
    {
      bankCodeString: '0500614',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '0614',
      branchName: '南台北分行',
      totalName: '0614 ╴南台北分行'
    },
    {
      bankCodeString: '0500706',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '0706',
      branchName: '復興分行',
      totalName: '0706 ╴復興分行'
    },
    {
      bankCodeString: '0500809',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '0809',
      branchName: '中山分行',
      totalName: '0809 ╴中山分行'
    },
    {
      bankCodeString: '0500810',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '0810',
      branchName: '建國分行',
      totalName: '0810 ╴建國分行'
    },
    {
      bankCodeString: '0500821',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '0821',
      branchName: '內湖分行',
      totalName: '0821 ╴內湖分行'
    },
    {
      bankCodeString: '0500902',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '0902',
      branchName: '南京東路分行',
      totalName: '0902 ╴南京東路分行'
    },
    {
      bankCodeString: '0500913',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '0913',
      branchName: '徵信部',
      totalName: '0913 ╴徵信部'
    },
    {
      bankCodeString: '0500924',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '0924',
      branchName: '業務管理部',
      totalName: '0924 ╴業務管理部'
    },
    {
      bankCodeString: '0501002',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '1002',
      branchName: '忠孝分行',
      totalName: '1002 ╴忠孝分行'
    },
    {
      bankCodeString: '0501024',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '1024',
      branchName: '世貿分行',
      totalName: '1024 ╴世貿分行'
    },
    {
      bankCodeString: '0501035',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '1035',
      branchName: '永春分行',
      totalName: '1035 ╴永春分行'
    },
    {
      bankCodeString: '0501057',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '1057',
      branchName: '南港分行',
      totalName: '1057 ╴南港分行'
    },
    {
      bankCodeString: '0501105',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '1105',
      branchName: '松南分行',
      totalName: '1105 ╴松南分行'
    },
    {
      bankCodeString: '0501109',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '1109',
      branchName: '證券商屏東分公司',
      totalName: '1109 ╴證券商屏東分公司'
    },
    {
      bankCodeString: '0501110',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '1110',
      branchName: '兼營證券商',
      totalName: '1110 ╴兼營證券商'
    },
    {
      bankCodeString: '0501111',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '1111',
      branchName: '證券商台中分公司',
      totalName: '1111 ╴證券商台中分公司'
    },
    {
      bankCodeString: '0501112',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '1112',
      branchName: '證券商台南分公司',
      totalName: '1112 ╴證券商台南分公司'
    },
    {
      bankCodeString: '0501113',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '1113',
      branchName: '證券商九如分公司',
      totalName: '1113 ╴證券商九如分公司'
    },
    {
      bankCodeString: '0501114',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '1114',
      branchName: '證券商嘉義分公司',
      totalName: '1114 ╴證券商嘉義分公司'
    },
    {
      bankCodeString: '0501115',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '1115',
      branchName: '證券商太平分公司',
      totalName: '1115 ╴證券商太平分公司'
    },
    {
      bankCodeString: '0501116',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '1116',
      branchName: '東湖分行',
      totalName: '1116 ╴東湖分行'
    },
    {
      bankCodeString: '0501117',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '1117',
      branchName: '證券商竹北分公司',
      totalName: '1117 ╴證券商竹北分公司'
    },
    {
      bankCodeString: '0501118',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '1118',
      branchName: '證券商豐原分公司',
      totalName: '1118 ╴證券商豐原分公司'
    },
    {
      bankCodeString: '0501119',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '1119',
      branchName: '證券商岡山分公司',
      totalName: '1119 ╴證券商岡山分公司'
    },
    {
      bankCodeString: '050111A',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '111A',
      branchName: '證券商民雄分公司',
      totalName: '111A ╴證券商民雄分公司'
    },
    {
      bankCodeString: '050111B',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '111B',
      branchName: '證券商建成分公司',
      totalName: '111B ╴證券商建成分公司'
    },
    {
      bankCodeString: '050111C',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '111C',
      branchName: '證券商三民分公司',
      totalName: '111C ╴證券商三民分公司'
    },
    {
      bankCodeString: '050111E',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '111E',
      branchName: '證券商桃園分公司',
      totalName: '111E ╴證券商桃園分公司'
    },
    {
      bankCodeString: '050111F',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '111F',
      branchName: '證券商北高雄分公司',
      totalName: '111F ╴證券商北高雄分公司'
    },
    {
      bankCodeString: '050111G',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '111G',
      branchName: '證券商埔墘分公司',
      totalName: '111G ╴證券商埔墘分公司'
    },
    {
      bankCodeString: '0501208',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '1208',
      branchName: '大安分行',
      totalName: '1208 ╴大安分行'
    },
    {
      bankCodeString: '0501219',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '1219',
      branchName: '雙和分行',
      totalName: '1219 ╴雙和分行'
    },
    {
      bankCodeString: '0501220',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '1220',
      branchName: '錦和分行',
      totalName: '1220 ╴錦和分行'
    },
    {
      bankCodeString: '0501301',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '1301',
      branchName: '五股分行',
      totalName: '1301 ╴五股分行'
    },
    {
      bankCodeString: '0501312',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '1312',
      branchName: '林口分行',
      totalName: '1312 ╴林口分行'
    },
    {
      bankCodeString: '0501323',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '1323',
      branchName: '東林口分行',
      totalName: '1323 ╴東林口分行'
    },
    {
      bankCodeString: '0501404',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '1404',
      branchName: '板橋分行',
      totalName: '1404 ╴板橋分行'
    },
    {
      bankCodeString: '0501415',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '1415',
      branchName: '樹林分行',
      totalName: '1415 ╴樹林分行'
    },
    {
      bankCodeString: '0501426',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '1426',
      branchName: '土城分行',
      totalName: '1426 ╴土城分行'
    },
    {
      bankCodeString: '0501437',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '1437',
      branchName: '迴龍分行',
      totalName: '1437 ╴迴龍分行'
    },
    {
      bankCodeString: '0501448',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '1448',
      branchName: '汐止分行',
      totalName: '1448 ╴汐止分行'
    },
    {
      bankCodeString: '0501482',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '1482',
      branchName: '三峽分行',
      totalName: '1482 ╴三峽分行'
    },
    {
      bankCodeString: '0501507',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '1507',
      branchName: '基隆分行',
      totalName: '1507 ╴基隆分行'
    },
    {
      bankCodeString: '0501518',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '1518',
      branchName: '埔墘分行',
      totalName: '1518 ╴埔墘分行'
    },
    {
      bankCodeString: '0501529',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '1529',
      branchName: '北三重分行',
      totalName: '1529 ╴北三重分行'
    },
    {
      bankCodeString: '0501530',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '1530',
      branchName: '南三重分行',
      totalName: '1530 ╴南三重分行'
    },
    {
      bankCodeString: '0501541',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '1541',
      branchName: '蘆洲分行',
      totalName: '1541 ╴蘆洲分行'
    },
    {
      bankCodeString: '0501600',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '1600',
      branchName: '宜蘭分行',
      totalName: '1600 ╴宜蘭分行'
    },
    {
      bankCodeString: '0501703',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '1703',
      branchName: '羅東分行',
      totalName: '1703 ╴羅東分行'
    },
    {
      bankCodeString: '0501714',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '1714',
      branchName: '蘇澳分行',
      totalName: '1714 ╴蘇澳分行'
    },
    {
      bankCodeString: '0502906',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '2906',
      branchName: '楊梅分行',
      totalName: '2906 ╴楊梅分行'
    },
    {
      bankCodeString: '0502917',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '2917',
      branchName: '湖口分行',
      totalName: '2917 ╴湖口分行'
    },
    {
      bankCodeString: '0503006',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '3006',
      branchName: '桃園分行',
      totalName: '3006 ╴桃園分行'
    },
    {
      bankCodeString: '0503017',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '3017',
      branchName: '大園分行',
      totalName: '3017 ╴大園分行'
    },
    {
      bankCodeString: '0503028',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '3028',
      branchName: '大溪分行',
      totalName: '3028 ╴大溪分行'
    },
    {
      bankCodeString: '0503109',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '3109',
      branchName: '中壢分行',
      totalName: '3109 ╴中壢分行'
    },
    {
      bankCodeString: '0503110',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '3110',
      branchName: '內壢分行',
      totalName: '3110 ╴內壢分行'
    },
    {
      bankCodeString: '0503121',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '3121',
      branchName: '新明分行',
      totalName: '3121 ╴新明分行'
    },
    {
      bankCodeString: '0503132',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '3132',
      branchName: '東桃園分行',
      totalName: '3132 ╴東桃園分行'
    },
    {
      bankCodeString: '0503154',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '3154',
      branchName: '新屋分行',
      totalName: '3154 ╴新屋分行'
    },
    {
      bankCodeString: '0503202',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '3202',
      branchName: '新竹分行',
      totalName: '3202 ╴新竹分行'
    },
    {
      bankCodeString: '0503213',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '3213',
      branchName: '竹北分行',
      totalName: '3213 ╴竹北分行'
    },
    {
      bankCodeString: '0503224',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '3224',
      branchName: '竹科分行',
      totalName: '3224 ╴竹科分行'
    },
    {
      bankCodeString: '0503305',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '3305',
      branchName: '八德分行',
      totalName: '3305 ╴八德分行'
    },
    {
      bankCodeString: '0503327',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '3327',
      branchName: '龍潭分行',
      totalName: '3327 ╴龍潭分行'
    },
    {
      bankCodeString: '0503408',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '3408',
      branchName: '竹東分行',
      totalName: '3408 ╴竹東分行'
    },
    {
      bankCodeString: '0503501',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '3501',
      branchName: '竹南分行',
      totalName: '3501 ╴竹南分行'
    },
    {
      bankCodeString: '0503512',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '3512',
      branchName: '頭份分行',
      totalName: '3512 ╴頭份分行'
    },
    {
      bankCodeString: '0503604',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '3604',
      branchName: '苗栗分行',
      totalName: '3604 ╴苗栗分行'
    },
    {
      bankCodeString: '0504601',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '4601',
      branchName: '豐原分行',
      totalName: '4601 ╴豐原分行'
    },
    {
      bankCodeString: '0504704',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '4704',
      branchName: '太平分行',
      totalName: '4704 ╴太平分行'
    },
    {
      bankCodeString: '0504807',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '4807',
      branchName: '大甲分行',
      totalName: '4807 ╴大甲分行'
    },
    {
      bankCodeString: '0504829',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '4829',
      branchName: '沙鹿分行',
      totalName: '4829 ╴沙鹿分行'
    },
    {
      bankCodeString: '0504830',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '4830',
      branchName: '烏日分行',
      totalName: '4830 ╴烏日分行'
    },
    {
      bankCodeString: '0504900',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '4900',
      branchName: '台中分行',
      totalName: '4900 ╴台中分行'
    },
    {
      bankCodeString: '0504911',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '4911',
      branchName: '民權分行',
      totalName: '4911 ╴民權分行'
    },
    {
      bankCodeString: '0505000',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '5000',
      branchName: '興中分行',
      totalName: '5000 ╴興中分行'
    },
    {
      bankCodeString: '0505011',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '5011',
      branchName: '北屯分行',
      totalName: '5011 ╴北屯分行'
    },
    {
      bankCodeString: '0505103',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '5103',
      branchName: '南投分行',
      totalName: '5103 ╴南投分行'
    },
    {
      bankCodeString: '0505114',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '5114',
      branchName: '草屯分行',
      totalName: '5114 ╴草屯分行'
    },
    {
      bankCodeString: '0505206',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '5206',
      branchName: '埔里分行',
      totalName: '5206 ╴埔里分行'
    },
    {
      bankCodeString: '0505217',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '5217',
      branchName: '潭子分行',
      totalName: '5217 ╴潭子分行'
    },
    {
      bankCodeString: '0505309',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '5309',
      branchName: '竹山分行',
      totalName: '5309 ╴竹山分行'
    },
    {
      bankCodeString: '0505402',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '5402',
      branchName: '彰化分行',
      totalName: '5402 ╴彰化分行'
    },
    {
      bankCodeString: '0505413',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '5413',
      branchName: '和美分行',
      totalName: '5413 ╴和美分行'
    },
    {
      bankCodeString: '0505505',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '5505',
      branchName: '員林分行',
      totalName: '5505 ╴員林分行'
    },
    {
      bankCodeString: '0505608',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '5608',
      branchName: '北斗分行',
      totalName: '5608 ╴北斗分行'
    },
    {
      bankCodeString: '0505619',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '5619',
      branchName: '二林分行',
      totalName: '5619 ╴二林分行'
    },
    {
      bankCodeString: '0506605',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '6605',
      branchName: '斗六分行',
      totalName: '6605 ╴斗六分行'
    },
    {
      bankCodeString: '0506708',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '6708',
      branchName: '北港分行',
      totalName: '6708 ╴北港分行'
    },
    {
      bankCodeString: '0506719',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '6719',
      branchName: '虎尾分行',
      totalName: '6719 ╴虎尾分行'
    },
    {
      bankCodeString: '0506801',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '6801',
      branchName: '嘉義分行',
      totalName: '6801 ╴嘉義分行'
    },
    {
      bankCodeString: '0506812',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '6812',
      branchName: '民雄分行',
      totalName: '6812 ╴民雄分行'
    },
    {
      bankCodeString: '0506867',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '6867',
      branchName: '嘉新分行',
      totalName: '6867 ╴嘉新分行'
    },
    {
      bankCodeString: '0506904',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '6904',
      branchName: '新營分行',
      totalName: '6904 ╴新營分行'
    },
    {
      bankCodeString: '0506915',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '6915',
      branchName: '開元分行',
      totalName: '6915 ╴開元分行'
    },
    {
      bankCodeString: '0507004',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '7004',
      branchName: '永康分行',
      totalName: '7004 ╴永康分行'
    },
    {
      bankCodeString: '0507015',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '7015',
      branchName: '學甲分行',
      totalName: '7015 ╴學甲分行'
    },
    {
      bankCodeString: '0507026',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '7026',
      branchName: '善化分行',
      totalName: '7026 ╴善化分行'
    },
    {
      bankCodeString: '0507037',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '7037',
      branchName: '永大分行',
      totalName: '7037 ╴永大分行'
    },
    {
      bankCodeString: '0507107',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '7107',
      branchName: '台南分行',
      totalName: '7107 ╴台南分行'
    },
    {
      bankCodeString: '0507118',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '7118',
      branchName: '仁德分行',
      totalName: '7118 ╴仁德分行'
    },
    {
      bankCodeString: '0507200',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '7200',
      branchName: '成功分行',
      totalName: '7200 ╴成功分行'
    },
    {
      bankCodeString: '0507211',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '7211',
      branchName: '東台南分行',
      totalName: '7211 ╴東台南分行'
    },
    {
      bankCodeString: '0507303',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '7303',
      branchName: '安平分行',
      totalName: '7303 ╴安平分行'
    },
    {
      bankCodeString: '0507602',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '7602',
      branchName: '花蓮分行',
      totalName: '7602 ╴花蓮分行'
    },
    {
      bankCodeString: '0507705',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '7705',
      branchName: '台東分行',
      totalName: '7705 ╴台東分行'
    },
    {
      bankCodeString: '0508207',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '8207',
      branchName: '東高雄分行',
      totalName: '8207 ╴東高雄分行'
    },
    {
      bankCodeString: '0508300',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '8300',
      branchName: '岡山分行',
      totalName: '8300 ╴岡山分行'
    },
    {
      bankCodeString: '0508403',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '8403',
      branchName: '北鳳山分行',
      totalName: '8403 ╴北鳳山分行'
    },
    {
      bankCodeString: '0508414',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '8414',
      branchName: '苓雅分行',
      totalName: '8414 ╴苓雅分行'
    },
    {
      bankCodeString: '0508506',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '8506',
      branchName: '高雄分行',
      totalName: '8506 ╴高雄分行'
    },
    {
      bankCodeString: '0508517',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '8517',
      branchName: '北高雄分行',
      totalName: '8517 ╴北高雄分行'
    },
    {
      bankCodeString: '0508528',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '8528',
      branchName: '大昌分行',
      totalName: '8528 ╴大昌分行'
    },
    {
      bankCodeString: '0508539',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '8539',
      branchName: '前鎮分行',
      totalName: '8539 ╴前鎮分行'
    },
    {
      bankCodeString: '0508609',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '8609',
      branchName: '九如分行',
      totalName: '8609 ╴九如分行'
    },
    {
      bankCodeString: '0508702',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '8702',
      branchName: '三民分行',
      totalName: '8702 ╴三民分行'
    },
    {
      bankCodeString: '0508805',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '8805',
      branchName: '鳳山分行',
      totalName: '8805 ╴鳳山分行'
    },
    {
      bankCodeString: '0508816',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '8816',
      branchName: '大發分行',
      totalName: '8816 ╴大發分行'
    },
    {
      bankCodeString: '0508908',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '8908',
      branchName: '屏東分行',
      totalName: '8908 ╴屏東分行'
    },
    {
      bankCodeString: '0508919',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '8919',
      branchName: '小港分行',
      totalName: '8919 ╴小港分行'
    },
    {
      bankCodeString: '0508920',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '8920',
      branchName: '潮州分行',
      totalName: '8920 ╴潮州分行'
    },
    {
      bankCodeString: '0508931',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '8931',
      branchName: '國際金融業務分行',
      totalName: '8931 ╴國際金融業務分行'
    },
    {
      bankCodeString: '0509008',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '9008',
      branchName: '洛杉磯分行',
      totalName: '9008 ╴洛杉磯分行'
    },
    {
      bankCodeString: '0509019',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '9019',
      branchName: '香港分行',
      totalName: '9019 ╴香港分行'
    },
    {
      bankCodeString: '0509020',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '9020',
      branchName: '雪梨分行',
      totalName: '9020 ╴雪梨分行'
    },
    {
      bankCodeString: '0509053',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '9053',
      branchName: '上海分行',
      totalName: '9053 ╴上海分行'
    },
    {
      bankCodeString: '0509064',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '9064',
      branchName: '布里斯本分行',
      totalName: '9064 ╴布里斯本分行'
    },
    {
      bankCodeString: '0509075',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '9075',
      branchName: '紐約分行',
      totalName: '9075 ╴紐約分行'
    },
    {
      bankCodeString: '0509086',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '9086',
      branchName: '武漢分行',
      totalName: '9086 ╴武漢分行'
    },
    {
      bankCodeString: '0509101',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '9101',
      branchName: '東京分行',
      totalName: '9101 ╴東京分行'
    },
    {
      bankCodeString: '0509503',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: '9503',
      branchName: '仰光代表人辦事處',
      totalName: '9503 ╴仰光代表人辦事處'
    },
    {
      bankCodeString: '050H017',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: 'H017',
      branchName: '董事會秘書處',
      totalName: 'H017 ╴董事會秘書處'
    },
    {
      bankCodeString: '050H018',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: 'H018',
      branchName: '債權管理部',
      totalName: 'H018 ╴債權管理部'
    },
    {
      bankCodeString: '050H020',
      bankCode: '050',
      bankName: '臺灣中小企業銀行',
      branchCode: 'H020',
      branchName: '個人金融部',
      totalName: 'H020 ╴個人金融部'
    }
  ],
  '052': [
    {
      bankCodeString: '0520001',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0001',
      branchName: '董事會',
      totalName: '0001 ╴董事會'
    },
    {
      bankCodeString: '0520041',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0041',
      branchName: '中壢分行',
      totalName: '0041 ╴中壢分行'
    },
    {
      bankCodeString: '0520052',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0052',
      branchName: '竹東分行',
      totalName: '0052 ╴竹東分行'
    },
    {
      bankCodeString: '0520063',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0063',
      branchName: '竹南分行',
      totalName: '0063 ╴竹南分行'
    },
    {
      bankCodeString: '0520074',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0074',
      branchName: '苗栗分行',
      totalName: '0074 ╴苗栗分行'
    },
    {
      bankCodeString: '0520096',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0096',
      branchName: '頭份分行',
      totalName: '0096 ╴頭份分行'
    },
    {
      bankCodeString: '0520111',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0111',
      branchName: '楊梅分行',
      totalName: '0111 ╴楊梅分行'
    },
    {
      bankCodeString: '0520122',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0122',
      branchName: '新屋分行',
      totalName: '0122 ╴新屋分行'
    },
    {
      bankCodeString: '0520133',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0133',
      branchName: '湖口分行',
      totalName: '0133 ╴湖口分行'
    },
    {
      bankCodeString: '0520144',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0144',
      branchName: '苑裡分行',
      totalName: '0144 ╴苑裡分行'
    },
    {
      bankCodeString: '0520166',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0166',
      branchName: '龍潭分行',
      totalName: '0166 ╴龍潭分行'
    },
    {
      bankCodeString: '0520177',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0177',
      branchName: '竹北分行',
      totalName: '0177 ╴竹北分行'
    },
    {
      bankCodeString: '0520199',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0199',
      branchName: '三民分行',
      totalName: '0199 ╴三民分行'
    },
    {
      bankCodeString: '0520214',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0214',
      branchName: '八德分行',
      totalName: '0214 ╴八德分行'
    },
    {
      bankCodeString: '0520225',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0225',
      branchName: '新明分行',
      totalName: '0225 ╴新明分行'
    },
    {
      bankCodeString: '0520247',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0247',
      branchName: '公館分行',
      totalName: '0247 ╴公館分行'
    },
    {
      bankCodeString: '0520269',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0269',
      branchName: '後龍分行',
      totalName: '0269 ╴後龍分行'
    },
    {
      bankCodeString: '0520281',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0281',
      branchName: '南崁分行',
      totalName: '0281 ╴南崁分行'
    },
    {
      bankCodeString: '0520328',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0328',
      branchName: '光復分行',
      totalName: '0328 ╴光復分行'
    },
    {
      bankCodeString: '0520351',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0351',
      branchName: '龍岡分行',
      totalName: '0351 ╴龍岡分行'
    },
    {
      bankCodeString: '0520362',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0362',
      branchName: '山子頂分行',
      totalName: '0362 ╴山子頂分行'
    },
    {
      bankCodeString: '0520373',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0373',
      branchName: '埔心分行',
      totalName: '0373 ╴埔心分行'
    },
    {
      bankCodeString: '0520384',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0384',
      branchName: '新豐分行',
      totalName: '0384 ╴新豐分行'
    },
    {
      bankCodeString: '0520454',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0454',
      branchName: '中正分行',
      totalName: '0454 ╴中正分行'
    },
    {
      bankCodeString: '0520476',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0476',
      branchName: '環北分行',
      totalName: '0476 ╴環北分行'
    },
    {
      bankCodeString: '0520513',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0513',
      branchName: '新興分行',
      totalName: '0513 ╴新興分行'
    },
    {
      bankCodeString: '0520524',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0524',
      branchName: '新社分行',
      totalName: '0524 ╴新社分行'
    },
    {
      bankCodeString: '0520579',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0579',
      branchName: '北新竹分行',
      totalName: '0579 ╴北新竹分行'
    },
    {
      bankCodeString: '0520649',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0649',
      branchName: '東內壢分行',
      totalName: '0649 ╴東內壢分行'
    },
    {
      bankCodeString: '0520650',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0650',
      branchName: '信託部',
      totalName: '0650 ╴信託部'
    },
    {
      bankCodeString: '0520661',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0661',
      branchName: '國際金融業務分行',
      totalName: '0661 ╴國際金融業務分行'
    },
    {
      bankCodeString: '0520672',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0672',
      branchName: '新店分行',
      totalName: '0672 ╴新店分行'
    },
    {
      bankCodeString: '0520683',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0683',
      branchName: '公西分行',
      totalName: '0683 ╴公西分行'
    },
    {
      bankCodeString: '0520708',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0708',
      branchName: '莊敬分行',
      totalName: '0708 ╴莊敬分行'
    },
    {
      bankCodeString: '0520719',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0719',
      branchName: '板橋分行',
      totalName: '0719 ╴板橋分行'
    },
    {
      bankCodeString: '0520731',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0731',
      branchName: '豐原分行',
      totalName: '0731 ╴豐原分行'
    },
    {
      bankCodeString: '0520742',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0742',
      branchName: '文心分行',
      totalName: '0742 ╴文心分行'
    },
    {
      bankCodeString: '0520753',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0753',
      branchName: '內湖分行',
      totalName: '0753 ╴內湖分行'
    },
    {
      bankCodeString: '0520764',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0764',
      branchName: '九如分行',
      totalName: '0764 ╴九如分行'
    },
    {
      bankCodeString: '0520775',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0775',
      branchName: '台南分行',
      totalName: '0775 ╴台南分行'
    },
    {
      bankCodeString: '0520797',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0797',
      branchName: '南屯分行',
      totalName: '0797 ╴南屯分行'
    },
    {
      bankCodeString: '0520812',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0812',
      branchName: '東寧分行',
      totalName: '0812 ╴東寧分行'
    },
    {
      bankCodeString: '0520878',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0878',
      branchName: '北屯分行',
      totalName: '0878 ╴北屯分行'
    },
    {
      bankCodeString: '0520889',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0889',
      branchName: '西屯分行',
      totalName: '0889 ╴西屯分行'
    },
    {
      bankCodeString: '0520926',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0926',
      branchName: '彰化分行',
      totalName: '0926 ╴彰化分行'
    },
    {
      bankCodeString: '0520959',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0959',
      branchName: '營業部',
      totalName: '0959 ╴營業部'
    },
    {
      bankCodeString: '0520960',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0960',
      branchName: '北高雄分行',
      totalName: '0960 ╴北高雄分行'
    },
    {
      bankCodeString: '0520971',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0971',
      branchName: '台中分行',
      totalName: '0971 ╴台中分行'
    },
    {
      bankCodeString: '0520982',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '0982',
      branchName: '高雄分行',
      totalName: '0982 ╴高雄分行'
    },
    {
      bankCodeString: '0521015',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '1015',
      branchName: '復興分行',
      totalName: '1015 ╴復興分行'
    },
    {
      bankCodeString: '0521060',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '1060',
      branchName: '三多分行',
      totalName: '1060 ╴三多分行'
    },
    {
      bankCodeString: '0521071',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '1071',
      branchName: '東台南分行',
      totalName: '1071 ╴東台南分行'
    },
    {
      bankCodeString: '0521093',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '1093',
      branchName: '南京分行',
      totalName: '1093 ╴南京分行'
    },
    {
      bankCodeString: '0521118',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '1118',
      branchName: '信義分行',
      totalName: '1118 ╴信義分行'
    },
    {
      bankCodeString: '0521129',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '1129',
      branchName: '敦北分行',
      totalName: '1129 ╴敦北分行'
    },
    {
      bankCodeString: '0521141',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '1141',
      branchName: '敦化分行',
      totalName: '1141 ╴敦化分行'
    },
    {
      bankCodeString: '0521152',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '1152',
      branchName: '天母分行',
      totalName: '1152 ╴天母分行'
    },
    {
      bankCodeString: '0521163',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '1163',
      branchName: '仁愛分行',
      totalName: '1163 ╴仁愛分行'
    },
    {
      bankCodeString: '0521185',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '1185',
      branchName: '中山分行',
      totalName: '1185 ╴中山分行'
    },
    {
      bankCodeString: '0521196',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '1196',
      branchName: '古亭分行',
      totalName: '1196 ╴古亭分行'
    },
    {
      bankCodeString: '0521200',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '1200',
      branchName: '大直分行',
      totalName: '1200 ╴大直分行'
    },
    {
      bankCodeString: '0521211',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '1211',
      branchName: '金山分行',
      totalName: '1211 ╴金山分行'
    },
    {
      bankCodeString: '0529801',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '9801',
      branchName: '電子商務部',
      totalName: '9801 ╴電子商務部'
    },
    {
      bankCodeString: '0529802',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '9802',
      branchName: '房貸及汽車貸款部',
      totalName: '9802 ╴房貸及汽車貸款部'
    },
    {
      bankCodeString: '0529806',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '9806',
      branchName: '桃園逾放中心',
      totalName: '9806 ╴桃園逾放中心'
    },
    {
      bankCodeString: '0529807',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '9807',
      branchName: '中壢逾放中心',
      totalName: '9807 ╴中壢逾放中心'
    },
    {
      bankCodeString: '0529808',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '9808',
      branchName: '新竹逾放中心',
      totalName: '9808 ╴新竹逾放中心'
    },
    {
      bankCodeString: '0529809',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '9809',
      branchName: '苗栗逾放中心',
      totalName: '9809 ╴苗栗逾放中心'
    },
    {
      bankCodeString: '0529820',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '9820',
      branchName: '主力企業金融中心',
      totalName: '9820 ╴主力企業金融中心'
    },
    {
      bankCodeString: '0529830',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '9830',
      branchName: '中小企業金融中心',
      totalName: '9830 ╴中小企業金融中心'
    },
    {
      bankCodeString: '0529840',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '9840',
      branchName: '微型企業金融中心',
      totalName: '9840 ╴微型企業金融中心'
    },
    {
      bankCodeString: '0529850',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '9850',
      branchName: '海外企業金融中心',
      totalName: '9850 ╴海外企業金融中心'
    },
    {
      bankCodeString: '0529984',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '9984',
      branchName: '集團特殊資產管理部',
      totalName: '9984 ╴集團特殊資產管理部'
    },
    {
      bankCodeString: '0529985',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '9985',
      branchName: '消費金融管理處',
      totalName: '9985 ╴消費金融管理處'
    },
    {
      bankCodeString: '0529986',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '9986',
      branchName: '總行第二辦公室',
      totalName: '9986 ╴總行第二辦公室'
    },
    {
      bankCodeString: '0529987',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '9987',
      branchName: '財富管理部',
      totalName: '9987 ╴財富管理部'
    },
    {
      bankCodeString: '0529988',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '9988',
      branchName: '風險管理部',
      totalName: '9988 ╴風險管理部'
    },
    {
      bankCodeString: '0529989',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '9989',
      branchName: '信用卡及個人貸款部',
      totalName: '9989 ╴信用卡及個人貸款部'
    },
    {
      bankCodeString: '0529990',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '9990',
      branchName: '行政管理部',
      totalName: '9990 ╴行政管理部'
    },
    {
      bankCodeString: '0529991',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '9991',
      branchName: '資訊室',
      totalName: '9991 ╴資訊室'
    },
    {
      bankCodeString: '0529992',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '9992',
      branchName: '財務部',
      totalName: '9992 ╴財務部'
    },
    {
      bankCodeString: '0529993',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '9993',
      branchName: '企業金融部',
      totalName: '9993 ╴企業金融部'
    },
    {
      bankCodeString: '0529994',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '9994',
      branchName: '金融業務部',
      totalName: '9994 ╴金融業務部'
    },
    {
      bankCodeString: '0529995',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '9995',
      branchName: '稽核室',
      totalName: '9995 ╴稽核室'
    },
    {
      bankCodeString: '0529996',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '9996',
      branchName: '消費金融管理處帳戶管理部',
      totalName: '9996 ╴消費金融管理處帳戶管理部'
    },
    {
      bankCodeString: '0529997',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '9997',
      branchName: '會計室',
      totalName: '9997 ╴會計室'
    },
    {
      bankCodeString: '0529998',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '9998',
      branchName: '法務室',
      totalName: '9998 ╴法務室'
    },
    {
      bankCodeString: '0529999',
      bankCode: '052',
      bankName: '渣打國際商業銀行',
      branchCode: '9999',
      branchName: '人力資源部',
      totalName: '9999 ╴人力資源部'
    }
  ],
  '053': [
    {
      bankCodeString: '0530001',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0001',
      branchName: '董事會辦公室',
      totalName: '0001 ╴董事會辦公室'
    },
    {
      bankCodeString: '0530002',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0002',
      branchName: '人力資源部',
      totalName: '0002 ╴人力資源部'
    },
    {
      bankCodeString: '0530003',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0003',
      branchName: '總務部',
      totalName: '0003 ╴總務部'
    },
    {
      bankCodeString: '0530004',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0004',
      branchName: '會計部',
      totalName: '0004 ╴會計部'
    },
    {
      bankCodeString: '0530005',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0005',
      branchName: '董事會稽核室',
      totalName: '0005 ╴董事會稽核室'
    },
    {
      bankCodeString: '0530006',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0006',
      branchName: '業務部',
      totalName: '0006 ╴業務部'
    },
    {
      bankCodeString: '0530007',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0007',
      branchName: '資訊部',
      totalName: '0007 ╴資訊部'
    },
    {
      bankCodeString: '0530008',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0008',
      branchName: '行員訓練中心',
      totalName: '0008 ╴行員訓練中心'
    },
    {
      bankCodeString: '0530009',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0009',
      branchName: '逾期放款處理中心',
      totalName: '0009 ╴逾期放款處理中心'
    },
    {
      bankCodeString: '0530010',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0010',
      branchName: '客服中心',
      totalName: '0010 ╴客服中心'
    },
    {
      bankCodeString: '0530012',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0012',
      branchName: '債權管理部',
      totalName: '0012 ╴債權管理部'
    },
    {
      bankCodeString: '0530019',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0019',
      branchName: '中正分行',
      totalName: '0019 ╴中正分行'
    },
    {
      bankCodeString: '053001C',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '001C',
      branchName: '豐原地區區域中心',
      totalName: '001C ╴豐原地區區域中心'
    },
    {
      bankCodeString: '053001F',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '001F',
      branchName: '彰化地區區域中心',
      totalName: '001F ╴彰化地區區域中心'
    },
    {
      bankCodeString: '053001H',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '001H',
      branchName: '北區區域中心',
      totalName: '001H ╴北區區域中心'
    },
    {
      bankCodeString: '053001I',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '001I',
      branchName: '台中縣地區區域中心',
      totalName: '001I ╴台中縣地區區域中心'
    },
    {
      bankCodeString: '053001K',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '001K',
      branchName: '台中地區區域中心',
      totalName: '001K ╴台中地區區域中心'
    },
    {
      bankCodeString: '0530020',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0020',
      branchName: '營業部',
      totalName: '0020 ╴營業部'
    },
    {
      bankCodeString: '0530042',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0042',
      branchName: '南台中分行',
      totalName: '0042 ╴南台中分行'
    },
    {
      bankCodeString: '0530053',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0053',
      branchName: '霧峰分行',
      totalName: '0053 ╴霧峰分行'
    },
    {
      bankCodeString: '0530064',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0064',
      branchName: '大甲分行',
      totalName: '0064 ╴大甲分行'
    },
    {
      bankCodeString: '0530075',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0075',
      branchName: '沙鹿分行',
      totalName: '0075 ╴沙鹿分行'
    },
    {
      bankCodeString: '0530086',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0086',
      branchName: '豐原分行',
      totalName: '0086 ╴豐原分行'
    },
    {
      bankCodeString: '0530097',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0097',
      branchName: '東勢分行',
      totalName: '0097 ╴東勢分行'
    },
    {
      bankCodeString: '0530101',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0101',
      branchName: '彰化分行',
      totalName: '0101 ╴彰化分行'
    },
    {
      bankCodeString: '0530112',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0112',
      branchName: '溪湖分行',
      totalName: '0112 ╴溪湖分行'
    },
    {
      bankCodeString: '0530123',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0123',
      branchName: '員林分行',
      totalName: '0123 ╴員林分行'
    },
    {
      bankCodeString: '0530134',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0134',
      branchName: '田中分行',
      totalName: '0134 ╴田中分行'
    },
    {
      bankCodeString: '0530145',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0145',
      branchName: '北斗分行',
      totalName: '0145 ╴北斗分行'
    },
    {
      bankCodeString: '0530156',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0156',
      branchName: '二林分行',
      totalName: '0156 ╴二林分行'
    },
    {
      bankCodeString: '0530167',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0167',
      branchName: '南投分行',
      totalName: '0167 ╴南投分行'
    },
    {
      bankCodeString: '0530189',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0189',
      branchName: '草屯分行',
      totalName: '0189 ╴草屯分行'
    },
    {
      bankCodeString: '0530190',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0190',
      branchName: '竹山分行',
      totalName: '0190 ╴竹山分行'
    },
    {
      bankCodeString: '0530204',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0204',
      branchName: '埔里分行',
      totalName: '0204 ╴埔里分行'
    },
    {
      bankCodeString: '0530215',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0215',
      branchName: '鹿港分行',
      totalName: '0215 ╴鹿港分行'
    },
    {
      bankCodeString: '0530226',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0226',
      branchName: '西台中分行',
      totalName: '0226 ╴西台中分行'
    },
    {
      bankCodeString: '0530237',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0237',
      branchName: '清水分行',
      totalName: '0237 ╴清水分行'
    },
    {
      bankCodeString: '0530248',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0248',
      branchName: '東豐原分行',
      totalName: '0248 ╴東豐原分行'
    },
    {
      bankCodeString: '0530259',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0259',
      branchName: '烏日分行',
      totalName: '0259 ╴烏日分行'
    },
    {
      bankCodeString: '0530260',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0260',
      branchName: '審查部',
      totalName: '0260 ╴審查部'
    },
    {
      bankCodeString: '0530271',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0271',
      branchName: '北台中分行',
      totalName: '0271 ╴北台中分行'
    },
    {
      bankCodeString: '0530318',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0318',
      branchName: '和美分行',
      totalName: '0318 ╴和美分行'
    },
    {
      bankCodeString: '0530330',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0330',
      branchName: '西屯分行',
      totalName: '0330 ╴西屯分行'
    },
    {
      bankCodeString: '0530341',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0341',
      branchName: '太平分行',
      totalName: '0341 ╴太平分行'
    },
    {
      bankCodeString: '0530363',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0363',
      branchName: '大雅分行',
      totalName: '0363 ╴大雅分行'
    },
    {
      bankCodeString: '0530374',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0374',
      branchName: '后里分行',
      totalName: '0374 ╴后里分行'
    },
    {
      bankCodeString: '0530385',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0385',
      branchName: '潭子分行',
      totalName: '0385 ╴潭子分行'
    },
    {
      bankCodeString: '0530396',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0396',
      branchName: '社頭分行',
      totalName: '0396 ╴社頭分行'
    },
    {
      bankCodeString: '0530400',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0400',
      branchName: '永靖分行',
      totalName: '0400 ╴永靖分行'
    },
    {
      bankCodeString: '0530411',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0411',
      branchName: '花壇分行',
      totalName: '0411 ╴花壇分行'
    },
    {
      bankCodeString: '0530422',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0422',
      branchName: '秀水分行',
      totalName: '0422 ╴秀水分行'
    },
    {
      bankCodeString: '0530444',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0444',
      branchName: '南屯分行',
      totalName: '0444 ╴南屯分行'
    },
    {
      bankCodeString: '0530455',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0455',
      branchName: '大肚分行',
      totalName: '0455 ╴大肚分行'
    },
    {
      bankCodeString: '0530466',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0466',
      branchName: '神岡分行',
      totalName: '0466 ╴神岡分行'
    },
    {
      bankCodeString: '0530477',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0477',
      branchName: '內新分行',
      totalName: '0477 ╴內新分行'
    },
    {
      bankCodeString: '0530488',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0488',
      branchName: '伸港分行',
      totalName: '0488 ╴伸港分行'
    },
    {
      bankCodeString: '0530499',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0499',
      branchName: '北太平分行',
      totalName: '0499 ╴北太平分行'
    },
    {
      bankCodeString: '0530503',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0503',
      branchName: '台中港分行',
      totalName: '0503 ╴台中港分行'
    },
    {
      bankCodeString: '0530514',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0514',
      branchName: '北員林分行',
      totalName: '0514 ╴北員林分行'
    },
    {
      bankCodeString: '0530525',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0525',
      branchName: '南陽分行',
      totalName: '0525 ╴南陽分行'
    },
    {
      bankCodeString: '0530536',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0536',
      branchName: '左營分行',
      totalName: '0536 ╴左營分行'
    },
    {
      bankCodeString: '0530570',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0570',
      branchName: '埤頭分行',
      totalName: '0570 ╴埤頭分行'
    },
    {
      bankCodeString: '0530581',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0581',
      branchName: '四民分行',
      totalName: '0581 ╴四民分行'
    },
    {
      bankCodeString: '0530592',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0592',
      branchName: '軍功分行',
      totalName: '0592 ╴軍功分行'
    },
    {
      bankCodeString: '0530628',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0628',
      branchName: '信託部',
      totalName: '0628 ╴信託部'
    },
    {
      bankCodeString: '0530639',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0639',
      branchName: '北屯分行',
      totalName: '0639 ╴北屯分行'
    },
    {
      bankCodeString: '0530662',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0662',
      branchName: '埔心分行',
      totalName: '0662 ╴埔心分行'
    },
    {
      bankCodeString: '0530673',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0673',
      branchName: '國外部',
      totalName: '0673 ╴國外部'
    },
    {
      bankCodeString: '0530684',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0684',
      branchName: '台北分行',
      totalName: '0684 ╴台北分行'
    },
    {
      bankCodeString: '0530710',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0710',
      branchName: '松山分行',
      totalName: '0710 ╴松山分行'
    },
    {
      bankCodeString: '0530721',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0721',
      branchName: '三重分行',
      totalName: '0721 ╴三重分行'
    },
    {
      bankCodeString: '0530732',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0732',
      branchName: '高雄分行',
      totalName: '0732 ╴高雄分行'
    },
    {
      bankCodeString: '0530754',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0754',
      branchName: '龍井分行',
      totalName: '0754 ╴龍井分行'
    },
    {
      bankCodeString: '0530776',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0776',
      branchName: '林口分行',
      totalName: '0776 ╴林口分行'
    },
    {
      bankCodeString: '0530787',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0787',
      branchName: '虎尾分行',
      totalName: '0787 ╴虎尾分行'
    },
    {
      bankCodeString: '0530798',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0798',
      branchName: '苑裡分行',
      totalName: '0798 ╴苑裡分行'
    },
    {
      bankCodeString: '0530802',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0802',
      branchName: '國際金融業務分行',
      totalName: '0802 ╴國際金融業務分行'
    },
    {
      bankCodeString: '0530813',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0813',
      branchName: '斗南分行',
      totalName: '0813 ╴斗南分行'
    },
    {
      bankCodeString: '0530824',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0824',
      branchName: '竹南分行',
      totalName: '0824 ╴竹南分行'
    },
    {
      bankCodeString: '0530846',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0846',
      branchName: '內湖分行',
      totalName: '0846 ╴內湖分行'
    },
    {
      bankCodeString: '0530857',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0857',
      branchName: '鳳山分行',
      totalName: '0857 ╴鳳山分行'
    },
    {
      bankCodeString: '0530868',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0868',
      branchName: '板橋分行',
      totalName: '0868 ╴板橋分行'
    },
    {
      bankCodeString: '0530880',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0880',
      branchName: '新莊分行',
      totalName: '0880 ╴新莊分行'
    },
    {
      bankCodeString: '0530891',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0891',
      branchName: '民雄分行',
      totalName: '0891 ╴民雄分行'
    },
    {
      bankCodeString: '0530905',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0905',
      branchName: '桃園分行',
      totalName: '0905 ╴桃園分行'
    },
    {
      bankCodeString: '0530916',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0916',
      branchName: '永康分行',
      totalName: '0916 ╴永康分行'
    },
    {
      bankCodeString: '0530927',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0927',
      branchName: '竹北分行',
      totalName: '0927 ╴竹北分行'
    },
    {
      bankCodeString: '0530938',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0938',
      branchName: '南崁分行',
      totalName: '0938 ╴南崁分行'
    },
    {
      bankCodeString: '0530949',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0949',
      branchName: '新竹分行',
      totalName: '0949 ╴新竹分行'
    },
    {
      bankCodeString: '0530950',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0950',
      branchName: '內壢分行',
      totalName: '0950 ╴內壢分行'
    },
    {
      bankCodeString: '0530961',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0961',
      branchName: '新豐分行',
      totalName: '0961 ╴新豐分行'
    },
    {
      bankCodeString: '0530972',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0972',
      branchName: '大園分行',
      totalName: '0972 ╴大園分行'
    },
    {
      bankCodeString: '0530983',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '0983',
      branchName: '中壢分行',
      totalName: '0983 ╴中壢分行'
    },
    {
      bankCodeString: '0531005',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '1005',
      branchName: '楊梅分行',
      totalName: '1005 ╴楊梅分行'
    },
    {
      bankCodeString: '0531016',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '1016',
      branchName: '土城分行',
      totalName: '1016 ╴土城分行'
    },
    {
      bankCodeString: '0531027',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '1027',
      branchName: '財富管理部',
      totalName: '1027 ╴財富管理部'
    },
    {
      bankCodeString: '0531038',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '1038',
      branchName: '復興分行',
      totalName: '1038 ╴復興分行'
    },
    {
      bankCodeString: '0531050',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '1050',
      branchName: '中山分行',
      totalName: '1050 ╴中山分行'
    },
    {
      bankCodeString: '0531061',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '1061',
      branchName: '大同分行',
      totalName: '1061 ╴大同分行'
    },
    {
      bankCodeString: '0531072',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '1072',
      branchName: '台南分行',
      totalName: '1072 ╴台南分行'
    },
    {
      bankCodeString: '0531083',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '1083',
      branchName: '新港分行',
      totalName: '1083 ╴新港分行'
    },
    {
      bankCodeString: '0531094',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '1094',
      branchName: '新店分行',
      totalName: '1094 ╴新店分行'
    },
    {
      bankCodeString: '0531108',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '1108',
      branchName: '燕巢分行',
      totalName: '1108 ╴燕巢分行'
    },
    {
      bankCodeString: '0539986',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '9986',
      branchName: '馬來西亞納閩分行吉隆坡行銷服務處',
      totalName: '9986 ╴馬來西亞納閩分行吉隆坡行銷服務處'
    },
    {
      bankCodeString: '0539997',
      bankCode: '053',
      bankName: '台中商業銀行',
      branchCode: '9997',
      branchName: '馬來西亞納閩分行',
      totalName: '9997 ╴馬來西亞納閩分行'
    }
  ],
  '054': [
    {
      bankCodeString: '0540001',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0001',
      branchName: '會計室',
      totalName: '0001 ╴會計室'
    },
    {
      bankCodeString: '0540002',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0002',
      branchName: '秘書室',
      totalName: '0002 ╴秘書室'
    },
    {
      bankCodeString: '0540004',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0004',
      branchName: '人力資源室',
      totalName: '0004 ╴人力資源室'
    },
    {
      bankCodeString: '0540005',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0005',
      branchName: '消金審查部',
      totalName: '0005 ╴消金審查部'
    },
    {
      bankCodeString: '0540006',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0006',
      branchName: '授信審查部',
      totalName: '0006 ╴授信審查部'
    },
    {
      bankCodeString: '0540010',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0010',
      branchName: '營業部',
      totalName: '0010 ╴營業部'
    },
    {
      bankCodeString: '0540012',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0012',
      branchName: '資訊室',
      totalName: '0012 ╴資訊室'
    },
    {
      bankCodeString: '0540013',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0013',
      branchName: '稽核室',
      totalName: '0013 ╴稽核室'
    },
    {
      bankCodeString: '0540014',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0014',
      branchName: '董事會',
      totalName: '0014 ╴董事會'
    },
    {
      bankCodeString: '0540015',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0015',
      branchName: '債權管理部',
      totalName: '0015 ╴債權管理部'
    },
    {
      bankCodeString: '0540016',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0016',
      branchName: '信用卡部',
      totalName: '0016 ╴信用卡部'
    },
    {
      bankCodeString: '0540017',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0017',
      branchName: '消費金融部',
      totalName: '0017 ╴消費金融部'
    },
    {
      bankCodeString: '0540018',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0018',
      branchName: '中小企業金融部',
      totalName: '0018 ╴中小企業金融部'
    },
    {
      bankCodeString: '0540019',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0019',
      branchName: '財富管理部',
      totalName: '0019 ╴財富管理部'
    },
    {
      bankCodeString: '0540020',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0020',
      branchName: '財務部',
      totalName: '0020 ╴財務部'
    },
    {
      bankCodeString: '0540022',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0022',
      branchName: '企劃部',
      totalName: '0022 ╴企劃部'
    },
    {
      bankCodeString: '0540023',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0023',
      branchName: '保險行銷部',
      totalName: '0023 ╴保險行銷部'
    },
    {
      bankCodeString: '0540024',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0024',
      branchName: '金融服務部',
      totalName: '0024 ╴金融服務部'
    },
    {
      bankCodeString: '0540032',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0032',
      branchName: '台南分行',
      totalName: '0032 ╴台南分行'
    },
    {
      bankCodeString: '0540043',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0043',
      branchName: '嘉義分行',
      totalName: '0043 ╴嘉義分行'
    },
    {
      bankCodeString: '0540054',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0054',
      branchName: '新營分行',
      totalName: '0054 ╴新營分行'
    },
    {
      bankCodeString: '0540065',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0065',
      branchName: '鹽水分行',
      totalName: '0065 ╴鹽水分行'
    },
    {
      bankCodeString: '0540076',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0076',
      branchName: '虎尾分行',
      totalName: '0076 ╴虎尾分行'
    },
    {
      bankCodeString: '0540087',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0087',
      branchName: '斗六分行',
      totalName: '0087 ╴斗六分行'
    },
    {
      bankCodeString: '0540098',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0098',
      branchName: '民雄分行',
      totalName: '0098 ╴民雄分行'
    },
    {
      bankCodeString: '0540102',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0102',
      branchName: '北港分行',
      totalName: '0102 ╴北港分行'
    },
    {
      bankCodeString: '0540113',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0113',
      branchName: '麻豆分行',
      totalName: '0113 ╴麻豆分行'
    },
    {
      bankCodeString: '0540124',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0124',
      branchName: '朴子分行',
      totalName: '0124 ╴朴子分行'
    },
    {
      bankCodeString: '0540135',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0135',
      branchName: '佳里分行',
      totalName: '0135 ╴佳里分行'
    },
    {
      bankCodeString: '0540146',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0146',
      branchName: '梅山分行',
      totalName: '0146 ╴梅山分行'
    },
    {
      bankCodeString: '0540157',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0157',
      branchName: '斗南分行',
      totalName: '0157 ╴斗南分行'
    },
    {
      bankCodeString: '0540168',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0168',
      branchName: '數位服務暨業務部',
      totalName: '0168 ╴數位服務暨業務部'
    },
    {
      bankCodeString: '0540180',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0180',
      branchName: '東台南分行',
      totalName: '0180 ╴東台南分行'
    },
    {
      bankCodeString: '0540227',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0227',
      branchName: '西螺分行',
      totalName: '0227 ╴西螺分行'
    },
    {
      bankCodeString: '0540249',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0249',
      branchName: '善化分行',
      totalName: '0249 ╴善化分行'
    },
    {
      bankCodeString: '0540250',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0250',
      branchName: '學甲分行',
      totalName: '0250 ╴學甲分行'
    },
    {
      bankCodeString: '0540272',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0272',
      branchName: '開元分行',
      totalName: '0272 ╴開元分行'
    },
    {
      bankCodeString: '0540283',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0283',
      branchName: '永康分行',
      totalName: '0283 ╴永康分行'
    },
    {
      bankCodeString: '0540294',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0294',
      branchName: '新市分行',
      totalName: '0294 ╴新市分行'
    },
    {
      bankCodeString: '0540308',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0308',
      branchName: '仁德分行',
      totalName: '0308 ╴仁德分行'
    },
    {
      bankCodeString: '0540319',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0319',
      branchName: '六甲分行',
      totalName: '0319 ╴六甲分行'
    },
    {
      bankCodeString: '0540320',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0320',
      branchName: '安南分行',
      totalName: '0320 ╴安南分行'
    },
    {
      bankCodeString: '0540331',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0331',
      branchName: '中埔分行',
      totalName: '0331 ╴中埔分行'
    },
    {
      bankCodeString: '0540342',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0342',
      branchName: '崙背分行',
      totalName: '0342 ╴崙背分行'
    },
    {
      bankCodeString: '0540353',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0353',
      branchName: '西港分行',
      totalName: '0353 ╴西港分行'
    },
    {
      bankCodeString: '0540364',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0364',
      branchName: '新化分行',
      totalName: '0364 ╴新化分行'
    },
    {
      bankCodeString: '0540375',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0375',
      branchName: '竹崎分行',
      totalName: '0375 ╴竹崎分行'
    },
    {
      bankCodeString: '0540386',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0386',
      branchName: '信託部',
      totalName: '0386 ╴信託部'
    },
    {
      bankCodeString: '0540397',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0397',
      branchName: '安和分行',
      totalName: '0397 ╴安和分行'
    },
    {
      bankCodeString: '0540401',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0401',
      branchName: '新興分行',
      totalName: '0401 ╴新興分行'
    },
    {
      bankCodeString: '0540412',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0412',
      branchName: '中華分行',
      totalName: '0412 ╴中華分行'
    },
    {
      bankCodeString: '0540434',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0434',
      branchName: '白河分行',
      totalName: '0434 ╴白河分行'
    },
    {
      bankCodeString: '0540445',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0445',
      branchName: '興業分行',
      totalName: '0445 ╴興業分行'
    },
    {
      bankCodeString: '0540467',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0467',
      branchName: '水上分行',
      totalName: '0467 ╴水上分行'
    },
    {
      bankCodeString: '0540489',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0489',
      branchName: '府城分行',
      totalName: '0489 ╴府城分行'
    },
    {
      bankCodeString: '0540504',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0504',
      branchName: '歸仁分行',
      totalName: '0504 ╴歸仁分行'
    },
    {
      bankCodeString: '0540515',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0515',
      branchName: '鹽行分行',
      totalName: '0515 ╴鹽行分行'
    },
    {
      bankCodeString: '0540537',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0537',
      branchName: '台北分行',
      totalName: '0537 ╴台北分行'
    },
    {
      bankCodeString: '0540548',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0548',
      branchName: '大林分行',
      totalName: '0548 ╴大林分行'
    },
    {
      bankCodeString: '0540607',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0607',
      branchName: '關廟分行',
      totalName: '0607 ╴關廟分行'
    },
    {
      bankCodeString: '0540641',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0641',
      branchName: '太保分行',
      totalName: '0641 ╴太保分行'
    },
    {
      bankCodeString: '0540652',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0652',
      branchName: '蘆洲分行',
      totalName: '0652 ╴蘆洲分行'
    },
    {
      bankCodeString: '0540663',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0663',
      branchName: '玉井分行',
      totalName: '0663 ╴玉井分行'
    },
    {
      bankCodeString: '0540674',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0674',
      branchName: '國外部',
      totalName: '0674 ╴國外部'
    },
    {
      bankCodeString: '0540685',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0685',
      branchName: '國際金融業務分行',
      totalName: '0685 ╴國際金融業務分行'
    },
    {
      bankCodeString: '0540696',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0696',
      branchName: '高雄分行',
      totalName: '0696 ╴高雄分行'
    },
    {
      bankCodeString: '0540700',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0700',
      branchName: '台中分行',
      totalName: '0700 ╴台中分行'
    },
    {
      bankCodeString: '0540711',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0711',
      branchName: '內湖分行',
      totalName: '0711 ╴內湖分行'
    },
    {
      bankCodeString: '0540744',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0744',
      branchName: '桃園分行',
      totalName: '0744 ╴桃園分行'
    },
    {
      bankCodeString: '0540755',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0755',
      branchName: '雙和分行',
      totalName: '0755 ╴雙和分行'
    },
    {
      bankCodeString: '0540766',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0766',
      branchName: '岡山分行',
      totalName: '0766 ╴岡山分行'
    },
    {
      bankCodeString: '0540777',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0777',
      branchName: '中正分行',
      totalName: '0777 ╴中正分行'
    },
    {
      bankCodeString: '0540788',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0788',
      branchName: '忠孝分行',
      totalName: '0788 ╴忠孝分行'
    },
    {
      bankCodeString: '0540799',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0799',
      branchName: '北高雄分行',
      totalName: '0799 ╴北高雄分行'
    },
    {
      bankCodeString: '0540814',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0814',
      branchName: '板橋分行',
      totalName: '0814 ╴板橋分行'
    },
    {
      bankCodeString: '0540825',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0825',
      branchName: '文心分行',
      totalName: '0825 ╴文心分行'
    },
    {
      bankCodeString: '0540836',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0836',
      branchName: '松山分行',
      totalName: '0836 ╴松山分行'
    },
    {
      bankCodeString: '0540847',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0847',
      branchName: '新莊分行',
      totalName: '0847 ╴新莊分行'
    },
    {
      bankCodeString: '0540858',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0858',
      branchName: '新竹分行',
      totalName: '0858 ╴新竹分行'
    },
    {
      bankCodeString: '0540869',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0869',
      branchName: '中壢分行',
      totalName: '0869 ╴中壢分行'
    },
    {
      bankCodeString: '0540870',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0870',
      branchName: '新店分行',
      totalName: '0870 ╴新店分行'
    },
    {
      bankCodeString: '0540881',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0881',
      branchName: '南崁分行',
      totalName: '0881 ╴南崁分行'
    },
    {
      bankCodeString: '0540892',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0892',
      branchName: '大里分行',
      totalName: '0892 ╴大里分行'
    },
    {
      bankCodeString: '0540906',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0906',
      branchName: '楠梓分行',
      totalName: '0906 ╴楠梓分行'
    },
    {
      bankCodeString: '0540917',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0917',
      branchName: '彰化分行',
      totalName: '0917 ╴彰化分行'
    },
    {
      bankCodeString: '0540928',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0928',
      branchName: '東新竹分行',
      totalName: '0928 ╴東新竹分行'
    },
    {
      bankCodeString: '0540939',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '0939',
      branchName: '裕農分行',
      totalName: '0939 ╴裕農分行'
    },
    {
      bankCodeString: '0548300',
      bankCode: '054',
      bankName: '京城商業銀行',
      branchCode: '8300',
      branchName: '兼營證券商',
      totalName: '8300 ╴兼營證券商'
    }
  ],
  '081': [
    {
      bankCodeString: '0810001',
      bankCode: '081',
      bankName: '滙豐(台灣)商業銀行',
      branchCode: '0001',
      branchName: '管理部',
      totalName: '0001 ╴管理部'
    },
    {
      bankCodeString: '0810002',
      bankCode: '081',
      bankName: '滙豐(台灣)商業銀行',
      branchCode: '0002',
      branchName: '證券服務部',
      totalName: '0002 ╴證券服務部'
    },
    {
      bankCodeString: '0810016',
      bankCode: '081',
      bankName: '滙豐(台灣)商業銀行',
      branchCode: '0016',
      branchName: '台北分行',
      totalName: '0016 ╴台北分行'
    },
    {
      bankCodeString: '0810027',
      bankCode: '081',
      bankName: '滙豐(台灣)商業銀行',
      branchCode: '0027',
      branchName: '高雄分行',
      totalName: '0027 ╴高雄分行'
    },
    {
      bankCodeString: '0810038',
      bankCode: '081',
      bankName: '滙豐(台灣)商業銀行',
      branchCode: '0038',
      branchName: '國際金融業務分行',
      totalName: '0038 ╴國際金融業務分行'
    },
    {
      bankCodeString: '0810049',
      bankCode: '081',
      bankName: '滙豐(台灣)商業銀行',
      branchCode: '0049',
      branchName: '台中分行',
      totalName: '0049 ╴台中分行'
    },
    {
      bankCodeString: '0810050',
      bankCode: '081',
      bankName: '滙豐(台灣)商業銀行',
      branchCode: '0050',
      branchName: '台南分行',
      totalName: '0050 ╴台南分行'
    },
    {
      bankCodeString: '0810061',
      bankCode: '081',
      bankName: '滙豐(台灣)商業銀行',
      branchCode: '0061',
      branchName: '板橋分行',
      totalName: '0061 ╴板橋分行'
    },
    {
      bankCodeString: '0810072',
      bankCode: '081',
      bankName: '滙豐(台灣)商業銀行',
      branchCode: '0072',
      branchName: '桃園分行',
      totalName: '0072 ╴桃園分行'
    },
    {
      bankCodeString: '0810083',
      bankCode: '081',
      bankName: '滙豐(台灣)商業銀行',
      branchCode: '0083',
      branchName: '建國分行',
      totalName: '0083 ╴建國分行'
    },
    {
      bankCodeString: '0810094',
      bankCode: '081',
      bankName: '滙豐(台灣)商業銀行',
      branchCode: '0094',
      branchName: '天母分行',
      totalName: '0094 ╴天母分行'
    },
    {
      bankCodeString: '0810108',
      bankCode: '081',
      bankName: '滙豐(台灣)商業銀行',
      branchCode: '0108',
      branchName: '東門分行',
      totalName: '0108 ╴東門分行'
    },
    {
      bankCodeString: '0810120',
      bankCode: '081',
      bankName: '滙豐(台灣)商業銀行',
      branchCode: '0120',
      branchName: '光復分行',
      totalName: '0120 ╴光復分行'
    },
    {
      bankCodeString: '0810164',
      bankCode: '081',
      bankName: '滙豐(台灣)商業銀行',
      branchCode: '0164',
      branchName: '新板分行',
      totalName: '0164 ╴新板分行'
    },
    {
      bankCodeString: '0810186',
      bankCode: '081',
      bankName: '滙豐(台灣)商業銀行',
      branchCode: '0186',
      branchName: '古亭分行',
      totalName: '0186 ╴古亭分行'
    },
    {
      bankCodeString: '0810197',
      bankCode: '081',
      bankName: '滙豐(台灣)商業銀行',
      branchCode: '0197',
      branchName: '苓雅分行',
      totalName: '0197 ╴苓雅分行'
    },
    {
      bankCodeString: '0810201',
      bankCode: '081',
      bankName: '滙豐(台灣)商業銀行',
      branchCode: '0201',
      branchName: '南港分行',
      totalName: '0201 ╴南港分行'
    },
    {
      bankCodeString: '0810212',
      bankCode: '081',
      bankName: '滙豐(台灣)商業銀行',
      branchCode: '0212',
      branchName: '林口分行',
      totalName: '0212 ╴林口分行'
    },
    {
      bankCodeString: '0810223',
      bankCode: '081',
      bankName: '滙豐(台灣)商業銀行',
      branchCode: '0223',
      branchName: '新竹分行',
      totalName: '0223 ╴新竹分行'
    },
    {
      bankCodeString: '0810234',
      bankCode: '081',
      bankName: '滙豐(台灣)商業銀行',
      branchCode: '0234',
      branchName: '松江分行',
      totalName: '0234 ╴松江分行'
    },
    {
      bankCodeString: '0810304',
      bankCode: '081',
      bankName: '滙豐(台灣)商業銀行',
      branchCode: '0304',
      branchName: '安和分行',
      totalName: '0304 ╴安和分行'
    },
    {
      bankCodeString: '0810315',
      bankCode: '081',
      bankName: '滙豐(台灣)商業銀行',
      branchCode: '0315',
      branchName: '中壢分行',
      totalName: '0315 ╴中壢分行'
    },
    {
      bankCodeString: '0810326',
      bankCode: '081',
      bankName: '滙豐(台灣)商業銀行',
      branchCode: '0326',
      branchName: '仁愛分行',
      totalName: '0326 ╴仁愛分行'
    },
    {
      bankCodeString: '0810337',
      bankCode: '081',
      bankName: '滙豐(台灣)商業銀行',
      branchCode: '0337',
      branchName: '敦北分行',
      totalName: '0337 ╴敦北分行'
    },
    {
      bankCodeString: '0810360',
      bankCode: '081',
      bankName: '滙豐(台灣)商業銀行',
      branchCode: '0360',
      branchName: '內湖分行',
      totalName: '0360 ╴內湖分行'
    },
    {
      bankCodeString: '0810382',
      bankCode: '081',
      bankName: '滙豐(台灣)商業銀行',
      branchCode: '0382',
      branchName: '復興分行',
      totalName: '0382 ╴復興分行'
    },
    {
      bankCodeString: '0810511',
      bankCode: '081',
      bankName: '滙豐(台灣)商業銀行',
      branchCode: '0511',
      branchName: '國美分行',
      totalName: '0511 ╴國美分行'
    },
    {
      bankCodeString: '0810522',
      bankCode: '081',
      bankName: '滙豐(台灣)商業銀行',
      branchCode: '0522',
      branchName: '敦南分行',
      totalName: '0522 ╴敦南分行'
    },
    {
      bankCodeString: '0810533',
      bankCode: '081',
      bankName: '滙豐(台灣)商業銀行',
      branchCode: '0533',
      branchName: '大興分行',
      totalName: '0533 ╴大興分行'
    },
    {
      bankCodeString: '0810566',
      bankCode: '081',
      bankName: '滙豐(台灣)商業銀行',
      branchCode: '0566',
      branchName: '大直分行',
      totalName: '0566 ╴大直分行'
    },
    {
      bankCodeString: '0810577',
      bankCode: '081',
      bankName: '滙豐(台灣)商業銀行',
      branchCode: '0577',
      branchName: '士林分行',
      totalName: '0577 ╴士林分行'
    },
    {
      bankCodeString: '0810588',
      bankCode: '081',
      bankName: '滙豐(台灣)商業銀行',
      branchCode: '0588',
      branchName: '蘆洲分行',
      totalName: '0588 ╴蘆洲分行'
    },
    {
      bankCodeString: '0810599',
      bankCode: '081',
      bankName: '滙豐(台灣)商業銀行',
      branchCode: '0599',
      branchName: '崇德分行',
      totalName: '0599 ╴崇德分行'
    }
  ]
}