import { createRouter, createWebHistory } from 'vue-router'

import store from '../store/index'

// 手機板 與 電腦版 會員中心會有差異所會做component變化
let viewType = 'web'
let memberDefaultUrl = 'AccountOverviewView.vue'
let seller_delivery = 'SellerDeliveryView.vue'
if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
  viewType = 'mobile'
  memberDefaultUrl = 'IndexView.vue'
  seller_delivery  = 'SellerDeliveryView.vue'
}

// 先檢查跳頁的網址
let WebHistoryName = '';
const urlPathName = window.location.pathname;
const urlShop = urlPathName.split('/')[1];
const refCode = new URL(location.href).searchParams.get('refcode')
// 判斷子商城session
if(sessionStorage.getItem('subMallStr')){
  // 如果商城名稱存在
  // 檢查是否有新帶的shop_id
  if(urlShop.indexOf('shop_') != -1 ){
      WebHistoryName = urlShop;
      sessionStorage.setItem('subMallStr', WebHistoryName);
  }else{
    WebHistoryName = sessionStorage.getItem('subMallStr');
    if(!urlShop){
      sessionStorage.removeItem("subMallStr");
      WebHistoryName = "";
    }
  }
}else{
  // 如果還沒進過子商城
  if(urlShop.indexOf('shop_') != -1 ){
      WebHistoryName = urlShop;
      sessionStorage.setItem('subMallStr', WebHistoryName);
  }
}

if(refCode){
  sessionStorage.setItem('refcode', refCode);
}
// 判斷推薦人


let shopDefaultUrl = 'Index.vue';
// 如果確認有子商城 那首頁將會被替換掉
if(sessionStorage.getItem('subMallStr')){
  shopDefaultUrl = 'SubMallView.vue';
}

const routes = [
  {
    path: '/',
    name: 'base',
    component: () => import(`../views/${viewType}/PageBasic.vue`),
    children: [
      {
        // 首頁
        path: '/',
        name: 'home',
        component: () => import(`../views/${viewType}/${shopDefaultUrl}`)
      },
      {
        // 商品列表
        path: '/product',
        name: 'product',
        query: { parent_categry_id: '' },
        component: () => import(`../views/${viewType}/ProductView.vue`)
      },
      {
        // 商品詳情
        path: '/product/details',
        name: 'productDetails',
        component: () => import(`../views/${viewType}/ProductDetailsView.vue`)
      },
      {
        // 子商品詳情
        path: '/product/detailsChild',
        name: 'detailsChild',
        component: () => import(`../views/${viewType}/ProductDetailsChildView.vue`)
      },
      {
        // 購物車
        path: '/select_cart',
        name: 'select_cart',
        component: () => import(`../views/${viewType}/SelectCartView.vue`)
      },
      {
        // 購物車
        path: '/cart',
        name: 'cart',
        component: () => import(`../views/${viewType}/CartView.vue`)
      },
      {
        // 訂單完成
        path: '/orderfinish',
        name: 'orderfinish',
        component: () => import(`../views/${viewType}/OrderFinishView.vue`)
      },
      {
        // 最新消息
        path: '/news',
        name: 'news',
        component: () => import(`../views/${viewType}/NewsView.vue`)
      },
      {
        // 最新消息詳情
        path: '/news/details',
        name: 'newsDetails',
        component: () => import(`../views/${viewType}/NewsDetailsView.vue`)
      },
      {
        // 最新消息
        path: '/content',
        name: 'content',
        component: () => import(`../views/${viewType}/ContentView.vue`)
      },
      {
        // 活動公告
        path: '/activity',
        name: 'activity',
        component: () => import(`../views/${viewType}/ActivityView.vue`)

      },
      {
        // 活動公告詳情
        path: '/activity/details',
        name: 'activitydetails',
        component: () => import(`../views/${viewType}/ActivityDetailsView.vue`)

      },
      {
        // 聯絡我們
        path: '/contactus',
        name: 'contactus',
        component: () => import(`../views/${viewType}/ContactUsView.vue`)

      },
      {
        // 隱私條款
        path: '/terms',
        name: 'terms',
        component: () => import(`../views/${viewType}/TermsView.vue`)

      },
      {
        // 陌生客查詢訂單
        path: '/stranger',
        name: 'strangerinquiry',
        component: () => import(`../views/${viewType}/StrangerView.vue`)

      },
      {
        // 經銷商註冊
        path: '/register',
        name: 'register',
        component: () => import(`../views/${viewType}/RegisterView.vue`)
      },
      {
        // 經銷商登入
        path: '/login',
        name: 'login',
        component: () => import(`../views/${viewType}/LoginView.vue`)
      },
      {
        // 忘記密碼
        path: '/password',
        name: 'password',
        component: () => import(`../views/${viewType}/PasswordView.vue`)
      },
      {
        // 手機版index
        path: '/phome',
        name: 'phome',
        component: () => import(`../views/${viewType}/PhomeView.vue`)
      },
      // 訂單查詢
      {
        path: '/order_search',
        name: 'order_search',
        component: () => import(`../views/${viewType}/OrderSearchView.vue`)
      },
      // 訂單查詢結果
      {
        path: '/order_detail',
        name: 'order_detail',
        component: () => import(`../views/${viewType}/OrderDetailView.vue`)
      },
      // 訂單查詢結果
      {
        path: '/order_delivery',
        name: 'order_delivery',
        component: () => import(`../views/${viewType}/OrderDeliveryView.vue`)
      },
      // {
      //   path: '/liff/liff_login',
      //   name: 'liff_login',
      //   component: () => import(`../views/${viewType}/liff/liffLogin_1.vue`)
      // },
      // {
      //   path: '/test',
      //   name: 'test',
      //   component: () => import(`../views/${viewType}/SubMallView.vue`)
      // },
      getMember(),
      getLiff(),
      getDealer()
    ]
  }
]
function getLiff () {
  const liffObject = {
    path: `/liff`,
    name: `liff_base`,
    // component: () => import(`../views/${viewType}/member/MemberBasic.vue`),
    children: [
      {
        // 首頁
        path: '/liff/liff_login_1',
        name: 'liff_login_home',
        component: () => import(`../views/liff/liffLogin_1.vue`)
      },
      {
        // 首頁->商品頁
        path: '/liff/liff_login_2',
        name: 'liff_login_product',
        component: () => import(`../views/liff/liffLogin_2.vue`)
      },
      {
        // 會員中心->訂單
        path: '/liff/liff_login_3',
        name: 'liff_login_order',
        component: () => import(`../views/liff/liffLogin_3.vue`)
      },
      {
        // 會員中心->獎金
        path: '/liff/liff_login_4',
        name: 'liff_login_bonus',
        component: () => import(`../views/liff/liffLogin_4.vue`)
      },
      {
        // 會員中心->分享賺現金
        path: '/liff/liff_login_5',
        name: 'liff_login_share',
        component: () => import(`../views/liff/liffLogin_5.vue`)
      },
      {
        // 會員中心->分享賺現金
        path: '/liff/Liff_AccountOverviewView',
        name: 'Liff_AccountOverviewView',
        component: () => import(`../views/mobile/member/Liff_AccountOverviewView.vue`)
      }
    ]
  }
  return liffObject

}



function getMember () {
  const memberObject = {
    path: `/member`,
    name: `member_base`,
    component: () => import(`../views/${viewType}/member/MemberBasic.vue`),
    children: [
      {
        // 會員中心
        path: `/member/account_overview`,
        name: `member_account_overview`,
        component: () => import(`../views/${viewType}/member/AccountOverviewView.vue`)
      },
      {
        // 會員中心
        path: `/member`,
        name: `member_account`,
        component: () => import(`../views/${viewType}/member/${memberDefaultUrl}`)
        // component: () => import(`../views/${viewType}/member/AccountView.vue`)
      },
      {
        // 修改密碼
        path: `/member/password`,
        name: `member_password`,
        component: () => import(`../views/${viewType}/member/PasswordView.vue`)
        // component: () => import(`../views/${viewType}/member/AccountView.vue`)
      },
      {
        // 常用地址
        path: `/member/address`,
        name: `member_address`,
        component: () => import(`../views/${viewType}/member/AddressView.vue`)
      },
      {
        //  通知總覽
        path: `/member/notice`,
        name: `member_notice`,
        component: () => import(`../views/${viewType}/member/NoticeView.vue`)
      },
      {
        // 購買清單
        path: `/member/buy_list`,
        name: `member_buy_list`,
        component: () => import(`../views/${viewType}/member/BuyListView.vue`)
      },
      {
        // 我的抽獎券
        path: `/member/my_ticket`,
        name: `member_my_ticket`,
        component: () => import(`../views/${viewType}/member/MyTicketView.vue`)
      },
      {
        // 我的抽獎券
        path: `/member/coupon`,
        name: `member_coupon`,
        component: () => import(`../views/${viewType}/member/CouponView.vue`)
      },
      {
        // 我的錢包
        path: `/member/wallet`,
        name: `member_wallet`,
        component: () => import(`../views/${viewType}/member/WalletView.vue`)
      },
      {
        path: `/member/point`,
        name: `member_point`,
        component: () => import(`../views/${viewType}/member/PointView.vue`)
      },
      {
        // 推薦圖
        path: `/member/refer`,
        name: `member_refer`,
        component: () => import(`../views/${viewType}/member/ReferView.vue`)
      },
      {
        // 分享專區
        path: `/member/share`,
        name: `member_share`,
        component: () => import(`../views/${viewType}/member/ShareView.vue`)
      },
      {
        // 推薦列表
        path: `/member/refer_list`,
        name: `member_refer_list`,
        component: () => import(`../views/${viewType}/member/ReferListView.vue`)
      },
      {
        // 月獎金
        path: `/member/month_point`,
        name: `member_month_point`,
        component: () => import(`../views/${viewType}/member/MonthPointView.vue`)
      },
      {
        // 賬號綁定
        path: `/member/account_binding`,
        name: `member_account_binding`,
        component: () => import(`../views/${viewType}/member/AccountBindingView.vue`)
      },
      {
        // 訂單詳情
        path: `/member/order_detail`,
        name: `member_order_detail`,
        component: () => import(`../views/${viewType}/member/OrderDetailView.vue`)
      },
      {
        // 配送進度
        path: `/member/delivery_progress`,
        name: `member_delivery_progress`,
        component: () => import(`../views/${viewType}/member/DeliveryProgressView.vue`)
      },
      // {
      //   // 經銷商雲倉庫 ok
      //   path: `/member/depot_cloud`,
      //   name: `member_depot_cloud`,
      //   component: () => import(`../views/${viewType}/member/DepotCloudView.vue`)
      // },
      // {
      //   // 經銷商雲倉出入存紀錄 ok
      //   path: `/member/depot_record`,
      //   name: `member_depot_record`,
      //   component: () => import(`../views/${viewType}/member/DepotRecordView.vue`)
      // },
      // {
      //   // 經銷商雲倉庫 – 提貨 ok
      //   path: `/member/pick_up`,
      //   name: `member_pick_up`,
      //   component: () => import(`../views/${viewType}/member/PickUpView.vue`)
      // },
      // {
      //   // 經銷商雲倉庫 – 提貨申請紀錄
      //   path: `/member/pickup_record`,
      //   name: `member_pickup_record`,
      //   component: () => import(`../views/${viewType}/member/PickUpRecordView.vue`)
      // },
      {
        // 經銷商雲倉庫 – 經銷商個人倉庫 – 1 ok
        path: `/member/i_depot`,
        name: `member_i_depot`,
        component: () => import(`../views/${viewType}/member/IDepotView.vue`)
      },
      {
        // 經銷商雲倉庫 – 經銷商個人倉出入存紀錄 ok
        path: `/member/idepot_stock`,
        name: `member_idepot_stock`,
        component: () => import(`../views/${viewType}/member/IDepotStockView.vue`)
      },
      {
        // 經銷商-我是賣家 - 全部
        path: `/member/seller`,
        name: `member_seller`,
        component: () => import(`../views/${viewType}/member/SellerView.vue`)
      },
      {
        // 經銷商--經銷商-我是賣家 -訂單詳情
        path: `/member/seller_detail`,
        name: `member_seller_detail`,
        component: () => import(`../views/${viewType}/member/SellerDetailView.vue`)
      },
      {
        // 經銷商--經銷商-我是賣家 -配送資訊
        path: `/member/seller_delivery`,
        name: `member_seller_delivery`,
        component: () => import(`../views/${viewType}/member/${seller_delivery}`)
      },
      // {
      //   // 經銷商雲倉庫 – 換貨申請紀錄
      //   path: `/member/exchange`,
      //   name: `member_exchange`,
      //   component: () => import(`../views/${viewType}/member/ExchangeView.vue`)
      // },
      // {
      //   //  經銷商雲倉庫 – 換貨申請
      //   path: `/member/ex_request`,
      //   name: `member_ex_request`,
      //   component: () => import(`../views/${viewType}/member/ExRequestView.vue`)
      // },
      {
        // 會員中心
        path: `/member/account_share`,
        name: `member_account_share`,
        component: () => import(`../views/${viewType}/member/AccountShareView.vue`)
      }
    ]
  }
  return memberObject
}

function getDealer () {
  const dealerObject = {
    path: `/dealer`,
    name: `dealer_base`,
    // component: () => import(`../views/${viewType}/member/MemberBasic.vue`),
    children: [
      {
        // 總列表
        path: '/dealer/list',
        name: 'dealer_list',
        component: () => import(`../views/web/DealerListView.vue`)
      },
      {
        // 子列表
        path: '/dealer/sub_list',
        name: 'dealer_sub_list',
        component: () => import(`../views/web/DealerListSubView.vue`)
      }
      ,
      {
        // 產品詳情
        path: '/dealer/product_detail',
        name: 'dealer_product_detail',
        component: () => import(`../views/web/DealerProductDetailsView.vue`)
      }
    ]
  }
  return dealerObject

}
const router = createRouter({
  history: createWebHistory(WebHistoryName),
  routes
})


// 在跳頁前的動作
router.beforeEach(function (to, from, next) {

  let token = store.state.token
  if (to['name'].includes('member') == 1) {
      if (token === '') {
        next({ path: `/login` })
      } else {
        next()
      }
  } else {
    next()
  }
  setTimeout(() => {
    window.scrollTo(0, 0)
  }, 100)
})


export default router
