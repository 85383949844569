<template>
   <v-select :clearable="false"  v-model="selectBank.bankCode"  label="totalName" :reduce="(options) => options.bankCode"  placeholder="請選擇銀行"
            :options="bankArray">
   </v-select>
</template>
<script>
import { bankData } from '../../../assets/js/bankCode.js'
import { computed, reactive, ref, watch } from 'vue'
export default {
  props: {
    defaultData: {
      type: Object,
      default: function () {
        return {
          bankCode: '',
          bankName: '',
        }
      }
    }
  },
  setup (props, { emit }) {
    const bankArray = ref(bankData)
    const selectBank = computed({
      get: () => reactive(props.defaultData),
      set: val => emit('update:defaultData', val)
    })
    watch(selectBank, () => {
      const realBankName = bankArray.value.filter(function(value) {
        return value.bankCode == selectBank.value.bankCode
      });
      selectBank.value.bankName = realBankName[0].bankName
      selectBank.value.bankCode = realBankName[0].bankCode
    }, { deep: true });

    return {
      bankArray,
      selectBank
    }
  },
  methods: {
    setSelected(value)
    { 
      console.log(12312312312)
      console.log(value)
        //  trigger a mutation, or dispatch an action  
    }
}
}
</script>
